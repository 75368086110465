import { post, get, del, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

// users
export const getUserList = (params) => get(`/user?page=${params.page}&size=1000`);
export const getUserListContent = (params) =>
  get(`/user?page=${params?.page == null ? 0 : params.page}&size=1000&${toQueryParams(params)}`).then(
    (data) => data.content
  );
export const deleteUser = (userId) => del(`/user/${userId}`);
export const editUser = (userId, data) => put(`/user/${userId}`, data);
export const createUser = (data) => post(`/user`, data);
export const changePassword = (data) => put(`/user/password/force`, data);

// currency
export const getCurrencies = (params) => get(`/currency?page=${params.page}&size=1000`);
export const deleteCurrency = (currencyId) => del(`/currency/${currencyId}`);
export const editCurrency = (currencyId, data) => put(`/currency/${currencyId}`, data);
export const createCurrency = (data) => post(`/currency`, data);

// currency
export const getCurrencyRate = (params) => get(`/currencyRate?page=${params.page}&size=1000`);

// tax group
export const getTaxGroups = (params) => get(`/taxGroup?page=${params.page}&size=1000`);
export const getTaxGroup = () => get(`/taxGroup`);
export const deleteTaxGroups = (taxGroupId) => del(`/taxGroup/${taxGroupId}`);
export const editTaxGroups = (taxGroupId, data) => put(`/taxGroup/${taxGroupId}`, data);
export const createTaxGroups = (data) => post(`/taxGroup`, data);

// tax group
export const getWebCategories = (params) => get(`/webCategory?page=${params.page}&size=1000`);
export const deleteWebCategories = (webCategoryId) => del(`/webCategory/${webCategoryId}`);
export const editWebCategories = (webCategoryId, data) => put(`/webCategory/${webCategoryId}`, data);
export const createWebCategories = (data) => post(`/webCategory`, data);

// colors
export const getColors = (params) => get(`/color?page=${params.page}&size=1000`);
export const deleteColors = (colorId) => del(`/color/${colorId}`);
export const editColors = (colorId, data) => put(`/color/${colorId}`, data);
export const createColors = (data) => post(`/color`, data);

// manufacturers
export const getManufacturers = (params) => get(`/manufacturer?page=${params.page}&size=1000`);
export const getManufacturer = () => get(`/manufacturer`);
export const deleteManufacturers = (manufacturerId) => del(`/manufacturer/${manufacturerId}`);
export const editManufacturers = (manufacturerId, data) => put(`/manufacturer/${manufacturerId}`, data);
export const createManufacturers = (data) => post(`/manufacturer`, data);

// manufacturers address
export const createManufacturersAddress = (manufacturerId, data) =>
  put(`/manufacturer/${manufacturerId}/address`, data);
export const getManufacturersAddress = (address, id) =>
  get(`/manufacturer/${id}/address?page=${address.page}&size=1000`);

// materials
export const getMaterials = (params) => get(`/material?page=${params.page}&size=1000`);
export const deleteMaterials = (materialId) => del(`/material/${materialId}`);
export const editMaterials = (materialId, data) => put(`/material/${materialId}`, data);
export const createMaterials = (data) => post(`/material`, data);

//financecompany
export const getFinanceCompany = (params) => get(`/financeCompany?page=${params.page}&size=1000`);
export const deleteFinanceCompany = (financeCompanyId) => del(`/financeCompany/${financeCompanyId}`);
export const editFinanceCompany = (financeCompanyId, data) => put(`/financeCompany/${financeCompanyId}`, data);
export const createFinanceCompany = (data) => post(`/financeCompany`, data);

// financecompany rate
export const createFinanceCompanyRate = (financeCompanyId, data) =>
  post(`/financeCompany/${financeCompanyId}/rate`, data);
export const getFinanceCompanyRates = (financeCompanyId, params) =>
  get(`/financeCompany/${financeCompanyId}/rates?page=${params.page}&size=1000`).then((data) =>
    data.map((item) => ({ ...item, label: `${item?.month} (${item?.rate}%)` }))
  );
export const updateFinanceCompanyRate = (financeCompanyId, id, data) =>
  put(`/financeCompany/${financeCompanyId}/rate/${id}`, data);
export const deleteFinanceCompanyRate = (financeCompanyId, id) => del(`/financeCompany/${financeCompanyId}/rate/${id}`);

// product types
export const getProductTypes = (params) => get(`/productType?page=${params.page}&size=1000`);
export const getProductType = () => get(`/productType`);

export const deleteProductTypes = (productTypeId) => del(`/productType/${productTypeId}`);
export const editProductTypes = (productTypeId, data) => put(`/productType/${productTypeId}`, data);
export const createProductTypes = (data) => post(`/productType`, data);

// product categories
export const getProductCategories = (params) => get(`/productCategory?page=${params.page}&size=1000`);
export const deleteProductCategory = (categoryId) => del(`/productCategory/${categoryId}`);
export const editProductCategory = (categoryId, data) => put(`/productCategory/${categoryId}`, data);
export const createProductCategory = (data) => post(`/productCategory`, data);

// product attributes
export const getProductAttribute = (params, productTypeId) =>
  get(`/productAttribute?page=${params.page}&size=1000&productTypeId=${productTypeId || ""}`);
export const deleteProductAttribute = (attributeId) => del(`/productAttribute/${attributeId}`);
export const editProductAttribute = (attributeId, data) => put(`/productAttribute/${attributeId}`, data);
export const createProductAttribute = (data) => post(`/productAttribute`, data);
export const getProductAttributeDetail = (productAttrId) => get(`/productAttribute/${productAttrId}`);

export const getEnumInputTypes = () => get(`/enum/inputTypes`);
export const getHouseTypes = () => get(`/enum/houseTypes`);
export const getBranchTypes = () => get(`/enum/branchTypes`);

export const createProductAttrOption = (productAttrId, data) => post(`/productAttribute/${productAttrId}/option`, data);
export const updateProductAttrOption = (productAttrOptId, data) =>
  put(`/productAttribute/option/${productAttrOptId}`, data);
export const deleteProductAttrOption = (productAttrOptId) => del(`/productAttribute/option/${productAttrOptId}`);

//address
export const getAddress = (id) => get(`/address/${id}`);
export const deleteAddress = (addressId) => del(`/address/${addressId}`);
export const editAddress = (addressId, data) => put(`/address/${addressId}`, data);

export const getDeliveryStatus = () => get(`/enum/deliveryStatus`);
export const getOrderStatus = () => get(`/enum/orderStatus`);
export const getOrderStatusExcludeProforma = () => {
  return get(`/enum/orderStatus`).then((res) => res.filter((item) => item.value != "PROFORMA"));
};
export const getProductionOrderStatus = () => get(`/enum/productionOrderStatus`);
export const getShipmentStatus = () => get(`/enum/shipmentStatus`);
export const getTransferStatus = () => get(`/enum/transferStatus`);
export const getVehicleTypes = () => get(`/enum/vehicleTypes`);
export const getVolumeTypes = () => get(`/enum/volumeTypes`);
export const getOrderTypes = () => get(`/enum/orderTypes`);
export const getOrderedProductStatus = () => get(`/enum/orderedProductStatus`);
export const getMediaTypes = () => get(`/enum/mediaTypes`);
export const getPaidTypes = () => get(`/enum/paidTypes`);
export const getPaymentTypes = () => get(`/enum/paymentTypes`); //get(`/enum`)
export const getAllEnumTypes = () => get(`/enum`);
export const getTicketStatus = () => get(`/enum/sshStatus`);
export const getActionLogTypes = () => get(`/enum/actionTypes`);
export const getProformaCancelStatus = () => get(`/enum/proformaCancelStatuses`);
export const getFinancePaymentStatuses = () => get(`/enum/financePaymentStatuses`);

//warehouse
export const getWarehouse = (params) => get(`/warehouse?page=${params.page}&size=1000`);
export const deleteWarehouse = (warehouseId) => del(`/warehouse/${warehouseId}`);
export const editWarehouse = (warehouseId, data) => put(`/warehouse/${warehouseId}`, data);
export const createWarehouse = (data) => post(`/warehouse`, data);

//after sales service problem types
export const getAssProblemTypes = (params) => get(`/sshProblemType?page=${params.page}&size=1000`);
export const deleteAssProblemTypes = (id) => del(`/sshProblemType/${id}`);
export const editAssProblemTypes = (id, data) => put(`/sshProblemType/${id}`, data);
export const createAssProblemTypes = (data) => post(`/sshProblemType`, data);

//privileges
export const getPrivileges = (params) => get(`/privilege?page=${params.page}&size=1000`);
export const deletePrivilege = (id) => del(`/privilege/${id}`);
export const editPrivilege = (id, data) => put(`/privilege/${id}`, data);
export const createPrivilege = (data) => post(`/privilege`, data);

//roles
export const getRoles = (params) => get(`/role?page=${params.page}&size=1000`);
export const deleteRole = (id) => del(`/role/${id}`);
export const editRole = (id, data) => put(`/role/${id}`, data);
export const createRole = (data) => post(`/role`, data);

//menu
export const getMenu = (params) => get(`/menu?page=${params.page}&size=1000`);
export const deleteMenu = (menuId) => del(`/menu/${menuId}`);
export const editMenu = (menuId, data) => put(`/menu/${menuId}`, data);
export const createMenu = (data) => post(`/menu`, data);

//after sales service problem subjects
export const getAssProblemSubjects = (params) => get(`/sshProblemSubject?page=${params.page}&size=1000`);
export const deleteAssProblemSubject = (id) => del(`/sshProblemSubject/${id}`);
export const editAssProblemSubject = (id, data) => put(`/sshProblemSubject/${id}`, data);
export const createAssProblemSubject = (data) => post(`/sshProblemSubject`, data);

//price tag
export const getPriceTags = (params) => get(`/priceTag?page=${params?.page ?? 0}&size=1000`);
export const getActivePriceTags = (params) => get(`/priceTag?status=y&page=${params?.page ?? 0}&size=1000`);
export const deletePriceTag = (id) => del(`/priceTag/${id}`);
export const editPriceTag = (id, data) => put(`/priceTag/${id}`, data);
export const createPriceTag = (data) => post(`/priceTag`, data);

//product delgete type
export const getProductDeleteTypes = (params) => get(`/productDeleteType?page=${params?.page ?? 0}&size=1000`);
export const getActiveProductDeleteTypes = (params) =>
  get(`/productDeleteType?status=y&page=${params?.page ?? 0}&size=1000`);
export const deleteProductDeleteType = (id) => del(`/productDeleteType/${id}`);
export const editProductDeleteType = (id, data) => put(`/productDeleteType/${id}`, data);
export const createProductDeleteType = (data) => post(`/priceproductDeleteTypeTag`, data);

export const getStatuses = (params) => {
  return Promise.resolve([
    {
      label: "Active",
      value: "y",
    },
    {
      label: "Passive",
      value: "n",
    },
  ]);
};
