import CustomSpinner from "components/Common/CustomSpinner";
import DateTimeOutput from "components/Common/DateTimeOutput";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { getDashboard } from "store/dashboard/services";
import { dashboardAppliedFilterState, dashboardState } from "./atoms";
import DashboardFilterForm from "./DashboardFilterForm";
import dashboardUtils from "./dashboard_utils";

function Dashboard({}) {
  const [loading, setLoading] = useState(false);
  const activeUser = useActiveUser();

  const [dashboard, setDashboard] = useRecoilState(dashboardState);
  const [appliedFilter, setAppliedFilter] = useRecoilState(dashboardAppliedFilterState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const { data } = dashboard;

  useEffect(() => {
    if (dashboard.lastUpdateTime == null || new Date().getTime() - dashboard.lastUpdateTime.getTime() > 5 * 60000) {
      loadDashboard(appliedFilter);
    }
  }, []);

  const loadDashboard = async (filter) => {
    setLoading(true);
    try {
      const data = await getDashboard(dashboardUtils.mapToOrderSearch(filter));
      setDashboard({
        data,
        lastUpdateTime: new Date(),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    loadDashboard(appliedFilter);
    setHasFilter(hasAnyValue(appliedFilter));
  };

  const handleRefresh = () => {
    loadDashboard(appliedFilter);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Novia Furniture CRM</title>
        </MetaTags>
        <Container fluid>
          <Row className="pb-2">
            <Col>
              <div className="nv-grid">
                <div className="nv-row">
                  <h4>Dashboard</h4>
                </div>
              </div>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Button color="primary" size="sm" outline onClick={handleRefresh} disabled={loading}>
                Refresh
              </Button>
              {dashboard.lastUpdateTime && (
                <div className="text-info">
                  <small className="text-info">
                    Last Updated:{" "}
                    <b>
                      {Number((new Date().getTime() - dashboard.lastUpdateTime.getTime()) / 60000).toFixed(0)} minutes
                      ago
                    </b>
                  </small>
                </div>
              )}
            </Col>
          </Row>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <DashboardFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          {loading && <CustomSpinner />}
          {data && !loading && (
            <Row>
              <Col md={12}>
                <Card className="shadow-lg">
                  <CardBody>
                    <Row className="py-1">
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Total Sales" value={data.totalSales} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Total Proforma" value={data.totalProforma} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Total Canceled Order" value={data.totalCancelOrder} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Total Product Sold" value={data.totalProductSold} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Coefficient" value={data.coefficient} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Avr Discount Rate" value={data.averageDiscountRate} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Avr Basket Size" value={data.averageBasketSize} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Payment With Card" value={data.paymentWithCardAmount} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Payment With Cash" value={data.paymentWithCashAmount} />
                        </Col>
                      )}
                      {/* // <Col sm={3}>
                    //   <DashboardCard
                    //     label="Total Onhold Customer Order"
                    //     value={data.totalOnHoldCustomerOrder}
                    //     
                    //   />
                    // </Col> */}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Payment With Finance" value={data.paymentFinanceAmount} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Payment As Deposit" value={data.paymentDepositAmount} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                        <Col sm={3}>
                          <DashboardCard label="Payment as Balance" value={data.paymentBalanceAmount} />
                        </Col>
                      )}
                      {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
                          <Col sm={3}>
                            <DashboardCard label="Money In" value={data.moneyIn} />
                          </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

function DashboardCard({ label, value }) {
  return (
    <Card style={{ backgroundColor: "#f7cfff" || "black", minHeight: 100 }}>
      <CardBody>
        <h1>
          <Badge color="warning">{value}</Badge>
        </h1>
        <h4 style={{ color: "gray" }}>{label}</h4>
      </CardBody>
    </Card>
  );
}

export default Dashboard;
