import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import React, { useCallback, useState } from "react";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import { getWarehouse } from "store/definitions/services";
import { getStores } from "store/store/services";

const InventoryFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col sm={6}>
            <div className="row mb-4">
              <Label htmlFor="sku" className="col-sm-3 col-form-label">
                SKU
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.sku || ""}
                  name="sku"
                  placeholder="sku.."
                  className="form-control"
                  onChange={handleChange}
                />
              </Col>
            </div>
          </Col>
          <Col sm={6}>
            <div className="row mb-4">
              <Label htmlFor="name" className="col-sm-3 col-form-label">
                Product Name
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.name || ""}
                  name="name"
                  placeholder="product name.."
                  className="form-control"
                  onChange={handleChange}
                />
              </Col>
            </div>
          </Col>
          <Col sm={6}>
            <div className="row mb-2">
              <Label htmlFor="warehouseId" className="col-sm-3 col-form-label">
                Warehouse
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getWarehouse}
                  name="warehouseId"
                  value={filter.warehouseId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={6}>
            <div className="row mb-2">
              <Label htmlFor="storeId" className="col-sm-3 col-form-label">
                Store
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getStores}
                  name="storeId"
                  value={filter.storeId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default InventoryFilterForm;
