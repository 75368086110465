import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import OrderCard from "./OrderCard";
import OrderFinances from "./OrderFinances";
import OrderPayments from "./OrderPayments";

const OrderPaymentsAndFinancesPage = () => {
  const { id } = useParams();
  return (
    <>
      <MetaTags>
        <title>Novia - Order Payments & Finances</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        <h2 className="text-primary">Order Payments & Finances</h2>
        <Row>
          <Col>
            <OrderCard id={id} />
            <Card className="shadow-lg">
              <CardBody className="">
                <OrderPayments orderId={id} />
              </CardBody>
            </Card>
            <Card className="shadow-lg">
              <CardBody className="">
                <OrderFinances orderId={id} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

OrderPaymentsAndFinancesPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(OrderPaymentsAndFinancesPage);
