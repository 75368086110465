function CustomLoadingIndicator() {
  return (
    <div className="custom-loadingIndicator">
      <span className="custom-loadingIndicator-item1"></span>
      <span className="custom-loadingIndicator-item2"></span>
      <span className="custom-loadingIndicator-item3"></span>
    </div>
  );
}

export default CustomLoadingIndicator;
