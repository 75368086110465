import enums, { getEnumLabel } from "common/enums";
import ConfirmButton from "components/Common/ConfirmButton";
import UploadDocumentModal from "components/Common/UploadDocumentModal";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { addDocumentToProduction, deleteProductionDocument, getProductionDocuments } from "store/production/services";

const initialDocumentState = { open: false, error: null, loading: false, document: {} };

const ProductionDocuments = ({ productionId }) => {
  const [documentState, setDocumentState] = useState(initialDocumentState);
  const [documents, setDocuments] = useState({});

  const openDocumentAddPopup = () => {
    setDocumentState((prev) => ({ ...prev, document: {}, open: true }));
  };

  const handleUploadDocument = async ({ file, type }) => {
    const fd = new FormData();
    fd.append("file", file, file.path);
    return addDocumentToProduction(productionId, fd, type);
  };

  const handleUploadSuccess = () => {
    loadDocuments(productionId);
  };
  const handleCloseDocument = () => {
    setDocumentState((prev) => ({ ...prev, open: false }));
  };
  const loadDocuments = async (productionId) => {
    const docs = await getProductionDocuments(productionId);
    const docsMap = {};
    docs.forEach((doc) => {
      let curr = docsMap[doc.mediaType];
      if (curr == null) {
        curr = [];
        docsMap[doc.mediaType] = curr;
      }
      curr.push(doc);
    });
    setDocuments(docsMap);
  };
  const handleDeleteDocument = async ({ id }) => {
    await deleteProductionDocument(productionId, id);
    loadDocuments(productionId);
  };
  useEffect(() => {
    loadDocuments(productionId);
  }, [productionId]);

  return (
    <>
      {" "}
      <h4 className="text-secondary">
        <b>Documents</b>
        <span style={{ float: "right" }}>
          <Button color="primary" size="sm" onClick={openDocumentAddPopup}>
            <i className="fa fa-plus"></i>Add Document
          </Button>
        </span>
      </h4>
      <br />
      {Object.keys(documents).map((mediaType) => (
        <div key={mediaType}>
          {documents[mediaType].map((doc) => (
            <li key={doc.id} className="pb-2">
              <span>
                <a href={doc.awsUrl} target="_blank">
                  {doc.fileName}
                </a>
                , <strong>Created At</strong> {doc.createdDate} by {doc.createUser}
                &nbsp;&nbsp;&nbsp;
                <ConfirmButton
                  size="sm"
                  color="danger"
                  onConfirm={() => handleDeleteDocument(doc)}
                  title="Are you sure you want to delete the document?"
                >
                  <i className="fa fa-trash"></i>
                </ConfirmButton>
              </span>
            </li>
          ))}
        </div>
      ))}
      {documentState.open && (
        <UploadDocumentModal
          onClose={handleCloseDocument}
          onUpload={handleUploadDocument}
          onUploadSuccess={handleUploadSuccess}
          showSelectMedia={false}
        />
      )}
    </>
  );
};

export default ProductionDocuments;
