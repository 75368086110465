import { atom } from "recoil";
import deliveryUtils from "./delivery_utils";

export const deliveriesAppliedFilterState = atom({
  key: "Deliveries.appliedFilter",
  default: deliveryUtils.initialFilterValues(),
});
export const deliveriesAppliedSortState = atom({
  key: "Deliveries.appliedSort",
  default: { sortField: "no", sortOrder: "desc" },
});
