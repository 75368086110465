import React, { useState } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";

function LongArrayOutput({ values = [], showLength = 3, title }) {
  const [open, setOpen] = useState(false);
  const [id] = useState(Math.floor(Math.random() * 1000000000));
  return (
    <>
      {values.slice(0, showLength).join(", ")}
      {values.length > showLength && (
        <>
          <button type="button" id={`popover_${id}`} className="btn p-2 pb-0 pt-0">
            <i className="fa fa-ellipsis-h"></i>
          </button>
          <Popover trigger="legacy" target={`popover_${id}`} toggle={() => setOpen((p) => !p)} isOpen={open}>
            <PopoverHeader>{title}</PopoverHeader>
            <PopoverBody style={{ maxHeight: "300px", overflow: "auto" }}>{values.join(", ")}</PopoverBody>
          </Popover>
        </>
      )}
    </>
  );
}

export default LongArrayOutput;
