import React from "react";
import { PaginationControl } from "react-bootstrap-pagination-control";

function CustomPaginate({ page, totalSize, sizePerPage, between = 2, ellipsis = 0, onPageChange, containerClassName }) {
  if (Math.ceil(totalSize / sizePerPage) <= 1) {
    return null;
  }
  return (
    <div className={containerClassName}>
      <PaginationControl
        page={page + 1}
        between={between}
        total={totalSize}
        limit={sizePerPage}
        changePage={(page) => {
          onPageChange("pagination", { page: page - 1, sizePerPage });
        }}
        ellipsis={ellipsis}
        last
        next={false}
      />
    </div>
  );
}

export default CustomPaginate;
