import ImagesModal from "components/Common/ImagesModal";
import MoneyOutput from "components/Common/MoneyOutput";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { getVariantOrProductImages } from "store/product/services";
import VariantDefaultImage from "./VariantDefaultImage";

const VariantCard = ({ variant }) => {
  const [variantIdForImage, setVariantIdForImage] = useState(null);
  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Product Name</h6>
                <Col sm={4}>{variant.productName}</Col>
                <h6 className="col-sm-2">Image</h6>
                <Col sm={4}>
                  <VariantDefaultImage
                    onClick={() => setVariantIdForImage(variant.id || -1)}
                    variant={variant}
                  ></VariantDefaultImage>
                </Col>
              </Row>
              <Row className="py-1">
                <h6 className="col-sm-2">SKU</h6>
                <Col sm={4}>{variant.sku}</Col>
                <h6 className="col-sm-2">Material-Color</h6>
                <Col sm={4}>{variant.color?.nameFirstMaterial}</Col>
              </Row>
              <Row className="py-1">
                <>
                  <h6 className="col-sm-2">Price</h6>
                  <Col sm={4}>
                    <MoneyOutput value={variant.finalPrice} currency={variant.finalPriceCurrency} />
                  </Col>
                </>
                {variant.option1 && (
                  <>
                    <h6 className="col-sm-2">{variant.option1.attributeName}</h6>
                    <Col sm={4}>{variant.option1.name}</Col>
                  </>
                )}
                {variant.option2 && (
                  <>
                    <h6 className="col-sm-2">{variant.option2.attributeName}</h6>
                    <Col sm={4}>{variant.option2.name}</Col>
                  </>
                )}
                {variant.option3 && (
                  <>
                    <h6 className="col-sm-2">{variant.option3.attributeName}</h6>
                    <Col sm={4}>{variant.option3.name}</Col>
                  </>
                )}
                {variant.option4 && (
                  <>
                    <h6 className="col-sm-2">{variant.option4.attributeName}</h6>
                    <Col sm={4}>{variant.option4.name}</Col>
                  </>
                )}
                {variant.option5 && (
                  <>
                    <h6 className="col-sm-2">{variant.option5.attributeName}</h6>
                    <Col sm={4}>{variant.option5.name}</Col>
                  </>
                )}
                {variant.option6 && (
                  <>
                    <h6 className="col-sm-2">{variant.option6.attributeName}</h6>
                    <Col sm={4}>{variant.option6.name}</Col>
                  </>
                )}
                {variant.option7 && (
                  <>
                    <h6 className="col-sm-2">{variant.option7.attributeName}</h6>
                    <Col sm={4}>{variant.option7.name}</Col>
                  </>
                )}
                {variant.option8 && (
                  <>
                    <h6 className="col-sm-2">{variant.option8.attributeName}</h6>
                    <Col sm={4}>{variant.option8.name}</Col>
                  </>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {variantIdForImage && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(variant.productId, variantIdForImage)}
          onClose={() => setVariantIdForImage(null)}
        />
      )}
    </>
  );
};

export default VariantCard;
