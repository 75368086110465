import enums, { getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import DateOutput from "components/Common/DateOutput";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import Privileges from "models/Privileges";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Row,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { downloadService } from "services/DownloadService";
import { deleteProduction, getProductionOrderCsv, getProductions } from "store/production/services";
import { productionsAppliedFilterState, productionsAppliedSortState } from "./atoms";
import ProductionFilterForm from "./ProductionFilterForm";
import productionUtils from "./production_utils";
const Productions = ({ history }) => {
  const activeUser = useActiveUser();
  const [loading, setLoading] = useState(false);
  const [productions, setProductions] = useState([]);
  const [appliedFilter, setAppliedFilter] = useRecoilState(productionsAppliedFilterState);
  const [appliedSort, setAppliedSort] = useRecoilState(productionsAppliedSortState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("Productions");
  const [deleteConfirmState, setDeleteConfirmState] = useState({
    open: false,
    id: null,
  });
  const loadProductions = async (page, filter, sort) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getProductions(page, productionUtils.mapToProductionSearch(filter), sort);
      setTotalSize(totalElements);
      setProductions(content);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // loadProductions(pageOptions, appliedFilter, appliedSort);
  }, []);

  const handlePageChange = (type, details) => {
    // console.log("handlePageChange", details, type);
    const { page, sizePerPage, sortField, sortOrder } = details;
    if (type == "pagination") {
      loadProductions(setPageNumber(page, sizePerPage), appliedFilter, appliedSort);
    } else if (type == "sort") {
      const newSort = { sortField, sortOrder };
      // console.log(appliedSort, newSort);
      setAppliedSort(newSort);
      loadProductions(pageOptions, appliedFilter, newSort);
    }
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    const newPage = setPageNumber(0);
    loadProductions(newPage, appliedFilter, appliedSort);
    setHasFilter(hasAnyValue(appliedFilter));
  };

  const handleDelete = async (id) => {
    try {
      await deleteProduction(id);
      toast.info("Production order deleted successfully");
      loadProductions(pageOptions, appliedFilter, appliedSort);
    } finally {
      setDeleteConfirmState({ open: false });
    }
  };
  const handleProductionOrderExportToCsv = async (id) => {
    const data = await getProductionOrderCsv(id);
    downloadService.downloadBufferedData({
      data,
      contentType: "text/csv",
      fileName: `production-order-${id}.csv`,
    });
  };

  const columns = [
    {
      dataField: "actions",
      text: "Actions",
      align: "center",
      headerAlign: "center",
      hidden:
        !activeUser.hasPrivilege(Privileges.PRODUCTION.DETAIL) &&
        !activeUser.hasPrivilege(Privileges.PRODUCTION.UPDATE) &&
        !activeUser.hasPrivilege(Privileges.PRODUCTION.DELETE),
      formatter: (cell, row) => {
        return (
          <ActionMenu>
            <DropdownMenu>
              {activeUser.hasPrivilege(Privileges.PRODUCTION.DETAIL) && (
                <DropdownItem onClick={() => handleProductionOrderExportToCsv(row.id)}>Export To CSV</DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.PRODUCTION.UPDATE) && (
                <DropdownItem onClick={() => history.push(`/production-order/${row.id}`)}>
                  Edit Production Order
                </DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.PRODUCTION.DELETE) && (
                <DropdownItem
                  onClick={() => {
                    setDeleteConfirmState({ open: true, id: row.id });
                  }}
                >
                  Delete Production Order
                </DropdownItem>
              )}
            </DropdownMenu>
          </ActionMenu>
        );
      },
    },
    {
      text: "Production Number",
      dataField: "productionNo",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {activeUser.hasPrivilege(Privileges.PRODUCTION.DETAIL) && (
              <Link to={`/production-order/${row.id}/detail`} title="View detail">
                {cell}
              </Link>
            )}
            {!activeUser.hasPrivilege(Privileges.PRODUCTION.DETAIL) && cell}
          </>
        );
      },
    },
    {
      dataField: "manufacturer.name",
      text: "Manufacturer",
      sort: true,
    },
    {
      dataField: "deliveryBranch.name",
      text: "Branch",
      sort: true,
    },
    {
      dataField: "productionStartDate",
      text: "Production Start Date",
      sort: true,
      formatter: (cell) => <DateOutput date={cell} />,
    },
    {
      dataField: "productionEndDate",
      text: "Production End Date",
      sort: true,
      formatter: (cell) => <DateOutput date={cell} />,
    },
    {
      dataField: "orderStatus",
      text: "Production Status",
      sort: true,
      formatter: (cell) => getEnumLabel(enums.productionOrderStatus, cell),
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Production Orders</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <ProductionFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <div className="d-flex justify-content-between">
                      <h4>Production Orders</h4>
                      {activeUser.hasPrivilege(Privileges.PRODUCTION.CREATE) && (
                        <div style={{ float: "right" }}>
                          <Button color="primary" size="sm" onClick={() => history.push(`/create-production-order`)}>
                            <i className="fa fa-plus"></i> New Production Order
                          </Button>
                        </div>
                      )}
                    </div>
                  </CardTitle>
                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        hover
                        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                        loading={loading}
                        defaultSorted={[{ dataField: appliedSort.sortField, order: appliedSort.sortOrder }]}
                        keyField="id"
                        responsive
                        bordered={false}
                        striped={false}
                        remote
                        pagination={paginationFactory(pageOptions)}
                        columns={columns}
                        data={productions}
                        onTableChange={handlePageChange}
                        wrapperClasses="table-responsive"
                        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                        headerWrapperClasses={"thead-light"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {deleteConfirmState.open && (
        <SweetAlert
          title="Are you sure you want to delete the production order?"
          warning
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDelete(deleteConfirmState.id)}
          onCancel={() => {
            setDeleteConfirmState({ open: false });
          }}
        ></SweetAlert>
      )}
    </React.Fragment>
  );
};

Productions.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Productions);
