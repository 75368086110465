class CustomerUtils {
  initialValues = () => ({
    id,
    title,
    name: "",
    email,
    phone: "",
    birthDate,
    contactEmail,
    contactMail,
    contactPhone,
  });
  mapToSaveModel = ({ id, title, name, email, phone, birthDate, contactEmail, contactMail, contactPhone }) => ({
    id,
    title,
    name,
    email,
    phone,
    birthDate,
    contactEmail,
    contactMail,
    contactPhone,
  });
  mapToNoteAddModel = ({ id, status = "y", notes }) => ({
    id,
    status,
    notes,
  });
  mapToFormDataModel = ({ id, title, name, email, phone, birthDate, contactEmail, contactMail, contactPhone }) => ({
    id,
    title,
    name,
    email,
    phone,
    birthDate,
    contactEmail,
    contactMail,
    contactPhone,
  });
  initialFilterValues = () => ({
    phone: "",
    fullName: "",
    email: "",
    postCode: "",
  });

  mapToCustomerSearch = (
    filter = {
      phone: "",
      fullName: "",
      email: "",
      postCode: "",
    }
  ) => {
    return {
      phone: filter.phone,
      fullName: filter.fullName,
      email: filter.email,
      postCode: filter.postCode,
    };
  };
}
const customerUtils = new CustomerUtils();
export default customerUtils;
