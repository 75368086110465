import { ibanKontrol } from "./iban";

class Validations {
  required(value) {
    if (value == null || `${value}`.length === 0) {
      return "Required field";
    }
    return null;
  }

  range(value, min, max) {
    if (value != null && `${value}`.length !== 0) {
      if (value < parseFloat(min) || value > parseFloat(max)) return `Value must be between ${min} and ${max}`;
    }
    return null;
  }

  min(value, min) {
    if (value != null && `${value}`.length !== 0) {
      if (value < parseFloat(min)) return `Value must be at least ${min}`;
    }
    return null;
  }

  max(value, max) {
    if (value != null && `${value}`.length !== 0) {
      if (value > parseFloat(max)) return `Value must be at most ${max}`;
    }
    return null;
  }

  lengthRange(value, min, max) {
    if (value != null && `${value}`.length !== 0) {
      if (`${value}`.length < parseInt(min, 10) || `${value}`.length > parseInt(max, 10))
        return `Length of value must be between ${min} and ${max}`;
    }
    return null;
  }

  length(value, length) {
    if (value != null && `${value}`.length !== 0) {
      if (`${value}`.length < parseInt(length, 10)) return `Length of value must be ${length}`;
    }
    return null;
  }

  minLength(value, min) {
    if (value != null && `${value}`.length !== 0) {
      if (`${value}`.length < parseInt(min, 10)) return `Length of value must be at least ${min}`;
    }
    return null;
  }

  maxLength(value, max) {
    if (value != null && `${value}`.length !== 0) {
      if (`${value}`.length > parseInt(max, 10)) return `Length of value must be at most ${max}`;
    }
    return null;
  }

  regex(value, pattern) {
    if (value != null && `${value}`.length !== 0) {
      if (!new RegExp(`^${pattern}$`).test(value)) return `Value must match with the given pattern ${pattern}`;
    }
    return null;
  }

  number(value) {
    if (value != null && `${value}`.length !== 0) {
      if (!new RegExp(`^[0-9]+$`).test(value)) return `Value must be numeric`;
    }
    return null;
  }

  iban(value) {
    if (value == null || value.length === 0) {
      return null;
    }
    const sonuc = ibanKontrol(value);
    if (sonuc !== 1) {
      return "IBAN is not valid";
    }
    return null;
  }
}
export const validations = new Validations();
