class VariantUtils {
  createHeaders = (variants = []) => {
    if (variants.length == 0) {
      return [];
    }
    const [variant] = variants;
    const headers = [];

    if (variants.find((v) => v.color != null) != null) {
      headers.push({
        text: "Color",
        dataField: "color",
      });
    }
    // if (variant.material != null) {
    //   headers.push({
    //     text: "Material",
    //     dataField: "material",
    //   });
    // }
    for (let i = 0; i < 8; i++) {
      const optVariant = variants.find((v) => v[`option${i + 1}`] != null);
      if (optVariant != null) {
        headers.push({
          text: optVariant[`attribute${i + 1}`].name,
          dataField: `option${i + 1}`,
        });
      }
    }
    // console.log("headers", headers);
    return headers;
  };
  /*
    sonraki dizilerin uzunluklarinin carpimi kadar tekrar eder;
    orn: a[1,2] b[3,4,5] c[6,7]
        136
        137
        146
        147
        156
        157
        236
        237
        246
        247
        256
        257
        aciklama:  a dizisindekiler length(b)*length(c)
                b dizisindekiler length(c)
                c dizisindekiler 1 tekrak yapar
    */
  createVariants = (colors = [], materials = [], attributes = []) => {
    // console.log(attributes);
    const multipleAttr = attributes
      .filter((attr) => attr.attribute.inputType == "SELECT" && attr.values?.length > 0)
      .sort((a, b) => a.attribute.orderNumber - b.attribute.orderNumber);
    if (colors.length < 1 && materials.length < 1 && multipleAttr.length < 1) {
      return [];
    }
    const variants = [];
    const colorLen = colors.length > 0 ? colors.length : 1;
    const materialLen = materials.length > 0 ? materials.length : 1;
    const multipleAttrLen = multipleAttr.map((item) => item.values.length).reduce((prev, curr) => prev * curr, 1);
    const variantLen = colorLen * materialLen * multipleAttrLen;

    function attrRepeat(begin) {
      let rpt = 1;
      for (let index = begin; index < multipleAttr.length; index++) {
        const attr = multipleAttr[index];
        rpt *= attr.values.length;
      }
      return rpt;
    }

    for (let i = 0; i < variantLen; i++) {
      const variant = this.createVariant();
      if (colors.length > 0) {
        variant.color = colors[Math.floor(i / (variantLen / colors.length))];
      }
      if (materials.length > 0) {
        variant.material = materials[Math.floor(i / multipleAttrLen) % materials.length];
      }
      if (multipleAttr.length > 0) {
        variant.attribute1 = multipleAttr[0].attribute;
        variant.option1 = multipleAttr[0].values[Math.floor((i / attrRepeat(1)) % multipleAttr[0].values.length)];
      }
      if (multipleAttr.length > 1) {
        variant.attribute2 = multipleAttr[1].attribute;
        variant.option2 = multipleAttr[1].values[Math.floor(i / attrRepeat(2)) % multipleAttr[1].values.length];
      }
      if (multipleAttr.length > 2) {
        variant.attribute3 = multipleAttr[2].attribute;
        variant.option3 = multipleAttr[2].values[Math.floor(i / attrRepeat(3)) % multipleAttr[2].values.length];
      }
      if (multipleAttr.length > 3) {
        variant.attribute4 = multipleAttr[3].attribute;
        variant.option4 = multipleAttr[3].values[Math.floor(i / attrRepeat(4)) % multipleAttr[3].values.length];
      }
      if (multipleAttr.length > 4) {
        variant.attribute5 = multipleAttr[4].attribute;
        variant.option5 = multipleAttr[4].values[Math.floor(i / attrRepeat(5)) % multipleAttr[4].values.length];
      }
      if (multipleAttr.length > 5) {
        variant.attribute6 = multipleAttr[5].attribute;
        variant.option6 = multipleAttr[5].values[Math.floor((i / attrRepeat(6)) % multipleAttr[5].values.length)];
      }
      if (multipleAttr.length > 6) {
        variant.attribute7 = multipleAttr[6].attribute;
        variant.option7 = multipleAttr[6].values[Math.floor(i / attrRepeat(7)) % multipleAttr[6].values.length];
      }
      if (multipleAttr.length > 7) {
        variant.attribute8 = multipleAttr[7].attribute;
        variant.option8 = multipleAttr[7].values[Math.floor(i / attrRepeat(8)) % multipleAttr[7].values.length];
      }

      variant.key = this.generateKey(variant);
      variants.push(variant);
    }
    // console.log("variants", variants);
    return variants;
  };

  createVariant = () => {
    const variant = {
      key: null,
      color: null,
      material: null,
      option1: null,
      option2: null,
      option3: null,
      option4: null,
      option5: null,
      option6: null,
      option7: null,
      option8: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      costCurrency: "GBP",
      priceCurrency: "GBP",
      salesPriceCurrency: "GBP",
    };
    return variant;
  };

  generateKey = ({
    color,
    material,
    option1,
    option2,
    option3,
    option4,
    option5,
    option6,
    option7,
    option8,
    attribute1,
    attribute2,
    attribute3,
    attribute4,
    attribute5,
    attribute6,
    attribute7,
    attribute8,
  }) => {
    return `
    c=${color && color.value ? color.value : ""}
    _a1=${attribute1 ? attribute1.id : ""}_o1=${option1 ? option1.value : ""}
    _a2=${attribute2 ? attribute2.id : ""}_o2=${option2 ? option2.value : ""}
    _a3=${attribute3 ? attribute3.id : ""}_o3=${option3 ? option3.value : ""}
    _a4=${attribute4 ? attribute4.id : ""}_o4=${option4 ? option4.value : ""}
    _a5=${attribute5 ? attribute5.id : ""}_o5=${option5 ? option5.value : ""}
    _a6=${attribute6 ? attribute6.id : ""}_o6=${option6 ? option6.value : ""}
    _a7=${attribute7 ? attribute7.id : ""}_o7=${option7 ? option7.value : ""}
    _a8=${attribute8 ? attribute8.id : ""}_o8=${option8 ? option8.value : ""}
    `.replace(/\s/g, "");
  };

  mergeVariants = (prev = [], curr = []) => {
    return curr.map((c) => {
      const currKey = this.generateKey(c);
      const prevVariant = prev.find((p) => {
        const prevKey = p.key ? p.key : this.generateKey(p);
        return currKey == prevKey;
      });
      if (prevVariant != null) {
        Object.keys(prevVariant).forEach((key) => (c[key] = prevVariant[key]));
      }
      return c;
    });
  };
  concatVariants = (currentVariants = [], newVariants = []) => {
    const variants = [...currentVariants];
    let newVariantCount = 0;
    newVariants.forEach((newVariant) => {
      const newVariantKey = this.generateKey(newVariant);
      const currentVariant = currentVariants.find((p) => {
        const prevKey = p.key ? p.key : this.generateKey(p);
        return newVariantKey == prevKey;
      });
      if (currentVariant == null) {
        variants.push(newVariant);
        newVariantCount += 1;
      }
    });
    return { variants, newVariantCount };
  };

  generateChangeDetectionValue = (clrs = [], mats = [], attrs = []) => {
    const colors = clrs.map((item) => item.value).sort();
    // const materials = mats.map((item) => item.value).sort();
    const attributes = attrs
      .filter((item) => item.attribute.inputType == "SELECT" && item.values.length > 0)
      .sort((o1, o2) => o1.attribute.orderNumber - o2.attribute.orderNumber)
      .map((item) => ({
        id: item.attribute.id,
        values: item.values.map((item) => item.value).sort(),
      }));
    const obj = {
      colors,
      // materials,
      attributes,
    };
    return JSON.stringify(obj);
  };
}
const variantUtils = new VariantUtils();
export default variantUtils;
