import moment from "moment";

class DeliveryUtils {
  initialValues = ({ orderId = "" }) => ({
    id: null,
    status: "y",
    orderId,
    deliveryStatus: {},
    plannedDate: "",
    orderedDate: "",
    estimateDeliveryDate: "",
  });
  mapToSaveModel = ({ id, status, deliveryStatus = {}, plannedDate, orderedDate, estimateDeliveryDate, orderId }) => ({
    id,
    status,
    orderId,
    deliveryStatus: deliveryStatus?.value,
    plannedDate: plannedDate && moment(plannedDate).utcOffset(0, true).format("YYYY-MM-DD"),
    orderedDate: orderedDate && moment(orderedDate).utcOffset(0, true).format("YYYY-MM-DD"),
    estimateDeliveryDate: estimateDeliveryDate && moment(estimateDeliveryDate).utcOffset(0, true).format("YYYY-MM-DD"),
  });

  mapToFormDataModel = ({
    id,
    status,
    deliveryStatus = "",
    order = {},
    plannedDate,
    orderedDate,
    estimateDeliveryDate,
  }) => ({
    id,
    status,
    deliveryStatus: {
      label: deliveryStatus,
      value: deliveryStatus,
    },
    orderId: order?.id,
    plannedDate: plannedDate && moment(plannedDate, "YYYY-MM-DD").toDate(),
    orderedDate: orderedDate && moment(orderedDate, "YYYY-MM-DD").toDate(),
    estimateDeliveryDate: estimateDeliveryDate && moment(estimateDeliveryDate, "YYYY-MM-DD").toDate(),
  });

  mapToProductAddModel = ({ id, status = "y", variant = {}, quantity, notes }) => ({
    id,
    status,
    variantId: variant?.id,
    quantity,
    notes,
  });

  mapToOrderedProductAddModel = ({ orderedProduct, notes, quantity }) => ({
    id: orderedProduct?.id,
    notes,
    quantity,
  });

  mapToNoteAddModel = ({ id, status = "y", notes }) => ({
    id,
    status,
    notes,
  });

  initialFilterValues = () => ({
    orderId: null,
    customerId: null,
    customerFullName: "",
    deliveryStatus: null,
    startPlannedDate: null,
    endDate: null,
  });

  mapToDeliverySearch = (
    filter = {
      orderId: null,
      customerId: null,
      customerFullName: "",
      deliveryStatus: null,
      startPlannedDate: null,
      endDate: null,
    }
  ) => {
    return {
      orderId: filter.orderId,
      customerId: filter.customerId,
      customerFullName: filter.customerFullName,
      deliveryStatus: filter.deliveryStatus,
      startPlannedDate: filter.startPlannedDate == null ? null : moment(filter.startPlannedDate).format("YYYY-MM-DD"),
      endPlannedDate: filter.endPlannedDate == null ? null : moment(filter.endPlannedDate).format("YYYY-MM-DD"),
    };
  };
}
const deliveryUtils = new DeliveryUtils();
export default deliveryUtils;
