import { post, get, del, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

//delivery
export const getDelivery = (id) => get(`/delivery/${id}`);
export const editDelivery = (id, data) => put(`/delivery/${id}`, data);
export const startDelivery = (id) => put(`/delivery/${id}/start`);
export const cancelDelivery = (id) => put(`/delivery/${id}/cancel`);
export const completeDelivery = (id) => put(`/delivery/${id}/complete`);
export const createDelivery = (data) => post(`/delivery`, data);
export const deleteDelivery = (id) => del(`/delivery/${id}`);
export const getDeliveries = (pageOpt = { page: 0, size: 1000 }, filter, sort) =>
  get(
    `/delivery/search?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );
export const getDeliveryProducts = (id) => get(`/delivery/${id}/products`);
export const getAvailableProducts = (orderId) => get(`/delivery/availableProducts?orderId=${orderId}`);

//delivery products
export const addProductToDelivery = (deliveryId, data) => put(`/delivery/${deliveryId}/product`, data);
export const addOrderedProductToDelivery = (deliveryId, data) => put(`/delivery/${deliveryId}/orderedProducts`, data);
export const deleteDeliveryProduct = (deliveryId, data) => del(`/delivery/${deliveryId}/orderedProducts`, data);

export const addNoteToDelivery = (deliveryId, data) => put(`/delivery/${deliveryId}/note`, data);
export const getDeliveryNotes = (deliveryId) => get(`/delivery/${deliveryId}/note`);
export const deleteDeliveryNote = (deliveryId, noteId) => del(`/delivery/${deliveryId}/note/${noteId}`);

export const addDocumentToDelivery = (deliveryId, data, type) =>
  put(`/delivery/${deliveryId}/document?type=${type}`, data);
export const getDeliveryDocuments = (deliveryId) => get(`/delivery/${deliveryId}/document`);
export const deleteDeliveryDocument = (deliveryId, docId) => del(`/delivery/${deliveryId}/document/${docId}`);

//assign users
export const updateUsers = (data) => put(`/delivery/users`, data);

export const getSatisfactionNote = (id) =>
  get(`/delivery/${id}/satisfactionNote`, {
    responseType: "blob",
  });
