class DownloadService {
  download(data) {
    const fileAsArrayBuffer = this.fileToArrayBuffer(data.file);

    this.downloadFile(fileAsArrayBuffer, data.fileName);
  }

  open(data, contentType) {
    const fileAsArrayBuffer = this.fileToArrayBuffer(data.file);

    const blob = new Blob([fileAsArrayBuffer], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  downloadBufferedData(data) {
    const blob = new Blob([data.data], { type: data.contentType });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = data.fileName || "";
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    }, 0);
  }
  openBufferedData(data) {
    const blob = new Blob([data.data], { type: data.contentType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  fileToArrayBuffer(file) {
    const binaryString = window.atob(file);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    return bytes;
  }

  downloadFile(file, fileName) {
    const blob = new Blob([file], { type: "application/octet-stream" });
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    link.click();
  }
}
export const downloadService = new DownloadService();
