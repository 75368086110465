import { queries } from "@testing-library/react";
import { post, get, del, put } from "helpers/axios_helper";
import { arrayToParams, toQueryParams } from "helpers/utils";

//ordered-product
export const getOrderedProduct = (id) => get(`/orderedProduct/${id}`);
export const editOrderedProduct = (id, data) => put(`/orderedProduct/${id}`, data);

export const getOrderedProducts = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/orderedProduct?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );

export const getInventoryProducts = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/orderedProduct/inventory?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${
      pageOpt.page
    }&size=${pageOpt.size}&&${toQueryParams(filter)}`
  );
export const exportInventoryToPdf = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/orderedProduct/exportInventoryToPdf?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${
      pageOpt.page
    }&size=${pageOpt.size}&&${toQueryParams(filter)}`,
    {
      responseType: "blob",
    }
  );

export const getProductsInStockCount = (sku) => get(`/orderedProduct/count?orderedProductStatuses=IN_STOCK&sku=${sku}`);

export const addProductToBranch = (branchId, product) => put(`/branch/${branchId}/product`, product);

export const deleteProductFromStock = (product) => del(`/orderedProduct/stock`, product);

export const getProductsInStock = async (sku) => {
  const resp = await get(`/orderedProduct?page=0&size=1000&orderedProductStatuses=IN_STOCK&sku=${sku}`);
  const data = resp.content;
  const ret = [];
  data.forEach((item) => {
    let r = null;
    for (let i = 0; i < ret.length; i++) {
      const element = ret[i];
      if (element.branchId == item.branch?.id) {
        r = element;
        break;
      }
    }
    if (r == null) {
      r = {
        branchId: item.branch.id,
        branchName: item.branch.name,
        stockCount: 1,
        label: `${item.branch.name} (1)`,
        value: item.id,
      };
      ret.push(r);
    } else {
      r.stockCount += 1;
      r.label = `${item.branch.name} (${r.stockCount})`;
    }
  });
  return Promise.resolve(ret);
};
export const getProductsInStockForUpdate = async (sku) => await get(`/orderedProduct/stock?sku=${sku}`);

export const searchOrderedProducts = (
  filter = {
    orderIds: [],
    customerIds: [],
  }
) => {
  let url = "/orderedProduct?page=0&size=1000";
  const orderIds = arrayToParams("orderDbIds", filter.orderIds);
  if (orderIds) {
    url += "&" + orderIds;
  }
  const customerIds = arrayToParams("customerIds", filter.customerIds);
  if (customerIds) {
    url += "&" + customerIds;
  }
  return get(url);
};
