import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";
import NumberFormat from "react-number-format";
import CreatableSelectBoxWithFilterService from "components/Selectbox/CreatableSelectBoxWithFilterService";
import { searchCustomersByFilter } from "store/customer/services";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { getOrderStatus, getPaidTypes, getPaymentTypes, getUserListContent } from "store/definitions/services";
import ReactDatePicker from "react-datepicker";
import { getStores } from "store/store/services";
import Select from "react-select";

const PaymentFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col xl="12" xxl="6">
            <div className="row mb-4">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Order Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.orderId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="orderId"
                  onValueChange={({ value }) => {
                    handleSetFilterValue("orderId", value);
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="salesPerson" className="col-sm-3 col-form-label">
                Customer
              </Label>
              <Col sm={9}>
                {!filter.customer && (
                  <CreatableSelectBoxWithFilterService
                    loadService={searchCustomersByFilter}
                    name="customerId"
                    value={filter.customerId}
                    handleChange={(name, value, val) => {
                      handleSetFilterValue(name, value);
                      handleSetFilterValue("customer", val);
                    }}
                    createTable={false}
                  />
                )}
                {filter.customer && (
                  <Select
                    onChange={(val) => {
                      if (!val) {
                        handleSetFilterValue("customerId", null);
                        handleSetFilterValue("customer", null);
                      }
                    }}
                    isClearable
                    isSearchable={false}
                    value={customerOpt[0]}
                    options={customerOpt}
                  />
                )}
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="paymentType" className="col-sm-3 col-form-label">
                Payment Type
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getPaymentTypes}
                  name="paymentType"
                  value={filter.paymentType}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="branchId" className="col-sm-3 col-form-label">
                Branch
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getStores}
                  name="branchId"
                  value={filter.branchId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col xl="12" xxl="6">
            <div className="row mb-4">
              <Label htmlFor="paidType" className="col-sm-3 col-form-label">
                Paid With
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getPaidTypes}
                  name="paidType"
                  value={filter.paidType}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="authCode" className="col-sm-3 col-form-label">
                Auth Code
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.authCode || ""}
                  name="authCode"
                  placeholder="Auth Code.."
                  className="form-control"
                  onChange={handleChange}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="startAmount" className="col-sm-3 col-form-label">
                Amount
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-m5">
                    <NumberFormat
                      value={filter.startAmount || ""}
                      className="form-control"
                      placeholder="only numbers.."
                      allowNegative={false}
                      name="startAmount"
                      onValueChange={({ value }) => {
                        handleSetFilterValue("startAmount", value);
                      }}
                    />
                    <NumberFormat
                      value={filter.endAmount || ""}
                      className="form-control"
                      placeholder="only numbers.."
                      allowNegative={false}
                      name="endAmount"
                      onValueChange={({ value }) => {
                        handleSetFilterValue("endAmount", value);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="startDate" className="col-sm-3 col-form-label">
                Pay Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.startDate}
                      placeholderText="Pay Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("startDate", date);
                      }}
                    />
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.endDate}
                      placeholderText="Pay End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("endDate", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp; &nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </form>
    </React.Fragment>
  );
};

export default PaymentFilterForm;
