import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import TransferCard from "./TransferCard";

const TransferDetail = () => {
  const { id } = useParams();

  return (
    <>
      <MetaTags>
        <title>Novia - Transfer Detail</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        <h2 className="text-primary">Transfer Detail</h2>
        <Row>
          <Col>
            <TransferCard id={id} />
          </Col>
        </Row>
      </div>
    </>
  );
};

TransferDetail.propTypes = {
  history: PropTypes.object,
};

export default withRouter(TransferDetail);
