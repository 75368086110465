import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import DeliveryDetailCard from "./DeliveryDetailCard";

const DeliveryDetail = () => {
  const { id } = useParams();

  return (
    <>
      <MetaTags>
        <title>Novia - Delivery Detail</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        <h4 className="text-primary">
          <Breadcrumb listClassName="p-0">
            <BreadcrumbItem>
              <Link to="/deliveries">Deliveries</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              <b>Delivery Detail</b>
            </BreadcrumbItem>
          </Breadcrumb>
        </h4>
        <DeliveryDetailCard id={id} />
      </div>
    </>
  );
};

DeliveryDetail.propTypes = {
  history: PropTypes.object,
};

export default withRouter(DeliveryDetail);
