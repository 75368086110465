import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Modal, ModalBody, ModalHeader, Row, Col, Container, UncontrolledTooltip } from "reactstrap";
import {
  deleteTransferProduct,
  getTransferProducts,
  addProductToTransfer,
  getTransfer,
} from "../../store/transfer/services";
import ImagesModal from "components/Common/ImagesModal";
import { getVariantImages, getVariantOrProductImages } from "store/product/services";
import ConfirmButton from "components/Common/ConfirmButton";
import TransferOrderedProductForm from "./TransferOrderedProductForm";
import { addOrderedProductToShipment } from "store/shipment/services";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";

const initialOrderedProductState = { open: false, error: null, loading: false, product: {} };
const TransferProducts = ({ transferId }) => {
  const [orderedProductState, setOrderedProductState] = useState(initialOrderedProductState);
  const [products, setProducts] = useState([]);
  const [transfer, setTransfer] = useState({
    senderLocation: {
      id: null,
    },
  });
  const [imageState, setImageState] = useState({
    productId: null,
    variantId: null,
  });
  const loadTransfer = async (transferId) => {
    const delivery = await getTransfer(transferId);
    setTransfer(delivery);
  };
  const openOrderedProductAddPopup = () => {
    setOrderedProductState((prev) => ({ ...prev, product: {}, open: true }));
  };

  const handleOrderedAddProduct = async (product) => {
    return addProductToTransfer(transferId, { ids: [product.id] }).then(() => {
      loadProducts(transferId);
      setOrderedProductState((prev) => ({ ...prev, product: {}, open: false }));
    });
  };
  const handleCloseOrderedProduct = () => {
    setOrderedProductState((prev) => ({ ...prev, open: false }));
  };
  const loadProducts = async (transferId) => {
    const shipmentOrderedProducts = await getTransferProducts(transferId);
    setProducts(shipmentOrderedProducts);
  };
  const handleDeleteProduct = async ({ id }) => {
    await deleteTransferProduct(transferId, { ids: [id] });
    loadProducts(transferId);
  };
  useEffect(() => {
    loadTransfer(transferId);
    loadProducts(transferId);
  }, [transferId]);

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        formatter: (productVariant, row) => (
          <VariantDefaultImage
            onClick={() =>
              setImageState({
                productId: row.productVariant?.productId,
                variantId: row.productVariant?.id,
              })
            }
            variant={productVariant}
          ></VariantDefaultImage>
        ),
      },
      {
        text: "SKU",
        dataField: "sku",
      },
      {
        text: "Order Number",
        dataField: "order.orderId",
      },
      {
        text: "Quantity",
        dataField: "quantity",
      },
      {
        text: "Notes",
        dataField: "notes",
        formatter: (cell, { shipmentNotes }) => {
          return <div style={{ maxWidth: "6rem" }}>{shipmentNotes}</div>;
        },
      },
      {
        text: "Delete",
        dataField: "delete",
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeleteProduct(row)}
              title="Are you sure you want to delete the product?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Products</b>
        <span style={{ float: "right" }}>
          <Button color="primary" size="sm" outline onClick={openOrderedProductAddPopup}>
            <i className="fa fa-plus"></i>Add Product
          </Button>
        </span>
      </h4>
      <br />
      {products.length > 0 && (
        <Row>
          <Col xl="12">
            <div className="table-responsive">
              <BootstrapTable keyField="id" data={products} columns={productColumns} />
            </div>
          </Col>
        </Row>
      )}
      {orderedProductState.open && (
        <TransferOrderedProductModal
          onClose={handleCloseOrderedProduct}
          onSubmit={handleOrderedAddProduct}
          branchId={transfer.senderLocation.id}
        />
      )}
      {imageState.variantId && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productId, imageState.variantId)}
          onClose={() => setImageState({})}
          title="Product Images"
        />
      )}
    </>
  );
};

const TransferOrderedProductModal = ({ branchId, product, onClose, onSubmit }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!product?.id ? "Add new product" : "Edit product"}</ModalHeader>
      <ModalBody>
        <TransferOrderedProductForm initialProduct={product} onSubmit={onSubmit} branchId={branchId} />
      </ModalBody>
    </Modal>
  );
};

export default TransferProducts;
