import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import ValidationForm from "components/ValidationForm";
import VariantModal from "pages/Product/VariantModal";
import { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Badge, Button, Card, CardBody, CardFooter, Col, Input, Label, Spinner } from "reactstrap";
import { getOrderTypes } from "store/definitions/services";
import { getProductsInStock, getProductsInStockCount } from "store/order/ordered-product-services";
import VariantCard from "../Product/VariantCard";
import orderUtils from "./order_utils";

const OrderProductForm = ({
  initialProduct = {
    id: null,
    variant: {},
    notes: "",
    discountRate: 0,
    netPrice: 0,
    quantity: 1,
    orderType: null,
    inStockProduct: null,
  },
  onSubmit,
}) => {
  const [openVariantPopup, setOpenVariantPopup] = useState(false);
  const [saving, setSaving] = useState(false);
  const [product, setProduct] = useState({ ...initialProduct });
  const [stockCount, setStockCount] = useState(null);

  useEffect(() => {
    async function loadStockCount() {
      const count = await getProductsInStockCount(product.variant.sku);
      setStockCount(count);
    }
    if (product?.variant?.sku) {
      loadStockCount();
    }
  }, [product?.variant?.sku]);

  const handleSelectVariant = ({ variant }) => {
    setProduct((prev) => ({
      ...prev,
      variant,
      inStockProduct: null,
      orderType: null,
      quantity: prev.quantity || 1,
      discountRate: +prev.discountRate || 0,
      netPrice: Number((+variant.finalPrice * (100 - (+prev.discountRate || 0))) / 100).toFixed(2),
    }));
    setOpenVariantPopup(false);
  };
  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleDiscountChange = (discountRate) => {
    if (product.variant && +product.variant.finalPrice > 0) {
      setProduct((prevValues) => ({
        ...prevValues,
        netPrice: Number((+product.variant.finalPrice * (100 - discountRate)) / 100).toFixed(2),
      }));
    }
  };
  const handlePriceChange = (price) => {
    if (product.variant && +product.variant.finalPrice > 0) {
      setProduct((prevValues) => ({
        ...prevValues,
        discountRate: Number(100 - (+price / +product.variant.finalPrice) * 100).toFixed(2),
      }));
    }
  };
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit(orderUtils.mapToProductAddModel(product));
    } finally {
      setSaving(false);
    }
  };

  return (
    <ValidationForm>
      <Card className="shadow-lg">
        <CardBody>
          <div className="row mb-4">
            <Label htmlFor="variant" className="col-sm-3 col-form-label">
              Product
            </Label>
            <Col sm={9}>
              {product.variant?.id && <VariantCard variant={product.variant} />}
              {!product.variant?.id && (
                <Button color="primary" className="rounded" onClick={() => setOpenVariantPopup(true)}>
                  <i className="fa fa-plus"></i>
                </Button>
              )}
              <input name="variantId" value={product.variant?.id} validations={["required"]} type="hidden" />
            </Col>
          </div>
          {stockCount != null && (
            <div className="row">
              <Col sm={12} style={{ textAlign: "right" }}>
                In Stock Count <Badge color="danger">{stockCount}</Badge>
              </Col>
            </div>
          )}
          <div className="row mb-4">
            <Label htmlFor="orderType" className="col-sm-3 col-form-label">
              Order Type
            </Label>
            <Col sm={9}>
              <MultiselectWithService
                service={getOrderTypes}
                name="orderType"
                validations={["required"]}
                value={product.orderType?.value}
                isMulti={false}
                isDisabled={product.id != null}
                setValue={handleSetValueObj}
                validationeventname="setValue"
              />
            </Col>
          </div>
          {product.orderType?.value == "STOCK" && product.variant?.sku && product.id == null && (
            <div className="row mb-4">
              <Label htmlFor="orderType" className="col-sm-3 col-form-label">
                Store
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  service={() => getProductsInStock(product.variant.sku)}
                  name="inStockProduct"
                  value={product.inStockProduct?.value}
                  isMulti={false}
                  setValue={handleSetValueObj}
                  validationeventname="setValue"
                  validations={["required"]}
                />
              </Col>
            </div>
          )}
          <div className="row mb-4">
            <Label htmlFor="quantity" className="col-sm-3 col-form-label">
              Quantity
            </Label>
            <Col sm={9}>
              <NumberFormat
                value={product.quantity}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                decimalScale={0}
                validationeventname="onValueChange"
                disabled={product.id != null}
                name="quantity"
                validations={["min|1", `max|${product.orderType?.value == "STOCK" ? stockCount : 999999}`]}
                onValueChange={({ value }) => {
                  handleSetValue("quantity", value);
                }}
              />
            </Col>
          </div>
          <div className="row mb-4">
            <Label htmlFor="discountRate" className="col-sm-3 col-form-label">
              Discount Rate / Item Net Price
            </Label>
            <Col sm={4}>
              <NumberFormat
                value={product.discountRate}
                className="form-control"
                placeholder="Discount Rate.."
                validationeventname="onValueChange"
                validations={[`max|100`]}
                allowNegative={false}
                name="discountRate"
                suffix="%"
                onValueChange={({ value }, { event }) => {
                  handleSetValue("discountRate", value);
                  event && handleDiscountChange(value);
                }}
              />
            </Col>
            <Col sm={5}>
              <NumberFormat
                value={product.netPrice}
                className="form-control"
                placeholder="Net Price..."
                allowNegative={false}
                name="netPrice"
                validationeventname="onValueChange"
                validations={[`max|${product.variant?.finalPrice}`]}
                onValueChange={({ value }, { event }) => {
                  handleSetValue("netPrice", value);
                  event && handlePriceChange(value);
                }}
              />
            </Col>
          </div>
          <div className="row mb-4">
            <Label htmlFor="notes" className="col-sm-3 col-form-label">
              Notes
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                value={product.notes}
                name="notes"
                placeholder="notes.."
                className="form-control"
                onChange={handleChange}
              />
            </Col>
          </div>
        </CardBody>
        <CardFooter>
          {product.variant?.id && product.id == null && (
            <>
              <Button color="primary" onClick={() => setOpenVariantPopup(true)}>
                Change Product
              </Button>
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          <Button color="success" onClick={handleSubmit} disabled={saving} validate>
            {!product.id && "Add to order "}
            {product.id && "Update "}
            {saving && <Spinner size="sm" />}
          </Button>
        </CardFooter>
      </Card>
      {openVariantPopup && <VariantModal onClose={() => setOpenVariantPopup(false)} onSelect={handleSelectVariant} />}
    </ValidationForm>
  );
};

export default OrderProductForm;
