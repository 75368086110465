import { atom } from "recoil";
import inventoryUtils from "./inventory_utils";

export const inventoryAppliedFilterState = atom({
  key: "Inventory.appliedFilter",
  default: inventoryUtils.initialFilterValues(),
});
export const inventoryAppliedSortState = atom({
  key: "Inventory.appliedSort",
  default: { sortField: "stock", sortOrder: "desc" },
});
export const inventoryInStockOnly = atom({
  key: "Inventory.inStockOnly",
  default: true,
});
