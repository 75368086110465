import enums, { getEnumLabel } from "common/enums";
import DateOutput from "components/Common/DateOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Card, CardBody, Label, Col, Row, Table } from "reactstrap";
import { getCustomer } from "store/customer/services";
import { getCustomerOrder } from "store/order/services";
import CustomerDocuments from "./CustomerDocuments";
import CustomerNotes from "./CustomerNotes";

const CustomerDetail = () => {
  let { id: customerId } = useParams();
  const [customer, setCustomer] = useState();
  const [customerOrders, setCustomerOrders] = useState([]);
  const activeUser = useActiveUser();

  useEffect(() => {
    if (customerId) {
      loadCustomer(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId) {
      loadCustomerOrder(customerId);
    }
  }, [customerId]);

  const loadCustomer = async (customerId) => {
    const mdl = await getCustomer(customerId);
    setCustomer(mdl);
  };
  const loadCustomerOrder = async (customerId) => {
    const { content } = await getCustomerOrder(customerId);
    setCustomerOrders(content);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Novia - Customer Detail</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        <h2 className="text-primary">
          <b>Customer Detail</b>
        </h2>
        <Row>
          <Col sm="12">
            <Card className="shadow">
              <CardBody className="pt-0">
                <div className="px-2 py-2">
                  <Row className="py-1">
                    {customer?.customerType == "COMPANY" && <h6 className="col-sm-2">Company Name</h6>}
                    {customer?.customerType != "COMPANY" && <h6 className="col-sm-2">Customer Name</h6>}
                    <Col sm={4}>
                      {customer?.customerType == "PERSON" && <>{customer?.title}</>} {customer?.fullName}
                    </Col>
                    <h6 className="col-sm-2">Email</h6>
                    <Col sm={4}>{customer?.email}</Col>
                  </Row>
                  <Row className="py-1">
                    <h6 className="col-sm-2">Mobile Phone</h6>
                    <Col sm={4}>{customer?.mobilePhone}</Col>
                    <h6 className="col-sm-2">Alternative Phone</h6>
                    <Col sm={4}>{customer?.phone}</Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="shadow">
              <CardBody className="pt-4">
                <div>
                  <h5 className="text-primary">
                    <b>Address Detail</b>
                  </h5>
                  <div className="row ">
                    <Table>
                      <thead>
                        <tr>
                          <th>id</th>
                          <th>address1</th>
                          <th>address2</th>
                          <th>city</th>
                          <th>town</th>
                          <th>houseType</th>
                          <th>floor</th>
                          <th>latitude</th>
                          <th>lift</th>
                          <th>longitude</th>
                          <th>parking</th>
                          <th>postCode</th>
                        </tr>
                      </thead>

                      <tbody>
                        {customer?.addresses?.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.address1}</td>
                            <td>{item.address2}</td>
                            <td>{item.city}</td>
                            <td>{item.town}</td>
                            <td>{item.houseType}</td>
                            <td>{item.floor}</td>
                            <td>{item.latitude}</td>
                            <td>{item.lift}</td>
                            <td>{item.longitude}</td>
                            <td>{item.parking}</td>
                            <td>{item.postCode}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Card className="shadow">
              <CardBody className="pt-4">
                <div>
                  <h5 className="text-primary">
                    <b>Customer Orders</b>
                  </h5>
                  <div className="row ">
                    <Table>
                      <thead>
                        <tr>
                          <th>Order Id</th>
                          <th>Order Status</th>
                          <th>Create User</th>
                          <th>Sales Person</th>
                          <th>Branch</th>
                          <th>Order Date</th>
                          <th>Delivery Date</th>
                          {activeUser.hasPrivilege(Privileges.CUSTOMER.BALANCE_VIEW) && (
                            <th className="align-r">Balance</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {customerOrders.map((item) => (
                          <tr key={item.id}>
                            <td>
                              {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (
                                <Link to={`/order/${item.id}/detail`} title="View order detail">
                                  {item.orderId}
                                </Link>
                              )}
                              {!activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && item.orderId}
                            </td>
                            <td>{getEnumLabel(enums.orderStatus, item.orderStatus)}</td>
                            <td>{item.createUser}</td>
                            <td>{item.salesPerson?.fullName}</td>
                            <td>{item.branch?.name}</td>
                            <td>
                              <DateOutput date={item.createdDate} />
                            </td>
                            <td>
                              <DateOutput date={item.deliveryDate} />
                            </td>
                            {activeUser.hasPrivilege(Privileges.CUSTOMER.BALANCE_VIEW) && (
                              <td className="align-r">
                                <MoneyOutput value={item.balance} currency="GBP" />
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Card className="shadow">
              <CardBody className="pt-4">
                <CustomerNotes customerId={customerId} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Card className="shadow">
              <CardBody className="pt-4">
                <CustomerDocuments customerId={customerId} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default CustomerDetail;
