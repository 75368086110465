import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import { Button, Input, Label, Modal, Row } from "reactstrap";
import * as Yup from "yup";
import {
  createManufacturers,
  deleteManufacturers,
  editManufacturers,
  getManufacturers,
} from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Address from "../Address/ManufacturersAddress";
import Privileges from "models/Privileges";

export const manufacturerFormValues = [
  { name: "name", label: "Name", size: 6, type: "text" },
  { name: "email", label: "Email", size: 6, type: "text" },
  { name: "phone", label: "Phone", size: 6, type: "text" },
  { name: "mobilePhone", label: "Mobile Phone", size: 6, type: "text" },
  { name: "deliveryDay", label: "Delivery Day", size: 6, type: "number" },
  { name: "status", label: "status", size: 12, type: "checkbox" },
];
const ManufaturersPage = () => {
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "mobilePhone",
      text: "Mobile Phone",
      sort: true,
    },
    {
      dataField: "deliveryDay",
      text: "Delivery Day",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      formatter: (cellContent, row) => {
        return <AddressPage page row={row} />;
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Manufacturers</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getManufacturers,
            deleteapi: deleteManufacturers,
            createapi: createManufacturers,
            updateapi: editManufacturers,
          }}
          tableColumns={columns}
          formValues={{
            values: manufacturerFormValues,
            validationSchema: {
              name: Yup.string().required("Please enter a name"),
              email: Yup.string().email("Invalid email format,please add your domain @..."),
              phone: Yup.number().typeError("you must specify a number"),
              mobilePhone: Yup.string().typeError("you must specify a number"),
              deliveryday: Yup.number(),
              status: Yup.string(),
            },
          }}
          pageDetails={{
            definitionName: "Manufacturer",
            title: "Manufacturer List",
          }}
          privilege={{
            create: Privileges.MANUFACTURER.CREATE,
            update: Privileges.MANUFACTURER.UPDATE,
            delete: Privileges.MANUFACTURER.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

const AddressPage = ({ row }) => {
  return (
    <Fragment>
      <Link to={{ pathname: `/manufacturer/${row.id}/address`, state: `${row.name}` }} className="btn btn-secondary">
        Address
      </Link>
    </Fragment>
  );
};

export default withRouter(ManufaturersPage);
