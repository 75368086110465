import { get } from "lodash";
import { getAllEnumTypes } from "store/definitions/services";

export const EnumValues = {
  PAID_TYPES: {
    CARD: "CARD",
    CASH: "CASH",
    BANK_TRANSFER: "BANK_TRANSFER",
    DEVICE: "DEVICE",
    STORE_CREDIT: "STORE_CREDIT",
  },
  ORDER_STATUS: {
    ORDERED: "ORDERED",
    PROFORMA: "PROFORMA",
    IN_PROGRESS: "IN_PROGRESS",
    PARTIAL_DELIVERED: "PARTIAL_DELIVERED",
    DELIVERED: "DELIVERED",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    WAITING_FOR_APPROVAL: "WAITING_FOR_APPROVAL",
  },
  DELIVERY_STATUS: {
    PLANNING: "PLANNING",
    PLANNED: "PLANNED",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
  },
  PRODUCTION_ORDER_STATUS: {
    PLANNING: "PLANNING",
    IN_PRODUCTION: "IN_PRODUCTION",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
  },
  PROFORMA_CANCEL_STATUS: {
    ANOTHER_COMPANY: "ANOTHER_COMPANY",
    NOT_INTERESTED: "NOT_INTERESTED",
    NO_REPLY: "NO_REPLY",
    SOLD_FROM_OTHER_BRANCH: "SOLD_FROM_OTHER_BRANCH",
    SOLD: "SOLD",
    ACTIVE: "ACTIVE",
  },
  ORDERED_PRODUCT_STATUS: {
    NEW_ORDER: "NEW_ORDER",
    STOCK: "STOCK",
    IN_PRODUCTION: "IN_PRODUCTION",
    WAITING_FOR_PRODUCTION: "WAITING_FOR_PRODUCTION",
    IN_SHIPMENT: "IN_SHIPMENT",
    WAITING_FOR_SHIPMENT: "WAITING_FOR_SHIPMENT",
    IN_DELIVERY: "IN_DELIVERY",
    DELIVERY_PLANNED: "DELIVERY_PLANNED",
    IN_TRANSFER: "IN_TRANSFER",
    IN_STOCK: "IN_STOCK",
    STOCK_CANDIDATE: "STOCK_CANDIDATE",
    STOCK_IN_PRODUCTION: "STOCK_IN_PRODUCTION",
    STOCK_IN_SHIPMENT: "STOCK_IN_SHIPMENT",
    WAITING_FOR_DELIVERY: "WAITING_FOR_DELIVERY",
    WAITING_FOR_TRANSFER_FOR_ORDER: "WAITING_FOR_TRANSFER_FOR_ORDER",
    WAITING_FOR_GOOD_ACCEPTANCE: "WAITING_FOR_GOOD_ACCEPTANCE",
    DELIVERED: "DELIVERED",
    DELETED: "DELETED",
    IN_REPAIR: "IN_REPAIR",
    IN_SSH: "IN_SSH",
    DISAGREEMENT_IN_SHIPMENT: "DISAGREEMENT_IN_SHIPMENT",
    CANCELLED: "CANCELLED",
  },
  SHIPMENT_STATUS: {
    DRAFT: "DRAFT",
    PLANNING: "PLANNING",
    ON_THE_WAY: "ON_THE_WAY",
    CANCELLED: "CANCELLED",
    COMPLETED: "COMPLETED",
  },
};

const enums = {
  inputTypes: {},
  orderStatus: {},
  productionOrderStatus: {},
  orderTypes: {},
  houseTypes: {},
  personalTitle: {},
  shipmentStatus: {},
  vehicleTypes: {},
  volumeTypes: {},
  paymentTypes: {},
  paidTypes: {},
  mediaTypes: {},
  transferStatus: {},
  deliveryStatus: {},
  sshStatus: {},
  orderedProductStatus: {},
  documentTypes: {},
  customerTypes: {},
  actionTypes: {},
  proformaCancelStatus: {},
  financePaymentStatuses: {},
};

export function getEnumLabel(obj, e) {
  return get(obj, e, e);
}

export async function loadEnums() {
  const data = await getAllEnumTypes();
  Object.keys(enums).forEach((key) => {
    const vals = data[key];
    if (vals && vals.length > 0) {
      vals.forEach(({ value, label }) => {
        enums[key][value] = label;
      });
    }
  });
  console.log("enums loaded: ", enums);
}

export default enums;
