import moment from "moment";

class InvoiceUtils {
  initialFilterValues = () => ({
    docType: "",
    customerFullName: "",
    authByFullName: "",
    invoiceNumber: null,
    receiptNumber: null,
    orderId: null,
    beforeThan: null,
    afterThan: null,
    branchId: null,
  });

  mapToOrderSearch = (
    filter = {
      docType: "",
      customerFullName: "",
      authByFullName: "",
      invoiceNumber: null,
      receiptNumber: null,
      orderId: null,
      beforeThan: null,
      afterThan: null,
      branchId: null,
    }
  ) => {
    return {
      branchId: filter.branchId,
      docType: filter.docType,
      customerFullName: filter.customerFullName,
      authByFullName: filter.authByFullName,
      invoiceNumber: filter.invoiceNumber,
      receiptNumber: filter.receiptNumber,
      orderId: filter.orderId,
      beforeThan: filter.beforeThan != null ? moment(filter.beforeThan).format("YYYY-MM-DD") : "",
      afterThan: filter.afterThan != null ? moment(filter.afterThan).format("YYYY-MM-DD") : "",
    };
  };
}
const invoiceUtils = new InvoiceUtils();
export default invoiceUtils;
