export function ibanKontrol(ibanDeger) {
  // IBAN değeri girilmiş mi?
  if (ibanDeger == null || ibanDeger === '') {
    return 0;
  }

  // TR ile başlıyor mu? (Hesap no'nun IBAN'mı normal
  // hesap no'mu olduğu burda kontrol ediliyor)
  if (ibanDeger.length > 1 && ibanDeger.substr(0, 2).toUpperCase() !== 'TR') {
    return 2;
  }

  // IBAN 26 karakter uzunluğunda mı?
  if (ibanDeger.length !== 26) {
    return 26;
  }

  // Banka kodu sayısal mı?
  if (ibanDeger.substr(4, 5).match(/[0-9]{5}/) == null) {
    return 5;
  }

  // Kontrol alanı sayısal mı?
  if (ibanDeger.substr(2, 2).match(/[0-9]{2}/) == null) {
    return 22;
  }

  // Rezerv alan sıfır mı?
  if (parseInt(ibanDeger.charAt(9), 10) !== 0) {
    return 9;
  }

  if (ibanDeger.substr(10, 16).match(/[A-Z0-9]{16}/) == null) {
    return 16;
  }

  // mod 97 kontrolü
  const strSol4 = ibanDeger.substr(0, 4);
  const strSag22 = ibanDeger.substr(4, 22);
  const ibanDeger2 = strSag22 + strSol4;
  let ibanDeger3 = '';

  for (let i = 0; i < 26; i += 1) {
    const karakter = ibanDeger2.charAt(i);
    if (karakter.match(/[0-9]{1}/) == null) ibanDeger3 += donustur(karakter);
    else ibanDeger3 += karakter;
  }

  function donustur(karakter) {
    const sayi = parseInt(karakter, 10);
    if (!Number.isNaN(sayi)) {
      return sayi;
    }
    return karakter.charCodeAt(0) - 55;
  }
  // var d = ibanDeger3 % 97;
  // mod 97 28+ basamalı bu sayılar için düzgün hesap
  // yapamıyor, 9ar basamak alıp yapıyoruz
  // tebliğde yazdığı üzere - Ufuk

  let uzunluk = ibanDeger3.length;

  while (parseInt(ibanDeger3, 10) > 96) {
    const strSol9 = parseInt(ibanDeger3.substr(0, 9), 0);
    const mod = strSol9 % 97;
    ibanDeger3 = mod + ibanDeger3.substr(9, uzunluk - 9);
    uzunluk = ibanDeger3.length;
  }

  if (parseInt(ibanDeger3, 10) !== 1) {
    return 97;
  }

  return 1;
}
