import Roles from "./Roles";

export class ActiveUser {
  roles = new Set();
  privileges = new Set();
  name = "";
  email = "";
  id = null;
  initial = true;
  constructor(
    userDetail = {
      name: "",
      email: "",
      id: null,
      roles: [
        {
          name: "",
          description: "",
          privileges: [
            {
              name: "",
              code: "",
            },
          ],
        },
      ],
      initial: true,
      privileges: [
        {
          name: "",
          code: "",
        },
      ],
    }
  ) {
    this.initial = userDetail.initial;
    userDetail.roles.forEach((role) => {
      this.roles.add(role.name);
      role.privileges.forEach((privilege) => {
        this.privileges.add(privilege.code);
      });
    });
    userDetail.privileges.forEach((privilege) => {
      this.privileges.add(privilege.code);
    });
    this.name = userDetail.name;
    this.email = userDetail.email;
    this.id = userDetail.id;
  }

  hasRole = (role) => {
    return this.isAdmin() || this.roles.has(role);
  };
  hasPrivilege = (privilege) => {
    if (this.isAdmin()) {
      return true;
    }
    if (Array.isArray(privilege)) {
      return privilege.find((p) => this.privileges.has(p)) != null;
    }
    // return privilege.includes("VIEW") || privilege.includes("MENU") || this.privileges.has(privilege);
    return this.privileges.has(privilege);
  };

  isAdmin = () => {
    return this.roles.has(Roles.ADMIN);
  };
}
