import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Switch, Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./assets/main.css";

import history from "routes/history";
import { loadEnums } from "common/enums";
import { ActiveUserProvider } from "contexts/ActiveUserContext";
import { useActiveUser } from "hooks";
import { authService } from "services/AuthService";
import Pages403 from "pages/Utility/pages-403";
import ScrollToTop from "components/Common/ScrollToTop";

//import fakeBackend from "./helpers/AuthType/fakeBackend"

//fakeBackend()

const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  useEffect(() => {
    if (authService.isLoggedIn()) {
      loadEnums();
    }
  }, [authService.getAuthToken() != null]);

  const Layout = getLayout();
  return (
    <React.Fragment>
      <ActiveUserProvider>
        <RouterWrapper layout={Layout} />
        <ScrollToTop />
      </ActiveUserProvider>
    </React.Fragment>
  );
};

function RouterWrapper({ layout }) {
  const activeUser = useActiveUser();
  return (
    <Router history={history}>
      <Switch>
        {publicRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            exact
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={layout}
            key={idx}
            component={!route.privilege || activeUser.hasPrivilege(route.privilege) ? route.component : Pages403}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </Router>
  );
}

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
