import { Button, UncontrolledTooltip } from "reactstrap";
import React from "react";

function VariantDefaultImage({ onClick, variant, width = 75, toolTipWidth = 300 }) {
  const { defaultImage, id } = variant;
  if (defaultImage != null) {
    return (
      <div onClick={onClick} style={{ cursor: "pointer" }}>
        <img src={defaultImage.awsUrl} width={width} id={`variant_image_${id}`} />
        <UncontrolledTooltip target={`variant_image_${id}`}>
          <img src={defaultImage.awsUrl} width={toolTipWidth} />
        </UncontrolledTooltip>
      </div>
    );
  }
  return (
    <Button color="primary" size="sm" onClick={onClick}>
      <i className="fa fa-image"></i>
    </Button>
  );
}

export default VariantDefaultImage;
