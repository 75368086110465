import moment from "moment";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Button, Input, Label } from "reactstrap";
import { getProductAttributeDetail } from "store/definitions/services";

const anonymousFunc = () => {};

const Attribute = ({
  id,
  value = "",
  values = [],
  setValue = anonymousFunc,
  setValues = anonymousFunc,
  options,
  onDelete,
  isMulti = true,
}) => {
  const [attribute, setAttribute] = useState({
    id: null,
    name: "",
    inputType: null,
    options: [],
  });
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectedOptions = (opts) => {
    const validItems = opts.filter((item) => values.find((x) => x == item.value) != null);
    const invalidItems = values
      .filter((item) => opts.find((opt) => opt.value == item) == null)
      .map((item) => ({
        label: "Invalid - " + item,
        value: item,
      }));
    setSelectedOptions([...validItems, ...invalidItems]);
  };

  useEffect(async () => {
    const response = await getProductAttributeDetail(id);
    const opts = options
      ? options
      : response.options.map((item) => ({
          label: item.name,
          value: item.id,
        }));
    setAttribute({ id: response.id, name: response.name, inputType: response.inputType, options: opts });
    if (response.inputType == "SELECT") {
      handleSelectedOptions(opts);
    }
  }, []);

  useEffect(() => {
    if (attribute.inputType == "SELECT") {
      handleSelectedOptions(attribute.options);
    }
  }, [JSON.stringify(values?.sort())]);
  const animatedComponents = makeAnimated();
  const htmlId = `attr_${attribute.id}`;
  return (
    <div className="row mb-4">
      <Label className="col-sm-3 col-form-label" htmlFor={htmlId}>
        {attribute.name}
      </Label>
      <div className={onDelete ? "col-8" : "col-9"}>
        {(attribute.inputType == "NUMBER" || attribute.inputType == "DECIMAL") && (
          <Input
            type="number"
            value={value}
            placeholder="only numbers.."
            className="form-control"
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        )}
        {attribute.inputType == "TEXT" && (
          <Input
            type="text"
            value={value}
            placeholder={`${attribute.name}..`}
            className="form-control"
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        )}
        {attribute.inputType == "TEXTAREA" && (
          <Input
            type="textarea"
            value={value}
            placeholder={`${attribute.name}..`}
            className="form-control"
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        )}
        {attribute.inputType == "DATE" && (
          <ReactDatePicker
            dateFormat="yyyy-MM-dd"
            isClearable
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            selected={value ? moment(value, "YYYY-MM-DD").isValid() && moment(value, "YYYY-MM-DD").toDate() : value}
            placeholderText={`${attribute.name}..`}
            className="form-control"
            onChange={(date) => {
              setValue(date ? moment(date).format("YYYY-MM-DD") : null);
            }}
          />
        )}
        {attribute.inputType == "CHECKBOX" && (
          <div className="form-check form-switch mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id={htmlId}
              onChange={(e) => setValue(e.target.checked ? "y" : "n")}
              // checked={value === "y"}
              defaultChecked={value === "y"}
            />
          </div>
        )}
        {attribute.inputType == "SELECT" && (
          <Select
            isMulti={isMulti}
            onChange={(vals) => {
              if (isMulti) {
                setValues(
                  vals.map((item) => item.value),
                  vals
                );
              } else {
                setValues(vals && [vals.value], vals && [vals]);
              }
            }}
            components={animatedComponents}
            value={selectedOptions}
            options={attribute.options}
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={!isMulti}
          />
        )}
      </div>
      {onDelete && (
        <div className="col-1 p-1">
          <Button color="danger" size="sm" onClick={() => onDelete(attribute.id)}>
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Attribute;
