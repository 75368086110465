import enums, { getEnumLabel } from "common/enums";
import ConfirmButton from "components/Common/ConfirmButton";
import CustomSpinner from "components/Common/CustomSpinner";
import UploadDocumentModal from "components/Common/UploadDocumentModal";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { addDocumentToOrder, deleteOrderDocument, getOrderDocuments } from "../../store/order/services";

const initialDocumentState = { open: false, error: null, loading: false, document: {} };
const OrderDocuments = ({ orderId, type }) => {
  const [documentState, setDocumentState] = useState(initialDocumentState);
  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(false);
  const openDocumentAddPopup = () => {
    setDocumentState((prev) => ({ ...prev, document: {}, open: true }));
  };
  const handleUploadDocument = async ({ file, type }) => {
    const fd = new FormData();
    fd.append("file", file, file.path);
    return addDocumentToOrder(orderId, fd, type);
  };
  const handleUploadSuccess = () => {
    loadDocuments(orderId);
  };
  const handleCloseDocument = () => {
    setDocumentState((prev) => ({ ...prev, open: false }));
  };
  const loadDocuments = async (orderId) => {
    setLoading(true);
    try {
      const docs = await getOrderDocuments(orderId);
      const docsMap = {};
      docs.forEach((doc) => {
        let curr = docsMap[doc.mediaType];
        if (curr == null) {
          curr = [];
          docsMap[doc.mediaType] = curr;
        }
        curr.push(doc);
      });
      setDocuments(docsMap);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteDocument = async ({ id }) => {
    await deleteOrderDocument(orderId, id);
    loadDocuments(orderId);
  };
  useEffect(() => {
    loadDocuments(orderId);
  }, [orderId]);
  return (
    <>
      <h4 className="text-secondary">
        <b>Documents</b>
        {type != "view" && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" onClick={openDocumentAddPopup}>
              <i className="fa fa-plus"></i>Add Document
            </Button>
          </span>
        )}
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading &&
            Object.keys(documents).map((mediaType) => (
              <li key={mediaType}>
                {getEnumLabel(enums.mediaTypes, mediaType)}
                {documents[mediaType].map((doc) => (
                  <ul key={doc.id}>
                    <span>
                      <a href={doc.awsUrl} target="_blank">
                        {doc.fileName}
                      </a>
                      , <strong>Created At</strong> {doc.createdDate}
                      {type != "view" && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <ConfirmButton
                            size="sm"
                            color="danger"
                            onConfirm={() => handleDeleteDocument(doc)}
                            title="Are you sure you want to delete the note?"
                          >
                            <i className="fa fa-trash"></i>
                          </ConfirmButton>
                        </>
                      )}
                    </span>
                  </ul>
                ))}
              </li>
            ))}
        </Col>
      </Row>
      {documentState.open && (
        <UploadDocumentModal
          onClose={handleCloseDocument}
          onUpload={handleUploadDocument}
          onUploadSuccess={handleUploadSuccess}
          showSelectMedia
        />
      )}
    </>
  );
};

export default OrderDocuments;
