import enums, { getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import DateOutput from "components/Common/DateOutput";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import Privileges from "models/Privileges";
import { PropTypes } from "prop-types";
import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Row,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { downloadService } from "services/DownloadService";
import { deleteTicket, getTicketPdf, getTickets } from "store/ass/services";
import { ticketsAppliedFilterState, ticketsAppliedSortState } from "./atoms";
import TicketFilterForm from "./TicketFilterForm";
import ticketUtils from "./ticket_utils";

const Tickets = ({ history }) => {
  const activeUser = useActiveUser();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState();
  const [deleteConfirmState, setDeleteConfirmState] = useState({
    open: false,
    id: null,
  });
  const [appliedFilter, setAppliedFilter] = useRecoilState(ticketsAppliedFilterState);
  const [appliedSort, setAppliedSort] = useRecoilState(ticketsAppliedSortState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("Tickets");

  const loadTickets = async (page, filter, sort) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getTickets(page, ticketUtils.mapToTicketSearch(filter), sort);
      setTotalSize(totalElements);
      setTickets(content);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (type, details) => {
    // console.log("handlePageChange", details, type);
    const { page, sizePerPage, sortField, sortOrder } = details;
    if (type == "pagination") {
      loadTickets(setPageNumber(page, sizePerPage), appliedFilter, appliedSort);
    } else if (type == "sort") {
      const newSort = { sortField, sortOrder };
      // console.log(appliedSort, newSort);
      setAppliedSort(newSort);
      loadTickets(pageOptions, appliedFilter, newSort);
    }
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    const newPage = setPageNumber(0);
    loadTickets(newPage, appliedFilter, appliedSort);
    setHasFilter(hasAnyValue(appliedFilter));
  };

  const handleDelete = async (id) => {
    try {
      await deleteTicket(id);
      toast.info("Ticket deleted successfully");
      loadTickets(pageOptions, appliedFilter, appliedSort);
    } finally {
      setDeleteConfirmState({ open: false });
    }
  };

  const handleDownloadTicket = async (orderId) => {
    const data = await getTicketPdf(orderId);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };

  const columns = [
    {
      dataField: "actions",
      text: "Actions",
      align: "center",
      headerAlign: "center",
      hidden:
        !activeUser.hasPrivilege(Privileges.ASS.UPDATE) &&
        !activeUser.hasPrivilege(Privileges.ASS.DELETE) &&
        !activeUser.hasPrivilege(Privileges.ASS.DETAIL),
      formatter: (cell, row) => {
        return (
          <ActionMenu>
            <DropdownMenu>
              {activeUser.hasPrivilege(Privileges.ASS.DETAIL) && (
                <DropdownItem onClick={() => history.push(`/ticket/${row.id}`)}>Edit Ticket</DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.ASS.UPDATE) && (
                <DropdownItem onClick={() => history.push(`/ticket/${row.id}/detail`)}>View Ticket</DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.ASS.DELETE) && (
                <DropdownItem
                  onClick={() => {
                    setDeleteConfirmState({ open: true, id: row.id });
                  }}
                >
                  Delete Ticket
                </DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (
                <DropdownItem onClick={() => handleDownloadTicket(row.id)}>Export to PDF</DropdownItem>
              )}
            </DropdownMenu>
          </ActionMenu>
        );
      },
    },
    {
      text: "Ticket Number",
      dataField: "id",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {activeUser.hasPrivilege(Privileges.ASS.DETAIL) && (
              <Link to={`/ticket/${row.id}/detail`} title="View ticket detail">
                {cell}
              </Link>
            )}
            {!activeUser.hasPrivilege(Privileges.ASS.DETAIL) && cell}
          </>
        );
      },
    },
    {
      dataField: "order.orderId",
      text: "Order Number",
      sort: true,
      formatter: (cell, row) => {
        if (row.order == null) {
          return null;
        }
        return (
          <>
            {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (
              <Link to={`/order/${row.order.id}/detail`} title="View order detail">
                {cell}
              </Link>
            )}
            {!activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && cell}
          </>
        );
      },
    },
    {
      dataField: "sshStatus",
      text: "Ticket Status",
      sort: true,
      formatter: (cell) => getEnumLabel(enums.sshStatus, cell),
    },
    {
      dataField: "subject.name",
      text: "Subject",
      sort: true,
      formatter: (cell, row) => row?.problemSubject.name,
    },
    {
      dataField: "problem.name",
      text: "Problem Type",
      sort: true,
      formatter: (cell, row) => row?.problemType.name,
    },
    {
      dataField: "customer.fullName",
      text: "Customer",
      sort: true,
    },
    {
      dataField: "serviceDate",
      text: "Service Date",
      sort: true,
      formatter: (cell, row) => <DateOutput date={cell} />,
    },
    {
      dataField: "reminderDate",
      text: "Reminder Date",
      sort: true,
      formatter: (cell, row) => <DateOutput date={cell} />,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tickets</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <TicketFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="col-12">
              <Card>
                <CardTitle className="h4 px-4 pt-4">
                  <div className="d-flex justify-content-between">
                    <h4>Tickets</h4>
                    {activeUser.hasPrivilege(Privileges.ASS.CREATE) && (
                      <div style={{ float: "right" }}>
                        <Button color="primary" size="sm" onClick={() => history.push(`/create-ticket`)}>
                          <i className="fa fa-plus"></i> New Ticket
                        </Button>
                      </div>
                    )}
                  </div>
                </CardTitle>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        hover
                        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                        loading={loading}
                        defaultSorted={[{ dataField: appliedSort.sortField, order: appliedSort.sortOrder }]}
                        keyField="id"
                        responsive
                        bordered={false}
                        striped={false}
                        remote
                        pagination={paginationFactory(pageOptions)}
                        columns={columns}
                        data={tickets}
                        onTableChange={handlePageChange}
                        wrapperClasses="table-responsive"
                        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                        headerWrapperClasses={"thead-light"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {deleteConfirmState.open && (
        <SweetAlert
          title="Are you sure you want to delete the ticket"
          warning
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDelete(deleteConfirmState.id)}
          onCancel={() => {
            setDeleteConfirmState({ open: false });
          }}
        ></SweetAlert>
      )}
    </React.Fragment>
  );
};

Tickets.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Tickets);
