import { useEffect, useState } from "react";
import { Card, CardBody, Col, CardTitle, Row, Spinner } from "reactstrap";
import { getTransfer } from "store/transfer/services";
import enums, { getEnumLabel } from "common/enums";

const TransferCard = ({ id }) => {
  const [transferState, setTransferState] = useState({
    error: null,
    loading: false,
    transfer: null,
  });
  const { transfer } = transferState;
  const loadTransfer = async () => {
    setTransferState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const ord = await getTransfer(id);
      setTransferState((state) => ({
        ...state,
        transfer: ord,
        loading: false,
      }));
    } catch (error) {
      setTransferState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadTransfer();
  }, [id]);

  return (
    <Row>
      <Col md={12}>
        {transferState.loading && (
          <Spinner size="md" style={{ marginLeft: 20 }}>
            Loading...
          </Spinner>
        )}
        {transferState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Transfer could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {transferState.transfer && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Transfer Number</h6>
                <Col sm={4}>
                  <h5>{transfer.transferNo}</h5>
                </Col>
                <h6 className="col-sm-2">Transfer Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.transferStatus, transfer.transferStatus)}</h5>
                </Col>
              </Row>
              <Row className="py-1">
                <h6 className="col-sm-2">Created By</h6>
                <Col sm={4}>{transfer.createUser}</Col>
                <h6 className="col-sm-2">Created At</h6>
                <Col sm={4}>{transfer.createdDate}</Col>
              </Row>
              <Row className="py-1">
                <h6 className="col-sm-2">Sender Location</h6>
                <Col sm={4}>{transfer.senderLocation?.name}</Col>
                <h6 className="col-sm-2">Arrival Location</h6>
                <Col sm={4}>{transfer.arrivalLocation?.name}</Col>
              </Row>
              <Row className="py-1">
                <h6 className="col-sm-2">Vehicle Plate No</h6>
                <Col sm={4}>{transfer.vehiclePlateNo}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default TransferCard;
