import CustomLoadingIndicator from "components/Common/CustomLoadingIndicator";
import endpoints from "env/endpoints";
import { useHttpCache } from "hooks";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import history from "routes/history";
import { version } from "../../../package.json";

const Footer = () => {
  const { data: apiVersion, loading } = useHttpCache(endpoints.version.getVersion);

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              <div role="button" onClick={history.goBack}>
                Back
              </div>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {loading && <CustomLoadingIndicator />}
              {!loading && (
                <>
                  {apiVersion}/{version}
                </>
              )}
              {" - "}
              {new Date().getFullYear()} © Novia
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
