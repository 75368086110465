import enums, { EnumValues, getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import CustomLoadingIndicator from "components/Common/CustomLoadingIndicator";
import DateOutput from "components/Common/DateOutput";
import DateTimeOutput from "components/Common/DateTimeOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import CustomerAddressCard from "pages/Customers/CustomerAddressCard";
import CustomerCard from "pages/Customers/CustomerCard";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, DropdownItem, DropdownMenu, Row } from "reactstrap";
import history from "routes/history";
import { downloadService } from "services/DownloadService";
import { getTickets } from "store/ass/services";
import { getOrderDetailsPdf, getOrders, getProformaDetailsPdf } from "store/order/services";

const OrderCardView = ({ order }) => {
  const activeUser = useActiveUser();

  const handleDownloadOrderDetails = async () => {
    const data = await getOrderDetailsPdf(order.id);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };
  const handleDownloadProformaDetails = async () => {
    const data = await getProformaDetailsPdf(order.id);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };
  const [tickets, setTickets] = useState([]);
  const [loadingTickets, setLoadingTickets] = useState(false);

  const [customerOrders, setCustomerOrders] = useState([]);
  const [loadingCustomerOrders, setLoadingCustomerOrders] = useState(false);

  const loadTickets = async () => {
    setLoadingTickets(true);
    try {
      const response = await getTickets(
        undefined,
        { customerId: order.customer.id },
        { sortField: "id", sortOrder: "desc" }
      );
      setTickets(response.content);
    } finally {
      setLoadingTickets(false);
    }
  };
  const loadCustomerOrdes = async () => {
    setLoadingCustomerOrders(true);
    try {
      const response = await getOrders(
        undefined,
        { customerIds: [order.customer.id] },
        { sortField: "orderId", sortOrder: "desc" }
      );
      setCustomerOrders(response.content);
    } finally {
      setLoadingCustomerOrders(false);
    }
  };
  useEffect(() => {
    loadTickets();
    loadCustomerOrdes();
  }, [order.customer.id]);

  return (
    <Card className="shadow-lg">
      <CardBody>
        <Row className="py-1">
          {order.orderId && <h6 className="col-sm-2">Order Number</h6>}
          {!order.orderId && order.proformaId && <h6 className="col-sm-2">Proforma Number</h6>}
          <Col sm={4}>
            <h5>
              {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (
                <Link to={`/order/${order.id}/detail`} title="View detail">
                  {order.orderId || order.proformaId}
                </Link>
              )}
              {!activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (order.orderId || order.proformaId)}
              {activeUser.hasPrivilege(Privileges.ORDER.UPDATE) && (
                <Link to={`/order/${order.id}`} className="p-2" title="Edit">
                  <i className="fa fa-edit"></i>
                </Link>
              )}
              <ActionMenu toggleClassName="py-0" horizontal>
                <DropdownMenu>
                  {activeUser.hasPrivilege(Privileges.DELIVERY.CREATE) && (
                    <DropdownItem onClick={() => history.push(`/create-delivery?orderId=${order.id}`)}>
                      Create Delivery
                    </DropdownItem>
                  )}
                  {activeUser.hasPrivilege(Privileges.ASS.CREATE) && (
                    <DropdownItem
                      onClick={() => history.push(`/create-ticket?customerId=${order.customer.id}&orderId=${order.id}`)}
                    >
                      Create Ticket
                    </DropdownItem>
                  )}
                  {activeUser.hasPrivilege(Privileges.PROFORMA.DETAIL) && !order.orderId && (
                    <DropdownItem onClick={handleDownloadProformaDetails}>Proforma Details PDF</DropdownItem>
                  )}
                  {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && order.orderId && (
                    <DropdownItem onClick={handleDownloadOrderDetails}>Order Details PDF</DropdownItem>
                  )}
                </DropdownMenu>
              </ActionMenu>
            </h5>
          </Col>
          <h6 className="col-sm-2">Order Status</h6>
          <Col sm={4}>
            <div className="d-flex flex-row align-items-start">
              <h5>{getEnumLabel(enums.orderStatus, order.orderStatus)}</h5>
            </div>
          </Col>
          <h6 className="col-sm-2">Created</h6>
          <Col sm={4}>
            <b>by</b> {order.createUser} <b>at </b>
            <DateTimeOutput date={order.createdDate} />
          </Col>
          {order.proformaCancelStatus &&
            order.orderId == null &&
            (order.orderStatus == EnumValues.ORDER_STATUS.PROFORMA ||
              order.orderStatus == EnumValues.ORDER_STATUS.CANCELLED) && (
              <>
                <h6 className="col-sm-2">Proforma Status Detail</h6>
                <Col sm={4}>{getEnumLabel(enums.proformaCancelStatus, order.proformaCancelStatus)}</Col>
              </>
            )}
          <h6 className="col-sm-2">Sales Person</h6>
          <Col sm={4}>{order.salesPerson?.fullName}</Col>
          <h6 className="col-sm-2">Store</h6>
          <Col sm={4}>{order.deliveryBranch?.name}</Col>
          {activeUser.hasPrivilege(Privileges.CUSTOMER.BALANCE_VIEW) && (
            <>
              <h6 className="col-sm-2">Balance</h6>
              <Col sm={4}>
                <h5>
                  <MoneyOutput value={order.balance} currency="GBP" />
                </h5>
              </Col>
            </>
          )}
          <h6 className="col-sm-2">Expected Delivery Date</h6>
          <Col sm={4}>
            <DateOutput date={order.estimateDeliveryDate} />
          </Col>
          <h6 className="col-sm-2">Order Date</h6>
          <Col sm={4}>
            <DateOutput date={order.orderedDate} />
          </Col>
          <h6 className="col-sm-2">After Sale Services</h6>
          <Col sm={4}>
            {loadingTickets && <CustomLoadingIndicator />}
            {!loadingTickets && tickets.length == 0 && "-"}
            {!loadingTickets &&
              tickets.length != 0 &&
              tickets.map((item, index) => (
                <span key={item.id}>
                  {activeUser.hasPrivilege(Privileges.ASS.DETAIL) && (
                    <Link to={`/ticket/${item.id}/detail`} title="View ticket detail">
                      {item.id}
                    </Link>
                  )}
                  {!activeUser.hasPrivilege(Privileges.ASS.DETAIL) && item.id}
                  {index < tickets.length - 1 && ", "}
                </span>
              ))}
          </Col>
          <h6 className="col-sm-2">Customer Orders</h6>
          <Col sm={4}>
            {loadingCustomerOrders && <CustomLoadingIndicator />}
            {!loadingCustomerOrders && customerOrders.length == 0 && "-"}
            {!loadingCustomerOrders &&
              customerOrders.length != 0 &&
              customerOrders.map((item, index) => (
                <span key={item.id}>
                  {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (
                    <Link to={`/order/${item.id}/detail`} title="View order detail">
                      {item.orderId || item.proformaId}
                    </Link>
                  )}
                  {!activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (item.orderId || item.proformaId)}
                  {index < customerOrders.length - 1 && ", "}
                </span>
              ))}
          </Col>
          {order.approvedUser && (
            <>
              <h6 className="col-sm-2">Approved</h6>
              <Col sm={4}>
                <b>by</b> {order.approvedUser.fullName} <b>at</b> <DateTimeOutput date={order.approvedDate} />
              </Col>
            </>
          )}
          {order.rejectedUser && (
            <>
              <h6 className="col-sm-2">Rejected</h6>
              <Col sm={4}>
                <b>by</b> {order.rejectedUser.fullName} <b>at</b> <DateTimeOutput date={order.rejectedDate} />
              </Col>
            </>
          )}
        </Row>
        <br />
        <div className="row py-1">
          <Col sm={12}>
            <h5>Customer Details</h5>
            <hr />
          </Col>
          <Col sm={12}>
            <CustomerCard id={order.customer?.id} />
          </Col>
          <Col sm={12}>
            <Row>
              {order.deliveryAddress?.id && (
                <Col sm={6}>
                  <Row>
                    <Col>
                      <h6>Delivery Address</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomerAddressCard id={order.deliveryAddress?.id} />
                    </Col>
                  </Row>
                </Col>
              )}
              {order.billingAddress?.id && (
                <Col sm={6}>
                  <Row>
                    <Col>
                      <h6>Billing Address</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomerAddressCard id={order.billingAddress?.id} />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

export default OrderCardView;
