import moment from "moment";

function DateTimeOutput({ date, format = "DD.MM.YYYY HH:mm" }) {
  if (!date) {
    return null;
  }
  return moment(date).utcOffset(0, true).format(format);
}

export default DateTimeOutput;
