import axios from "axios";
import { logoutUser } from "store/actions";
import store from "../store";
import history from "../routes/history";
import { showError } from "./utils";
import { authService } from "services/AuthService";

const authHeader = () => {
  return {}; // Token'i boş döndürerek kimlik doğrulama başlığını pasif yapın
};

//apply base url for axios
const API_URL = "http://houseofsofa.co.uk:8443/";
//const API_URL = "https://localhost:8091/";
//const API_URL = "http://ec2-3-73-52-60.eu-central-1.compute.amazonaws.com:8091/api/v2/"; //TEST
// const API_URL = "https://crm.noviafurniture.co.uk/api/v2/"; //PROD

// test

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = authHeader()?.Authorization;
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// axiosApi.defaults.headers.common["Authorization"] = authHeader()?.Authorization;

axiosApi.interceptors.response.use(
  (response) => {
    if (response.headers.authorization) {
      authService.setAuthToken(response.headers.authorization);
    }
    return response;
  },
  (error) => {
    if (error.response?.status == 401) {
      if (authHeader().Authorization) {
        console.log("token expired: " + error.message);
        const { dispatch } = store; // direct access to redux store.
        dispatch(logoutUser(history));
      }
    } else {
      showError(error);
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => {
    // console.log("response get", url, response);

    return response.data;
  });
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then((response) => {
    // console.log("response post", url, response);

    return response.data;
  });
}

export async function put(url, data = {}, config = {}) {
  return axiosApi.put(url, data, { ...config }).then((response) => {
    // console.log("response put", url, response);
    return response.data;
  });
}

export async function del(url, data, config = {}) {
  return await axiosApi.delete(url, { ...config, data }).then((response) => {
    return response.data;
  });
}

export async function postLogin(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => {
      // response.data = {
      //   accessToken: response.headers.authorization,
      // }
      return response;
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        throw new Error(e.response.data.message);
      }
      throw e;
    });
}
