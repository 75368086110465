import ConfirmButton from "components/Common/ConfirmButton";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { addNoteToProduction, deleteProductionNote, getProductionNotes } from "../../store/production/services";
import productionUtils from "./production_utils";
import DateTimeOutput from "components/Common/DateTimeOutput";

const initialNoteState = { open: false, error: null, loading: false, note: {} };
const ProductionNotes = ({ productionId }) => {
  const [noteState, setNoteState] = useState(initialNoteState);
  const [notes, setNotes] = useState([]);
  const openNoteAddPopup = () => {
    setNoteState((prev) => ({ ...prev, note: {}, open: true }));
  };
  const handleAddNote = async (note) => {
    return addNoteToProduction(productionId, note).then((data) => {
      loadNotes(productionId);
      setNoteState((prev) => ({ ...prev, note: {}, open: false }));
    });
  };
  const handleCloseNote = () => {
    setNoteState((prev) => ({ ...prev, open: false }));
  };
  const loadNotes = async (productionId) => {
    const notes = await getProductionNotes(productionId);
    setNotes(notes);
  };
  const handleDeleteNote = async ({ id }) => {
    await deleteProductionNote(productionId, id);
    loadNotes(productionId);
  };
  useEffect(() => {
    loadNotes(productionId);
  }, [productionId]);

  return (
    <>
      <h4 className="text-secondary">
        <b>Notes</b>
        <span style={{ float: "right" }}>
          <Button color="primary" size="sm" onClick={openNoteAddPopup}>
            <i className="fa fa-plus"></i>Add Note
          </Button>
        </span>
      </h4>
      <br />
      {notes.length > 0 && (
        <ul>
          {notes.map((n) => (
            <li key={n.id}>
              <div className="py-2">
                <b>{n.createUser}</b> (<DateTimeOutput date={n.createdDate} />) <br /> {n.notes}
                &nbsp;&nbsp;&nbsp;
                <ConfirmButton
                  size="sm"
                  color="danger"
                  onConfirm={() => handleDeleteNote(n)}
                  title="Are you sure you want to delete the note?"
                >
                  <i className="fa fa-trash"></i>
                </ConfirmButton>
              </div>
            </li>
          ))}
        </ul>
      )}
      {noteState.open && (
        <ProductionNoteModal onClose={handleCloseNote} onSubmit={handleAddNote} note={noteState.note} />
      )}
    </>
  );
};

const ProductionNoteModal = ({ note, onClose, onSubmit }) => {
  const [noteForm, setNoteForm] = useState({ ...note });
  const [saving, setSaving] = useState(false);
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setNoteForm((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit(productionUtils.mapToNoteAddModel(noteForm));
    } finally {
      setSaving(false);
    }
  };
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!note?.id ? "Add new note" : "Edit note"}</ModalHeader>
      <ModalBody>
        <div className="row mb-4">
          <Label htmlFor="notes" className="col-sm-3 col-form-label">
            Notes
          </Label>
          <Col sm={9}>
            <Input
              type="textarea"
              value={noteForm.notes}
              name="notes"
              placeholder="notes.."
              className="form-control"
              onChange={handleChange}
            />
          </Col>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleSubmit} disabled={saving}>
          {!note.id && "Add to production "}
          {note.id && "Update "}
          {saving && <Spinner size="sm" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProductionNotes;
