import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import Production from "./Production";

const ProductionPage = (props) => {
  const { id } = useParams();
  return (
    <>
      <MetaTags>
        <title>Novia - Production Order</title>
      </MetaTags>
      <Production id={id} />
    </>
  );
};

ProductionPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ProductionPage);
