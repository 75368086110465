import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Row, Col } from "reactstrap";
import ImagesModal from "components/Common/ImagesModal";
import { getVariantOrProductImages } from "store/product/services";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import CustomSpinner from "components/Common/CustomSpinner";
import { getTicket, getTicketProducts } from "store/ass/services";

const TicketProductsView = ({ ticketId }) => {
  const [products, setProducts] = useState([]);
  const [imageState, setImageState] = useState({
    productId: null,
    variantId: null,
  });
  const [loading, setLoading] = useState(false);
  const loadProducts = async () => {
    setLoading(true);
    try {
      const { products } = await getTicket(ticketId);
      setProducts(products);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadProducts();
  }, [ticketId]);

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        formatter: (productVariant) => (
          <VariantDefaultImage
            onClick={() =>
              setImageState({
                productId: productVariant.productId,
                variantId: productVariant.id,
              })
            }
            variant={productVariant}
          ></VariantDefaultImage>
        ),
      },
      {
        text: "SKU",
        dataField: "sku",
      },
      {
        text: "Order Number",
        dataField: "order.orderId",
      },
      {
        text: "Quantity",
        dataField: "quantity",
      },
      {
        text: "Notes",
        dataField: "notes",
        formatter: (cell, { ticketNotes }) => {
          return <div style={{ maxWidth: "6rem" }}>{ticketNotes}</div>;
        },
      },
    ],
    []
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Products</b>
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading && products.length > 0 && (
            <BootstrapTable
              keyField="id"
              data={products}
              columns={productColumns}
              wrapperClasses="table-responsive"
              classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
              headerWrapperClasses={"thead-light"}
            />
          )}
        </Col>
      </Row>
      {imageState.variantId && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productId, imageState.variantId)}
          onClose={() => setImageState({})}
          title="Product Images"
        />
      )}
    </>
  );
};

export default TicketProductsView;
