import CustomSpinner from "components/Common/CustomSpinner";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { checkGetOrCreateVariant, getProduct, getProducts } from "store/product/services";
import Attributes from "./Attributes";
import productUtils from "./product_utils";
import VariantCard from "./VariantCard";

const VariantModal = ({ onClose, onSelect }) => {
  const [productLoading, setProductLoading] = useState(false);
  const [variantLoading, setVariantLoading] = useState(false);
  const [productId, setProductId] = useState();
  const [product, setProduct] = useState({
    productId: null,
  });
  const [formValues, setFormValues] = useState({
    color: null,
    attributes: [],
  });
  const [variant, setVariant] = useState();

  useEffect(() => {
    loadVariant();
  }, [formValues]);

  const loadVariant = async () => {
    if (validateForm(false)) {
      setVariantLoading(true);
      try {
        const variant = await checkGetOrCreateVariant(productId, {
          colorId: formValues.color == null ? null : formValues.color.value,
          optionIds: formValues.attributes.map((a) => a.values[0].value),
          create: false,
        });
        setVariant(variant);
      } finally {
        setVariantLoading(false);
      }
    } else {
      setVariant(null);
    }
  };

  const handleProductSelected = async (name, productId) => {
    setProductLoading(true);
    try {
      setVariant(null);
      const mdl = await getProduct(productId);
      const product = productUtils.mapToFormDataModel(mdl);
      setProductId(productId);
      setProduct(product);
      setFormValues({
        color: null,
        attributes: product.attributes
          .filter((a) => a.attribute.inputType == "SELECT")
          .map((a) => ({
            attribute: a.attribute,
            value: null,
            values: [],
            options: [...a.values],
          })),
      });
    } finally {
      setProductLoading(false);
    }
  };

  const handleSelectVariant = async () => {
    if (!validateForm(true)) {
      return;
    }
    let selectedVariant = variant;
    if (variant.id == null) {
      setVariantLoading(true);
      try {
        selectedVariant = await checkGetOrCreateVariant(productId, {
          colorId: formValues.color == null ? null : formValues.color.value,
          optionIds: formValues.attributes.map((a) => a.values[0].value),
          create: true,
        });
      } finally {
        setVariantLoading(false);
      }
    }
    onSelect({ product, variant: selectedVariant });
  };

  const validateForm = (showMessage) => {
    if (product == null || product.productId == null) {
      showMessage && toast.error("Product should be selected");
      return false;
    }
    if (product.colors.length > 0 && (formValues.color == null || formValues.color.value == null)) {
      showMessage && toast.error("Color should be selected");
      return false;
    }
    const invalidAttr = formValues.attributes.find(
      (a) =>
        (a.attribute.inputType == "SELECT" && (a.values == null || a.values.length == 0)) ||
        (a.attribute.inputType != "SELECT" && (a.value == null || a.value == ""))
    );
    if (invalidAttr != null) {
      showMessage && toast.error("Attribute should be selected");
      return false;
    }
    return true;
  };

  const handleSetValue = useCallback((name, value, valueObject) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);

  const handleAttributeValueChange = useCallback((id, value) => {
    setFormValues((prevValues) => {
      const attr = prevValues.attributes.find((item) => item.attribute.id == id);
      attr.value = value;
      return {
        ...prevValues,
        attributes: [...prevValues.attributes],
      };
    });
  }, []);
  const handleAttributeValuesChange = useCallback((id, values, objValues) => {
    setFormValues((prevValues) => {
      const attr = prevValues.attributes.find((item) => item.attribute.id == id);
      attr.values = objValues;
      return {
        ...prevValues,
        attributes: [...prevValues.attributes],
      };
    });
  }, []);

  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Select Product Variant</ModalHeader>
      <ModalBody>
        <div className="p-2">
          <div className="row mb-4">
            <Label htmlFor="product" className="col-sm-3 col-form-label">
              Products
            </Label>
            <Col sm={9}>
              <MultiselectWithService
                service={getProducts}
                name="products"
                value={productId}
                isMulti={false}
                setValue={handleProductSelected}
                autoFocus
                defaultMenuIsOpen
              />
            </Col>
          </div>
          {productLoading && <CustomSpinner />}
          {!productLoading && product.productId && (
            <>
              {product.colors.length > 0 && (
                <div className="row mb-4">
                  <Label className="col-sm-3 col-form-label" htmlFor="autoSizingSelect">
                    Color
                  </Label>
                  <div className="col-9">
                    <MultiselectWithService
                      service={() => Promise.resolve(product.colors)}
                      name="color"
                      value={formValues.color?.value}
                      isMulti={false}
                      isClearable
                      setValue={handleSetValue}
                      labelField="nameFirstMaterial"
                    />
                  </div>
                </div>
              )}
              <Attributes
                items={formValues.attributes}
                setValue={handleAttributeValueChange}
                setValues={handleAttributeValuesChange}
                isMulti={false}
              />
            </>
          )}
          {variantLoading && <CustomSpinner />}
          {!variantLoading && variant && <VariantCard variant={variant} />}
        </div>
      </ModalBody>
      {!variantLoading && variant && (
        <ModalFooter>
          <Button color="success" size="md" onClick={handleSelectVariant}>
            Select
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default VariantModal;
