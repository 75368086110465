import { useCallback, useMemo, useState } from "react";

const pageButtonRenderer = (props) => {
  const { page, active, disabled, title, onPageChange } = props;
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  return (
    <li
      className={active ? "active page-item" : "page-item"}
      title={Number.isNaN(+page) ? title : +page + 1}
      key={page}
    >
      <a className="page-link" onClick={handleClick}>
        {Number.isNaN(+page) ? page : +page + 1}
      </a>
    </li>
  );
};

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => {
  return (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          title={`Show ${option.page} items`}
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn btn-block ${currSizePerPage === `${option.page}` ? "btn-primary" : "btn-secondary"}`}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

const paginationTotalRenderer = (from, to, size) => <h6 className="pt-1">{size} results found</h6>;

const defaultSizePerPageList = [25, 50, 100, 200];

function useRemotePaging(pageName, limit) {
  let sizePerPageDef = limit;
  let sizePerPageKey = null;
  if (pageName) {
    sizePerPageKey = pageName + ".sizePerPage";
    sizePerPageDef = +localStorage.getItem(sizePerPageKey);
    if (sizePerPageDef == null || sizePerPageDef <= 0 || Number.isNaN(sizePerPageDef)) {
      sizePerPageDef = defaultSizePerPageList[0];
      localStorage.setItem(sizePerPageKey, sizePerPageDef);
    }
  }
  const [sizePerPage, setSizePerPage] = useState(sizePerPageDef);
  const [totalSize, setTotalSize] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const pageOptions = useMemo(() => {
    const hideSizePerPage = (totalSize * 1.0) / sizePerPage <= 1 && totalSize <= defaultSizePerPageList[0];
    return {
      page: pageNumber,
      size: sizePerPage,
      sizePerPage: sizePerPage,
      totalSize,
      pageStartIndex: 0,
      custom: false,
      hidePageListOnlyOnePage: true,
      hideSizePerPage,
      sizePerPageRenderer: sizePerPageRenderer,
      sizePerPageList: defaultSizePerPageList,
      onSizePerPageChange: (spp) => {
        sizePerPageKey && localStorage.setItem(sizePerPageKey, spp);
        setSizePerPage(spp);
      },
      pageButtonRenderer,
      showTotal: !hideSizePerPage,
      paginationTotalRenderer,
    };
  }, [sizePerPage, totalSize, pageNumber]);
  const handleSetPageNumber = useCallback(
    (pn, spp = sizePerPage) => {
      setPageNumber(pn);
      return {
        ...pageOptions,
        page: pn,
        sizePerPage: spp,
        size: spp,
      };
    },
    [sizePerPage]
  );
  return [pageOptions, handleSetPageNumber, setTotalSize];
}

function useClientPaging(pageName, totalSize) {
  const sizePerPageKey = pageName + ".sizePerPage";
  let sizePerPageDef = +localStorage.getItem(sizePerPageKey);
  if (sizePerPageDef == null || sizePerPageDef <= 0 || Number.isNaN(sizePerPageDef)) {
    sizePerPageDef = defaultSizePerPageList[0];
    localStorage.setItem(sizePerPageKey, sizePerPageDef);
  }
  const [sizePerPage, setSizePerPage] = useState(sizePerPageDef);
  const pageOptions = useMemo(() => {
    const hideSizePerPage = (totalSize * 1.0) / sizePerPage <= 1 && totalSize <= defaultSizePerPageList[0];
    return {
      sizePerPage: sizePerPage,
      totalSize,
      custom: false,
      pageStartIndex: 0,
      hidePageListOnlyOnePage: true,
      hideSizePerPage,
      sizePerPageRenderer: sizePerPageRenderer,
      sizePerPageList: defaultSizePerPageList,
      onSizePerPageChange: (spp) => {
        sizePerPageKey && localStorage.setItem(sizePerPageKey, spp);
        setSizePerPage(spp);
      },
      pageButtonRenderer,
      showTotal: !hideSizePerPage,
      paginationTotalRenderer,
    };
  }, [sizePerPage, totalSize]);
  return [pageOptions];
}
export { useRemotePaging, useClientPaging };
