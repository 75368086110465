import { post, get, del, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

//shipment
export const getShipment = (id) => get(`/shipment/${id}`);
export const editShipment = (id, data) => put(`/shipment/${id}`, data);
export const createShipment = (data) => post(`/shipment`, data);
export const deleteShipment = (id) => del(`/shipment/${id}`);
export const startShipment = (id) => put(`/shipment/${id}/start`);
export const cancelShipment = (id) => put(`/shipment/${id}/cancel`);
export const completeShipment = (id) => put(`/shipment/${id}/complete`);
export const getShipmentProducts = (id) => get(`/shipment/${id}/products`);
export const getAvailableProducts = () => get(`/shipment/availableProducts`);

//shipment products
export const addProductToShipment = (shipmentId, data) => put(`/shipment/${shipmentId}/product`, data);
export const addOrderedProductToShipment = (shipmentId, data) => put(`/shipment/${shipmentId}/orderedProducts`, data);
export const deleteShipmentProduct = (shipmentId, data) => del(`/shipment/${shipmentId}/orderedProducts`, data);

export const addNoteToShipment = (shipmentId, data) => put(`/shipment/${shipmentId}/note`, data);
export const getShipmentNotes = (shipmentId) => get(`/shipment/${shipmentId}/note`);
export const deleteShipmentNote = (shipmentId, noteId) => del(`/shipment/${shipmentId}/note/${noteId}`);

export const getShipmentDocuments = (shipmentId) => get(`/shipment/${shipmentId}/document`);
export const addDocumentToShipment = (shipmentId, data, type) =>
  put(`/shipment/${shipmentId}/document?type=${type}`, data);
export const deleteShipmentDocument = (shipmentId, documentId) => del(`/shipment/${shipmentId}/document/${documentId}`);

export const getShipments = (pageOpt = { page: 0, size: 1000 }, filter, sort) =>
  get(
    `/shipment/search?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );
