import { post, get, del, put } from "helpers/axios_helper";

// stores
export const getStores = (params) => get(`/store?page=${params.page}&size=1000`);
export const deleteStore = (branchId) => del(`/store/${branchId}`);
export const editStore = (branchId, data) => put(`/store/${branchId}`, data);
export const createStore = (data) => post(`/store`, data);

// branches
export const getBranches = (params) => get(`/branch?page=${params.page}&size=1000`);
