import PropTypes from "prop-types";
import { createContext, useState, useEffect } from "react";
import { ActiveUser } from "models/ActiveUser";
import { getInfo, getRoles } from "store/auth/login/services";
import { authService } from "services/AuthService";

const initialActiveUser = new ActiveUser({
  name: "",
  roles: [],
  initial: true,
  privileges: [],
});

const ActiveUserContext = createContext(initialActiveUser);

ActiveUserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function ActiveUserProvider({ children }) {
  const [activeUser, setActiveUser] = useState(initialActiveUser);

  useEffect(() => {
    async function loadUserDetails() {
      const [roles, info] = await Promise.all([getRoles(), getInfo()]);
      setActiveUser(
        new ActiveUser({
          roles,
          name: info.fullName,
          initial: false,
          id: info.id,
          email: info.email,
          privileges: info.privileges,
        })
      );
    }
    if (authService.isLoggedIn()) {
      loadUserDetails();
    }
  }, [authService.getAuthToken() != null]);

  return <ActiveUserContext.Provider value={activeUser}>{children}</ActiveUserContext.Provider>;
}

export { ActiveUserProvider, ActiveUserContext };
