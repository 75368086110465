import { atom } from "recoil";
import ticketUtils from "./ticket_utils";

export const ticketsAppliedFilterState = atom({
  key: "Tickets.appliedFilter",
  default: ticketUtils.initialFilterValues(),
});
export const ticketsAppliedSortState = atom({
  key: "Tickets.appliedSort",
  default: { sortField: "serviceDate", sortOrder: "desc" },
});
