import React from "react";
import { Redirect } from "react-router-dom";

// // Pages Component

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import SetNewPassword from "../pages/Authentication/SetNewPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Charts

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// definitons
import Users from "pages/Definitions/Users/Users";
import Currencies from "pages/Definitions/Currency/Currencies";
import CurrencyRate from "pages/Definitions/CurrencyRate/CurrencyRate";
import TaxGroups from "pages/Definitions/TaxGroup/TaxGroup";
import WebCategories from "pages/Definitions/WebCategories/WebCategories";
import Colors from "pages/Definitions/Colors/Colors";
import Manufacturers from "pages/Definitions/Manufacturers.js/Manufacturers";
import Materials from "pages/Definitions/Materials/Materials";
import ProductType from "pages/Definitions/ProductDefinitions/ProductType";
import ProductCategories from "pages/Definitions/ProductDefinitions/ProductCategories";
import ProductAttribute from "pages/Definitions/ProductDefinitions/ProductAttribute";
import ManufacturersAddress from "pages/Definitions/Address/ManufacturersAddress";
import CustomerAddress from "pages/Definitions/Address/CustomerAddress";

//customers
import Customers from "pages/Customers/Customers";

//branches
import Branch from "pages/Branch/Branch";

import ProductPage from "pages/Product/ProductPage";
import Products from "pages/Product/Products";
import OrderPage from "pages/Order/OrderPage";
import Orders from "pages/Order/Orders";
import Warehouse from "pages/Definitions/Warehouse/Warehouse";
import FinanceCompany from "pages/Definitions/FinanceCompany/FinanceCompany";
import ProductDetail from "pages/Product/ProductDetail";
import PaymentPage from "pages/Payment/PaymentPage";
import FinancePage from "pages/Finance/FinancePage";
import OrderPaymentsAndFinancesPage from "pages/Order/OrderPaymentsAndFinancesPage";
import OrderDetailPage from "pages/Order/OrderDetailPage";
import CustomerDetail from "pages/Customers/CustomerDetail";
import Productions from "pages/Production/Productions";
import ProductionPage from "pages/Production/ProductionPage";
import ProductionDetail from "pages/Production/ProductionDetail";
import Shipments from "pages/Shipment/Shipments";
import ShipmentPage from "pages/Shipment/ShipmentPage";
import ShipmentDetail from "pages/Shipment/ShipmentDetail";
import Transfers from "pages/Transfers/Transfers";
import TransferPage from "pages/Transfers/TransferPage";
import Deliveries from "pages/Delivery/Deliveries";
import DeliveryPage from "pages/Delivery/DeliveryPage";
import DeliveryDetail from "pages/Delivery/DeliveryDetail";
import AssProblemTypes from "pages/Definitions/AssProblemTypes/AssProblemTypes";
import Tickets from "pages/Ass/Tickets";
import TicketPage from "pages/Ass/TicketPage";
import TicketDetailPage from "pages/Ass/TicketDetailPage";
import PrivilegesPage from "pages/Definitions/Privileges/PrivilegesPage";
import RolesPage from "pages/Definitions/Roles/RolesPage";
import Menu from "pages/Definitions/Menu/Menu";
import Privileges from "models/Privileges";
import TransferDetail from "pages/Transfers/TransferDetail";
import Invoices from "pages/Invoices/Invoices";
import Payments from "pages/Payment/Payments";
import Finances from "pages/Finance/Finances";
import AssProblemSubjects from "pages/Definitions/AssProblemSubjects/AssProblemSubjects";
import ProductReport from "pages/ProductReport/ProductReport";
import Proformas from "pages/Proforma/Proformas";
import PriceTags from "pages/Definitions/PriceTags/PriceTags";
import Inventory from "pages/Inventory/Inventory";
import OperationCenter from "pages/OperationCenter/OperationCenter";
import ResetPassword from "pages/Authentication/ResetPassword";
import ActionLogs from "pages/ActionLog/ActionLogs";
import FinanceCompanyRate from "pages/Definitions/FinanceCompany/FinanceCompanyRate";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard, privilege: "" },

  //chat

  // //profile
  { path: "/profile", component: UserProfile },

  // definitions
  { path: "/users", component: Users, privilege: Privileges.USER.MENU },
  { path: "/currencies", component: Currencies, privilege: Privileges.CURRENCY.MENU },
  { path: "/currency-rate", component: CurrencyRate, privilege: Privileges.CURRENCYRATE.MENU },
  { path: "/finance-company", component: FinanceCompany, privilege: Privileges.FINANCECOMPANY.MENU },
  { path: "/finance-company/:id/rate", component: FinanceCompanyRate, privilege: Privileges.FINANCECOMPANY.MENU },

  { path: "/tax-groups", component: TaxGroups, privilege: Privileges.TAXGROUP.MENU },
  { path: "/warehouse", component: Warehouse, privilege: Privileges.WAREHOUSE.MENU },

  { path: "/web-categories", component: WebCategories, privilege: Privileges.WEB_CATEGORY.MENU },
  { path: "/ass-problem-types", component: AssProblemTypes, privilege: Privileges.ASS_PROBLEM_TYPE.MENU },
  { path: "/ass-problem-subjects", component: AssProblemSubjects, privilege: Privileges.ASS_PROBLEM_SUBJECT.MENU },
  { path: "/colors", component: Colors, privilege: Privileges.COLOR.MENU },
  { path: "/manufacturers", component: Manufacturers, privilege: Privileges.MANUFACTURER.MENU },
  { path: "/materials", component: Materials, privilege: Privileges.MATERIAL.MENU },
  { path: "/product-types", component: ProductType, privilege: Privileges.PRODUCT_TYPE.MENU },
  { path: "/product-categories", component: ProductCategories, privilege: Privileges.PRODUCT_CATEGORY.MENU },
  { path: "/product-attributes", component: ProductAttribute, privilege: Privileges.PRODUCT_ATTRIBUTE.MENU },
  { path: "/manufacturer/:id/address", component: ManufacturersAddress, privilege: Privileges.MANUFACTURER.MENU },
  { path: "/privileges", component: PrivilegesPage, privilege: Privileges.PRIVILEGE.MENU },
  { path: "/roles", component: RolesPage, privilege: Privileges.ROLE.MENU },
  { path: "/price-tags", component: PriceTags, privilege: Privileges.PRICETAG.MENU },
  { path: "/menu", component: Menu, privilege: Privileges.MENU.MENU },

  //Product
  { path: "/products", component: Products, privilege: Privileges.PRODUCT.MENU },
  { path: "/new-product", component: ProductPage, privilege: Privileges.PRODUCT.CREATE },
  { path: "/product/:id", component: ProductPage, privilege: Privileges.PRODUCT.UPDATE },
  { path: "/product-detail/:id", component: ProductDetail, privilege: Privileges.PRODUCT.DETAIL },

  //Order
  { path: "/orders", component: Orders, privilege: Privileges.ORDER.MENU },
  { path: "/create-order", component: OrderPage, privilege: Privileges.PROFORMA.CREATE },
  { path: "/order/:id", component: OrderPage, privilege: [Privileges.ORDER.UPDATE, Privileges.PROFORMA.UPDATE] },
  {
    path: "/order/:id/payments-and-finances",
    component: OrderPaymentsAndFinancesPage,
    privilege: [Privileges.ORDER.UPDATE_PF, Privileges.PROFORMA.UPDATE_PF],
  },
  {
    path: "/order/:id/detail",
    component: OrderDetailPage,
    privilege: [Privileges.ORDER.DETAIL, Privileges.PROFORMA.DETAIL],
  },
  { path: "/proformas", component: Proformas, privilege: Privileges.PROFORMA.MENU },

  //Payment
  { path: "/payments", component: Payments, privilege: Privileges.PAYMENT.MENU },
  { path: "/create-payment", component: PaymentPage, privilege: Privileges.PAYMENT.CREATE },
  { path: "/payment/:id", component: PaymentPage, privilege: Privileges.PAYMENT.UPDATE },

  //Finance
  { path: "/finances", component: Finances, privilege: Privileges.FINANCE.MENU },
  { path: "/create-finance", component: FinancePage, privilege: Privileges.FINANCE.CREATE },
  { path: "/finance/:id", component: FinancePage, privilege: Privileges.FINANCE.UPDATE },

  //Production Order
  { path: "/production-orders", component: Productions, privilege: Privileges.PRODUCTION.MENU },
  { path: "/create-production-order", component: ProductionPage, privilege: Privileges.PRODUCTION.CREATE },
  { path: "/production-order/:id", component: ProductionPage, privilege: Privileges.PRODUCTION.UPDATE },
  { path: "/production-order/:id/detail", component: ProductionDetail, privilege: Privileges.PRODUCTION.DETAIL },

  //Shipment
  { path: "/shipments", component: Shipments, privilege: Privileges.SHIPMENT.MENU },
  { path: "/create-shipment", component: ShipmentPage, privilege: Privileges.SHIPMENT.CREATE },
  { path: "/shipment/:id", component: ShipmentPage, privilege: Privileges.SHIPMENT.UPDATE },
  { path: "/shipment/:id/detail", component: ShipmentDetail, privilege: Privileges.SHIPMENT.DETAIL },

  //Transfers
  { path: "/transfers", component: Transfers, privilege: Privileges.TRANSFER.MENU },
  { path: "/create-transfer", component: TransferPage, privilege: Privileges.TRANSFER.CREATE },
  { path: "/transfer/:id", component: TransferPage, privilege: Privileges.TRANSFER.UPDATE },
  { path: "/transfer/:id/detail", component: TransferDetail, privilege: Privileges.TRANSFER.DETAIL },

  //Deliveries
  { path: "/deliveries", component: Deliveries, privilege: Privileges.DELIVERY.MENU },
  { path: "/create-delivery", component: DeliveryPage, privilege: Privileges.DELIVERY.CREATE },
  { path: "/delivery/:id", component: DeliveryPage, privilege: Privileges.DELIVERY.UPDATE },
  { path: "/delivery/:id/detail", component: DeliveryDetail, privilege: Privileges.DELIVERY.DETAIL },

  //after-sales service
  { path: "/tickets", component: Tickets, privilege: Privileges.ASS.MENU },
  { path: "/create-ticket", component: TicketPage, privilege: Privileges.ASS.CREATE },
  { path: "/ticket/:id", component: TicketPage, privilege: Privileges.ASS.UPDATE },
  { path: "/ticket/:id/detail", component: TicketDetailPage, privilege: Privileges.ASS.DETAIL },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  //customers
  { path: "/customers", component: Customers, privilege: Privileges.CUSTOMER.MENU },
  { path: "/customer/:id/address", component: CustomerAddress, privilege: Privileges.CUSTOMER.UPDATE },
  { path: "/customer-detail/:id", component: CustomerDetail, privilege: Privileges.CUSTOMER.DETAIL },

  //stores
  { path: "/stores", component: Branch, privilege: Privileges.STORE.MENU },

  //invoices
  { path: "/invoices", component: Invoices, privilege: Privileges.INVOICE.MENU },

  //operation-center
  { path: "/operation-center", component: OperationCenter, privilege: Privileges.OPERATION_CENTER.MENU },

  //inventory
  { path: "/inventory", component: Inventory, privilege: Privileges.INVENTORY.MENU },
  { path: "/product-report", component: ProductReport, privilege: Privileges.PRODUCTREPORT.MENU },

  //action logs
  { path: "/action-logs", component: ActionLogs, privilege: Privileges.ACTION_LOGS.MENU },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "*", component: Pages404 },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/set-new-password", component: SetNewPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

export { authProtectedRoutes, publicRoutes };
