import ImagesModal from "components/Common/ImagesModal";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";
import { addVariantImage, deleteVariantImage, getVariantImages } from "store/product/services";
import ConfirmButton from "../../components/Common/ConfirmButton";
import productUtils from "./product_utils";
import variantUtils from "./variant_utils";

const anonymousFunc = () => {};
const emptyArray = [];

const Variants = (props) => {
  const activeUser = useActiveUser();
  const { items = emptyArray, productId, onDelete } = props;
  const [columns, setColumns] = useState([]);
  const currencies = useSelector((state) => state.definitionsReducers.currencies);
  const [imagesState, setImagesState] = useState({
    show: false,
    variantId: null,
  });
  const handleShowImages = (id) => {
    setImagesState({
      show: true,
      variantId: id,
    });
  };
  const handleCloseImages = () => {
    setImagesState({
      show: false,
      variantId: null,
    });
  };

  const createColumns = () => {
    const variantHeaders = variantUtils.createHeaders(items);
    const clmns = variantHeaders.map((item) => ({
      text: item.text,
      dataField: item.dataField,
      formatter: (cell, variant) => {
        // console.log("formatter", variant);
        return variant[item.dataField]?.label;
      },
    }));
    setColumns([
      ...createBaseColumns({
        ...props,
        showImages: handleShowImages,
        currencies: currencies.map((item) => ({ label: item.code, value: item.code })),
        activeUser,
        items,
      }),
      ...clmns,
      {
        dataField: "id",
        text: "Delete",
        hidden: !activeUser.hasPrivilege(Privileges.PRODUCT.DELETE_VARIANT),
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => onDelete(row)}
              title="Are you sure you want to delete this variant?"
            >
              <i className="fa fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ]);
  };
  useEffect(() => {
    createColumns();
  }, [items, currencies]);

  return (
    <>
      <h4 className="text-secondary">
        <b>Product Variants</b>
      </h4>
      <br />
      {items.length > 0 && columns.length > 0 && (
        <BootstrapTable
          keyField="key"
          data={items}
          columns={columns}
          pagination={paginationFactory({
            showTotal: true,
          })}
          wrapperClasses="table-responsive"
          hover
        />
      )}
      {imagesState.show && (
        <ImagesModal
          title="Variant Images"
          onClose={handleCloseImages}
          onDelete={(imageId) => deleteVariantImage(productId, imagesState.variantId, imageId)}
          onList={() => getVariantImages(productId, imagesState.variantId)}
          onUpload={({ file, isDefault, documentType }) => {
            const imageData = new FormData();
            imageData.append("imageFile", file, file.path);
            imageData.append("isDefault", isDefault);
            imageData.append("documentType", documentType ?? "");
            return addVariantImage(productId, imagesState.variantId, imageData);
          }}
        />
      )}
    </>
  );
};

const createBaseColumns = (props) => {
  const { setValue = anonymousFunc, currencies, showImages = anonymousFunc, activeUser, items } = props;
  return [
    {
      text: "Images",
      dataField: "images",
      formatter: (images, row) => {
        if (!row.id) {
          return null;
        }
        const defImage = productUtils.findDefaultImage(images);
        if (defImage) {
          return (
            <div onClick={() => showImages(row.id)} style={{ cursor: "pointer" }}>
              <img src={defImage.awsUrl} width="75" id={`variant_image_${row.id}`} />
              <UncontrolledTooltip target={`variant_image_${row.id}`}>
                <img src={defImage.awsUrl} width="300" />
              </UncontrolledTooltip>
            </div>
          );
        }
        return (
          <Button color="primary" size="sm" onClick={() => showImages(row.id)}>
            <i className="fa fa-image"></i>
          </Button>
        );
      },
    },
    {
      text: "SKU",
      dataField: "sku",
      formatter: (cell, row) => {
        return `${row.sku ? row.sku : ""}`;
      },
    },
    {
      text: "Cost",
      dataField: "cost",
      formatExtraData: { currencies, items },
      hidden: !activeUser.hasPrivilege(Privileges.PRODUCT.EDIT_COST),
      formatter: (cell, row, xxx, { currencies, items }) => {
        const rowIndex = items.findIndex((item) => item === row);
        return (
          <Row style={{ width: 250 }}>
            <Col style={{ width: 130 }}>
              <NumberFormat
                value={row.cost}
                style={{ width: 125 }}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                name="cost"
                autoFocus
                onValueChange={({ value }) => setValue(rowIndex, "cost", value)}
                decimalScale={2}
              />
            </Col>
            <Col style={{ width: 100 }}>
              <Select
                isMulti={false}
                style={{ width: 90 }}
                onChange={(val) => {
                  setValue(rowIndex, "costCurrency", val.value, val);
                }}
                value={currencies.find((item) => row.costCurrency == item.value)}
                options={currencies}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect
              />
            </Col>
          </Row>
        );
      },
    },
    {
      text: "Price",
      dataField: "price",
      formatExtraData: { currencies, items },
      formatter: (cell, row, xxx, { currencies, items }) => {
        const rowIndex = items.findIndex((item) => item === row);
        return (
          <Row style={{ width: 250 }}>
            <Col style={{ width: 130 }}>
              <NumberFormat
                value={row.price}
                style={{ width: 125 }}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                name="price"
                autoFocus
                onValueChange={({ value }) => setValue(rowIndex, "price", value)}
                decimalScale={2}
              />
            </Col>
            <Col style={{ width: 100 }}>
              <Select
                isMulti={false}
                style={{ width: 90 }}
                onChange={(val) => {
                  setValue(rowIndex, "priceCurrency", val.value, val);
                }}
                value={currencies.find((item) => row.priceCurrency == item.value)}
                options={currencies}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect
              />
            </Col>
          </Row>
        );
      },
    },
    {
      text: "Sales Price",
      dataField: "salesPrice",
      formatExtraData: { currencies, items },
      formatter: (cell, row, xxx, { currencies, items }) => {
        const rowIndex = items.findIndex((item) => item === row);
        return (
          <Row style={{ width: 250 }}>
            <Col style={{ width: 130 }}>
              <NumberFormat
                value={row.salesPrice}
                style={{ width: 125 }}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                name="salesPrice"
                autoFocus
                onValueChange={({ value }) => setValue(rowIndex, "salesPrice", value)}
                decimalScale={2}
              />
            </Col>
            <Col style={{ width: 100 }}>
              <Select
                isMulti={false}
                style={{ width: 90 }}
                onChange={(val) => {
                  setValue(rowIndex, "salesPriceCurrency", val.value, val);
                }}
                value={currencies.find((item) => row.salesPriceCurrency == item.value)}
                options={currencies}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect
              />
            </Col>
          </Row>
        );
      },
    },
  ];
};

export default React.memo(Variants);
/*

*/
