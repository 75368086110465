import moment from "moment";

class OrderUtils {
  initialValues = () => ({
    id: null,
    name: "",
    status: "y",
    deliveryBranch: {},
    orderStatus: {
      label: "PROFORMA",
      value: "PROFORMA",
    },
    billingAddressId: "",
    deliveryAddressId: "",
    customerId: "",
    salesPerson: {},
    proformaCancelStatus: "ACTIVE",
  });
  mapToSaveModel = ({
    id,
    name = "",
    status = "y",
    deliveryBranch = {},
    orderStatus = {},
    billingAddressId,
    deliveryAddressId,
    customerId,
    salesPerson = {},
    proformaCancelStatus = "",
  }) => ({
    id,
    name,
    status,
    deliveryBranchId: deliveryBranch?.value,
    orderStatus: orderStatus?.value,
    billingAddressId,
    deliveryAddressId,
    customerId,
    salesPersonId: salesPerson?.value,
    proformaCancelStatus: proformaCancelStatus || null,
  });

  mapToFormDataModel = ({
    id,
    name,
    status,
    deliveryBranch,
    billingAddress,
    deliveryAddress,
    salesPerson,
    orderStatus,
    customer,
    proformaCancelStatus,
  }) => ({
    id,
    name,
    status,
    deliveryBranch: {
      label: deliveryBranch?.name,
      value: deliveryBranch.id,
    },
    orderStatus: {
      label: orderStatus,
      value: orderStatus,
    },
    billingAddressId: billingAddress?.id,
    deliveryAddressId: deliveryAddress?.id,
    customerId: customer?.id,
    salesPerson: {
      label: salesPerson?.fullName,
      value: salesPerson.id,
    },
    proformaCancelStatus,
  });

  mapToProductFormModel = ({ id, orderType, productVariant, quantity, notes, discountRate, netPrice }) => ({
    id,
    variant: productVariant,
    notes,
    discountRate,
    quantity,
    orderType: {
      value: orderType,
    },
    netPrice,
  });

  mapToProductAddModel = ({
    id,
    status = "y",
    orderType = {},
    variant = {},
    quantity,
    notes,
    discountRate,
    inStockProduct,
    netPrice,
  }) => ({
    id,
    status,
    orderType: orderType?.value,
    variantId: variant?.id,
    quantity,
    notes,
    discountRate,
    inStockProduct,
    netPrice,
  });

  mapToNoteAddModel = ({ id, status = "y", notes }) => ({
    id,
    status,
    notes,
  });

  initialFilterValues = () => ({
    orderId: null,
    customerFullName: "",
    postCode: "",
    phone: "",
    email: "",
    orderStatus: null,
    deliveryDateStart: null,
    deliveryDateEnd: null,
    deliveryBranchId: null,
    salesPersonId: null,
  });

  mapToOrderSearch = (
    filter = {
      orderId: null,
      customerFullName: "",
      postCode: "",
      phone: "",
      email: "",
      orderStatus: null,
      deliveryDateStart: null,
      deliveryDateEnd: null,
      deliveryBranchId: null,
      salesPersonId: null,
      orderDateStart: null,
      orderDateEnd: null,
    }
  ) => {
    return {
      orderIds: filter.orderId != null ? [filter.orderId] : [],
      customerFullName: filter.customerFullName,
      postCode: filter.postCode,
      phone: filter.phone,
      email: filter.email,
      salesPersonIds: filter.salesPersonId != null ? [filter.salesPersonId] : [],
      statuses: filter.orderStatus != null ? [filter.orderStatus] : [],
      deliveryBranchIds: filter.deliveryBranchId != null ? [filter.deliveryBranchId] : [],
      deliveryDateStart: filter.deliveryDateStart != null ? moment(filter.deliveryDateStart).format("YYYY-MM-DD") : "",
      deliveryDateEnd: filter.deliveryDateEnd != null ? moment(filter.deliveryDateEnd).format("YYYY-MM-DD") : "",
      orderDateStart: filter.orderDateStart != null ? moment(filter.orderDateStart).format("YYYY-MM-DD") : "",
      orderDateEnd: filter.orderDateEnd != null ? moment(filter.orderDateEnd).format("YYYY-MM-DD") : "",
    };
  };
}
const orderUtils = new OrderUtils();
export default orderUtils;
