import { Card, CardBody, Col, Row } from "reactstrap";
import DeliveryCard from "./DeliveryCard";
import DeliveryDocuments from "./DeliveryDocuments";
import DeliveryNotes from "./DeliveryNotes";
import DeliveryProducts from "./DeliveryProducts";

const DeliveryDetailCard = ({ id }) => {
  return (
    <Row>
      <Col>
        <DeliveryCard id={id} />
        <Card className="shadow-lg">
          <CardBody className="">
            <DeliveryProducts deliveryId={id} type="view" />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <DeliveryNotes deliveryId={id} type="view" />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <DeliveryDocuments deliveryId={id} type="view" />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default DeliveryDetailCard;
