import { atom } from "recoil";
import dashboardUtils from "./dashboard_utils";

export const dashboardAppliedFilterState = atom({
  key: "Dashboard.appliedFilter",
  default: dashboardUtils.initialFilterValues(),
});

export const dashboardState = atom({
  key: "Dashboard",
  default: {
    data: null,
    lastUpdateTime: null,
  },
});
