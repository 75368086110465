import React from "react";
import { Col, Row, Spinner } from "reactstrap";

function CustomSpinner() {
  return (
    <Row style={{ textAlign: "center" }}>
      <Col md={12}>
        <Spinner size="sm" color="secondary" type="grow"></Spinner>
        <Spinner size="sm" color="primary" type="grow"></Spinner>
      </Col>
    </Row>
  );
}

export default CustomSpinner;
