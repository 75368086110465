import { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Container,
  Label,
  ModalFooter,
  Input,
  Spinner,
} from "reactstrap";
import { addNoteToTicket, deleteTicketNote, getTicketNotes } from "../../store/ass/services";
import ConfirmButton from "components/Common/ConfirmButton";
import ticketUtils from "./ticket_utils";
import CustomSpinner from "components/Common/CustomSpinner";
import DateTimeOutput from "components/Common/DateTimeOutput";

const initialNoteState = { open: false, error: null, loading: false, note: {} };
const TicketNotes = ({ ticketId, type }) => {
  const [noteState, setNoteState] = useState(initialNoteState);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const openNoteAddPopup = () => {
    setNoteState((prev) => ({ ...prev, note: {}, open: true }));
  };
  const handleAddNote = async (note) => {
    return addNoteToTicket(ticketId, note).then((data) => {
      loadNotes(ticketId);
      setNoteState((prev) => ({ ...prev, note: {}, open: false }));
    });
  };
  const handleCloseNote = () => {
    setNoteState((prev) => ({ ...prev, open: false }));
  };
  const loadNotes = async (ticketId) => {
    setLoading(true);
    try {
      const notes = await getTicketNotes(ticketId);
      setNotes(notes);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteNote = async ({ id }) => {
    await deleteTicketNote(ticketId, id);
    loadNotes(ticketId);
  };
  useEffect(() => {
    loadNotes(ticketId);
  }, [ticketId]);

  return (
    <>
      <h4 className="text-secondary">
        <b>Notes</b>
        {type != "view" && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" onClick={openNoteAddPopup}>
              <i className="fa fa-plus"></i>Add Note
            </Button>
          </span>
        )}
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading && notes.length > 0 && (
            <ul>
              {notes.map((n) => (
                <li key={n.id}>
                  <div className="py-2">
                    <b>{n.createUser}</b> (<DateTimeOutput date={n.createdDate} />) <br /> {n.notes}
                    {type != "view" && (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <ConfirmButton
                          size="sm"
                          color="danger"
                          onConfirm={() => handleDeleteNote(n)}
                          title="Are you sure you want to delete the note?"
                        >
                          <i className="fa fa-trash"></i>
                        </ConfirmButton>
                      </>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </Col>
      </Row>
      {noteState.open && <TicketNoteModal onClose={handleCloseNote} onSubmit={handleAddNote} note={noteState.note} />}
    </>
  );
};

const TicketNoteModal = ({ note, onClose, onSubmit }) => {
  const [noteForm, setNoteForm] = useState({ ...note });
  const [saving, setSaving] = useState(false);
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setNoteForm((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit(ticketUtils.mapToNoteAddModel(noteForm));
    } finally {
      setSaving(false);
    }
  };
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!note?.id ? "Add new note" : "Edit note"}</ModalHeader>
      <ModalBody>
        <div className="row mb-4">
          <Label htmlFor="notes" className="col-sm-3 col-form-label">
            Notes
          </Label>
          <Col sm={9}>
            <Input
              type="textarea"
              value={noteForm.notes}
              name="notes"
              placeholder="notes.."
              className="form-control"
              onChange={handleChange}
            />
          </Col>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleSubmit} disabled={saving}>
          {!note.id && "Add to ticket "}
          {note.id && "Update "}
          {saving && <Spinner size="sm" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TicketNotes;
