import { useState } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";

function BasicPopover({ content, trigger, title }) {
  const [open, setOpen] = useState(false);
  const [id] = useState(Math.floor(Math.random() * 1000000000));
  return (
    <>
      <span role="button" id={`basicPopover${id}`}>
        {trigger}
      </span>
      <Popover trigger="legacy" target={`basicPopover${id}`} toggle={() => setOpen((p) => !p)} isOpen={open}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{content}</PopoverBody>
      </Popover>
    </>
  );
}

export default BasicPopover;
