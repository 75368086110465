const endpoints = {
  dashboard: {
    getDashboard: "/dashboard",
  },
  currency: {
    getCurrencies: "/currency",
  },
  version: {
    getVersion: "/version",
  },
};
export default endpoints;
