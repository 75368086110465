import enums, { EnumValues, getEnumLabel } from "common/enums";
import ConfirmButton from "components/Common/ConfirmButton";
import ImagesModal from "components/Common/ImagesModal";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getVariantOrProductImages } from "store/product/services";
import {
  addOrderedProductToDelivery,
  deleteDeliveryProduct,
  getAvailableProducts,
  getDelivery,
  getDeliveryProducts,
} from "../../store/delivery/services";
import DeliveryOrderedProductForm from "./DeliveryOrderedProductForm";

const initialOrderedProductState = { open: false, error: null, loading: false, product: {} };
const DeliveryProducts = ({ deliveryId, type }) => {
  const [orderedProductState, setOrderedProductState] = useState(initialOrderedProductState);
  const [products, setProducts] = useState([]);
  const [delivery, setDelivery] = useState({
    order: {
      id: null,
    },
  });
  const [imageState, setImageState] = useState({
    productId: null,
    variantId: null,
  });
  const openOrderedProductAddPopup = () => {
    setOrderedProductState((prev) => ({ ...prev, product: {}, open: true }));
  };
  const handleOrderedAddProduct = async (product) => {
    return addOrderedProductToDelivery(deliveryId, { idsNotes: [product] }).then(() => {
      loadProducts(deliveryId);
      setOrderedProductState((prev) => ({ ...prev, product: {}, open: false }));
    });
  };
  const handleAddAllProducts = async ({ notes }) => {
    return getAvailableProducts(delivery.order.id)
      .then((availableProducts) =>
        addOrderedProductToDelivery(deliveryId, {
          idsNotes: availableProducts.map((item) => ({ id: item.id, notes, quantity: item.quantity })),
        })
      )
      .then(() => {
        loadProducts(deliveryId);
        setOrderedProductState((prev) => ({ ...prev, product: {}, open: false }));
      });
  };
  const handleCloseOrderedProduct = () => {
    setOrderedProductState((prev) => ({ ...prev, open: false }));
  };
  const loadDelivery = async (deliveryId) => {
    const delivery = await getDelivery(deliveryId);
    setDelivery(delivery);
  };
  const loadProducts = async (deliveryId) => {
    const deliveryOrderedProducts = await getDeliveryProducts(deliveryId);
    setProducts(deliveryOrderedProducts);
  };
  const handleDeleteProduct = async ({ id }) => {
    await deleteDeliveryProduct(deliveryId, { ids: [id] });
    loadProducts(deliveryId);
  };
  useEffect(() => {
    loadDelivery(deliveryId);
    loadProducts(deliveryId);
  }, [deliveryId]);

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        align: "center",
        headerAlign: "center",
        formatter: (productVariant, row) => (
          <VariantDefaultImage
            onClick={() =>
              setImageState({
                productId: row.productVariant?.productId,
                variantId: row.productVariant?.id,
              })
            }
            variant={productVariant}
          ></VariantDefaultImage>
        ),
      },
      {
        text: "Product",
        dataField: "productVariant.productName",
      },
      {
        text: "Variant",
        dataField: "productVariant.name",
      },
      {
        text: "Status",
        dataField: "productStatus",
        formatter: (cell) => getEnumLabel(enums.orderedProductStatus, cell),
      },
      {
        text: "SKU",
        dataField: "sku",
      },
      {
        text: "Order Number",
        dataField: "order.orderId",
      },
      {
        text: "Quantity",
        dataField: "quantity",
      },
      {
        text: "Notes",
        dataField: "notes",
        formatter: (cell, { deliveryNotes }) => {
          return <div style={{ maxWidth: "6rem" }}>{deliveryNotes}</div>;
        },
      },
      {
        text: "Delete",
        dataField: "delete",
        align: "center",
        headerAlign: "center",
        hidden: type == "view" || delivery.deliveryStatus != EnumValues.DELIVERY_STATUS.PLANNING,
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeleteProduct(row)}
              title="Are you sure you want to delete the product?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    [delivery]
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Products</b>
        {type != "view" && delivery != null && delivery.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNING && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" outline onClick={openOrderedProductAddPopup}>
              <i className="fa fa-plus"></i>Add Product
            </Button>
          </span>
        )}
      </h4>
      <br />
      {products.length > 0 && (
        <Row>
          <Col xl="12">
            <div className="table-responsive">
              <BootstrapTable keyField="id" data={products} columns={productColumns} />
            </div>
          </Col>
        </Row>
      )}
      {orderedProductState.open && (
        <DeliveryOrderedProductModal
          onClose={handleCloseOrderedProduct}
          onSubmit={handleOrderedAddProduct}
          onAddAllProduct={handleAddAllProducts}
          orderId={delivery.order?.id}
        />
      )}
      {imageState.variantId && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productId, imageState.variantId)}
          onClose={() => setImageState({})}
          title="Product Images"
        />
      )}
    </>
  );
};

const DeliveryOrderedProductModal = ({ orderId, product, onClose, onSubmit, onAddAllProduct }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!product?.id ? "Add new product" : "Edit product"}</ModalHeader>
      <ModalBody>
        <DeliveryOrderedProductForm
          initialProduct={product}
          onSubmit={onSubmit}
          orderId={orderId}
          onAddAllProduct={onAddAllProduct}
        />
      </ModalBody>
    </Modal>
  );
};

export default DeliveryProducts;
