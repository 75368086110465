import { atom } from "recoil";
import actionLogUtils from "./actionlog_utils";

export const actionLogsAppliedFilterState = atom({
  key: "ActionLogs.appliedFilter",
  default: actionLogUtils.initialFilterValues(),
});
export const actionLogsAppliedSortState = atom({
  key: "ActionLogs.appliedSort",
  default: { sortField: "createdDate", sortOrder: "desc" },
});
