import { PropTypes } from "prop-types";
import React, { Fragment, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, Container } from "reactstrap";
import * as Yup from "yup";

import enums, { getEnumLabel } from "common/enums";
import DefinitionTable from "components/Tables/DefinitionTable";
import { hasAnyValue } from "helpers/utils";
import Privileges from "models/Privileges";
import { useRecoilState } from "recoil";
import { createCustomers, deleteCustomers, editCustomers, getCustomers } from "store/customer/services";
import { customersAppliedFilterState } from "./atoms";
import CustomerFilterForm from "./CustomerFilterForm";

const CustomerType = [
  { value: "PERSON", label: "Person" },
  { value: "COMPANY", label: "Company" },
];
const CustomerTitle = [
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
  { value: "Miss", label: "Miss" },
];

const CustomerNationality = [
  { value: "British", label: "British" },
  { value: "Iranian", label: "Iranian" },
  { value: "Indian", label: "Indian" },
  { value: "Polish", label: "Polish" },
  { value: "Saudi", label: "Saudi" },
  { value: "Turkish", label: "Turkish" },
];
export const customerFormValues = [
  {
    name: "customerType",
    label: "Customer Type",
    size: 6,
    type: "select",
    initialValue: "PERSON",
    options: CustomerType,
  },
  {
    name: "title",
    label: "Title",
    size: 6,
    type: "select",
    initialValue: "Mr.",
    options: CustomerTitle,
    visible: (formValues) => {
      return formValues["customerType"] == "PERSON";
    },
  },
  // { name: "nationality", label: "Nationality", size: 6, type: "select", options: CustomerNationality },
  { name: "name", label: "Name", size: 12, type: "text" },
  { name: "email", label: "Email", size: 12, type: "text" },
  { name: "mobilePhone", label: "Mobile Phone", size: 6, type: "text" },
  { name: "phone", label: "Alternative Phone", size: 6, type: "text" },
  // { name: "birthDate", label: "Birth Date", size: 6, type: "datetime" },
  // { name: "contactEmail", label: "Contact Email", size: 6, type: "checkbox" },
  // { name: "contactMail", label: "Contact Mail", size: 6, type: "checkbox" },
  // { name: "contactPhone", label: "Contact Phone", size: 6, type: "checkbox" },
];

export const customerValidationSchema = {
  name: Yup.string().required("Please enter a name"),
  title: Yup.string().required("Please select title"),
  customerType: Yup.string().required("Please select customer type"),
  //email: Yup.string().required("Please enter e-mail").email("Invalid email format,please add your domain @..."),
  mobilePhone: Yup.number().typeError("You must specify a number").required("Please enter mobile phone"),
  phone: Yup.number().typeError("You must specify a number"),
};

const Customers = ({ history }) => {
  const [appliedFilter, setAppliedFilter] = useRecoilState(customersAppliedFilterState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    setHasFilter(hasAnyValue(appliedFilter));
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "customerType",
      text: "Type",
      sort: true,
      formatter: (cell) => getEnumLabel(enums.customerTypes, cell),
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      formatter: (cell, row) => (row.customerType == "PERSON" ? cell : null),
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "mobilePhone",
      text: "Mobile Phone",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Alternative Phone",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row) => {
        return <AddressPage page row={row} />;
      },
    },
    {
      dataField: "view",
      text: "View",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => {
        return (
          <Button color="info" size="sm" onClick={() => history.push(`/customer-detail/${row.id}`)}>
            <i className="fas fa-eye"></i>
          </Button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customers</title> {/* degistir */}
        </MetaTags>
        <Container fluid>
          <Card>
            <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
              <AccordionItem>
                <AccordionHeader targetId="1">
                  <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                  <div className="px-2">Filter Form</div>
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <CustomerFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </Card>
        </Container>
        <DefinitionTable
          reloadKey={appliedFilter}
          services={{
            getapi: () => getCustomers({ page: 0, size: 100 }, appliedFilter).then((res) => res.content),
            deleteapi: deleteCustomers,
            createapi: createCustomers,
            updateapi: editCustomers,
          }}
          tableColumns={columns}
          formValues={{
            values: customerFormValues,
            validationSchema: customerValidationSchema,
          }}
          pageDetails={{
            definitionName: "Customer",
            title: "Customer List",
          }}
          privilege={{
            create: Privileges.CUSTOMER.CREATE,
            update: Privileges.CUSTOMER.UPDATE,
            delete: Privileges.CUSTOMER.DELETE,
          }}
          search={false}
        />
      </div>
    </React.Fragment>
  );
};

const AddressPage = ({ row }) => {
  return (
    <Fragment>
      <Link to={{ pathname: `/customer/${row.id}/address`, state: `${row.name}` }} className="btn btn-secondary">
        Address
      </Link>
    </Fragment>
  );
};
Customers.propTypes = {
  history: PropTypes.object,
};
export default withRouter(Customers);
