import ConfirmButton from "components/Common/ConfirmButton";
import ImagesModal from "components/Common/ImagesModal";
import MoneyOutput from "components/Common/MoneyOutput";
import OrderedProductModal from "pages/Order/OrderedProductModal";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getVariantOrProductImages } from "store/product/services";
import enums, { EnumValues, getEnumLabel } from "../../common/enums";
import {
  addOrderedProductToProduction,
  addProductToProduction,
  deleteProductionProduct,
  getAvailableProducts,
  getProduction,
} from "../../store/production/services";
import ProductionProductForm from "./ProductionProductForm";

const initialProductState = { open: false, error: null, loading: false, product: {} };
const initialOrderedProductState = { open: false, error: null, loading: false, product: {} };
const ProductionProducts = ({ productionId, type }) => {
  const [productState, setProductState] = useState(initialProductState);
  const [orderedProductState, setOrderedProductState] = useState(initialOrderedProductState);
  const [products, setProducts] = useState([]);
  const [production, setProduction] = useState();
  const [imageState, setImageState] = useState({
    productId: null,
    variantId: null,
  });
  const openProductAddPopup = () => {
    setProductState((prev) => ({ ...prev, product: {}, open: true }));
  };
  const openOrderedProductAddPopup = () => {
    setOrderedProductState((prev) => ({ ...prev, product: {}, open: true }));
  };
  const handleAddProduct = async (product) => {
    return addProductToProduction(productionId, product).then((data) => {
      setProducts((prev) => [...data.productionOrderedProducts]);
      setProductState((prev) => ({ ...prev, product: {}, open: false }));
    });
  };
  const handleCloseProduct = () => {
    setProductState((prev) => ({ ...prev, open: false }));
  };

  const handleAddOrderedProduct = async (products) => {
    return addOrderedProductToProduction(productionId, {
      idsNotes: [
        ...products.map((p) => ({
          id: p.id,
          quantity: 1,
        })),
      ],
    }).then((data) => {
      setProducts((prev) => [...data.productionOrderedProducts]);
      setOrderedProductState((prev) => ({ ...prev, product: {}, open: false }));
    });
  };
  const handleCloseOrderedProduct = () => {
    setOrderedProductState((prev) => ({ ...prev, open: false }));
  };

  const loadProducts = async (productionId) => {
    const production = await getProduction(productionId);
    const { productionOrderedProducts } = production;
    setProduction(production);
    setProducts(productionOrderedProducts);
  };
  const handleDeleteProduct = async ({ id }) => {
    await deleteProductionProduct(productionId, id);
    loadProducts(productionId);
  };
  useEffect(() => {
    loadProducts(productionId);
  }, [productionId]);

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        align: "center",
        headerAlign: "center",
        formatter: (productVariant, row) => (
          <VariantDefaultImage
            onClick={() =>
              setImageState({
                productId: row.productVariant?.productId,
                variantId: row.productVariant?.id,
              })
            }
            variant={productVariant}
          ></VariantDefaultImage>
        ),
      },
      {
        text: "Product",
        dataField: "productName",
      },
      {
        text: "Variant",
        dataField: "productVariant.name",
      },
      {
        text: "SKU",
        dataField: "sku",
      },
      {
        text: "Status",
        dataField: "productStatus",
        formatter: (cell) => getEnumLabel(enums.orderedProductStatus, cell),
      },
      {
        text: "Order Number",
        dataField: "order.orderId",
      },
      {
        text: "Quantity",
        dataField: "quantity",
      },
      {
        text: "Cost",
        dataField: "cost",
        align: "right",
        headerAlign: "right",
        formatter: (cell) => <MoneyOutput value={cell} />,
      },
      {
        text: "Delete",
        dataField: "delete",
        align: "center",
        headerAlign: "center",
        hidden: EnumValues.PRODUCTION_ORDER_STATUS.PLANNING != production?.orderStatus || type == "view",
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeleteProduct(row)}
              title="Are you sure you want to delete the product?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    [production]
  );
  const expandRow = useMemo(
    () => ({
      showExpandColumn: false,
      expandByColumnOnly: true,
      expanded: products.filter((i) => i.productionNotes).map((item, i) => item.id),
      renderer: (row) => (
        <div className="w-100 row">
          <div className="col-2 col-xs-1 d-flex justify-content-end">
            <b>Production Note:</b>
          </div>
          <span className="col-10 col-xs-11 d-flex justify-content-start text-nowrap">{row.productionNotes}</span>
        </div>
      ),
    }),
    [products]
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Products</b>
        {EnumValues.PRODUCTION_ORDER_STATUS.PLANNING == production?.orderStatus && type != "view" && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" onClick={openProductAddPopup}>
              <i className="fa fa-plus"></i>Add Product for Stock
            </Button>
            &nbsp;
            <Button color="primary" outline size="sm" onClick={openOrderedProductAddPopup}>
              <i className="fa fa-plus"></i>Add Product for Order
            </Button>
          </span>
        )}
      </h4>
      <br />
      {products.length > 0 && (
        <Row>
          <Col xl="12">
            <div className="table-responsive">
              <BootstrapTable keyField="id" data={products} columns={productColumns} expandRow={expandRow} />
            </div>
          </Col>
        </Row>
      )}
      {productState.open && <ProductionProductModal onClose={handleCloseProduct} onSubmit={handleAddProduct} />}
      {orderedProductState.open && (
        <ProductionOrderedProductModal onClose={handleCloseOrderedProduct} onSubmit={handleAddOrderedProduct} />
      )}
      {imageState.variantId && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productId, imageState.variantId)}
          onClose={() => setImageState({})}
          title="Product Images"
        />
      )}
    </>
  );
};

const ProductionProductModal = ({ product, onClose, onSubmit }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!product?.id ? "Add new product" : "Edit product"}</ModalHeader>
      <ModalBody>
        <ProductionProductForm initialProduct={product} onSubmit={onSubmit} />
      </ModalBody>
    </Modal>
  );
};

const ProductionOrderedProductModal = ({ product, onClose, onSubmit }) => {
  return (
    // <Modal isOpen centered={true} size="lg">
    //   <ModalHeader toggle={onClose}>{!product?.id ? "Add new product" : "Edit product"}</ModalHeader>
    //   <ModalBody>
    //     <ProductionOrderedProductForm initialProduct={product} onSubmit={onSubmit}  />
    //   </ModalBody>
    // </Modal>

    <OrderedProductModal
      onClose={onClose}
      onSelect={onSubmit}
      service={getAvailableProducts}
      showSku={false}
      showProductName
      showVariantName
      showProductManufacturer
      multiple
      selectButtonLabel="Add to production"
    />
  );
};

export default ProductionProducts;
