import moment from "moment";

class InventoryUtils {
  initialFilterValues = () => ({
    warehouseId: null,
    storeId: null,
    sku: "",
    name: "",
  });

  mapToInventorySearch = (
    filter = {
      warehouseId: null,
      storeId: null,
      sku: "",
      name: "",
    },
    inStockOnly = true
  ) => ({
    name: filter.name,
    sku: filter.sku,
    storeIds: filter.storeId != null ? [filter.storeId] : [],
    warehouseIds: filter.warehouseId != null ? [filter.warehouseId] : [],
    minStock: inStockOnly ? 1 : null,
  });

  mapToProductAddModel = ({ quantity, variant, notes, branch }) => ({
    variantId: variant?.id,
    quantity,
    notes,
    branchId: branch?.value,
  });
}
const inventoryUtils = new InventoryUtils();
export default inventoryUtils;
