class AuthService {
  isLoggedIn() {
    return this.getAuthToken() != null;
  }

  getAuthToken() {
    return localStorage.getItem("authUser");
  }

  setAuthToken(token) {
    return localStorage.setItem("authUser", JSON.stringify(token));
  }
}
export const authService = new AuthService();
