import { post, get, del, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

//payment
export const getPayment = (id) => get(`/payment/${id}`);
export const editPayment = (id, data) => put(`/payment/${id}`, data);
export const createPayment = (data) => post(`/payment`, data);
export const deletePayment = (id) => del(`/payment/${id}`);
export const getPayments = (pageOpt = { page: 0, size: 100 }, filter) =>
  get(`/payment/search?page=${pageOpt.page}&size=${pageOpt.size}&${toQueryParams(filter)}`);
export const exportPaymentsToPdf = (pageOpt = { page: 0, size: 100 }, filter) =>
  get(`/payment/exportToPdf?page=${pageOpt.page}&size=${pageOpt.size}&${toQueryParams(filter)}`, {
    responseType: "blob",
  });
export const exportPaymentsToCsv = (pageOpt = { page: 0, size: 100 }, filter) =>
  get(`/payment/exportToCsv?page=${pageOpt.page}&size=${pageOpt.size}&${toQueryParams(filter)}`, {
    responseType: "blob",
  });
export const getInvoice = (id) =>
  get(`/payment/${id}/invoice`, {
    responseType: "blob",
  });

//payment notes
export const addNoteToPayment = (orderId, data) => put(`/payment/${orderId}/note`, data);
export const getPaymentNotes = (orderId) => get(`/payment/${orderId}/note`);
export const deletePaymentNote = (orderId, noteId) => del(`/payment/${orderId}/note/${noteId}`);
