import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSetPassword, loginSuccess, logoutUserSuccess } from "./actions";

import { login } from "./services";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(login, {
      username: user.email,
      password: user.password,
    });
    if (response.status === 202) {
      // should set new password
      const code = response.headers.authorization;
      yield put(loginSetPassword({ code, old_pass: user.password }));
      history.push("/set-new-password");
    } else {
      // normal login
      localStorage.setItem("authUser", JSON.stringify(response.headers.authorization));

      yield put(loginSuccess(response));
      history.push("/dashboard");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
