export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const API_ERROR = "API_ERROR";
export const GET_COLORS = "GET_COLORS";
export const GET_COLORS_SUCCESS = "GET_COLORS_SUCCESS";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCY_RATE = "GET_CURRENCY_RATE";
export const GET_CURRENCY_RATE_SUCCESS = "GET_CURRENCY_RATE_SUCCESS";
export const GET_FINANCE_COMPANY = "GET_FINANCE_COMPANY";
export const GET_FINANCE_COMPANY_SUCCESS = "GET_FINANCE_COMPANY_SUCCESS";
export const GET_MANUFACTURERS = "GET_MANUFACTURERS";
export const GET_MANUFACTURERS_SUCCESS = "GET_MANUFACTURERS_SUCCESS";
export const GET_MATERIALS = "GET_MATERIALS";
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS";
export const GET_PRODUCT_ATTRIBUTES = "GET_PRODUCT_ATTRIBUTES";
export const GET_PRODUCT_ATTRIBUTES_SUCCESS = "GET_PRODUCT_ATTRIBUTES_SUCCESS";
export const GET_PRODUCT_CATEGORIES = "GET_PRODUCT_CATEGORIES";
export const GET_PRODUCT_CATEGORIES_SUCCESS = "GET_PRODUCT_CATEGORIES_SUCCESS";
export const GET_PRODUCT_TYPES = "GET_PRODUCT_TYPES";
export const GET_PRODUCT_TYPES_SUCCESS = "GET_PRODUCT_TYPES_SUCCESS";
export const GET_TAX_GROUP = "GET_TAX_GROUP";
export const GET_TAX_GROUP_SUCCESS = "GET_TAX_GROUP_SUCCESS";
export const GET_WAREHOUSE = "GET_WAREHOUSE";
export const GET_WAREHOUSE_SUCCESS = "GET_WAREHOUSE_SUCCESS";
export const GET_WEB_CATEGORIES = "GET_WEB_CATEGORIES";
export const GET_WEB_CATEGORIES_SUCCESS = "GET_WEB_CATEGORIES_SUCCESS";
export const GET_ASS_PROBLEM_TYPES = "GET_ASS_PROBLEM_TYPES";
export const GET_ASS_PROBLEM_TYPES_SUCCESS = "GET_ASS_PROBLEM_TYPES_SUCCESS";
