import moment from "moment";

class DashboardUtils {
  initialFilterValues = () => ({
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    branchId: null,
    salesPersonId: null,
  });

  mapToOrderSearch = (
    filter = {
      startDate: null,
      endDate: null,
      branchId: null,
      salesPersonId: null,
    }
  ) => {
    return {
      branchId: filter.branchId,
      salesPersonId: filter.salesPersonId,
      startDate: filter.startDate != null ? moment(filter.startDate).format("YYYY-MM-DD") : "",
      endDate: filter.endDate != null ? moment(filter.endDate).format("YYYY-MM-DD") : "",
    };
  };
}
const dashboardUtils = new DashboardUtils();
export default dashboardUtils;
