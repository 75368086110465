import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import Ticket from "./Ticket";

const TicketPage = (props) => {
  const { id } = useParams();
  return (
    <>
      <MetaTags>
        <title>Novia - Ticket</title>
      </MetaTags>
      <Ticket id={id} />
    </>
  );
};

TicketPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(TicketPage);
