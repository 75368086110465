import ConfirmButton from "components/Common/ConfirmButton";
import ImagesModal from "components/Common/ImagesModal";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getVariantOrProductImages } from "store/product/services";
import {
  addOrderedProductToShipment,
  addProductToShipment,
  deleteShipmentProduct,
  getShipment,
  getShipmentProducts,
} from "../../store/shipment/services";
import ShipmentOrderedProductForm from "./ShipmentOrderedProductForm";
import ShipmentProductForm from "./ShipmentProductForm";
import { EnumValues } from "common/enums";

const initialProductState = { open: false, error: null, loading: false, product: {} };
const initialOrderedProductState = { open: false, error: null, loading: false, product: {} };
const ShipmentProducts = ({ shipmentId, type }) => {
  const [productState, setProductState] = useState(initialProductState);
  const [orderedProductState, setOrderedProductState] = useState(initialOrderedProductState);
  const [products, setProducts] = useState([]);
  const [shipment, setShipment] = useState(null);
  const [imageState, setImageState] = useState({
    productId: null,
    variantId: null,
  });
  const openProductAddPopup = () => {
    setProductState((prev) => ({ ...prev, product: {}, open: true }));
  };
  const openOrderedProductAddPopup = () => {
    setOrderedProductState((prev) => ({ ...prev, product: {}, open: true }));
  };
  const handleAddProduct = async (product) => {
    return addProductToShipment(shipmentId, product).then(() => {
      loadProducts(shipmentId);
      setProductState((prev) => ({ ...prev, product: {}, open: false }));
    });
  };
  const handleCloseProduct = () => {
    setProductState((prev) => ({ ...prev, open: false }));
  };
  const handleOrderedAddProduct = async (product) => {
    return addOrderedProductToShipment(shipmentId, { idsNotes: [product] }).then(() => {
      loadProducts(shipmentId);
      setOrderedProductState((prev) => ({ ...prev, product: {}, open: false }));
    });
  };
  const handleCloseOrderedProduct = () => {
    setOrderedProductState((prev) => ({ ...prev, open: false }));
  };
  const loadProducts = async (shipmentId) => {
    const shipmentOrderedProducts = await getShipmentProducts(shipmentId);
    setProducts(shipmentOrderedProducts);
  };
  const loadShipment = async (shipmentId) => {
    const shipment = await getShipment(shipmentId);
    setShipment(shipment);
  };
  const handleDeleteProduct = async ({ id }) => {
    await deleteShipmentProduct(shipmentId, { ids: [id] });
    loadProducts(shipmentId);
  };
  useEffect(() => {
    loadShipment(shipmentId);
    loadProducts(shipmentId);
  }, [shipmentId]);

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        align: "center",
        headerAlign: "center",
        dataField: "productVariant",
        formatter: (productVariant, row) => (
          <VariantDefaultImage
            onClick={() =>
              setImageState({
                productId: row.productVariant?.productId,
                variantId: row.productVariant?.id,
              })
            }
            variant={productVariant}
          ></VariantDefaultImage>
        ),
      },
      {
        text: "Product",
        dataField: "productVariant.productName",
      },
      {
        text: "Variant",
        dataField: "productVariant.name",
      },
      {
        text: "SKU",
        dataField: "sku",
      },
      {
        text: "Order Number",
        dataField: "order.orderId",
      },
      {
        text: "Quantity",
        dataField: "quantity",
      },
      // {
      //   text: "Notes",
      //   dataField: "notes",
      //   formatter: (cell, { shipmentNotes }) => {
      //     return <div style={{ maxWidth: "6rem" }}>{shipmentNotes}</div>;
      //   },
      // },
      {
        text: "Delete",
        dataField: "delete",
        align: "center",
        headerAlign: "center",
        hidden: type == "view" || shipment?.shipmentStatus != EnumValues.SHIPMENT_STATUS.PLANNING,
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeleteProduct(row)}
              title="Are you sure you want to delete the product?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    [shipment]
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Products</b>
        {type != "view" && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" onClick={openProductAddPopup}>
              <i className="fa fa-plus"></i>Add Free Style Product
            </Button>
            &nbsp;
            <Button color="primary" size="sm" outline onClick={openOrderedProductAddPopup}>
              <i className="fa fa-plus"></i>Add Product
            </Button>
          </span>
        )}
      </h4>
      <br />
      {products.length > 0 && (
        <Row>
          <Col xl="12">
            <div className="table-responsive">
              <BootstrapTable keyField="id" data={products} columns={productColumns} />
            </div>
          </Col>
        </Row>
      )}
      {productState.open && <ShipmentProductModal onClose={handleCloseProduct} onSubmit={handleAddProduct} />}
      {orderedProductState.open && (
        <ShipmentOrderedProductModal onClose={handleCloseOrderedProduct} onSubmit={handleOrderedAddProduct} />
      )}
      {imageState.variantId && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productId, imageState.variantId)}
          onClose={() => setImageState({})}
          title="Product Images"
        />
      )}
    </>
  );
};

const ShipmentProductModal = ({ product, onClose, onSubmit }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!product?.id ? "Add new product" : "Edit product"}</ModalHeader>
      <ModalBody>
        <ShipmentProductForm initialProduct={product} onSubmit={onSubmit} />
      </ModalBody>
    </Modal>
  );
};

const ShipmentOrderedProductModal = ({ product, onClose, onSubmit }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!product?.id ? "Add new product" : "Edit product"}</ModalHeader>
      <ModalBody>
        <ShipmentOrderedProductForm initialProduct={product} onSubmit={onSubmit} />
      </ModalBody>
    </Modal>
  );
};

export default ShipmentProducts;
