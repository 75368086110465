import VariantModal from "pages/Product/VariantModal";
import { useCallback, useState } from "react";
import { Button, Card, CardBody, CardFooter, Col, Input, Label, Spinner } from "reactstrap";
import VariantCard from "../Product/VariantCard";
import productionUtils from "./production_utils";

const ProductionProductForm = ({
  initialProduct = {
    id: null,
    variant: {},
    notes: "",
    quantity: 1,
  },
  onSubmit,
}) => {
  const [openVariantPopup, setOpenVariantPopup] = useState(false);
  const [saving, setSaving] = useState(false);
  const [product, setProduct] = useState({ ...initialProduct });

  const handleSelectVariant = ({ variant }) => {
    setProduct((prev) => ({ ...prev, variant }));
    setOpenVariantPopup(false);
  };
  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit(productionUtils.mapToProductAddModel(product));
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Card className="shadow-lg">
        <CardBody>
          <div className="row mb-4">
            <Label htmlFor="variant" className="col-sm-3 col-form-label">
              Product
            </Label>
            <Col sm={9}>
              {product.variant?.id && <VariantCard variant={product.variant} />}
              {!product.variant?.id && (
                <Button color="primary" className="rounded" onClick={() => setOpenVariantPopup(true)}>
                  <i className="fa fa-plus"></i>
                </Button>
              )}
            </Col>
          </div>
          <div className="row mb-4">
            <Label htmlFor="quantity" className="col-sm-3 col-form-label">
              Quantity
            </Label>
            <Col sm={9}>
              <Input
                type="number"
                value={product.quantity}
                name="quantity"
                placeholder="only numbers.."
                className="form-control"
                onChange={handleChange}
              />
            </Col>
          </div>
          <div className="row mb-4">
            <Label htmlFor="notes" className="col-sm-3 col-form-label">
              Notes
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                value={product.notes}
                name="notes"
                placeholder="notes.."
                className="form-control"
                onChange={handleChange}
              />
            </Col>
          </div>
        </CardBody>
        <CardFooter>
          {product.variant?.id && (
            <>
              <Button color="primary" onClick={() => setOpenVariantPopup(true)}>
                Change Product
              </Button>
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          <Button color="success" onClick={handleSubmit} disabled={saving}>
            {!product.id && "Add to production "}
            {product.id && "Update "}
            {saving && <Spinner size="sm" />}
          </Button>
        </CardFooter>
      </Card>
      {openVariantPopup && <VariantModal onClose={() => setOpenVariantPopup(false)} onSelect={handleSelectVariant} />}
    </>
  );
};

export default ProductionProductForm;
