import { atom } from "recoil";
import proformaUtils from "./proforma_utils";

export const proformasAppliedFilterState = atom({
  key: "Proformas.appliedFilter",
  default: proformaUtils.initialFilterValues(),
});
export const proformasAppliedSortState = atom({
  key: "Proformas.appliedSort",
  default: { sortField: "proformaId", sortOrder: "desc" },
});
