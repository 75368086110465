import enums, { getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import DateOutput from "components/Common/DateOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import Privileges from "models/Privileges";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Row,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { downloadService } from "services/DownloadService";
import { exportPaymentsToCsv, exportPaymentsToPdf, getInvoice, getPayments } from "store/payment/services";
import { paymentsAppliedFilterState } from "./atoms";
import PaymentFilterForm from "./PaymentFilterForm";
import paymentUtils from "./payment_utils";

const Payments = ({ history }) => {
  const activeUser = useActiveUser();
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appliedFilter, setAppliedFilter] = useRecoilState(paymentsAppliedFilterState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("Payments");
  const loadPayments = async (page, filter) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getPayments(page, paymentUtils.mapToPaymentSearch(filter));
      setTotalSize(totalElements);
      setPayments(content);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPayments(pageOptions, appliedFilter);
  }, []);

  const handlePageChange = (arg0, { page, sizePerPage }) => {
    loadPayments(setPageNumber(page, sizePerPage), appliedFilter);
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    const newPage = setPageNumber(0);
    loadPayments(newPage, appliedFilter);
    setHasFilter(hasAnyValue(appliedFilter));
  };

  const handleDownloadInvoice = async (paymentId) => {
    const data = await getInvoice(paymentId);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };
  const handleExportToPdf = async () => {
    const data = await exportPaymentsToPdf(pageOptions, paymentUtils.mapToPaymentSearch(appliedFilter));
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };
  const handleExportToCsv = async () => {
    const data = await exportPaymentsToCsv(pageOptions, paymentUtils.mapToPaymentSearch(appliedFilter));
    downloadService.downloadBufferedData({
      data,
      contentType: "text/csv",
      fileName: "payments.csv",
    });
  };

  const columns = [
    {
      dataField: "actions",
      text: "Actions",
      align: "center",
      headerAlign: "center",
      hidden:
        !activeUser.hasPrivilege(Privileges.ORDER.UPDATE_PF) &&
        !activeUser.hasPrivilege(Privileges.INVOICE.DOWNLOAD) &&
        !activeUser.hasPrivilege(Privileges.PAYMENT.UPDATE),
      formatter: (cell, row) => {
        return (
          <ActionMenu>
            <DropdownMenu>
              {activeUser.hasPrivilege(Privileges.PAYMENT.UPDATE) && (
                <DropdownItem onClick={() => history.push(`/payment/${row.id}`)}>Edit</DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.ORDER.UPDATE_PF) && (
                <DropdownItem onClick={() => history.push(`/order/${row.order?.id}/payments-and-finances`)}>
                  Order Payments & Finances
                </DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.INVOICE.DOWNLOAD) && (
                <DropdownItem onClick={() => handleDownloadInvoice(row.id)}>Invoice PDF</DropdownItem>
              )}
            </DropdownMenu>
          </ActionMenu>
        );
      },
    },
    {
      text: "Order No",
      dataField: "order.orderId",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (
              <Link to={`/order/${row.order.id}/detail`} title="View order detail">
                {cell}
              </Link>
            )}
            {!activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && cell}
          </>
        );
      },
    },
    {
      text: "Proforma No",
      dataField: "order.proformaId",
      sort: false,
    },
    {
      text: "Payment Number",
      dataField: "paymentNumber",
      sort: false,
    },
    {
      text: "Date",
      dataField: "date",
      sort: false,
      formatter: (cell) => <DateOutput date={cell} />,
    },
    {
      text: "Paid With",
      dataField: "paidType",
      formatter: (cell) => getEnumLabel(enums.paidTypes, cell),
    },
    {
      text: "Payment Type",
      dataField: "paymentType",
      formatter: (cell) => getEnumLabel(enums.paymentTypes, cell),
    },
    {
      text: "Auth Code",
      dataField: "authCode",
    },
    {
      text: "Auth By",
      dataField: "authBy.fullName",
    },
    {
      text: "Created By",
      dataField: "createUser",
    },
    {
      text: "Branch",
      dataField: "order.deliveryBranch.name",
    },
    {
      text: "Amount",
      dataField: "amount",
      align: "right",
      headerAlign: "right",
      formatter: (cell, row) => <MoneyOutput value={cell} currency="GBP" />,
    },
  ];

  return (
    <>
      <MetaTags>
        <title>Novia - Payments</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <PaymentFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <div className="d-flex justify-content-between">
                      <h4>Payments</h4>
                      <div style={{ float: "right" }}>
                        {payments.length > 0 && (
                          <div className="d-flex justify-content-end gap-2 pb-2">
                            <Button outline size="sm" onClick={handleExportToPdf}>
                              Export to PDF
                            </Button>
                            <Button outline size="sm" onClick={handleExportToCsv}>
                              Export to CSV
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </CardTitle>
                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        hover
                        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                        loading={loading}
                        keyField="id"
                        responsive
                        bordered={false}
                        striped={false}
                        remote
                        pagination={paginationFactory(pageOptions)}
                        columns={columns}
                        data={payments}
                        onTableChange={handlePageChange}
                        wrapperClasses="table-responsive"
                        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                        headerWrapperClasses={"thead-light"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Payments.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Payments);
