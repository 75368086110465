import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import * as Yup from "yup";
import {
  createManufacturersAddress,
  deleteAddress,
  editAddress,
  getManufacturersAddress,
  getHouseTypes,
} from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Privileges from "models/Privileges";

export const productAttributeFormValues = [
  { name: "fullName", label: "Name", size: 12, type: "text" },
  { name: "address1", label: "Address Line 1", size: 12, type: "text" },
  { name: "address2", label: "Address Line 2", size: 12, type: "text" },
  { name: "town", label: "Town", size: 6, type: "text" },
  { name: "city", label: "City", size: 6, type: "text" },
  { name: "postCode", label: "Post Code", size: 6, type: "text" },
  { name: "latitude", label: "Latitude", size: 6, type: "number" },
  { name: "longitude", label: "Longitude", size: 6, type: "number" },
  { name: "houseType", label: "House Type", size: 6, type: "select", getService: getHouseTypes },
  { name: "floor", label: "Floor", size: 6, type: "number" },
  { name: "parking", label: "Parking", size: 6, type: "text" },
  { name: "lift", label: "Lift", size: 6, type: "text" },
];

const ManufacturersAddress = (props) => {
  const manufacterName = props.location.state;

  const columns = [
    {
      dataField: "name",
      text: "Title",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{manufacterName} </span>;
      },
    },
    {
      dataField: "address1",
      text: "Address Line 1",
      sort: true,
    },
    {
      dataField: "address2",
      text: "Address Line 2",
      sort: true,
    },
    {
      dataField: "town",
      text: "Town",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
    },

    {
      dataField: "postCode",
      text: "Post Code",
      sort: true,
    },

    {
      dataField: "latitude",
      text: "Latitude",
      sort: true,
    },
    {
      dataField: "longitude",
      text: "Longitude",
      sort: true,
    },

    {
      dataField: "houseType",
      text: "House Type",
      sort: true,
    },
    {
      dataField: "floor",
      text: "Floor",
      sort: true,
    },
    {
      dataField: "parking",
      text: "Parking",
      sort: true,
    },
    {
      dataField: "lift",
      text: "Lift",
      sort: true,
    },
  ];
  const { id } = useParams();

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Address</title> {/* degistir */}
        </MetaTags>
        <Link to="/manufacturers">
          <i className="fa fa-chevron-left p-2"></i>
          <span className="pl-4">Back</span>
        </Link>
        <DefinitionTable
          services={{
            getapi: (data) => {
              return getManufacturersAddress(data, id);
            },
            deleteapi: deleteAddress,
            createapi: (data) => {
              return createManufacturersAddress(id, data);
            },
            updateapi: editAddress,
          }}
          tableColumns={columns}
          formValues={{
            values: productAttributeFormValues,
            validationSchema: {
              fullName: Yup.string().required("Please enter name"),
              address1: Yup.string().required("Please enter address line"),
              address2: Yup.string(),
              town: Yup.string(),
              city: Yup.string().required("Please enter city"),
              postCode: Yup.string().required("Please enter post code"),
              latitude: Yup.string(),
              longitude: Yup.string(),
              houseType: Yup.string().required("Please select house type"),
              floor: Yup.string(),
              parking: Yup.string(),
              lift: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{
            definitionName: "Address",
            title: "Address List",
          }}
          privilege={{
            create: Privileges.MANUFACTURER_ADDRESS.CREATE,
            update: Privileges.MANUFACTURER_ADDRESS.UPDATE,
            delete: Privileges.MANUFACTURER_ADDRESS.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(ManufacturersAddress);
