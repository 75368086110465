import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";

import store from "./store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

const app = (
  <RecoilRoot>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </RecoilRoot>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
