import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { createWarehouse, deleteWarehouse, editWarehouse, getWarehouse } from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

const WarehousePage = () => {
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "shortName",
      text: "Short Name",
      sort: true,
    },
    {
      dataField: "info",
      text: "Info",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Warehouse</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getWarehouse,
            deleteapi: deleteWarehouse,
            createapi: createWarehouse,
            updateapi: editWarehouse,
          }}
          tableColumns={columns}
          formValues={{
            values: [
              { name: "name", label: "Name", size: 12, type: "text" },
              {
                name: "code",
                label: "Code",
                size: 12,
                type: "text",
              },
              {
                name: "phone",
                label: "Phone",
                size: 12,
                type: "text",
              },

              {
                name: "shortName",
                label: "Short Name",
                size: 12,
                type: "text",
              },
              {
                name: "info",
                label: "Info",
                size: 12,
                type: "text",
              },
              { name: "status", label: "status", size: 12, type: "checkbox" },
            ],
            validationSchema: {
              name: Yup.string().required(""),
              code: Yup.string(),
              phone: Yup.string(),
              shortName: Yup.string(),
              info: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{ definitionName: "Warehouse", title: "Warehouse List" }}
          privilege={{
            create: Privileges.WAREHOUSE.CREATE,
            update: Privileges.WAREHOUSE.UPDATE,
            delete: Privileges.WAREHOUSE.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(WarehousePage);
