import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { useParams, withRouter, useLocation } from "react-router-dom";
import Payment from "./Payment";

const PaymentPage = ({ history }) => {
  const { id } = useParams();
  const { search } = useLocation();
  const orderId = new URLSearchParams(search).get("orderId");
  return (
    <>
      <MetaTags>
        <title>Novia - Payment</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        <h2 className="text-primary">
          {!id && <b>New Payment</b>}
          {id && <b>Edit Payment</b>}
        </h2>
        &nbsp;
        <Payment
          paymentId={id}
          orderId={orderId}
          onSave={(payment) => history.push(`/payment/${payment.id}${orderId ? `?orderId=${orderId}` : ""}`)}
        />
      </div>
    </>
  );
};

PaymentPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(PaymentPage);
