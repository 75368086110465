import PropTypes from "prop-types";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import Product from "./Product";

const ProductPage = (props) => {
  const { id } = useParams();
  return <Product id={id} />;
};

ProductPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ProductPage);
