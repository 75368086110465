import { atom } from "recoil";
import productReportUtils from "./productReport_utils";

export const productReportAppliedFilterState = atom({
  key: "ProductReport.appliedFilter",
  default: productReportUtils.initialFilterValues(),
});
export const productReportAppliedSortState = atom({
  key: "ProductReport.appliedSort",
  default: { sortField: "createdDate", sortOrder: "desc" },
});
