import React, { useReducer } from "react";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { deleteAddress, editAddress, getHouseTypes } from "store/definitions/services";
import { createCustomersAddress, getCustomersAddress } from "store/customer/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Privileges from "models/Privileges";

const HouseType = [
  { value: "HOUSE", label: "House" },
  { value: "FLAT", label: "Flat" },
];
const ParkingDistance = [
  { value: "0", label: "0 m" },
  { value: "50", label: "50 m" },
  { value: "100", label: "100 m" },
  { value: "200", label: "200 m" },
  { value: "200+", label: "200+ m" },
];

export const customerAddressFormValues = [
  // { name: "name", label: "Name", size: 12, type: "text" },
  { name: "address1", label: "Address Line 1", size: 12, type: "text" },
  { name: "address2", label: "Address Line 2", size: 12, type: "text" },
  { name: "houseType", label: "House Type", size: 6, type: "select", options: HouseType },
  { name: "floor", label: "Floor", size: 6, type: "number" },
  { name: "parkingDistance", label: "Parking distance", size: 6, type: "select", options: ParkingDistance },
  { name: "elevator", label: "Elevator?", size: 6, type: "checkbox" },
  { name: "town", label: "Town", size: 6, type: "text" },
  { name: "city", label: "City", size: 6, type: "text" },
  { name: "postCode", label: "Post Code", size: 6, type: "text" },
];

export const customerAddressValidationSchema = {
  // name: Yup.string().required("Please enter a address name"),
  address1: Yup.string().required("Please enter address line"),
  address2: Yup.string(),
  town: Yup.string(),
  houseType: Yup.string().required("Please select house type"),
  city: Yup.string().required("Please enter city"),
  postCode: Yup.string().required("Please enter post code"),
};

const CustomerAddress = (props) => {
  const customerName = props.location.state;
  const columns = [
    {
      dataField: "customerName",
      text: "Customer",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{customerName} </span>;
      },
    },
    // {
    //   dataField: "name",
    //   text: "Address Name",
    //   sort: true,
    // },
    {
      dataField: "address1",
      text: "Address Line 1",
      sort: true,
    },
    {
      dataField: "address2",
      text: "Address Line 2",
      sort: true,
    },
    {
      dataField: "town",
      text: "Town",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
    },

    {
      dataField: "postCode",
      text: "Post Code",
      sort: true,
    },
  ];
  const { id } = useParams();

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customer Address</title>
        </MetaTags>
        <Link to="/customers">
          <i className="fa fa-chevron-left p-2"></i>
          <span className="pl-4">Back</span>
        </Link>
        <DefinitionTable
          services={{
            getapi: (data) => {
              return getCustomersAddress(data, id);
            },
            deleteapi: deleteAddress,
            createapi: (data) => {
              return createCustomersAddress(id, data);
            },
            updateapi: editAddress,
          }}
          tableColumns={columns}
          formValues={{
            values: customerAddressFormValues,
            validationSchema: customerAddressValidationSchema,
          }}
          pageDetails={{
            definitionName: "Address",
            title: "Address List",
          }}
          privilege={{
            create: Privileges.CUSTOMER_ADDRESS.CREATE,
            update: Privileges.CUSTOMER_ADDRESS.UPDATE,
            delete: Privileges.CUSTOMER_ADDRESS.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(CustomerAddress);
