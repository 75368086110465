import Attribute from "./Attribute";
import SweetAlert from "react-bootstrap-sweetalert";
import React, { useState } from "react";
import { Button, Col, Row, Modal, Form, Label } from "reactstrap";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { getProductAttribute, getProductAttributeDetail } from "store/definitions/services";

const anonymousFunc = () => {};
const confirmDeleteInitialValues = { open: false, index: null };

const Attributes = ({
  items = [],
  setValue = anonymousFunc,
  setValues = anonymousFunc,
  deleteItem = null,
  isMulti = true,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(confirmDeleteInitialValues);

  const handleDelete = (id) => {
    setConfirmDelete({ open: true, index: items.findIndex((a) => a.attribute.id == id) });
  };
  return (
    <>
      {items.map((attr) => (
        <Attribute
          key={attr.attribute.id}
          id={attr.attribute.id}
          value={attr.value}
          values={attr.values.map((item) => item.value)}
          setValue={(val, objVal) => {
            setValue(attr.attribute.id, val, objVal);
          }}
          setValues={(vals, objVals) => {
            setValues(attr.attribute.id, vals, objVals);
          }}
          options={attr.options}
          onDelete={deleteItem ? handleDelete : undefined}
          isMulti={isMulti}
        />
      ))}
      {confirmDelete.open && (
        <SweetAlert
          title="Are you sure you want to delete?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            deleteItem(confirmDelete.index);
            setConfirmDelete(confirmDeleteInitialValues);
          }}
          onCancel={() => setConfirmDelete(confirmDeleteInitialValues)}
        ></SweetAlert>
      )}
    </>
  );
};

export const AddAttributePopup = ({
  onAdd = anonymousFunc,
  onClose = anonymousFunc,
  productTypeId,
  attributes = [],
}) => {
  const [attributeId, setAttributeId] = useState(null);
  const handleSubmit = async () => {
    if (attributeId) {
      const response = await getProductAttributeDetail(attributeId);
      onAdd({
        id: response.id,
        name: response.name,
        inputType: response.inputType,
        orderNumber: response.orderNumber,
      });
      setAttributeId(null);
    }
  };
  const toggleModal = () => {
    onClose();
  };
  return (
    <Modal isOpen={true} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Select Attribute
        </h5>
        <Button onClick={toggleModal} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </Button>
      </div>
      <div className="modal-body">
        <div className="p-2">
          <Form className="form-horizontal">
            <Row>
              <Col sm={12}>
                <div className="mb-3">
                  <Label className="form-label">Product Attribute</Label>
                  <MultiselectWithService
                    service={(params) =>
                      getProductAttribute(params, productTypeId).then((attrs) => {
                        const ret = [];
                        attrs.forEach((a) => {
                          if (attributes.find((x) => x.attribute.id == a.id) == null) {
                            ret.push(a);
                          }
                        });
                        return ret;
                      })
                    }
                    name="productAttributeId"
                    value={attributeId}
                    setValue={(name, val) => setAttributeId(val)}
                    isMulti={false}
                    reloadOptionsKey={attributes}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="modal-footer">
        <Button onClick={toggleModal} color="secondary" data-dismiss="modal">
          Close
        </Button>
        <Button onClick={handleSubmit} color="success">
          Add
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(Attributes);
/*

*/
