import { atom } from "recoil";
import productUtils from "./product_utils";

export const appliedFilterState = atom({
  key: "Products.appliedFilter",
  default: productUtils.initialFilterValues(),
});
export const appliedSortState = atom({
  key: "Products.appliedSort",
  default: { sortField: "name", sortOrder: "asc" },
});
