import moment from "moment";

class OperationCenterUtils {
  initialOrderFilterValues = (deliveryDate) => ({
    deliveryDate: deliveryDate,
  });
  initialDeliveryFilterValues = (plannedDate) => ({
    plannedDate: plannedDate,
  });
  initialTicketFilterValues = (serviceDate) => ({
    serviceDate: serviceDate,
  });
  mapToDeliverySearch = ({ plannedDate }) => {
    return {
      startPlannedDate: plannedDate && moment(plannedDate).format("YYYY-MM-DD"),
      endPlannedDate: plannedDate && moment(plannedDate).format("YYYY-MM-DD"),
      activeDeliveries: true,
    };
  };
  mapToOrderSearch = ({ deliveryDate }) => {
    return {
      deliveryDateStart: deliveryDate && moment(deliveryDate).format("YYYY-MM-DD"),
      deliveryDateEnd: deliveryDate && moment(deliveryDate).format("YYYY-MM-DD"),
    };
  };
  mapToTicketSearch = ({ serviceDate }) => {
    return {
      serviceDateStart: serviceDate && moment(serviceDate).format("YYYY-MM-DD"),
      serviceDateEnd: serviceDate && moment(serviceDate).format("YYYY-MM-DD"),
    };
  };
}
const operationCenterUtils = new OperationCenterUtils();
export default operationCenterUtils;
