import { post, get, del, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

//finance
export const getFinance = (id) => get(`/order-finance/${id}`);
export const editFinance = (id, data) => put(`/order-finance/${id}`, data);
export const createFinance = (data) => post(`/order-finance`, data);
export const deleteFinance = (id) => del(`/order-finance/${id}`);
export const getFinances = (pageOpt = { page: 0, size: 100 }, filter) =>
  get(`/order-finance/search?page=${pageOpt.page}&size=${pageOpt.size}&${toQueryParams(filter)}`);

//finance notes
export const addNoteToFinance = (orderId, data) => put(`/order-finance/${orderId}/note`, data);
export const getFinanceNotes = (orderId) => get(`/order-finance/${orderId}/note`);
export const deleteFinanceNote = (orderId, noteId) => del(`/order-finance/${orderId}/note/${noteId}`);
