// import NumberFormat from 'react-number-format';

import endpoints from "env/endpoints";
import { useHttpCache } from "hooks";
import NumberFormat from "react-number-format";
import { currencyService } from "services/CurrencyService";

function MoneyOutput({ value = 0, showSymbol = true, currency = "GBP", scale }) {
  const numVal = Number(value);
  let decimalScale = 2;
  if (!Number.isNaN(numVal)) {
    const s = `${numVal}`.split(".");
    if (s.length > 1) {
      decimalScale = s[1].length;
    }
  }
  if (decimalScale < 2) {
    decimalScale = 2;
  }

  if (!showSymbol) {
    return (
      <NumberFormat
        value={value == null ? "" : value}
        displayType="text"
        thousandSeparator={true}
        fixedDecimalScale
        decimalScale={scale ?? decimalScale}
      />
    );
  }
  const { data: currencies } = useHttpCache(endpoints.currency.getCurrencies);
  return (
    <NumberFormat
      value={value == null ? "" : value}
      displayType="text"
      thousandSeparator={true}
      fixedDecimalScale
      decimalScale={scale ?? decimalScale}
      prefix={currencyService.getCurrencySymbol(currencies, currency)}
    />
  );
}

export default MoneyOutput;
