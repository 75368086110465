import { atom } from "recoil";
import shipmentUtils from "./shipment_utils";

export const shipmentsAppliedFilterState = atom({
  key: "Shipments.appliedFilter",
  default: shipmentUtils.initialFilterValues(),
});
export const shipmentsAppliedSortState = atom({
  key: "Shipments.appliedSort",
  default: { sortField: "no", sortOrder: "desc" },
});
