import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { useParams, withRouter, useLocation } from "react-router-dom";
import Finance from "./Finance";

const FinancePage = ({ history }) => {
  const { id } = useParams();
  const { search } = useLocation();
  const orderId = new URLSearchParams(search).get("orderId");
  return (
    <>
      <MetaTags>
        <title>Novia - Finance</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        <h2 className="text-primary">
          {!id && <b>New Finance</b>}
          {id && <b>Edit Finance</b>}
        </h2>
        &nbsp;
        <Finance
          financeId={id}
          orderId={orderId}
          onSave={(finance) => history.push(`/finance/${finance.id}${orderId ? `?orderId=${orderId}` : ""}`)}
        />
      </div>
    </>
  );
};

FinancePage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(FinancePage);
