import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import ValidationForm from "components/ValidationForm";
import VariantCard from "pages/Product/VariantCard";
import VariantModal from "pages/Product/VariantModal";
import React, { useCallback, useState } from "react";
import NumberFormat from "react-number-format";
import { Button, Card, CardBody, CardFooter, Col, Input, Label, Spinner } from "reactstrap";
import { getBranches } from "store/store/services";
import inventoryUtils from "./inventory_utils";

const InventoryProductForm = ({ onSubmit }) => {
  const [openVariantPopup, setOpenVariantPopup] = useState(false);
  const [product, setProduct] = useState({
    quantity: null,
    notes: "",
    branch: {},
  });
  const [saving, setSaving] = useState(false);

  const handleSelectVariant = ({ variant }) => {
    setProduct((prev) => ({
      ...prev,
      variant,
      inStockProduct: null,
      orderType: null,
    }));
    setOpenVariantPopup(false);
  };

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit(inventoryUtils.mapToProductAddModel(product));
    } finally {
      setSaving(false);
    }
  };

  return (
    <ValidationForm>
      <Card className="shadow-lg">
        <CardBody>
          <div className="row mb-4">
            <Label htmlFor="variant" className="col-sm-3 col-form-label">
              Product
            </Label>
            <Col sm={9}>
              {product.variant?.id && <VariantCard variant={product.variant} />}
              {!product.variant?.id && (
                <Button color="primary" className="rounded" onClick={() => setOpenVariantPopup(true)}>
                  <i className="fa fa-plus"></i>
                </Button>
              )}
              <input name="variantId" value={product.variant?.id} validations={["required"]} type="hidden" />
            </Col>
          </div>
          <div className="row mb-4">
            <Label htmlFor="branch" className="col-sm-3 col-form-label">
              Inventory Add To
            </Label>
            <Col sm={9}>
              <MultiselectWithService
                service={getBranches}
                name="branch"
                value={product.branch.value || ""}
                isMulti={false}
                setValue={handleSetValueObj}
                validationeventname="setValue"
                validations={["required"]}
              />
            </Col>
          </div>
          <div className="row mb-4">
            <Label htmlFor="quantity" className="col-sm-3 col-form-label">
              Quantity
            </Label>
            <Col sm={9}>
              <NumberFormat
                value={product.quantity || ""}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                decimalScale={0}
                validationeventname="onValueChange"
                name="quantity"
                validations={["required", "min|1"]}
                onValueChange={({ value }) => {
                  handleSetValue("quantity", value);
                }}
              />
            </Col>
          </div>
          <div className="row mb-4">
            <Label htmlFor="notes" className="col-sm-3 col-form-label">
              Notes
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                value={product.notes || ""}
                name="notes"
                placeholder="notes.."
                className="form-control"
                onChange={handleChange}
              />
            </Col>
          </div>
        </CardBody>
        <CardFooter>
          {product.variant?.id && (
            <>
              <Button color="primary" onClick={() => setOpenVariantPopup(true)}>
                Change Product
              </Button>
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          <Button color="success" onClick={handleSubmit} disabled={saving} validate>
            Add to inventory
            {saving && <Spinner size="sm"></Spinner>}
          </Button>
        </CardFooter>
      </Card>
      {openVariantPopup && <VariantModal onClose={() => setOpenVariantPopup(false)} onSelect={handleSelectVariant} />}
    </ValidationForm>
  );
};

export default InventoryProductForm;
