import { post, get, del, put } from "helpers/axios_helper";
import { arrayToParams, toQueryParams } from "helpers/utils";
import { filter } from "lodash";

//order
export const getOrder = (id) => get(`/order/${id}`);
export const editOrder = (id, data) => put(`/order/${id}`, data);
export const createOrder = (data) => post(`/order`, data);
export const deleteOrder = (id) => del(`/order/${id}`);
export const getOrders = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/order/search?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );
export const getProformas = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/order/search/proforma?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${
      pageOpt.page
    }&size=${pageOpt.size}&&${toQueryParams(filter)}`
  );
export const getOrderDiscountStatus = (id) => get(`/order/${id}/discountStatus`);
export const startOrder = (id) => put(`/order/${id}/start`);
export const cancelOrder = (id) => put(`/order/${id}/cancel`);
export const approveOrder = (id) => put(`/order/${id}/approve`);

export const getOrderDetailsPdf = (id) =>
  get(`/order/${id}/orderDetailsPdf`, {
    responseType: "blob",
  });
export const getProformaDetailsPdf = (id) =>
  get(`/order/${id}/proformaDetailsPdf`, {
    responseType: "blob",
  });

export const getPreviousNext = (id) => get(`/order/${id}/previousNext`);

//order products
export const getOrderProducts = (orderId) => get(`/order/${orderId}/product`);
export const addProductToOrder = (orderId, data) => put(`/order/${orderId}/product`, data);
export const addInStockProductToOrder = (orderId, orderedProductId, data) =>
  put(`/order/${orderId}/product/${orderedProductId}/stock`, data);

export const deleteOrderProduct = (orderId, productId) => del(`/order/${orderId}/product/${productId}`);
export const updateDiscountRates = (orderId, discountRate, discountTotal) =>
  put(`/order/${orderId}/discountRate?discountRate=${discountRate || ""}&discountTotal=${discountTotal || ""}`);

export const getCustomerOrder = (customerId) => get(`/order/search?page=0&size=1000&customerIds=${customerId}`);

export const addImageToOrder = (orderId, imageData) => put(`/order/${orderId}/image`, imageData);
export const deleteOrderImage = (orderId, imageId) => del(`/order/${orderId}/image/${imageId}`);
export const getOrderImages = (orderId) => get(`/order/${orderId}/image`);

export const addNoteToOrder = (orderId, data) => put(`/order/${orderId}/note`, data);
export const getOrderNotes = (orderId) => get(`/order/${orderId}/note`);
export const deleteOrderNote = (orderId, noteId) => del(`/order/${orderId}/note/${noteId}`);

export const addDocumentToOrder = (orderId, data, type) => put(`/order/${orderId}/document?type=${type}`, data);
export const getOrderDocuments = (orderId) => get(`/order/${orderId}/document`);
export const deleteOrderDocument = (orderId, docId) => del(`/order/${orderId}/document/${docId}`);

export const getOrderPayments = (orderId) => get(`/order/${orderId}/payment`);
export const getOrderFinances = (orderId) => get(`/order/${orderId}/finance`);

export const getOrdersFinances = (orderId) => get(`/order/${orderId}/finance`);

export const searchOrders = (
  filter = {
    orderIds: [],
    customerIds: [],
  }
) => {
  let url = "/order/search?page=0&size=20";
  const orderIds = arrayToParams("orderIds", filter.orderIds);
  if (orderIds) {
    url += "&" + orderIds;
  }
  const customerIds = arrayToParams("customerIds", filter.customerIds);
  if (customerIds) {
    url += "&" + customerIds;
  }
  return get(url);
};
export const searchOrdersByOrderNumber = (orderNumber) => {
  const url = "/order/query?page=0&size=20&query=" + orderNumber;
  return get(url).then((data) => data.content);
};
