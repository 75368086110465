import { useState, useCallback } from "react";
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

const ConfirmButton = ({ title = "", confirmButtonText = "OK", onConfirm, onCancel, data, children, ...rest }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleConfirm = useCallback(() => {
    setOpenConfirm(() => {
      onConfirm && onConfirm(data);
      return false;
    });
  }, []);
  const handleCancel = useCallback(() => {
    setOpenConfirm(() => {
      onCancel && onCancel(data);
      return false;
    });
  }, []);

  return (
    <>
      <Button {...rest} onClick={() => setOpenConfirm(true)}>
        {children}
      </Button>
      {openConfirm && (
        <SweetAlert
          title={<div>{title}</div>}
          warning
          showCancel
          confirmButtonText={confirmButtonText}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        ></SweetAlert>
      )}
    </>
  );
};
export default ConfirmButton;
