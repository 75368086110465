import enums, { getEnumLabel } from "common/enums";
import ImagesModal from "components/Common/ImagesModal";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { getVariantOrProductImages } from "store/product/services";

const OrderedProductModal = ({
  onClose,
  onSelect,
  service,
  multiple = false,
  showProductName = false,
  showProductStatus = false,
  showVariantName = false,
  showProductManufacturer = false,
  showSku = true,
  selectButtonLabel = "Select",
}) => {
  const [imageState, setImageState] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [applying, setApplying] = useState(-1);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const loadProducts = async () => {
    setLoading(true);
    try {
      const prds = await service();
      setProducts(prds);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadProducts();
  }, []);

  const handleApplyOne = async (item) => {
    setApplying(item.id);
    try {
      await onSelect(item);
    } finally {
      setApplying(-1);
    }
  };
  const handleApplyAll = async () => {
    setApplying(0);
    try {
      await onSelect(selectedProducts);
    } finally {
      setApplying(-1);
    }
  };

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        formatter: (productVariant, row) => (
          <VariantDefaultImage
            onClick={(e) => {
              e.stopPropagation();
              setImageState(row);
            }}
            variant={productVariant}
          ></VariantDefaultImage>
        ),
      },
      {
        text: "Order Number",
        dataField: "order.orderId",
        filter: products.length == 0 ? undefined : textFilter(),
      },
      {
        text: "Product",
        dataField: "productVariant.productName",
        filter: products.length == 0 ? undefined : textFilter(),
        hidden: !showProductName,
      },
      {
        text: "Variant",
        dataField: "productVariant.name",
        filter: products.length == 0 ? undefined : textFilter(),
        hidden: !showVariantName,
      },
      {
        text: "Manufacturer",
        dataField: "productVariant.productManufacturerName",
        filter: products.length == 0 ? undefined : textFilter(),
        hidden: !showProductManufacturer,
      },
      {
        text: "Status",
        dataField: "productStatus",
        filter: products.length == 0 ? undefined : textFilter(),
        formatter: (cell) => getEnumLabel(enums.orderedProductStatus, cell),
        hidden: !showProductStatus,
      },
      {
        text: "SKU",
        dataField: "sku",
        filter: products.length == 0 ? undefined : textFilter(),
        hidden: !showSku,
      },
      {
        text: "Color",
        dataField: "productVariant.color.nameWithMaterial",
        filter: products.length == 0 ? undefined : textFilter(),
      },
      {
        text: "Select",
        dataField: "select",
        hidden: multiple,
        formatter: (cell, row) => {
          return (
            <Button color="success" size="sm" onClick={() => onSelect(row)} disabled={applying == row.id}>
              {applying == row.id ? <Spinner color="white" size="sm" /> : <>{selectButtonLabel}</>}
            </Button>
          );
        },
      },
    ],
    [products, applying]
  );
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedProducts((prev) => [...prev, row]);
    } else {
      setSelectedProducts((prev) => prev.filter((p) => p.id != row.id));
    }
    // return true;
  };
  // const handleOnSelectAll = (isSelect, rows, e) => {
  //   console.log(123);
  //   rows.forEach((row) => (row.selected = isSelect));
  //   return true;
  // };
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectAll: false,
    // onSelectAll: handleOnSelectAll,//calismiyor!!!
    onSelect: handleOnSelect,
    selected: selectedProducts.map((p) => p.id),
  };
  return (
    <>
      <Modal isOpen centered={true} size="lg">
        <ModalHeader toggle={onClose}>Select Ordered Product</ModalHeader>
        <ModalBody>
          <Card className="shadow-lg">
            <CardBody>
              <div className="table-responsive">
                <BootstrapTable
                  keyField="id"
                  data={products}
                  columns={productColumns}
                  filter={filterFactory()}
                  filterPosition="inline"
                  hover
                  responsive
                  bordered={false}
                  striped={false}
                  wrapperClasses="table-responsive"
                  classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                  headerWrapperClasses={"thead-light"}
                  selectRow={multiple ? selectRow : undefined}
                  noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                  loading={loading}
                />
              </div>
            </CardBody>
          </Card>
        </ModalBody>
        {multiple && (
          <ModalFooter>
            <Button color="success" onClick={handleApplyAll} disabled={applying == 0 || selectedProducts.length == 0}>
              {selectButtonLabel} {applying == 0 && <Spinner color="white" size="sm" />}
            </Button>
          </ModalFooter>
        )}
      </Modal>
      {imageState && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productVariant.productId, imageState.productVariant.id)}
          onClose={() => setImageState(null)}
        />
      )}
    </>
  );
};

export default OrderedProductModal;
