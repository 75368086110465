class TransferUtils {
  initialValues = () => ({
    id: null,
    status: "y",
    senderLocation: {},
    arrivalLocation: {},
    transferStatus: {},
    vehiclePlateNo: "",
    receiverUser: {},
    senderUser: {},
  });
  mapToSaveModel = ({
    id,
    status,
    senderLocation = {},
    arrivalLocation = {},
    transferStatus = {},
    vehiclePlateNo,
    receiverUser = {},
    senderUser = {},
  }) => ({
    id,
    status,
    senderLocationId: senderLocation?.value,
    arrivalLocationId: arrivalLocation?.value,
    transferStatus: transferStatus?.value,
    vehiclePlateNo,
    receiverUserId: receiverUser?.value,
    senderUserId: senderUser?.value,
  });

  mapToFormDataModel = ({
    id,
    status,
    senderLocation = {},
    arrivalLocation = {},
    transferStatus = "",
    vehiclePlateNo,
    receiverUserId,
    senderUserId,
  }) => ({
    id,
    status,
    senderLocation: {
      label: senderLocation?.name,
      value: senderLocation?.id,
    },
    arrivalLocation: {
      label: arrivalLocation?.name,
      value: arrivalLocation?.id,
    },
    transferStatus: {
      label: transferStatus,
      value: transferStatus,
    },
    vehiclePlateNo,
    receiverUser: {
      // label: receiverUser?.fullName,
      value: receiverUserId,
    },
    senderUser: {
      // label: senderUser?.fullName,
      value: senderUserId,
    },
  });

  mapToProductAddModel = ({ id, status = "y", variant = {}, quantity, notes }) => ({
    id,
    status,
    variantId: variant?.id,
    quantity,
    notes,
  });

  mapToTransferProductAddModel = ({ transferProduct, notes }) => ({
    id: transferProduct?.id,
    notes,
  });
  mapToOrderedProductAddModel = ({ orderedProduct, notes }) => ({
    id: orderedProduct?.id,
    notes,
  });

  mapToNoteAddModel = ({ id, status = "y", notes }) => ({
    id,
    status,
    notes,
  });

  initialFilterValues = () => ({
    transferNumber: null,
    orderId: null,
    transferStatus: null,
    senderLocationId: null,
    arrivalLocationId: null,
    receiverUserId: null,
    senderUserId: null,
    vehiclePlateNo: "",
  });

  mapToTransferSearch = (
    filter = {
      transferNumber: null,
      orderId: null,
      transferStatus: null,
      senderLocationId: null,
      arrivalLocationId: null,
      receiverUserId: null,
      senderUserId: null,
      vehiclePlateNo: "",
    }
  ) => {
    return {
      transferNumber: filter.transferNumber,
      orderId: filter.orderId,
      statuses: filter.transferStatus ? [filter.transferStatus] : [],
      senderLocationId: filter.senderLocationId,
      arrivalLocationId: filter.arrivalLocationId,
      receiverUserId: filter.receiverUserId,
      senderUserId: filter.senderUserId,
      vehiclePlateNo: filter.vehiclePlateNo,
    };
  };
}

const transferUtils = new TransferUtils();
export default transferUtils;
