import { post, get, del, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

// customers
// export const getCustomers = (params) => get(`/customer?page=${params.page}&size=1000`);
export const deleteCustomers = (customerId) => del(`/customer/${customerId}`);
export const editCustomers = (customerId, data) => put(`/customer/${customerId}`, data);
export const createCustomers = (data) => post(`/customer`, data);
export const searchCustomersByFilter = (filterText, limit = { page: 0 }) =>
  get(`/customer/search?page=${limit.page}&size=20&freeSearch=${filterText}`).then((res) => res.content);
export const getCustomer = (id) => get(`/customer/${id}`);
export const getCustomers = (pageOpt = { page: 0, size: 1000 }, filter, sort) =>
  get(
    `/customer/search?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );
// customers address
export const createCustomersAddress = (customerId, data) => put(`/customer/${customerId}/address`, data);
export const getCustomersAddress = (address, id) => get(`/customer/${id}/address?page=${address.page}&size=1000`);

// customer note
export const addNoteToCustomer = (customerId, data) => put(`/customer/${customerId}/note`, data);
export const getCustomerNotes = (customerId) => get(`/customer/${customerId}/note`);
export const deleteCustomerNote = (customerId, noteId) => del(`/customer/${customerId}/note/${noteId}`);

//customer document

export const addDocumentToCustomer = (customerId, data, type) =>
  put(`/customer/${customerId}/document?type=${type}`, data);
export const getCustomerDocuments = (customerId) => get(`/customer/${customerId}/document`);
export const deleteCustomerDocument = (customerId, docId) => del(`/customer/${customerId}/document/${docId}`);
