import { call, put, takeLeading } from "redux-saga/effects";

// Login Redux States
import {
  apiError,
  getCOlorsSuccess,
  getCurrencySuccess,
  getCurrencyRateSuccess,
  getFinanceCompanySuccess,
  getManufacturersSuccess,
  getMaterialsSuccess,
  getProductAttributesSuccess,
  getProductCategoriesSuccess,
  getProductTypesSuccess,
  getTaxGroupSuccess,
  getWarehouseSuccess,
  getWebCategroriesSuccess,
  getAddressSuccess,
  getAssProblemTypesSuccess,
} from "./actions";
import {
  GET_COLORS,
  GET_CURRENCIES,
  GET_CURRENCY_RATE,
  GET_FINANCE_COMPANY,
  GET_MANUFACTURERS,
  GET_MATERIALS,
  GET_PRODUCT_ATTRIBUTES,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_TYPES,
  GET_TAX_GROUP,
  GET_WAREHOUSE,
  GET_WEB_CATEGORIES,
  GET_ADDRESS,
  GET_ASS_PROBLEM_TYPES,
} from "./actionTypes";

import {
  getColors,
  getCurrencies,
  getCurrencyRate,
  getFinanceCompany,
  getManufacturers,
  getMaterials,
  getProductAttribute,
  getProductCategories,
  getProductTypes,
  getTaxGroups,
  getWarehouse,
  getWebCategories,
  getManufacturersAddress,
  getAssProblemTypes,
} from "./services";

function* takeOneAndBlock(pattern, worker, ...args) {
  const task = yield fork(function* () {
    while (true) {
      const action = yield take(pattern);
      yield call(worker, ...args, action);
    }
  });
  return task;
}

function* getColorsSaga({ payload: { params } }) {
  try {
    const response = yield call(getColors, params);
    yield put(getCOlorsSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getManufacturersSaga({ payload: { params } }) {
  try {
    const response = yield call(getManufacturers, params);
    yield put(getManufacturersSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getCurrenciesSaga({ payload: { params } }) {
  try {
    const response = yield call(getCurrencies, params);
    yield put(getCurrencySuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getCurrencyRateSaga({ payload: { params } }) {
  try {
    const response = yield call(getCurrencyRate, params);
    yield put(getCurrencyRateSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getFinanceCompanySaga({ payload: { params } }) {
  try {
    const response = yield call(getFinanceCompany, params);
    yield put(getFinanceCompanySuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getMaterialsSaga({ payload: { params } }) {
  try {
    const response = yield call(getMaterials, params);
    yield put(getMaterialsSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getproductCategoriesSaga({ payload: { params } }) {
  try {
    const response = yield call(getProductCategories, params);
    yield put(getProductCategoriesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getproductTypesSaga({ payload: { params } }) {
  try {
    const response = yield call(getProductTypes, params);
    yield put(getProductTypesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getproductAttributesSaga({ payload: { params } }) {
  try {
    const response = yield call(getProductAttribute, params);
    yield put(getProductAttributesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getTaxGroupsSaga({ payload: { params } }) {
  try {
    const response = yield call(getTaxGroups, params);
    yield put(getTaxGroupSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getWarehouseSaga({ payload: { params } }) {
  try {
    const response = yield call(getWarehouse, params);
    yield put(getWarehouseSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getWebCategoriesSaga({ payload: { params } }) {
  try {
    const response = yield call(getWebCategories, params);
    yield put(getWebCategroriesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getAssProblemTypesSaga({ payload: { params } }) {
  try {
    const response = yield call(getAssProblemTypes, params);
    yield put(getAssProblemTypesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getAddressSaga({ payload: { params } }) {
  try {
    const response = yield call(getManufacturersAddress, params);
    yield put(getAddressSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* definitionsSaga() {
  yield takeLeading(GET_COLORS, getColorsSaga);
  yield takeLeading(GET_CURRENCIES, getCurrenciesSaga);
  yield takeLeading(GET_CURRENCY_RATE, getCurrencyRateSaga);
  yield takeLeading(GET_FINANCE_COMPANY, getFinanceCompanySaga);
  yield takeLeading(GET_MANUFACTURERS, getManufacturersSaga);
  yield takeLeading(GET_MATERIALS, getMaterialsSaga);
  yield takeLeading(GET_PRODUCT_CATEGORIES, getproductCategoriesSaga);
  yield takeLeading(GET_PRODUCT_TYPES, getproductTypesSaga);
  yield takeLeading(GET_TAX_GROUP, getTaxGroupsSaga);
  yield takeLeading(GET_WEB_CATEGORIES, getWebCategoriesSaga);
  yield takeLeading(GET_PRODUCT_ATTRIBUTES, getproductAttributesSaga);
  yield takeLeading(GET_COLORS, getColorsSaga);
  yield takeLeading(GET_MANUFACTURERS, getManufacturersSaga);
  yield takeLeading(GET_CURRENCIES, getCurrenciesSaga);
  yield takeLeading(GET_MATERIALS, getMaterialsSaga);
  yield takeLeading(GET_PRODUCT_CATEGORIES, getproductCategoriesSaga);
  yield takeLeading(GET_PRODUCT_TYPES, getproductTypesSaga);
  yield takeLeading(GET_TAX_GROUP, getTaxGroupsSaga);
  yield takeLeading(GET_WAREHOUSE, getWarehouseSaga);
  yield takeLeading(GET_ASS_PROBLEM_TYPES, getAssProblemTypesSaga);
  yield takeLeading(GET_ADDRESS, getAddressSaga);
}

export default definitionsSaga;
