import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { createCurrency, deleteCurrency, editCurrency, getCurrencies } from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

export const currencyFormValues = [
  { name: "name", label: "Currency Name", size: 12, type: "text" },
  { name: "code", label: "Code", size: 12, type: "text" },
  { name: "symbol", label: "Symbol", size: 12, type: "text" },
  { name: "status", label: "status", size: 12, type: "checkbox" },
];

const Currencies = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
    },
    {
      dataField: "symbol",
      text: "Symbol",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Currencies</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getCurrencies,
            deleteapi: deleteCurrency,
            createapi: createCurrency,
            updateapi: editCurrency,
          }}
          tableColumns={columns}
          formValues={{
            values: currencyFormValues,
            validationSchema: {
              name: Yup.string().required(""),
              code: Yup.string(),
              symbol: Yup.string().required(""),
              status: Yup.string(),
            },
          }}
          pageDetails={{ definitionName: "Currency", title: "Currency List" }}
          privilege={{
            create: Privileges.CURRENCY.CREATE,
            update: Privileges.CURRENCY.UPDATE,
            delete: Privileges.CURRENCY.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Currencies);
