class ActionLogUtils {
  initialFilterValues = () => ({
    recordId: null,
    process: null,
    actionType: null,
    userId: null,
  });

  mapToActionLogSearch = (
    filter = {
      recordId: null,
      process: null,
      actionType: null,
      userId: null,
    }
  ) => {
    return {
      recordId: filter.recordId,
      process: filter.process,
      actionType: filter.actionType,
      userId: filter.userId,
    };
  };
}
const actionLogUtils = new ActionLogUtils();
export default actionLogUtils;
