import {
  GET_PRODUCT_ATTRIBUTES,
  GET_PRODUCT_ATTRIBUTES_SUCCESS,
  API_ERROR,
  GET_COLORS,
  GET_COLORS_SUCCESS,
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCY_RATE,
  GET_CURRENCY_RATE_SUCCESS,
  GET_FINANCE_COMPANY,
  GET_FINANCE_COMPANY_SUCCESS,
  GET_MANUFACTURERS,
  GET_MANUFACTURERS_SUCCESS,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_TYPES,
  GET_PRODUCT_TYPES_SUCCESS,
  GET_TAX_GROUP,
  GET_TAX_GROUP_SUCCESS,
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_WAREHOUSE,
  GET_WAREHOUSE_SUCCESS,
  GET_ASS_PROBLEM_TYPES,
  GET_ASS_PROBLEM_TYPES_SUCCESS,
} from "./actionTypes";

export const getColorsAction = (params) => {
  return {
    type: GET_COLORS,
    payload: { params },
  };
};

export const getCOlorsSuccess = (items) => {
  return {
    type: GET_COLORS_SUCCESS,
    payload: items,
  };
};
export const getCurrencyAction = (params) => {
  return {
    type: GET_CURRENCIES,
    payload: { params },
  };
};

export const getCurrencySuccess = (items) => {
  return {
    type: GET_CURRENCIES_SUCCESS,
    payload: items,
  };
};

export const getCurrencyRate = (params) => {
  return {
    type: GET_CURRENCY_RATE,
    payload: { params },
  };
};

export const getCurrencyRateSuccess = (items) => {
  return {
    type: GET_CURRENCY_RATE_SUCCESS,
    payload: items,
  };
};

export const getFinanceCompany = (params) => {
  return {
    type: GET_FINANCE_COMPANY,
    payload: { params },
  };
};

export const getFinanceCompanySuccess = (items) => {
  return {
    type: GET_FINANCE_COMPANY_SUCCESS,
    payload: items,
  };
};

export const getManufacturersAction = (params) => {
  return {
    type: GET_MANUFACTURERS,
    payload: { params },
  };
};

export const getManufacturersSuccess = (items) => {
  return {
    type: GET_MANUFACTURERS_SUCCESS,
    payload: items,
  };
};

export const getMaterialsAction = (params) => {
  return {
    type: GET_MATERIALS,
    payload: { params },
  };
};

export const getMaterialsSuccess = (items) => {
  return {
    type: GET_MATERIALS_SUCCESS,
    payload: items,
  };
};

export const getProductCategoriesAction = (params) => {
  return {
    type: GET_PRODUCT_CATEGORIES,
    payload: { params },
  };
};
export const getProductCategoriesSuccess = (items) => {
  return {
    type: GET_PRODUCT_CATEGORIES_SUCCESS,
    payload: items,
  };
};
export const getProductTypesAction = (params) => {
  return {
    type: GET_PRODUCT_TYPES,
    payload: { params },
  };
};
export const getProductTypesSuccess = (items) => {
  return {
    type: GET_PRODUCT_TYPES_SUCCESS,
    payload: items,
  };
};
export const getTaxGroupAction = (params) => {
  return {
    type: GET_TAX_GROUP,
    payload: { params },
  };
};

export const getTaxGroupSuccess = (items) => {
  return {
    type: GET_TAX_GROUP_SUCCESS,
    payload: items,
  };
};

export const getWarehouseAction = (params) => {
  return {
    type: GET_WAREHOUSE,
    payload: { params },
  };
};

export const getWarehouseSuccess = (items) => {
  return {
    type: GET_WAREHOUSE_SUCCESS,
    payload: items,
  };
};

export const getWebCategroriesAction = (params) => {
  return {
    type: GET_WEB_CATEGORIES,
    payload: { params },
  };
};

export const getWebCategroriesSuccess = (items) => {
  return {
    type: GET_WEB_CATEGORIES_SUCCESS,
    payload: items,
  };
};

export const getAddressAction = (params) => {
  return {
    type: GET_ADDRESS,
    payload: { params },
  };
};

export const getAddressSuccess = (items) => {
  return {
    type: GET_ADDRESS_SUCCESS,
    payload: items,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
export const getProductAttributesAction = (params) => {
  return {
    type: GET_PRODUCT_ATTRIBUTES,
    payload: { params },
  };
};
export const getProductAttributesSuccess = (items) => {
  return {
    type: GET_PRODUCT_ATTRIBUTES_SUCCESS,
    payload: items,
  };
};
export const getAssProblemTypesAction = (params) => {
  return {
    type: GET_ASS_PROBLEM_TYPES,
    payload: { params },
  };
};
export const getAssProblemTypesSuccess = (items) => {
  return {
    type: GET_ASS_PROBLEM_TYPES_SUCCESS,
    payload: items,
  };
};
