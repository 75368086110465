import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const MultiselectWithService = ({
  service,
  name,
  value,
  isMulti,
  setValue,
  valueField = "id",
  labelField = "name",
  placeholder,
  isDisabled,
  isClearable = false,
  reloadOptionsKey,
  autoFocus = false,
  defaultMenuIsOpen = false,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(isMulti ? [] : null);
  const animatedComponents = makeAnimated();
  const ref = React.useRef();
  const handleSelect = (newValue) => {
    if (isMulti) {
      setValue(
        name,
        newValue?.map((i) => i.value),
        newValue
      );
      applySelection(
        options,
        newValue.map((i) => i.value)
      );
    } else {
      setValue(name, newValue?.value, newValue);
      applySelection(options, newValue?.value);
    }
  };
  const applySelection = (opts, val) => {
    if (isMulti) {
      if (val) {
        setSelectedOptions(opts.filter((item) => val.find((x) => x == item.value) != null));
      } else {
        setSelectedOptions([]);
      }
    } else {
      setSelectedOptions(opts.find((item) => item.value == val) || null);
    }
  };
  useEffect(() => {
    setLoading(true);
    service({ page: 0 })
      .then((res) => {
        const opts = res.map((i) => ({
          value: i[valueField] || i.id || i.value,
          label: i[labelField] || i.name || i.label,
          option: i,
        }));
        setOptions(opts);
      })
      .finally(() => {
        setLoading(false);
        autoFocus && ref.current?.focus();
      });
  }, [reloadOptionsKey]);
  useEffect(() => {
    applySelection(options, value);
  }, [options, value]);
  //   const selectedOption = options.find((i) => i.value == value);
  return (
    <Select
      isMulti={isMulti}
      onChange={handleSelect}
      components={animatedComponents}
      isClearable={isClearable}
      name={name}
      value={selectedOptions}
      // value={{ value: value, label: options.find((i) => i.value == value)?.label }}
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      closeMenuOnSelect={!isMulti}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isLoading={loading}
      ref={ref}
      defaultMenuIsOpen={defaultMenuIsOpen}
    />
  );
};

export default MultiselectWithService;
