import enums, { EnumValues, getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import CustomSpinner from "components/Common/CustomSpinner";
import DateOutput from "components/Common/DateOutput";
import DateTimeOutput from "components/Common/DateTimeOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { showInfo } from "helpers/utils";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import OrderCard from "pages/Order/OrderCard";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Col, DropdownItem, DropdownMenu, Row } from "reactstrap";
import { downloadService } from "services/DownloadService";
import { getUserListContent } from "store/definitions/services";
import { getDelivery, getSatisfactionNote, updateUsers } from "store/delivery/services";

const DeliveryCard = ({ id }) => {
  const activeUser = useActiveUser();
  const [deliveryState, setDeliveryState] = useState({
    error: null,
    loading: false,
    delivery: null,
  });
  const [editUsers, setEditUsers] = useState(false);
  const [savedDeliveryUsers, setSavedDeliveryUsers] = useState([]);
  const { delivery } = deliveryState;
  const loadDelivery = async (showLoading = true) => {
    setDeliveryState((state) => ({
      ...state,
      loading: showLoading,
    }));
    try {
      const ord = await getDelivery(id);
      setDeliveryState((state) => ({
        ...state,
        delivery: ord,
        loading: false,
      }));
      setSavedDeliveryUsers(ord.users);
    } catch (error) {
      setDeliveryState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadDelivery();
  }, [id]);

  const handleUpdateDeliveryUsers = async () => {
    await updateUsers({
      deliveryIds: [delivery.id],
      userIds: (delivery.users || []).map((u) => u.id),
    });
    showInfo("Delivery users updated successfully");
    loadDelivery(false);
    setEditUsers(false);
  };

  const handleDownloadSatisfactionNote = async () => {
    const data = await getSatisfactionNote(id);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };

  return (
    <Row>
      <Col md={12}>
        {deliveryState.loading && <CustomSpinner />}
        {deliveryState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Delivery could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {deliveryState.delivery && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Delivery Number</h6>
                <Col sm={4}>
                  <h5>
                    {activeUser.hasPrivilege(Privileges.DELIVERY.DETAIL) && (
                      <Link to={`/delivery/${delivery.id}/detail`} title="View detail">
                        {delivery.no}
                      </Link>
                    )}
                    {!activeUser.hasPrivilege(Privileges.DELIVERY.DETAIL) && delivery.no}
                    {activeUser.hasPrivilege(Privileges.DELIVERY.UPDATE) && (
                      <Link to={`/delivery/${delivery.id}`} className="p-2" title="Edit">
                        <i className="fa fa-edit"></i>
                      </Link>
                    )}
                    {activeUser.hasPrivilege(Privileges.DELIVERY.DETAIL) &&
                      [EnumValues.DELIVERY_STATUS.PLANNED, EnumValues.DELIVERY_STATUS.COMPLETED].includes(
                        delivery.deliveryStatus
                      ) && (
                        <ActionMenu toggleClassName="py-0" horizontal>
                          <DropdownMenu>
                            <DropdownItem onClick={handleDownloadSatisfactionNote}>Satisfaction Note PDF</DropdownItem>
                          </DropdownMenu>
                        </ActionMenu>
                      )}
                  </h5>
                </Col>
                <h6 className="col-sm-2">Delivery Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.deliveryStatus, delivery.deliveryStatus)}</h5>
                </Col>
                <h6 className="col-sm-2">Planned Date</h6>
                <Col sm={4}>
                  <DateOutput date={delivery.plannedDate} />
                </Col>
                <h6 className="col-sm-2">Created</h6>
                <Col sm={4}>
                  <b>by</b> {delivery.createUser}
                  <b> at</b> <DateTimeOutput date={delivery.createdDate} />
                </Col>
                <h6 className="col-sm-2">Amount On Delivery</h6>
                <Col sm={4}>
                  <span className={delivery.amountBeforeDelivery > 0 ? "text-danger text-bold" : ""}>
                    <MoneyOutput value={delivery.amountBeforeDelivery} />
                  </span>
                </Col>
              </Row>
              <Row>
                <h6 className="col-sm-2">Delivery Users</h6>
                {!editUsers && (
                  <Col sm={10}>
                    {(delivery.users || [{ fullName: "-" }]).map((u) => u.fullName).join(", ")}
                    {activeUser.hasPrivilege(Privileges.DELIVERY.UPDATE) &&
                      (delivery.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNING ||
                        delivery.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNED) && (
                        <i
                          className="fa fa-pen px-1"
                          title="Edit users"
                          role="button"
                          onClick={() => setEditUsers(true)}
                        ></i>
                      )}
                  </Col>
                )}
                {editUsers && (
                  <Col sm={10}>
                    <MultiselectWithService
                      service={getUserListContent}
                      name="Users"
                      isMulti={true}
                      setValue={(name, value, values) => {
                        setDeliveryState((prev) => ({
                          ...prev,
                          delivery: {
                            ...delivery,
                            users: values?.map((v) => v.option) || [],
                          },
                        }));
                      }}
                      labelField="fullName"
                      valueField="id"
                      value={(delivery.users || []).map((u) => u.id)}
                    />
                    <div className="pt-2 d-flex gap-2">
                      <Button onClick={handleUpdateDeliveryUsers}>Save</Button>
                      <Button
                        outline
                        onClick={() => {
                          setDeliveryState((prev) => ({
                            ...prev,
                            delivery: {
                              ...delivery,
                              users: savedDeliveryUsers,
                            },
                          }));
                          setEditUsers(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
              {delivery.order && (
                <Row className="py-1">
                  <Col sm={12}>
                    <h5>Order Details</h5>
                    <hr />
                  </Col>
                  <Col sm={12}>
                    <OrderCard id={delivery.order?.id} />
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default DeliveryCard;
