import { post, get, del, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

//ass
export const getTicket = (id) => get(`/ssh/${id}`);
export const editTicket = (id, data) => put(`/ssh/${id}`, data);
export const createTicket = (data) => post(`/ssh`, data);
export const deleteTicket = (id) => del(`/ssh/${id}`);
export const getTicketList = () => get(`/ssh`);
export const getTickets = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/ssh/search?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );
export const getTicketProducts = (id) => get(`/ssh/${id}/products`);
export const getAvailableProducts = (customerId) => get(`/ssh/availableProducts?orderId=${customerId}`);
export const getTicketPdf = (id) =>
  get(`/ssh/${id}/pdf`, {
    responseType: "blob",
  });
//ass products
export const addProductToTicket = (sshId, data) => put(`/ssh/${sshId}/product`, data);
export const addOrderedProductToTicket = (sshId, data) => put(`/ssh/${sshId}/orderedProducts`, data);
export const deleteTicketProduct = (sshId, data) => del(`/ssh/${sshId}/orderedProducts`, data);

export const addNoteToTicket = (sshId, data) => put(`/ssh/${sshId}/note`, data);
export const getTicketNotes = (sshId) => get(`/ssh/${sshId}/note`);
export const deleteTicketNote = (sshId, noteId) => del(`/ssh/${sshId}/note/${noteId}`);
