import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import TicketDetailCard from "./TicketDetailCard";

const TicketDetailPage = () => {
  const { id } = useParams();

  return (
    <>
      <MetaTags>
        <title>Novia - Ticket Detail</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        <h4 className="text-primary">
          <Breadcrumb listClassName="p-0">
            <BreadcrumbItem>
              <Link to="/tickets">Tickets</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              <b>Ticket Detail</b>
            </BreadcrumbItem>
          </Breadcrumb>
        </h4>
        <TicketDetailCard id={id} />
      </div>
    </>
  );
};

TicketDetailPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(TicketDetailPage);
