import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Col, Label, ModalFooter, Input, Spinner } from "reactstrap";
import ConfirmButton from "components/Common/ConfirmButton";
import paymentUtils from "./payment_utils";
import { addNoteToPayment, deletePaymentNote, getPaymentNotes } from "store/payment/services";
import DateTimeOutput from "components/Common/DateTimeOutput";

const initialNoteState = { open: false, error: null, loading: false, note: {} };
const PaymentNotes = ({ paymentId, type }) => {
  const [noteState, setNoteState] = useState(initialNoteState);
  const [notes, setNotes] = useState([]);
  const openNoteAddPopup = () => {
    setNoteState((prev) => ({ ...prev, note: {}, open: true }));
  };
  const handleAddNote = async (note) => {
    return addNoteToPayment(paymentId, note).then((data) => {
      loadNotes(paymentId);
      setNoteState((prev) => ({ ...prev, note: {}, open: false }));
    });
  };
  const handleCloseNote = () => {
    setNoteState((prev) => ({ ...prev, open: false }));
  };
  const loadNotes = async (paymentId) => {
    const notes = await getPaymentNotes(paymentId);
    setNotes(notes);
  };
  const handleDeleteNote = async ({ id }) => {
    await deletePaymentNote(paymentId, id);
    loadNotes(paymentId);
  };
  useEffect(() => {
    loadNotes(paymentId);
  }, [paymentId]);

  return (
    <>
      <h4 className="text-secondary">
        <b>Notes</b>
        {type != "view" && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" onClick={openNoteAddPopup}>
              <i className="fa fa-plus"></i>Add Note
            </Button>
          </span>
        )}
      </h4>
      {notes.length > 0 && (
        <ul>
          {notes.map((n) => (
            <li key={n.id}>
              <div className="py-2">
                <b>{n.createUser}</b> (<DateTimeOutput date={n.createdDate} />) <br /> {n.notes}
                &nbsp;&nbsp;&nbsp;
                {type != "view" && (
                  <ConfirmButton
                    size="sm"
                    color="danger"
                    onConfirm={() => handleDeleteNote(n)}
                    title="Are you sure you want to delete the note?"
                  >
                    <i className="fa fa-trash"></i>
                  </ConfirmButton>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
      {noteState.open && <PaymentNoteModal onClose={handleCloseNote} onSubmit={handleAddNote} note={noteState.note} />}
    </>
  );
};

const PaymentNoteModal = ({ note, onClose, onSubmit }) => {
  const [noteForm, setNoteForm] = useState({ ...note });
  const [saving, setSaving] = useState(false);
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setNoteForm((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit(paymentUtils.mapToNoteAddModel(noteForm));
    } finally {
      setSaving(false);
    }
  };
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!note?.id ? "Add new note" : "Edit note"}</ModalHeader>
      <ModalBody>
        <div className="row mb-4">
          <Label htmlFor="notes" className="col-sm-3 col-form-label">
            Notes
          </Label>
          <Col sm={9}>
            <Input
              type="textarea"
              value={noteForm.notes}
              name="notes"
              placeholder="notes.."
              className="form-control"
              onChange={handleChange}
            />
          </Col>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleSubmit} disabled={saving}>
          {!note.id && "Add to payment "}
          {note.id && "Update "}
          {saving && <Spinner size="sm" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PaymentNotes;
