import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { validations as builtinValidations } from "./validations";
import "./index.css";

export default class ValidationForm extends Component {
  validations = {};

  values = {};

  state = {
    touched: {},
    errors: {},
  };

  handleOnChange(name, value, callback, ...args) {
    const waitValidate = () => {
      setTimeout(() => {
        this.validate(name);
      }, 1);
    };
    if (callback) {
      const result = callback(...args);
      if (result instanceof Promise) {
        result.finally(waitValidate);
      } else {
        waitValidate();
      }
    } else {
      waitValidate();
    }
  }

  handleValidate(callback, ...args) {
    if (this.validate() && callback) {
      callback(...args);
    }
  }

  handleResetValidation(callback, ...args) {
    this.setState(
      {
        touched: {},
        errors: {},
      },
      () => {
        if (callback) {
          callback(...args);
        }
      }
    );
  }

  handleOnSubmit = (callback, ...args) => {
    if (this.validate() && callback) {
      callback(...args);
    }
  };

  hasError = (name) => {
    const { touched, errors } = this.state;
    if (name) {
      if (touched[name] && errors[name]) {
        return true;
      }
      return false;
    }
    const fieldNames = Object.keys(this.validations);
    for (let index = 0; index < fieldNames.length; index += 1) {
      const key = fieldNames[index];
      if (touched[key] && errors[key]) {
        return true;
      }
    }
    return false;
  };

  validate = (nameOrNames) => {
    const { touched, errors } = this.state;
    const validationMap = new Map();
    if (nameOrNames) {
      let names = [nameOrNames];
      if (Array.isArray(nameOrNames)) {
        names = nameOrNames;
      }
      names.forEach((name) => {
        const validations = this.validations[name];
        if (validations) {
          validationMap.set(name, validations);
        }
        errors[name] = "";
      });
    } else {
      Object.keys(errors).forEach((key) => {
        errors[key] = "";
      });
      Object.keys(this.validations).forEach((key) => {
        const validations = this.validations[key];
        if (validations) {
          validationMap.set(key, validations);
        }
      });
    }
    let valid = true;
    if (validationMap.size > 0) {
      validationMap.forEach((validations, nameKey) => {
        const value = this.values[nameKey];
        touched[nameKey] = true;

        for (let index = 0; index < validations.length; index += 1) {
          const validation = validations[index];
          let validatorName = validation.name;
          if (typeof validation === "string") {
            validatorName = validation;
          }
          let validator = null;
          let params = null;
          if (validatorName) {
            const temp = validatorName.split("|");
            validator = builtinValidations[temp[0]];
            params = temp.slice(1);
          } else if (typeof validation === "function") {
            validator = validation;
          } else if (typeof validation.test === "function") {
            validator = validation.test;
          }
          const err = params ? validator(value, ...params) : validator(value);
          if (err) {
            valid = false;
            errors[nameKey] = err;
            break;
          } else {
            errors[nameKey] = "";
          }
        }
      });
      this.setState({
        touched,
        errors,
      });
    }
    return valid;
  };

  renderWrappedChildren(children) {
    return React.Children.map(children, (item) => {
      let child = item;
      if (!child || !child.props) {
        return child;
      }

      if (child.props.children) {
        child = React.cloneElement(child, {
          children: this.renderWrappedChildren(child.props.children),
        });
      }
      if (child.props.name) {
        const { name } = child.props;
        if (child.props.validations && (!child.props.disabled || `${child.props.forcevalidation}` === "true")) {
          const { value, validations, validationeventname } = child.props;
          this.values[name] = value;
          this.validations[name] = validations;
          const hasError = this.state.touched[name] && this.state.errors[name];
          const showError = hasError && `${child.props.hideerror}` !== "true";
          const eventName = validationeventname ? validationeventname : "onChange";
          const validationEventFunc = child.props[eventName];
          const props = {
            className: cn(child.props.className, `${hasError ? "error invalid-field" : "valid-field"}`),
            validations: null,
            forcevalidation: null,
            hideerror: null,
            title: hasError && !showError ? this.state.errors[name] : child.title,
          };
          props[eventName] = (...args) => this.handleOnChange(name, value, validationEventFunc, ...args);
          const clone = React.cloneElement(child, props);
          if (hasError) {
            return (
              <Fragment>
                <span className="field error invalid-content">
                  {clone}
                  {showError && (
                    <div className="invalid-message-wrapper">
                      <div className="invalid-message">{this.state.errors[name]}</div>
                    </div>
                  )}
                </span>
              </Fragment>
            );
          }
          return (
            <Fragment>
              <span className="field valid-content">{clone}</span>
            </Fragment>
          );
        }
      }
      if (`${child.props.validate}` === "true") {
        const { onClick } = child.props;
        const clone = React.cloneElement(child, {
          onClick: (...args) => this.handleValidate(onClick, ...args),
          validate: null,
        });
        return clone;
      }
      if (`${child.props.resetvalidation}` === "true") {
        const { onClick } = child.props;
        const clone = React.cloneElement(child, {
          onClick: (...args) => this.handleResetValidation(onClick, ...args),
          resetvalidation: null,
        });
        return clone;
      }
      return child;
    });
  }

  render() {
    this.validations = {};
    this.values = {};
    const { children, onSubmit, ...others } = this.props;
    const childs = this.renderWrappedChildren(children);
    return (
      <form onSubmit={(...args) => this.handleOnSubmit(onSubmit, ...args)} autoComplete="off" {...others}>
        {childs}
      </form>
    );
  }
}
ValidationForm.propTypes = {
  children: PropTypes.any,
  onSubmit: PropTypes.func,
};
