import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteModal from "./DeleteModal";
import "./ImagesModal.scss";
import {
  Carousel,
  CarouselCaption,
  CarouselIndicators,
  CarouselItem,
  CarouselControl,
  Col,
  Modal,
  ModalBody,
  Row,
  Button,
  ModalHeader,
  ModalFooter,
  Spinner,
  List,
  Badge,
} from "reactstrap";
import { showInfo } from "helpers/utils";
import enums, { getEnumLabel } from "common/enums";

const testImages = [
  {
    id: 1,
    awsUrl: "https://novia-imgs.s3.eu-central-1.amazonaws.com/products/f60a95083f0b437ca549601f1af68d8b.jpg",
  },
  {
    id: 2,
    awsUrl: "https://picsum.photos/id/456/1200/600",
  },
  {
    id: 3,
    awsUrl: "https://picsum.photos/id/678/1200/600",
  },
];

const anonymousFunc = () => Promise.resolve();

const ImagesModal = ({
  editable = true,
  onClose = anonymousFunc,
  onUpload = anonymousFunc,
  onDelete = anonymousFunc,
  onList = anonymousFunc,
  title = "Images",
  keyField = "id",
  srcField = "awsUrl",
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [deleteConfirmState, setDeleteConfirmState] = useState({
    show: false,
    key: null,
  });
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageDeleting, setImageDeleting] = useState(false);

  const nextImage = () => {
    setActiveIndex((prev) => (prev + 1) % images.length);
  };
  const prevImage = () => {
    setActiveIndex((prev) => (prev - 1 < 0 ? images.length - 1 : prev - 1));
  };
  const handCancelUpload = async () => {
    const notUploadeds = files.filter(({ uploaded, uploading }) => !uploaded && !uploading);
    if (notUploadeds.length > 0) {
      notUploadeds.forEach((item) => (item.cancelled = true));
      setFiles((prev) => [...prev]);
    }
  };
  const loadImages = async () => {
    setImageLoading(true);
    try {
      const images = await onList();
      // const images = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve(testImages);
      //   }, 2000);
      // });
      if (activeIndex > images.length - 1) {
        setActiveIndex(images.length > 0 ? images.length - 1 : 0);
      }
      const newImages = images.map((img) => ({
        src: img[srcField],
        key: img[keyField],
        isDefault: img.isDefault,
        documentType: img.documentType,
      }));
      setImages(newImages);
    } finally {
      setImageLoading(false);
    }
  };
  useEffect(() => {
    loadImages();
  }, []);

  const handleDeleteImage = async () => {
    const { key } = deleteConfirmState;
    setDeleteConfirmState({
      show: false,
      key: null,
    });
    setImageDeleting(true);
    try {
      await onDelete(key);
      showInfo(`The image deleted successfully`);
      loadImages();
    } finally {
      setImageDeleting(false);
    }
  };

  const handleUploadFiles = async () => {
    let successCount = 0;
    for (let index = 0; index < files.length; index++) {
      const item = files[index];
      if (item.uploaded || item.cancelled) {
        continue;
      }
      try {
        item.uploading = true;
        item.error = null;
        setFiles((prev) => [...prev]);
        await onUpload({ file: item.file, isDefault: item.isDefault, documentType: item.documentType });
        item.uploaded = true;
        item.uploading = false;
        setFiles((prev) => [...prev]);
        successCount += 1;
      } catch (error) {
        item.uploading = false;
        item.error = error;
        setFiles((prev) => [...prev]);
      }
    }
    if (successCount > 0) {
      showInfo(`${successCount} image${successCount > 1 ? "s" : ""} uploaded successfully`);
      loadImages();
    }
  };

  const handleSetDefaultImage = (file) => {
    setFiles((prev) => prev.map((item) => ({ ...item, isDefault: file === item })));
  };
  const handleSetDocumentType = (file, dt) => {
    setFiles((prev) => prev.map((item) => ({ ...item, documentType: file === item ? dt : null })));
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ accept: "image/*" });
  const handleImageLoaded = () => {
    setFiles((prev) => [...prev]);
  };
  useEffect(() => {
    setFiles(
      acceptedFiles.map((file) => {
        const fr = new FileReader();
        const data = {
          file,
          uploading: false,
          cancelled: false,
          uploaded: false,
          error: null,
          previewUrl: null,
          isDefault: false,
        };
        fr.onloadend = () => {
          data.previewUrl = fr.result;
          handleImageLoaded();
        };
        fr.readAsDataURL(file);
        return data;
      })
    );
  }, [acceptedFiles]);

  const formUploading = files.find((item) => item.uploading) != null;
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col>
            <div className="text-center">
              {imageLoading && <Spinner />}
              {!imageLoading && images.length == 0 && <h2>Image not found</h2>}
              {!imageLoading && images.length > 0 && (
                <Carousel slide={false} activeIndex={activeIndex} next={anonymousFunc} previous={anonymousFunc}>
                  <CarouselIndicators
                    activeIndex={activeIndex}
                    items={images.map((item) => ({ key: item.key, src: item.src }))}
                    onClickHandler={setActiveIndex}
                  />
                  {images.map((img) => (
                    <CarouselItem key={img.key}>
                      {(img.isDefault || img.documentType) && (
                        <div className="flex justify-content-center">
                          <h4>
                            {img.isDefault && (
                              <Badge color="success" className="m-2 p-2">
                                Showcase Image
                              </Badge>
                            )}
                            {img.documentType && (
                              <Badge color="warning" className="m-2 p-2">
                                {getEnumLabel(enums.documentTypes, img.documentType)}
                              </Badge>
                            )}
                          </h4>
                        </div>
                      )}
                      <img src={img.src} style={{ maxWidth: 800, maxHeight: 600 }} />
                      <CarouselCaption
                        captionText={
                          <>
                            {editable && (
                              <Button
                                size="sm"
                                color="danger"
                                disabled={imageDeleting}
                                onClick={() =>
                                  setDeleteConfirmState({
                                    show: true,
                                    key: img.key,
                                  })
                                }
                              >
                                {!imageDeleting && "Delete Image"}
                                {imageDeleting && (
                                  <>
                                    Image Deleting.. <Spinner size="sm" />
                                  </>
                                )}
                              </Button>
                            )}
                          </>
                        }
                      />
                    </CarouselItem>
                  ))}
                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={prevImage} />
                  <CarouselControl direction="next" directionText="Next" onClickHandler={nextImage} />
                </Carousel>
              )}
            </div>
          </Col>
        </Row>
        {editable && (
          <Row className="mt-3">
            <Col>
              <div {...getRootProps({ className: "imageDropzone" })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              {files?.length > 0 && (
                <Row className="mt-3">
                  <Col md={12}>
                    <br />
                    <List>
                      {files.map((item) => {
                        const { file, uploading, uploaded, cancelled, error, previewUrl, isDefault, documentType } =
                          item;
                        return (
                          <li key={file.path} style={{ textDecoration: cancelled ? "line-through" : "" }}>
                            <Button
                              size="sm"
                              outline
                              className="m-2"
                              color={isDefault ? "success" : "danger"}
                              onClick={() => handleSetDefaultImage(item)}
                              disabled={uploaded || uploading}
                            >
                              Set as Showcase Image
                            </Button>
                            <Button
                              size="sm"
                              outline
                              className="m-2"
                              color={documentType ? "success" : "danger"}
                              onClick={() => handleSetDocumentType(item, "PRODUCT_DRAWING")}
                              disabled={uploaded || uploading}
                            >
                              Set as Drawing Image
                            </Button>
                            {previewUrl && <img src={previewUrl} width="70" height="70" style={{ padding: 5 }} />}
                            {file.path} &nbsp;
                            {uploaded && <i className="fas fa-check-circle text-success"></i>}
                            {uploading && <Spinner size="sm" />}
                            {error && (
                              <div>
                                <i className="fas fa-exclamation-triangle text-danger"> {error?.message}</i>
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </List>
                    <Button color="success" onClick={handleUploadFiles} disabled={formUploading}>
                      {!formUploading && "Upload"}
                      {formUploading && (
                        <>
                          Uploading.. <Spinner size="sm" />
                        </>
                      )}
                    </Button>
                    &nbsp;
                    <Button color="danger" onClick={handCancelUpload}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        )}
        <DeleteModal
          show={deleteConfirmState.show}
          onCloseClick={() =>
            setDeleteConfirmState({
              show: false,
              id: null,
            })
          }
          onDeleteClick={handleDeleteImage}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};
export default ImagesModal;
