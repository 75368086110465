import { atom } from "recoil";
import productionUtils from "./production_utils";

export const productionsAppliedFilterState = atom({
  key: "Productions.appliedFilter",
  default: productionUtils.initialFilterValues(),
});
export const productionsAppliedSortState = atom({
  key: "Productions.appliedSort",
  default: { sortField: "productionNo", sortOrder: "desc" },
});
