import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Button,
  Spinner,
  Modal,
  Input,
  Label,
  Form,
  FormFeedback,
  Alert,
  UncontrolledTooltip,
  CardFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Yup from "yup";
import { useFormik } from "formik";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "../../../assets/scss/datatables.scss";
import {
  changePassword,
  createUser,
  deleteUser,
  editUser,
  getPrivileges,
  getRoles,
  getUserList,
} from "store/definitions/services";
import date from "lib/date";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { getStores } from "store/store/services";
import { useClientPaging } from "hooks/usePaging";
import ValidationForm from "components/ValidationForm";
import { toast } from "react-toastify";
import { atom, useRecoilState } from "recoil";

const Users = () => {
  const activeUser = useActiveUser();
  const [userList, setUserList] = useState({
    loading: false,
    content: [],
    page: {},
  });
  const [passwordChangeState, setPasswordChangeState] = useState({
    open: false,
    user: null,
  });
  const confirmDeleteInitialValues = {
    itemId: "",
    open: false,
    title: "",
    description: "",
    confirmed: false,
    success: false,
    loading: false,
  };
  const [confirmDelete, setConfirmDelete] = useState(confirmDeleteInitialValues);
  const [pageOptions] = useClientPaging("Users", userList.content.length);

  const handlePasswordChange = async ({ password }) => {
    await changePassword({
      email: passwordChangeState.user.email,
      password,
    });
    toast.info("Password changed successfully");
    setPasswordChangeState({ open: false });
  };

  const handleGetUserList = () => {
    setUserList({ loading: true, content: [], page: {} });
    getUserList({ page: 0 })
      .then((res) => {
        setUserList({ loading: false, content: res.content, page: res.page });
      })
      .catch((e) => {
        setUserList({ loading: false, content: [], page: {} });
      });
  };

  const handleDeleteConfirmation = (id, name) => {
    setConfirmDelete({
      itemId: id,
      open: true,
      title: `Are you sure you want to delete this user ${name}`,
      description: "",
      confirmed: false,
      success: false,
      loading: false,
    });
  };

  const deleteItem = (id) => {
    // call
    setConfirmDelete({
      itemId: id,
      open: false,
      title: "",
      description: "",
      confirmed: true,
      success: false,
      loading: true,
    });
    deleteUser(id).then((res) => {
      setConfirmDelete({
        itemId: id,
        open: false,
        title: "Deleted.",
        description: "",
        confirmed: true,
        success: true,
        loading: false,
      });
    });
  };

  useEffect(() => {
    handleGetUserList();
  }, []);

  const columns = [
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell) => {
        return cell === "y" ? (
          <i className="fas fa-check-circle text-success"></i>
        ) : (
          <i className="fas fa-times-circle text-danger"></i>
        );
      },
    },
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "fullName",
      text: "Full Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "mobilePhone",
      text: "Mobile Phone",
      sort: true,
    },
    {
      dataField: "discountRate",
      text: "Discount Rate",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
      formatter: (cell) => date.sliceString(cell),
    },
    // {
    //   dataField: "lastAccessDate",
    //   text: "Last Access Date",
    //   sort: true,
    // },
    {
      dataField: "roles",
      text: "Roles",
      formatter: (cellContent, row, index) => {
        if (row.roles == null || row.roles.length == 0) {
          return null;
        }
        if (row.roles.length == 1) {
          return row.roles[0].name;
        }
        const targetId = `targetRole${index}`;
        return (
          <div>
            <span
              href="#"
              id={targetId}
              style={{
                color: "blue",
                textDecoration: "underline",
              }}
            >
              {row.roles[0].name} ...
            </span>
            <UncontrolledTooltip placement="top" target={targetId}>
              {row.roles.map((item) => item.name).join(", ")}
            </UncontrolledTooltip>
          </div>
        );
      },
    },
    {
      dataField: "privileges",
      text: "Privileges",
      formatter: (cellContent, row, index) => {
        if (row.privileges == null || row.privileges.length == 0) {
          return null;
        }
        if (row.privileges.length == 1) {
          return row.privileges[0].name;
        }
        const targetId = `targetPrivilege${index}`;
        return (
          <div>
            <span
              href="#"
              id={targetId}
              style={{
                color: "blue",
                textDecoration: "underline",
              }}
            >
              {row.privileges[0].name} ...
            </span>
            <UncontrolledTooltip placement="top" target={targetId}>
              {row.privileges.map((item) => item.name).join(", ")}
            </UncontrolledTooltip>
          </div>
        );
      },
    },
    {
      dataField: "stores",
      text: "Stores",
      formatter: (cellContent, row, index) => {
        if (row.stores == null || row.stores.length == 0) {
          return null;
        }
        if (row.stores.length == 1) {
          return row.stores[0].name;
        }
        const targetId = `targetStores${index}`;
        return (
          <div>
            <span
              href="#"
              id={targetId}
              style={{
                color: "blue",
                textDecoration: "underline",
              }}
            >
              {row.stores[0].name} ...
            </span>
            <UncontrolledTooltip placement="top" target={targetId}>
              {row.stores.map((item) => item.name).join(", ")}
            </UncontrolledTooltip>
          </div>
        );
      },
    },
    {
      dataField: "changePassword",
      text: "Change Password",
      hidden: !activeUser.isAdmin(),
      formatter: (cellContent, row) => {
        return (
          <Button
            size="sm"
            onClick={() =>
              setPasswordChangeState({
                user: row,
                open: true,
              })
            }
          >
            <i className="fa fa-edit"></i>
          </Button>
        );
      },
    },
    {
      dataField: "edit",
      text: "Edit",
      hidden: !activeUser.hasPrivilege(Privileges.USER.UPDATE),
      formatter: (cellContent, row) => {
        return <CreateUpdateModal isEdit={true} details={row} updateapi={editUser} callback={handleGetUserList} />;
      },
    },
    {
      dataField: "remove",
      text: "Delete",
      hidden: !activeUser.hasPrivilege(Privileges.USER.DELETE),
      formatter: (cellContent, row) => {
        return (
          <Button color="danger" size="sm" onClick={() => handleDeleteConfirmation(row.id, row.fullName)}>
            <i className="fa fa-trash"></i>
          </Button>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <div className="d-flex justify-content-between">
                      <h4>Users List</h4>
                      {activeUser.hasPrivilege(Privileges.USER.CREATE) && (
                        <div>
                          <CreateUpdateModal isEdit={false} callback={handleGetUserList} createapi={createUser} />
                        </div>
                      )}
                    </div>
                  </CardTitle>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={userList.content}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider keyField="id" columns={columns} data={userList.content} search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <CustomSearchBar
                                      {...toolkitProps.searchProps}
                                      pageName={"Users List"}
                                      tableContent={userList.content}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  hover
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  // selectRow={selectRow}
                                  classes={`table align-middle table-nowrap${userList.loading ? " loading" : ""}`}
                                  noDataIndication={() => (userList.loading ? <div>&nbsp;</div> : "No data found")}
                                  headerWrapperClasses={"thead-light"}
                                  wrapperClasses="table-responsive"
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </Col>
                            </Row>

                            {/* <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row> */}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {confirmDelete.open ? (
            <SweetAlert
              title={confirmDelete.title}
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                deleteItem(confirmDelete.itemId);
              }}
              onCancel={() => setConfirmDelete(confirmDeleteInitialValues)}
            >
              {!confirmDelete.confirmed && "You won't be able to revert this!"}
            </SweetAlert>
          ) : null}
          {confirmDelete.confirmed ? (
            <SweetAlert
              disabled={confirmDelete.loading}
              success
              title={"Successfully deleted."}
              onConfirm={() => {
                setConfirmDelete(confirmDeleteInitialValues);
                handleGetUserList();
              }}
            >
              {confirmDelete.loading && <Spinner className="ms-2" color="primary" />}
            </SweetAlert>
          ) : null}
        </Container>

        {passwordChangeState.open && (
          <PasswordChangeModal
            onClose={() => setPasswordChangeState({ open: false })}
            onSubmit={handlePasswordChange}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const CreateUpdateModal = ({
  details,
  isEdit,
  callback,

  createapi,
  updateapi,
  formValues = {
    values: [{ name: "", label: "", type: "", size: "" }],
    validationSchema: {
      status: Yup.string().required("Please enter status"),
      fullName: Yup.string().required("Please enter your name"),
      email: Yup.string().required("Please enter your email"),
      mobilePhone: Yup.number().required("Please enter your phone"),
    },
  },
}) => {
  const [fullName, setFullName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen((prev) => !prev);
  const [formData, setFormData] = useState({
    loading: false,
    success: null,
    error: null,
  });
  const initialValues = {
    status: isEdit ? details.status : "y",
    fullName: isEdit ? details.fullName : "",
    email: isEdit ? details.email : "",
    mobilePhone: isEdit ? details.mobilePhone : "",
    discountRate: isEdit ? details.discountRate : "",
    roles: isEdit ? details.roles.map((item) => item.id) : [],
    privileges: isEdit ? details.privileges.map((item) => item.id) : [],
    stores: isEdit ? details.stores?.map((item) => item.id) : [],

    ...(isEdit ? null : { password: "" }),
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object(formValues.validationSchema),
    onSubmit: (values) => {
      console.log("onSubmit", values);
      setFormData({ loading: true, success: null, error: null });
      if (isEdit) {
        // console.log(validation.values, "val");
        // console.log(validation.values, "----");

        updateapi(details.id, values) // service
          .then((res) => {
            setFormData({ loading: false, success: true, error: null });
            toggleModal();
            callback && callback();
          })
          .catch((err) => {
            console.log(err, "errorr");
            setFormData({
              loading: false,
              success: false,
              error: err.response.data.message,
            });
          });
      } else {
        createapi(values) // service
          .then((res) => {
            setFormData({ loading: false, success: true, error: null });
            validation.resetForm();
            toggleModal();
            callback && callback();
          })
          .catch((err) => {
            setFormData({
              loading: false,
              success: false,
              error: err.response.data.message,
            });
          });
      }
    },
  });
  const handleSubmit = () => {
    validation.handleSubmit();
  };

  return (
    <Fragment>
      <Button color={isEdit ? "warning" : "primary"} size={isEdit ? "sm" : "md"} onClick={toggleModal}>
        {isEdit ? (
          <i className="fa fa-edit"></i>
        ) : (
          <Fragment>
            <i className="fa fa-plus"></i> New User
          </Fragment>
        )}
      </Button>
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {isEdit ? "Edit User" : "Create New User"}
          </h5>
          <Button onClick={toggleModal} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="p-2">
            <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              {formData.error ? <Alert color="danger">{formData.error}</Alert> : null}

              <Row>
                <Col sm={9}>
                  <div className="mb-3">
                    <Label className="form-label">Full Name</Label>
                    <Input
                      name="fullName"
                      className="form-control"
                      placeholder="Enter Full Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.fullName || ""}
                      invalid={validation.touched.fullName && validation.errors.fullName ? true : false}
                    />
                    {validation.touched.fullName && validation.errors.fullName ? (
                      <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <div className="form-check form-switch form-switch-md mb-9">
                      <Input
                        name="status"
                        className="form-control form-check-input"
                        type="checkbox"
                        defaultChecked={validation.values.status == "y"}
                        onChange={(e) => validation.setFieldValue("status", e.target.checked ? "y" : "n")}
                        value={validation.values.status || ""}
                        invalid={validation.touched.status && validation.errors.status ? true : false}
                      />
                    </div>
                    {validation.touched.status && validation.errors.status ? (
                      <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={isEdit ? 12 : 6}>
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={validation.touched.email && validation.errors.email ? true : false}
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
                {!isEdit && (
                  <Col sm={6}>
                    <div className="mb-3">
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Set password"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={validation.touched.password && validation.errors.password ? true : false}
                      />
                      {validation.touched.password && validation.errors.password ? (
                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="mb-3">
                    <Label className="form-label">Mobile Phone</Label>
                    <Input
                      name="mobilePhone"
                      className="form-control"
                      placeholder="Enter Mobile Phone"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.mobilePhone || ""}
                      invalid={validation.touched.mobilePhone && validation.errors.mobilePhone ? true : false}
                    />
                    {validation.touched.mobilePhone && validation.errors.mobilePhone ? (
                      <FormFeedback type="invalid">{validation.errors.mobilePhone}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="mb-3">
                    <Label className="form-label">Discount Rate</Label>
                    <Input
                      name="discountRate"
                      className="form-control"
                      placeholder="Enter Discount Rate"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.discountRate || ""}
                      invalid={validation.touched.discountRate && validation.errors.discountRate ? true : false}
                    />
                    {validation.touched.discountRate && validation.errors.discountRate ? (
                      <FormFeedback type="invalid">{validation.errors.discountRate}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="mb-3">
                    <Label className="col-sm-3 col-form-label" htmlFor="roles">
                      Roles
                    </Label>
                    <MultiselectWithService
                      service={getRoles}
                      name="roles"
                      value={validation.values.roles}
                      isMulti={true}
                      setValue={validation.setFieldValue}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="mb-3">
                    <Label className="col-sm-3 col-form-label" htmlFor="privileges">
                      Privileges
                    </Label>
                    <MultiselectWithService
                      service={getPrivileges}
                      name="privileges"
                      value={validation.values.privileges}
                      isMulti={true}
                      setValue={validation.setFieldValue}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="mb-3">
                    <Label className="col-sm-3 col-form-label" htmlFor="stores">
                      Stores
                    </Label>
                    <MultiselectWithService
                      service={getStores}
                      name="stores"
                      value={validation.values.stores}
                      isMulti={true}
                      setValue={validation.setFieldValue}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={toggleModal} color="secondary" data-dismiss="modal">
            Close
          </Button>
          <Button onClick={handleSubmit} color="success" disabled={formData.loading}>
            {formData.loading ? <Spinner color="white" size="sm" /> : "Save"}
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

function PasswordChangeModal({ onSubmit, onClose }) {
  const [password, setPassword] = useState("");
  const [saving, setSaving] = useState(false);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit({ password });
    } finally {
      setSaving(false);
    }
  };
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Change user password</ModalHeader>
      <ModalBody>
        <ValidationForm>
          <Card className="shadow-lg">
            <CardBody>
              <div className="row mb-4">
                <Label htmlFor="notes" className="col-sm-3 col-form-label">
                  New passwod
                </Label>
                <Col sm={9}>
                  <Input
                    type="password"
                    value={password || ""}
                    name="password"
                    placeholder="password.."
                    className="form-control"
                    validations={["required", "minLength|6"]}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Col>
              </div>
            </CardBody>
            <CardFooter>
              <Button color="success" onClick={handleSubmit} disabled={saving} validate>
                Update password
                {saving && <Spinner size="sm"></Spinner>}
              </Button>
            </CardFooter>
          </Card>
        </ValidationForm>
      </ModalBody>
    </Modal>
  );
}

const searchInputState = atom({
  key: "UserList.searchText",
  default: {},
});

const CustomSearchBar = ({ onSearch, pageName, tableContent }) => {
  const [searchText, setSearchText] = useRecoilState(searchInputState);
  useEffect(() => {
    if (searchText[pageName]) {
      search(searchText[pageName]);
    }
  }, [tableContent]);

  const handleChange = (e) => {
    search(e.target.value);
  };
  const search = (txt) => {
    setSearchText((prev) => ({ ...prev, [pageName]: txt }));
    onSearch(txt);
  };
  return (
    <>
      <input
        className="form-control"
        onChange={handleChange}
        type="text"
        placeholder="Search"
        value={searchText[pageName] || ""}
      />
      <i className="bx bx-search-alt search-icon" />
    </>
  );
};

export default withRouter(Users);
