import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import Transfer from "./Transfer";

const TransferPage = (props) => {
  const { id } = useParams();
  return (
    <>
      <MetaTags>
        <title>Novia - Transfer</title>
      </MetaTags>
      <Transfer id={id} />
    </>
  );
};

TransferPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(TransferPage);
