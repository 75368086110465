import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";
import React from "react";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import {
  createFinanceCompanyRate,
  deleteFinanceCompanyRate,
  getFinanceCompanyRates,
  updateFinanceCompanyRate,
} from "store/definitions/services";
import * as Yup from "yup";

export const financeCompanyRateFormValues = [
  { name: "month", label: "Month", size: 12, type: "number" },
  { name: "rate", label: "Rate", size: 12, type: "number" },
];

export const financeCompanyRateValidationSchema = {
  month: Yup.string().required("Please enter month"),
  rate: Yup.string().required("Please enter rate"),
};

const FinanceCompanyRate = (props) => {
  const name = props.location.state;
  const columns = [
    {
      dataField: "name",
      text: "Finance Company",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{name} </span>;
      },
    },
    {
      dataField: "month",
      text: "Month",
      sort: true,
    },
    {
      dataField: "rate",
      text: "rate",
      sort: true,
    },
  ];
  const { id: financeCompanyId } = useParams();

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Finance Company Rates</title>
        </MetaTags>
        <Link to="/finance-company">
          <i className="fa fa-chevron-left p-2"></i>
          <span className="pl-4">Back</span>
        </Link>
        <DefinitionTable
          services={{
            getapi: (data) => {
              return getFinanceCompanyRates(financeCompanyId, data);
            },
            deleteapi: (rateId) => {
              return deleteFinanceCompanyRate(financeCompanyId, rateId);
            },
            createapi: (data) => {
              return createFinanceCompanyRate(financeCompanyId, data);
            },
            updateapi: (rateId, data) => {
              return updateFinanceCompanyRate(financeCompanyId, rateId, data);
            },
          }}
          tableColumns={columns}
          formValues={{
            values: financeCompanyRateFormValues,
            validationSchema: financeCompanyRateValidationSchema,
          }}
          pageDetails={{
            definitionName: "Rate",
            title: "Rate List",
          }}
          privilege={{
            create: Privileges.FINANCECOMPANY.CREATE,
            update: Privileges.FINANCECOMPANY.UPDATE,
            delete: Privileges.FINANCECOMPANY.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(FinanceCompanyRate);
