import { atom } from "recoil";
import invoiceUtils from "./invoice_utils";

export const invoicesAppliedFilterState = atom({
  key: "Invoices.appliedFilter",
  default: invoiceUtils.initialFilterValues(),
});
export const invoicesAppliedSortState = atom({
  key: "Invoices.appliedSort",
  default: { sortField: "created_date", sortOrder: "desc" },
});
