import { atom } from "recoil";
import transferUtils from "./transfer_utils";

export const transfersAppliedFilterState = atom({
  key: "Transfers.appliedFilter",
  default: transferUtils.initialFilterValues(),
});
export const transfersAppliedSortState = atom({
  key: "Transfers.appliedSort",
  default: { sortField: "transferNumber", sortOrder: "desc" },
});
