import { toast } from "react-toastify";

export const noop = (...p) => {};

export const get = (obj, path, defaultValue = undefined) => {
  const paths = path.split(".");
  let current = obj;
  for (let i = 0; i < paths.length; i += 1) {
    if (current[paths[i]] === undefined) {
      return defaultValue;
    }
    current = current[paths[i]];
  }
  return current || defaultValue;
};

export const showError = (e, title) => {
  const errMsg = e.response?.data?.message || e.message;
  const msg = (
    <>
      {title && <h5>{title}</h5>}
      <div>{errMsg}</div>
    </>
  );
  return toast.error(msg);
};
export const showInfo = (msg) => {
  return toast.success(msg);
};
export const showWarn = (msg) => {
  return toast.warn(msg);
};

export function arrayToParams(name, params = []) {
  return params == null || params.length == 0 ? "" : `${params.map((n) => `${name}=${n}`).join("&")}`;
}

export function toQueryParams(filter) {
  if (!filter) {
    return "";
  }
  let qp = "";
  const filledKeys = Object.keys(filter).filter((k) => filter[k] != null);
  filledKeys.forEach((k, i) => {
    qp += `${k}=${filter[k]}`;
    if (i < filledKeys.length - 1) {
      qp += "&";
    }
  });
  return qp;
}

export function hasAnyValue(obj) {
  if (!obj) {
    return false;
  }
  return Object.keys(obj).find((k) => obj[k] != null && obj[k] !== "") != null;
}

export function contains(item, list = []) {
  return list.includes(item);
}
