import { get, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

export const getInvoices = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/invoiceReceipt?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );

export const getInvoicePdf = (id) =>
  get(`/invoiceReceipt/${id}/pdf`, {
    responseType: "blob",
  });
export const convertReceiptToInvoice = (id) => put(`/invoiceReceipt/${id}/receiptToInvoice`);
