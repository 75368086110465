import ActionMenu from "components/Common/ActionMenu";
import DateTimeOutput from "components/Common/DateTimeOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import Privileges from "models/Privileges";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Row,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { downloadService } from "services/DownloadService";
import { convertReceiptToInvoice, getInvoicePdf, getInvoices } from "store/invoice/services";
import { invoicesAppliedFilterState, invoicesAppliedSortState } from "./atoms";
import InvoiceFilterForm from "./InvoiceFilterForm";
import invoiceUtils from "./invoice_utils";

const Invoices = ({ history }) => {
  const activeUser = useActiveUser();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [appliedFilter, setAppliedFilter] = useRecoilState(invoicesAppliedFilterState);
  const [appliedSort, setAppliedSort] = useRecoilState(invoicesAppliedSortState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("Invoices");
  const loadInvoices = async (page, filter, sort) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getInvoices(page, invoiceUtils.mapToOrderSearch(filter), sort);
      setTotalSize(totalElements);
      setInvoices(content);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // loadInvoices(pageOptions, appliedFilter, appliedSort);
  }, []);

  const handleReceiptToInvoice = async (item) => {
    await convertReceiptToInvoice(item.id);
    toast.info("Receipt updated successfully");
    loadInvoices(pageOptions, appliedFilter, appliedSort);
  };

  const handlePageChange = (type, details) => {
    // console.log("handlePageChange", details, type);
    const { page, sizePerPage, sortField, sortOrder } = details;
    if (type == "pagination") {
      loadInvoices(setPageNumber(page, sizePerPage), appliedFilter, appliedSort);
    } else if (type == "sort") {
      const newSort = { sortField: sortField, sortOrder };
      setAppliedSort(newSort);
      loadInvoices(pageOptions, appliedFilter, newSort);
    }
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    const newPage = setPageNumber(0);
    loadInvoices(newPage, appliedFilter, appliedSort);
    setHasFilter(hasAnyValue(appliedFilter));
  };

  const handleDownloadInvoice = async (id) => {
    const data = await getInvoicePdf(id);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };

  const columns = [
    {
      dataField: "actions",
      text: "Actions",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => {
        const items = [];
        if (activeUser.hasPrivilege(Privileges.INVOICE.DOWNLOAD)) {
          items.push(<DropdownItem onClick={() => handleDownloadInvoice(row.id)}>Invoice PDF</DropdownItem>);
        }
        if (activeUser.hasPrivilege(Privileges.INVOICE.RECEIPT_TO_INVOICE) && row.docType == "Receipt") {
          items.push(<DropdownItem onClick={() => handleReceiptToInvoice(row)}>Convert to Invoice</DropdownItem>);
        }
        if (items.length > 0) {
          return (
            <ActionMenu>
              <DropdownMenu>
                {items.map((item, i) => (
                  <React.Fragment key={i}>{item}</React.Fragment>
                ))}
              </DropdownMenu>
            </ActionMenu>
          );
        }
        return null;
      },
    },
    {
      dataField: "doc_type",
      text: "Type",
      sort: true,
      formatter: (cell, row) => row.docType,
    },
    {
      dataField: "customerFullName",
      text: "Customer",
      sort: false,
    },
    {
      dataField: "authByFullName",
      text: "Auth By",
      sort: false,
    },
    {
      dataField: "o.order_id",
      text: "Order Number",
      sort: true,
      formatter: (cell, row) => row.orderId,
    },
    {
      dataField: "invoice_number",
      text: "Invoice Number",
      sort: true,
      formatter: (cell, row) => row.invoiceNumber,
    },
    {
      dataField: "receipt_number",
      text: "Receipt Number",
      sort: true,
      formatter: (cell, row) => row.receiptNumber,
    },
    {
      dataField: "amount",
      text: "Amount",
      align: "right",
      headerAlign: "right",
      sort: false,
      formatter: (cell, row) => <MoneyOutput value={cell} currency="GBP" />,
    },
    {
      text: "Branch",
      dataField: "branchName",
    },
    {
      dataField: "created_date",
      text: "Created At",
      sort: true,
      formatter: (cell, row) => <DateTimeOutput date={row.createdDate} />,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Invoices & Receipts</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <InvoiceFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <div className="d-flex justify-content-between">
                      <h4>Invoices & Receipts</h4>
                    </div>
                  </CardTitle>
                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        hover
                        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                        defaultSorted={[{ dataField: appliedSort.sortField, order: appliedSort.sortOrder }]}
                        loading={loading}
                        keyField="id"
                        responsive
                        bordered={false}
                        striped={false}
                        remote
                        pagination={paginationFactory(pageOptions)}
                        columns={columns}
                        data={invoices}
                        onTableChange={handlePageChange}
                        wrapperClasses="table-responsive"
                        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                        headerWrapperClasses={"thead-light"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Invoices);
