import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Label, Spinner, Col, Input, Row, Button } from "reactstrap";
import transferUtils from "./transfer_utils";
import { getTransferStatus } from "store/definitions/services";
import { getStores } from "store/store/services";
import { getUserListContent } from "store/definitions/services";
import { createTransfer, editTransfer, getTransfer } from "store/transfer/services";
import { showInfo } from "helpers/utils";
import TransferProducts from "./TransferProducts";
import TransferDocuments from "./TransferDocuments";

const initialLoadState = { loading: false, error: null, loaded: false };

const Transfer = ({ id, history }) => {
  const [loadState, setLoadState] = useState(initialLoadState);
  const [transfer, setTransfer] = useState(transferUtils.initialValues());
  const [transferView, setTransferView] = useState();
  const [saving, setSaving] = useState(false);

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setTransfer((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setTransfer((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const loadTransfer = async (transferId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getTransfer(transferId);
      const prd = transferUtils.mapToFormDataModel(mdl);
      setTransfer(prd);
      setTransferView(mdl);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      console.error(error);
      setLoadState({ loading: false, error, loaded: false });
    }
  };
  const handleSaveTransfer = async () => {
    setSaving(true);
    try {
      const saveModel = transferUtils.mapToSaveModel(transfer);
      const mdl = await createTransfer(saveModel);
      showInfo("Transfer created successfully");
      history.push(`/transfer/${mdl.id}`);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateTransfer = async () => {
    setSaving(true);
    try {
      const saveModel = transferUtils.mapToSaveModel(transfer);
      const mdl = await editTransfer(id, saveModel);
      setTransfer(transferUtils.mapToFormDataModel(mdl));
      setTransferView(mdl);
      showInfo("Transfer updated successfully");
    } finally {
      setSaving(false);
    }
  };
  useEffect(() => {
    if (id) {
      loadTransfer(id);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);
  return (
    <React.Fragment>
      <div className="my-5 px-4 pt-sm-5">
        <h2 className="text-primary">
          {!id && <b>New Transfer</b>}
          {id && <b>Edit Transfer</b>}
          {loadState.loading && (
            <Spinner size="md" style={{ marginLeft: 20 }}>
              Loading...
            </Spinner>
          )}
        </h2>
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Transfer could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {transferView && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-3">Transfer Number</h6>
                <Col>
                  <h5>{transferView.no}</h5>
                </Col>
                <h6 className="col-sm-3">Created By</h6>
                <Col>{transferView.createUser}</Col>
              </Row>
              <Row className="py-1">
                <h6 className="col-sm-3">Last Modified At</h6>
                <Col>{transferView.updateDate}</Col>
                <h6 className="col-sm-3">Created At</h6>
                <Col>{transferView.createdDate}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {loadState.loaded && (
          <>
            <Card>
              <CardBody>
                <div className="px-2 py-2">
                  <div className="row mb-4">
                    <Label htmlFor="transferStatus" className="col-sm-3 col-form-label">
                      Transfer Status
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getTransferStatus}
                        name="transferStatus"
                        value={transfer.transferStatus.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="senderLocation" className="col-sm-3 col-form-label">
                      Sender Location
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getStores}
                        name="senderLocation"
                        value={transfer.senderLocation.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="arrivalLocation" className="col-sm-3 col-form-label">
                      Arrival Location
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getStores}
                        name="arrivalLocation"
                        value={transfer.arrivalLocation.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="vehiclePlateNo" className="col-sm-3 col-form-label">
                      Vehicle Plate No
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        value={transfer.vehiclePlateNo}
                        name="vehiclePlateNo"
                        placeholder="Vehicle Plate No.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="receiverUser" className="col-sm-3 col-form-label">
                      Receiver User
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getUserListContent}
                        name="receiverUser"
                        value={transfer.receiverUser.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        labelField="fullName"
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="senderUser" className="col-sm-3 col-form-label">
                      Sender User
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getUserListContent}
                        name="senderUser"
                        value={transfer.senderUser.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        labelField="fullName"
                      />
                    </Col>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card
              className="shadow-lg"
              style={{
                backgroundColor: "#2a3042",
                // bshipmentTop: "1px solid #E7E7E7",
                // textAlign: "center",
                // padding: "20px",
                position: "fixed",
                // left: 0,
                bottom: 0,
                zIndex: 1,
                // height: "60px",
                // width: "80%",
              }}
            >
              <CardBody className="">
                <Row>
                  {id && (
                    <Col>
                      <Button color="success" size="md" onClick={handleUpdateTransfer}>
                        <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                      </Button>
                    </Col>
                  )}
                  {!id && (
                    <Col>
                      <Button color="success" size="md" onClick={handleSaveTransfer}>
                        <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
            {id && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <TransferProducts transferId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <TransferDocuments transferId={id} />
                  </CardBody>
                </Card>
              </>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

Transfer.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Transfer);
