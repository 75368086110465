import enums, { EnumValues, getEnumLabel } from "common/enums";
import ConfirmButton from "components/Common/ConfirmButton";
import CustomSpinner from "components/Common/CustomSpinner";
import DateOutput from "components/Common/DateOutput";
import ImagesModal from "components/Common/ImagesModal";
import MoneyOutput from "components/Common/MoneyOutput";
import PercentageOutput from "components/Common/PercentageOutput";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { Button, Col, Modal, ModalBody, ModalHeader, Popover, PopoverBody, PopoverHeader, Row } from "reactstrap";
import { editOrderedProduct, getOrderedProduct } from "store/order/ordered-product-services";
import { getVariantOrProductImages } from "store/product/services";
import {
  addInStockProductToOrder,
  addProductToOrder,
  deleteOrderProduct,
  getOrder,
  updateDiscountRates,
} from "../../store/order/services";
import OrderProductForm from "./OrderProductForm";
import orderUtils from "./order_utils";

const initialProductState = { open: false, error: null, loading: false, product: undefined };
const initialUpdateDiscountRateState = {
  open: false,
  error: null,
  loading: false,
  discountRate: null,
  discountTotal: null,
};
const OrderProducts = ({ orderId, type, onChange }) => {
  const [productState, setProductState] = useState(initialProductState);
  const [order, setOrder] = useState({});
  const [products, setProducts] = useState([]);
  const [imageState, setImageState] = useState({
    productId: null,
    variantId: null,
  });
  const [loading, setLoading] = useState(false);
  const openProductAddPopup = () => {
    setProductState((prev) => ({ ...prev, product: undefined, open: true }));
  };
  const openProductUpdatePopup = async (product) => {
    const p = await getOrderedProduct(product.id);
    const m = orderUtils.mapToProductFormModel(p);
    setProductState((prev) => ({ ...prev, product: m, open: true }));
  };
  const handleAddOrUpdateProduct = async (product) => {
    if (product.id) {
      await editOrderedProduct(product.id, product);
    } else if (product.orderType == "NEW") {
      await addProductToOrder(orderId, product);
    } else if (product.orderType == "STOCK") {
      await addInStockProductToOrder(orderId, product.inStockProduct.value, product);
    }
    setProductState((prev) => ({ ...prev, product: undefined, open: false }));
    await loadProducts(orderId);
    onChange && onChange();
  };
  const handleCloseProduct = () => {
    setProductState((prev) => ({ ...prev, open: false }));
  };
  const loadProducts = async (orderId) => {
    setLoading(true);
    try {
      const order = await getOrder(orderId);
      setOrder(order);
      setProducts(order.orderedProducts);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteProduct = async ({ id }) => {
    await deleteOrderProduct(orderId, id);
    loadProducts(orderId);
    onChange && onChange();
  };
  useEffect(() => {
    loadProducts(orderId);
  }, [orderId]);

  const handleUpdateDiscountRates = async (discountRate, discountTotal) => {
    await updateDiscountRates(orderId, discountRate, discountTotal);
    toast.info("Discounts updated successfully");
    loadProducts(orderId);
  };

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        align: "center",
        headerAlign: "center",
        formatter: (productVariant) => (
          <VariantDefaultImage
            onClick={() =>
              setImageState({
                productId: productVariant.productId,
                variantId: productVariant.id,
              })
            }
            variant={productVariant}
          ></VariantDefaultImage>
        ),
        footer: "",
      },
      {
        text: "Name",
        dataField: "productVariant.productName",
        footer: "",
      },
      {
        text: "Variant",
        dataField: "productVariant.name",
        footer: "",
      },
      {
        text: "Status",
        dataField: "productStatus",
        formatter: (cell, row) => (
          <>
            {getEnumLabel(enums.orderedProductStatus, cell)}
            {[EnumValues.ORDERED_PRODUCT_STATUS.DELIVERY_PLANNED, EnumValues.ORDERED_PRODUCT_STATUS.DELIVERED].includes(
              cell
            ) &&
              row.delivery != null &&
              row.delivery.plannedDate && (
                <div className="text-danger">
                  (<DateOutput date={row.delivery.plannedDate} />)
                </div>
              )}
          </>
        ),
        footer: "",
      },
      {
        text: "Type",
        dataField: "orderType",
        formatter: (cell) => getEnumLabel(enums.orderTypes, cell),
        footer: "",
      },
      {
        text: "Quantity",
        dataField: "quantity",
        footer: "",
      },
      {
        text: "Item Price",
        dataField: "orjPrice",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} currency={row.priceCurrency} />,
        footer: "Totals",
        footerStyle: {
          textAlign: "right",
        },
      },
      {
        text: "Item Total",
        dataField: "price",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} currency={row.priceCurrency} />,
        footer: (cell, row) => <MoneyOutput value={Number(order.price).toFixed(2)} currency="GBP" />,
        footerStyle: {
          textAlign: "right",
        },
      },
      {
        text: "Discount Rate",
        dataField: "discountRate",
        align: "right",
        headerAlign: "right",
        formatter: (cell) => <PercentageOutput value={cell} />,
        footer: (cell) => {
          return <PercentageOutput value={Number(order.discountRate).toFixed(2)} />;
        },
        footerStyle: {
          textAlign: "right",
        },
      },
      {
        text: "Discount",
        dataField: "discountAmount",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} currency={row.priceCurrency} />,
        footer: (cell, row) => <MoneyOutput value={Number(order.discountAmount).toFixed(2)} currency="GBP" />,
        footerStyle: {
          textAlign: "right",
        },
      },
      {
        text: "Discounted Total",
        dataField: "totalNetPrice",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} currency={row.priceCurrency} />,
        footer: (cell, row) => <MoneyOutput value={Number(order.netPrice).toFixed(2)} currency="GBP" />,
        footerStyle: {
          textAlign: "right",
        },
      },
      {
        dataField: "edit",
        text: "Edit",
        hidden: type == "view",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => {
          return (
            <Button color="warning" size="sm" onClick={() => openProductUpdatePopup(row)}>
              <i className="fa fa-edit"></i>
            </Button>
          );
        },
        footer: "",
      },
      {
        text: "Delete",
        dataField: "delete",
        hidden: type == "view",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeleteProduct(row)}
              title="Are you sure you want to delete the product?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
        footer: "",
      },
    ],
    [products]
  );

  const expandRow = useMemo(
    () => ({
      showExpandColumn: false,
      expandByColumnOnly: true,
      expanded: products.filter((i) => i.notes).map((item, i) => item.id),
      renderer: (row) => (
        <>
          <div className="w-100 row">
            <div className="col-2 col-xs-1 d-flex justify-content-end">
              <b>Product Note:</b>
            </div>
            <span className="col-10 col-xs-11 d-flex justify-content-start text-nowrap">{row.notes}</span>
          </div>
        </>
      ),
    }),
    [products]
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Products</b>
        {type != "view" && (
          <span style={{ float: "right" }}>
            {products.length > 0 && (
              <span>
                <UpdateDiscountRatesButton onSubmit={handleUpdateDiscountRates} />
                &nbsp;
              </span>
            )}
            <Button color="primary" size="sm" onClick={openProductAddPopup}>
              <i className="fa fa-plus"></i>Add Product
            </Button>
          </span>
        )}
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading && products.length > 0 && (
            <BootstrapTable
              keyField="id"
              responsive
              data={products}
              columns={productColumns}
              wrapperClasses="table-responsive"
              classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
              headerWrapperClasses={"thead-light"}
              expandRow={expandRow}
            />
          )}
        </Col>
      </Row>
      {productState.open && (
        <OrderProductModal
          onClose={handleCloseProduct}
          onSubmit={handleAddOrUpdateProduct}
          product={productState.product}
        />
      )}
      {imageState.variantId && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productId, imageState.variantId)}
          onClose={() => setImageState({})}
          title="Product Images"
        />
      )}
    </>
  );
};

const OrderProductModal = ({ product, onClose, onSubmit }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!product?.id ? "Add new product" : "Edit product"}</ModalHeader>
      <ModalBody>
        <OrderProductForm initialProduct={product} onSubmit={onSubmit} />
      </ModalBody>
    </Modal>
  );
};
const UpdateDiscountRatesButton = ({ onSubmit }) => {
  const [updateDiscountRateState, setUpdateDiscountRateState] = useState(initialUpdateDiscountRateState);
  const updateDiscountRateBtn = document.getElementById("updatePopoverBtn");
  return (
    <>
      <Button
        color="primary"
        id="updatePopoverBtn"
        outline
        size="sm"
        type="button"
        onClick={() =>
          setUpdateDiscountRateState({
            ...initialUpdateDiscountRateState,
            open: true,
          })
        }
      >
        <i className="fa fa-edit"></i>Update Discounts
      </Button>
      {updateDiscountRateBtn && updateDiscountRateState.open && (
        <Popover
          isOpen
          // placement="bottom"
          target={updateDiscountRateBtn}
          trigger="legacy"
          toggle={() => setUpdateDiscountRateState({ ...initialUpdateDiscountRateState })}
        >
          <PopoverHeader>Update All Discounts</PopoverHeader>
          <PopoverBody>
            <div className="row mb-2">
              <Col sm={12}>
                <label>Discount Rate</label>
              </Col>
              <Col sm={12}>
                <NumberFormat
                  value={updateDiscountRateState.discountRate}
                  className="form-control"
                  placeholder="Discount rate.."
                  allowNegative={false}
                  onValueChange={({ value }) => {
                    setUpdateDiscountRateState((prev) => ({
                      ...prev,
                      discountRate: value,
                    }));
                  }}
                />
              </Col>
            </div>
            <div className="row mb-2">
              <Col sm={12}>
                <label>Discount Amount</label>
              </Col>
              <Col sm={12}>
                <NumberFormat
                  value={updateDiscountRateState.discountTotal}
                  className="form-control"
                  placeholder="Discount amount.."
                  allowNegative={false}
                  onValueChange={({ value }) => {
                    setUpdateDiscountRateState((prev) => ({
                      ...prev,
                      discountTotal: value,
                    }));
                  }}
                />
              </Col>
            </div>
            <p className="text-info">* You can enter one of the rate or amount</p>
            <Button
              color="primary"
              outline
              size="sm"
              type="button"
              disabled={
                (updateDiscountRateState.discountRate == null || updateDiscountRateState.discountRate == "") &&
                (updateDiscountRateState.discountTotal == null || updateDiscountRateState.discountTotal == "")
              }
              onClick={() => {
                onSubmit(updateDiscountRateState.discountRate, updateDiscountRateState.discountTotal);
                setUpdateDiscountRateState({
                  ...initialUpdateDiscountRateState,
                  open: false,
                });
              }}
            >
              <i className="fa fa-edit"></i>Update
            </Button>
          </PopoverBody>
        </Popover>
      )}
    </>
  );
};

export default OrderProducts;
