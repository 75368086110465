import variantUtils from "./variant_utils";

class ProductUtils {
  initialValues = (productId) => ({
    productId,
    name: "",
    productTypeId: "",
    status: "y",
    productCategoryId: "",
    description: "",
    createVariant: false,
    taxGroupId: "",
    manufacturerId: "",
    manufacturerProductCode: "",
    barcode: "",
    sku: "",
    cost: "",
    costCurrency: "GBP",
    price: "",
    priceCurrency: "GBP",
    salesPrice: "",
    salesPriceCurrency: "GBP",
    colors: [],
    materials: [],
    attributes: [],
    variants: [],
    webCategories: [],
    webPageHeader: "",
    webPageMeta: "",
    webPageUrl: "",
    salesOnWeb: "n",
    publishOnWeb: "n",
  });
  mapToSaveModel = ({
    productId,
    name,
    productTypeId,
    status,
    productCategoryId,
    description,
    createVariant,
    taxGroupId,
    manufacturerId,
    manufacturerProductCode,
    barcode,
    sku,
    cost,
    costCurrency,
    price,
    priceCurrency,
    salesPrice,
    salesPriceCurrency,
    colors = [],
    materials = [],
    attributes = [],
    variants = [],
    webCategories = [],
    webPageHeader,
    webPageMeta,
    webPageUrl,
    salesOnWeb,
    publishOnWeb,
  }) => ({
    productId,
    name,
    productTypeId,
    status,
    productCategoryId,
    description,
    createVariant,
    taxGroupId,
    manufacturerId,
    manufacturerProductCode,
    barcode,
    sku,
    cost,
    costCurrency,
    price,
    priceCurrency,
    salesPrice,
    salesPriceCurrency,
    colorIds: colors.map((item) => item.value),
    materialIds: materials.map((item) => item.value),
    attributeValueOptions: attributes.map((item) => ({
      id: item.id,
      status: item.status || "y",
      attributeId: item.attribute.id,
      value: item.value,
      optionIds: item.values?.map((opt) => opt.value),
    })),
    variants: variants.map((item) => ({
      id: item.id,
      status: item.status || "y",
      colorId: item.color?.value,
      materialId: item.material?.value,
      sku: item.sku,
      cost: item.cost,
      costCurrency: item.costCurrency,
      salesPrice: item.salesPrice,
      salesPriceCurrency: item.salesPriceCurrency,
      price: item.price,
      priceCurrency: item.priceCurrency,
      optionId1: item.option1?.value,
      optionId2: item.option2?.value,
      optionId3: item.option3?.value,
      optionId4: item.option4?.value,
      optionId5: item.option5?.value,
      optionId6: item.option6?.value,
      optionId7: item.option7?.value,
      optionId8: item.option8?.value,
    })),
    webCategoryIds: webCategories.map((item) => item.value),
    webPageHeader,
    webPageMeta,
    webPageUrl,
    salesOnWeb: salesOnWeb == "y",
    publishOnWeb: publishOnWeb == "y",
  });
  mapToFormDataModel = ({
    id = 0,
    status = "",
    name = "",
    description = "",
    createVariant = false,
    manufacturerProductCode = "",
    barcode = "",
    sku = "",
    cost = 0,
    costCurrency = "",
    price = 0,
    priceCurrency = "",
    salesPrice = 0,
    salesCurrency = "",
    publishOnWeb = true,
    salesOnWeb = true,
    webPageHeader = "",
    webPageMeta = "",
    webPageUrl = "",
    manufacturer = {},
    type = {},
    category = {},
    taxGroup = {},
    colors = [],
    webCategories = [],
    attributeValueOptions = [],
    variants = [],
  }) => {
    return {
      productId: id,
      name,
      productTypeId: type?.id,
      status,
      productCategoryId: category?.id,
      description,
      createVariant,
      taxGroupId: taxGroup?.id,
      manufacturerId: manufacturer?.id,
      manufacturerProductCode,
      barcode,
      sku,
      cost,
      costCurrency,
      price,
      priceCurrency,
      salesPrice,
      salesPriceCurrency: salesCurrency,
      colors: colors.map((item) => ({ label: item.nameWithMaterial, value: item.id })),
      attributes: attributeValueOptions.map((item) => ({
        attribute: {
          id: item.attribute.id,
          inputType: item.attribute.inputType,
          name: item.attribute.name,
          orderNumber: item.attribute.orderNumber,
        },
        value: item.value,
        values: item.options.map((opt) => ({
          label: opt.name,
          value: opt.id,
        })),
      })),
      variants: variants
        .map((item) => ({
          id: item.id,
          images: item.images,
          color: { label: item.color?.nameWithMaterial, value: item.color?.id },
          material: { label: item.material?.name, value: item.material?.id },
          cost: item.cost,
          costCurrency: item.costCurrency,
          salesPrice: item.salesPrice,
          salesPriceCurrency: item.salesPriceCurrency,
          price: item.price,
          priceCurrency: item.priceCurrency,
          sku: item.sku,
          option1: item.option1 && { label: item.option1?.name, value: item.option1?.id },
          option2: item.option2 && { label: item.option2?.name, value: item.option2?.id },
          option3: item.option3 && { label: item.option3?.name, value: item.option3?.id },
          option4: item.option4 && { label: item.option4?.name, value: item.option4?.id },
          option5: item.option5 && { label: item.option5?.name, value: item.option5?.id },
          option6: item.option6 && { label: item.option6?.name, value: item.option6?.id },
          option7: item.option7 && { label: item.option7?.name, value: item.option7?.id },
          option8: item.option8 && { label: item.option8?.name, value: item.option8?.id },
          attribute1: item.option1 && {
            name: item.option1?.attributeName,
            id: item.option1?.attributeId,
            inputType: item.option1?.attributeInputType,
            orderNumber: item.option1?.attributeOrderNumber,
          },
          attribute2: item.option2 && {
            name: item.option2?.attributeName,
            id: item.option2?.attributeId,
            inputType: item.option2?.attributeInputType,
            orderNumber: item.option2?.attributeOrderNumber,
          },
          attribute3: item.option3 && {
            name: item.option3?.attributeName,
            id: item.option3?.attributeId,
            inputType: item.option3?.attributeInputType,
            orderNumber: item.option3?.attributeOrderNumber,
          },
          attribute4: item.option4 && {
            name: item.option4?.attributeName,
            id: item.option4?.attributeId,
            inputType: item.option4?.attributeInputType,
            orderNumber: item.option4?.attributeOrderNumber,
          },
          attribute5: item.option5 && {
            name: item.option5?.attributeName,
            id: item.option5?.attributeId,
            inputType: item.option5?.attributeInputType,
            orderNumber: item.option5?.attributeOrderNumber,
          },
          attribute6: item.option6 && {
            name: item.option6?.attributeName,
            id: item.option6?.attributeId,
            inputType: item.option6?.attributeInputType,
            orderNumber: item.option6?.attributeOrderNumber,
          },
          attribute7: item.option7 && {
            name: item.option7?.attributeName,
            id: item.option7?.attributeId,
            inputType: item.option7?.attributeInputType,
            orderNumber: item.option7?.attributeOrderNumber,
          },
          attribute8: item.option8 && {
            name: item.option8?.attributeName,
            id: item.option8?.attributeId,
            inputType: item.option8?.attributeInputType,
            orderNumber: item.option8?.attributeOrderNumber,
          },
        }))
        .map((v) => {
          v.key = variantUtils.generateKey(v);
          return v;
        }),
      webCategories: webCategories.map((item) => ({ label: item.name, value: item.id })),
      webPageHeader,
      webPageMeta,
      webPageUrl,
      salesOnWeb: salesOnWeb ? "y" : "n",
      publishOnWeb: publishOnWeb ? "y" : "n",
    };
  };

  cloneToFormDataModel = ({ colors = [], attributeValueOptions = [], variants = [] }) => {
    const initials = this.initialValues();
    return {
      ...initials,
      colors: colors.map((item) => ({ label: item.nameWithMaterial, value: item.id })),
      attributes: attributeValueOptions.map((item) => ({
        attribute: {
          id: item.attribute.id,
          inputType: item.attribute.inputType,
          name: item.attribute.name,
          orderNumber: item.attribute.orderNumber,
        },
        value: item.value,
        values: item.options.map((opt) => ({
          label: opt.name,
          value: opt.id,
        })),
      })),
      variants: variants
        .map((item) => ({
          id: null,
          images: [],
          color: { label: item.color?.nameWithMaterial, value: item.color?.id },
          material: { label: item.material?.name, value: item.material?.id },
          cost: item.cost,
          costCurrency: item.costCurrency,
          salesPrice: item.salesPrice,
          salesPriceCurrency: item.salesPriceCurrency,
          price: item.price,
          priceCurrency: item.priceCurrency,
          sku: null,
          option1: item.option1 && { label: item.option1?.name, value: item.option1?.id },
          option2: item.option2 && { label: item.option2?.name, value: item.option2?.id },
          option3: item.option3 && { label: item.option3?.name, value: item.option3?.id },
          option4: item.option4 && { label: item.option4?.name, value: item.option4?.id },
          option5: item.option5 && { label: item.option5?.name, value: item.option5?.id },
          option6: item.option6 && { label: item.option6?.name, value: item.option6?.id },
          option7: item.option7 && { label: item.option7?.name, value: item.option7?.id },
          option8: item.option8 && { label: item.option8?.name, value: item.option8?.id },
          attribute1: item.option1 && {
            name: item.option1?.attributeName,
            id: item.option1?.attributeId,
            inputType: item.option1?.attributeInputType,
            orderNumber: item.option1?.attributeOrderNumber,
          },
          attribute2: item.option2 && {
            name: item.option2?.attributeName,
            id: item.option2?.attributeId,
            inputType: item.option2?.attributeInputType,
            orderNumber: item.option2?.attributeOrderNumber,
          },
          attribute3: item.option3 && {
            name: item.option3?.attributeName,
            id: item.option3?.attributeId,
            inputType: item.option3?.attributeInputType,
            orderNumber: item.option3?.attributeOrderNumber,
          },
          attribute4: item.option4 && {
            name: item.option4?.attributeName,
            id: item.option4?.attributeId,
            inputType: item.option4?.attributeInputType,
            orderNumber: item.option4?.attributeOrderNumber,
          },
          attribute5: item.option5 && {
            name: item.option5?.attributeName,
            id: item.option5?.attributeId,
            inputType: item.option5?.attributeInputType,
            orderNumber: item.option5?.attributeOrderNumber,
          },
          attribute6: item.option6 && {
            name: item.option6?.attributeName,
            id: item.option6?.attributeId,
            inputType: item.option6?.attributeInputType,
            orderNumber: item.option6?.attributeOrderNumber,
          },
          attribute7: item.option7 && {
            name: item.option7?.attributeName,
            id: item.option7?.attributeId,
            inputType: item.option7?.attributeInputType,
            orderNumber: item.option7?.attributeOrderNumber,
          },
          attribute8: item.option8 && {
            name: item.option8?.attributeName,
            id: item.option8?.attributeId,
            inputType: item.option8?.attributeInputType,
            orderNumber: item.option8?.attributeOrderNumber,
          },
        }))
        .map((v) => {
          v.key = variantUtils.generateKey(v);
          return v;
        }),
    };
  };

  findDefaultImage(images = []) {
    let defImage;
    if (images && (defImage = images.find((img) => img.isDefault)) != null) {
      return defImage;
    }
    if (images && images.length > 0) {
      return images[0];
    }
    return null;
  }

  findImageByDocumentType(images = [], documentType) {
    let img;
    if (images && (img = images.find((img) => img.documentType == documentType)) != null) {
      return img;
    }
    return null;
  }

  initialFilterValues = () => ({
    name: "",
    barcode: "",
    sku: "",
    typeId: "",
    categoryId: "",
    status: "",
  });

  mapToProductSearch = (
    filter = {
      name: null,
      barcode: null,
      sku: null,
      typeId: null,
      status: null,
      categoryId: null,
    }
  ) => {
    return {
      name: filter.name ? filter.name : null,
      barcode: filter.barcode ? filter.barcode : null,
      sku: filter.sku ? filter.sku : null,
      typeId: filter.typeId ? filter.typeId : null,
      categoryId: filter.categoryId ? filter.categoryId : null,
      status: filter.status ? filter.status : null,
    };
  };
}
const productUtils = new ProductUtils();
export default productUtils;
