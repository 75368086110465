import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import {
  createProductCategory,
  deleteProductCategory,
  editProductCategory,
  getProductCategories,
} from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

export const productCategoryFormValues = [
  { name: "parentId", label: "Parent", size: 6, type: "select", getService: getProductCategories },
  { name: "shortCode", label: "Short Code", size: 6, type: "text" },
  { name: "name", label: "Name", size: 12, type: "text" },
  { name: "description", label: "Description", size: 12, type: "textarea" },
  { name: "status", label: "status", size: 12, type: "checkbox" },
];
const ProductCategories = () => {
  const columns = [
    {
      dataField: "parent",
      text: "Parent",
      sort: true,
      formatter: (cell, row) => row?.parent?.name,
    },
    {
      dataField: "shortCode",
      text: "Short Code",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product Categories</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getProductCategories,
            deleteapi: deleteProductCategory,
            createapi: createProductCategory,
            updateapi: editProductCategory,
          }}
          tableColumns={columns}
          formValues={{
            values: productCategoryFormValues,
            validationSchema: {
              shortCode: Yup.string().required(""),
              name: Yup.string().required(""),
              description: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{
            definitionName: "Product Category",
            title: "Product Category List",
          }}
          privilege={{
            create: Privileges.PRODUCT_CATEGORY.CREATE,
            update: Privileges.PRODUCT_CATEGORY.UPDATE,
            delete: Privileges.PRODUCT_CATEGORY.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProductCategories);
