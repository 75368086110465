import enums, { getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import CustomSpinner from "components/Common/CustomSpinner";
import DateOutput from "components/Common/DateOutput";
import DateTimeOutput from "components/Common/DateTimeOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, DropdownItem, DropdownMenu, Row, Spinner } from "reactstrap";
import { downloadService } from "services/DownloadService";
import { getProduction, getProductionOrderCsv, getProductionOrderDetailsPdf } from "store/production/services";

const ProductionCard = ({ id }) => {
  const activeUser = useActiveUser();
  const [productionState, setProductionState] = useState({
    error: null,
    loading: false,
    production: null,
  });
  const { production } = productionState;
  const loadProduction = async () => {
    setProductionState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const ord = await getProduction(id);
      setProductionState((state) => ({
        ...state,
        production: ord,
        loading: false,
      }));
    } catch (error) {
      setProductionState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadProduction();
  }, [id]);

  const handleProductionOrderExportToCsv = async () => {
    const data = await getProductionOrderCsv(id);
    downloadService.downloadBufferedData({
      data,
      contentType: "text/csv",
      fileName: `production-order-${id}.csv`,
    });
  };

  return (
    <Row>
      <Col md={12}>
        {productionState.loading && <CustomSpinner />}
        {productionState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Production Order could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {productionState.production && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Production Number</h6>
                <Col sm={4}>
                  <h5>
                    {activeUser.hasPrivilege(Privileges.PRODUCTION.DETAIL) && (
                      <Link to={`/production-order/${production.id}/detail`} title="View detail">
                        {production.productionNo}
                      </Link>
                    )}
                    {!activeUser.hasPrivilege(Privileges.PRODUCTION.DETAIL) && production.productionNo}
                    {activeUser.hasPrivilege(Privileges.PRODUCTION.UPDATE) && (
                      <Link to={`/production-order/${production.id}`} className="p-2" title="Edit">
                        <i className="fa fa-edit"></i>
                      </Link>
                    )}
                    {activeUser.hasPrivilege(Privileges.PRODUCTION.DETAIL) && (
                      <ActionMenu toggleClassName="py-0" horizontal>
                        <DropdownMenu>
                          <DropdownItem onClick={handleProductionOrderExportToCsv}>Export To CSV</DropdownItem>
                        </DropdownMenu>
                      </ActionMenu>
                    )}
                  </h5>
                </Col>
                <h6 className="col-sm-2">Order Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.productionOrderStatus, production.orderStatus)}</h5>
                </Col>
                <h6 className="col-sm-2">Created</h6>
                <Col sm={4}>
                  <b> by </b>
                  {production.createUser} <b> at </b> <DateTimeOutput date={production.createdDate} />
                </Col>
                <h6 className="col-sm-2">Delivery Branch</h6>
                <Col sm={4}>{production.deliveryBranch?.name ?? "-"}</Col>
                <h6 className="col-sm-2">Manufacturer</h6>
                <Col sm={4}>{production.manufacturer?.name ?? "-"}</Col>
                <h6 className="col-sm-2">Start Date</h6>
                <Col sm={4}>
                  <DateOutput date={production.productionStartDate} />
                </Col>
                <h6 className="col-sm-2">End Date</h6>
                <Col sm={4}>
                  <DateOutput date={production.productionEndDate} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default ProductionCard;
