import enums, { EnumValues, getEnumLabel } from "common/enums";
import { Badge } from "reactstrap";

const OrderStatusLabel = ({ orderStatus }) => {
  if (orderStatus == EnumValues.ORDER_STATUS.ORDERED) {
    return (
      <Badge color="success" style={{ padding: "7px" }} pill>
        {getEnumLabel(enums.orderStatus, orderStatus)}
      </Badge>
    );
  }
  if (orderStatus == EnumValues.ORDER_STATUS.CANCELLED) {
    return (
      <Badge color="danger" style={{ padding: "7px" }} pill>
        {getEnumLabel(enums.orderStatus, orderStatus)}
      </Badge>
    );
  }
  if (orderStatus == EnumValues.ORDER_STATUS.WAITING_FOR_APPROVAL) {
    return (
      <Badge color="warning" style={{ padding: "7px" }} pill>
        {getEnumLabel(enums.orderStatus, orderStatus)}
      </Badge>
    );
  }
  return (
    <Badge color="secondary" style={{ padding: "7px" }} pill>
      {getEnumLabel(enums.orderStatus, orderStatus)}
    </Badge>
  );
};

export default OrderStatusLabel;
