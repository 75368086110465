import {
  API_ERROR,
  GET_COLORS,
  GET_COLORS_SUCCESS,
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCY_RATE,
  GET_CURRENCY_RATE_SUCCESS,
  GET_MANUFACTURERS,
  GET_MANUFACTURERS_SUCCESS,
  GET_MATERIALS,
  GET_MATERIALS_SUCCESS,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_TYPES,
  GET_PRODUCT_TYPES_SUCCESS,
  GET_TAX_GROUP,
  GET_TAX_GROUP_SUCCESS,
  GET_WEB_CATEGORIES,
  GET_WEB_CATEGORIES_SUCCESS,
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_WAREHOUSE,
  GET_WAREHOUSE_SUCCESS,
  GET_FINANCE_COMPANY,
  GET_FINANCE_COMPANY_SUCCESS,
  GET_ASS_PROBLEM_TYPES,
  GET_ASS_PROBLEM_TYPES_SUCCESS,
} from "./actionTypes";

const initialState = {
  colors: [],
  manufacturers: [],
  currencies: [],
  currencyrate: [],
  materials: [],
  productCategories: [],
  productTypes: [],
  taxGroup: [],
  warehouse: [],
  webCategories: [],
  address: [],
  financeCompany: [],
  sshProblemTypes: [],
  error: "",
  loading: false,
};

const definitionsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLORS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_COLORS_SUCCESS:
      state = {
        ...state,
        loading: false,
        colors: action.payload,
      };
      break;
    case GET_MANUFACTURERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_MANUFACTURERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        manufacturers: action.payload,
      };
      break;
    case GET_CURRENCIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CURRENCIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        currencies: action.payload,
      };
      break;
    case GET_CURRENCY_RATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CURRENCY_RATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        currencyrate: action.payload,
      };
      break;

    case GET_FINANCE_COMPANY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FINANCE_COMPANY_SUCCESS:
      state = {
        ...state,
        loading: false,
        financeCompany: action.payload,
      };
      break;

    case GET_MATERIALS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_MATERIALS_SUCCESS:
      state = {
        ...state,
        loading: false,
        materials: action.payload,
      };
      break;
    case GET_PRODUCT_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PRODUCT_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        productCategories: action.payload,
      };
      break;
    case GET_PRODUCT_TYPES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PRODUCT_TYPES_SUCCESS:
      state = {
        ...state,
        loading: false,
        productTypes: action.payload,
      };
      break;
    case GET_TAX_GROUP:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_TAX_GROUP_SUCCESS:
      state = {
        ...state,
        loading: false,
        taxGroup: action.payload,
      };
      break;
    case GET_WAREHOUSE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_WAREHOUSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        warehouse: action.payload,
      };
      break;

    case GET_WEB_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_WEB_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        webCategories: action.payload,
      };
      break;

    case GET_ASS_PROBLEM_TYPES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ASS_PROBLEM_TYPES_SUCCESS:
      state = {
        ...state,
        loading: false,
        sshProblemTypes: action.payload,
      };
      break;

    case GET_ADDRESS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ADDRESS_SUCCESS:
      state = {
        ...state,
        loading: false,
        address: action.payload,
      };
      break;

    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default definitionsReducers;
