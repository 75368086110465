import { post, get, del, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

//product
export const getProduct = (id) => get(`/product/${id}`);
export const editProduct = (id, data) => put(`/product/${id}`, data);
export const createProduct = (data) => post(`/product`, data);
export const getProducts = () => get(`/product?status=y`);
export const deleteProduct = (id) => del(`/product/${id}`);
export const deleteProductVariant = (id) => del(`/product/variant/${id}`);
export const copyVariants = (data) => post(`/product/copyVariants`, data);
export const searchProducts = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/product/search?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&${toQueryParams(filter)}`
  );

//product stock
export const getProductWithStock = (productId) => get(`/product/${productId}/stockDetail`);
//product images
export const getProductImages = (productId) => get(`/product/${productId}/image`);
export const deleteProductImage = (productId, imageId) => del(`/product/${productId}/image/${imageId}`);
export const addProductImage = (productId, imageData) => put(`/product/${productId}/image/`, imageData);

//product variant images
export const getVariantImages = (productId, variantId) => get(`/product/${productId}/variant/${variantId}/image`);
export const getVariantOrProductImages = async (productId, variantId) => {
  if (variantId != null && variantId > 0) {
    const variantImages = await getVariantImages(productId, variantId);
    if (variantImages.length > 0) {
      return Promise.resolve(variantImages);
    }
  }
  return getProductImages(productId);
};
export const deleteVariantImage = (productId, variantId, imageId) =>
  del(`/product/${productId}/variant/${variantId}/image/${imageId}`);
export const addVariantImage = (productId, variantId, imageData) =>
  put(`/product/${productId}/variant/${variantId}/image/`, imageData);

export const downloadPriceTag = (id, variantId) => get(`/product/exportPriceTag/${id}?variantId=${variantId}`);

export const checkGetOrCreateVariant = (productId, data) => put(`/product/${productId}/checkVariant`, data);
//product price history
export const getProductPriceHistories = (productId) => get(`/product/${productId}/priceHistory`);
//variant price history
export const getVariantPriceHistories = (variantId) => get(`/product/variant/${variantId}/priceHistory`);
