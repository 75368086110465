import { del, get, post, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

//productionOrder
export const getProduction = (id) => get(`/productionOrder/${id}`);
export const editProduction = (id, data) => put(`/productionOrder/${id}`, data);
export const createProduction = (data) => post(`/productionOrder`, data);
export const startProduction = (id) => put(`/productionOrder/${id}/start`);
export const cancelProduction = (id) => put(`/productionOrder/${id}/cancel`);
export const completeProduction = (id) => put(`/productionOrder/${id}/completeProduction`);
export const deleteProduction = (id) => del(`/productionOrder/${id}`);
export const getProductions = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/productionOrder?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );

export const getProductionProducts = (id) => get(`/productionOrder/${id}/product`);
export const getAvailableProducts = () => get(`/productionOrder/availableProducts`);

//productionOrder products
export const addProductToProduction = (id, data) => put(`/productionOrder/${id}/product`, data);
export const deleteProductionProduct = (id, productId) => del(`/productionOrder/${id}/product/${productId}`);

export const addOrderedProductToProduction = (id, data) => put(`/productionOrder/${id}/orderedProducts`, data);

export const addNoteToProduction = (id, data) => put(`/productionOrder/${id}/note`, data);
export const getProductionNotes = (id) => get(`/productionOrder/${id}/note`);
export const deleteProductionNote = (id, noteId) => del(`/productionOrder/${id}/note/${noteId}`);

export const addDocumentToProduction = (transferId, data, type) =>
  put(`/productionOrder/${transferId}/document?type=${type}`, data);
export const getProductionDocuments = (transferId) => get(`/productionOrder/${transferId}/document`);
export const deleteProductionDocument = (transferId, docId) => del(`/productionOrder/${transferId}/document/${docId}`);

export const getProductionOrderCsv = (id) =>
  get(`/productionOrder/${id}/exportToCsv`, {
    responseType: "blob",
  });
