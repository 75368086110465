import ValidationForm from "components/ValidationForm";
import OrderedProductCard from "pages/Order/OrderedProductCard";
import OrderedProductModal from "pages/Order/OrderedProductModal";
import { useCallback, useState } from "react";
import NumberFormat from "react-number-format";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { getAvailableProducts } from "store/delivery/services";
import deliveryUtils from "./delivery_utils";

const DeliveryOrderedProductForm = ({
  initialProduct = {
    id: null,
    notes: "",
    orderedProduct: null,
    quantity: 1,
  },
  onSubmit,
  orderId,
  onAddAllProduct,
}) => {
  const [openOrderedProductPopup, setOpenOrderedProductPopup] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingAll, setSavingAll] = useState(false);
  const [product, setProduct] = useState({ ...initialProduct });

  const handleSelectOrderedProduct = (orderedProduct) => {
    setProduct((prev) => ({ ...prev, orderedProduct, quantity: orderedProduct.quantity }));
    setOpenOrderedProductPopup(false);
  };
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit(deliveryUtils.mapToOrderedProductAddModel(product));
    } finally {
      setSaving(false);
    }
  };
  const handleAddAllProductsSubmit = async () => {
    setSavingAll(true);
    try {
      await onAddAllProduct({ notes: product.notes });
    } finally {
      setSavingAll(false);
    }
  };

  return (
    <ValidationForm>
      <Card className="shadow-lg">
        <CardBody>
          <div className="row mb-4">
            <Label htmlFor="orderedProduct" className="col-sm-3 col-form-label">
              Ordered Product
            </Label>
            <Col sm={9}>
              {product.orderedProduct?.id && <OrderedProductCard orderedProduct={product.orderedProduct} />}
              {!product.orderedProduct?.id && (
                <Button color="primary" className="rounded" onClick={() => setOpenOrderedProductPopup(true)}>
                  <i className="fa fa-plus"></i>
                </Button>
              )}
            </Col>
          </div>
          {product.orderedProduct != null && (
            <div className="row">
              <Col sm={12} style={{ textAlign: "right" }}>
                Total Quantity <Badge color="info">{product.orderedProduct.quantity}</Badge>
              </Col>
            </div>
          )}
          <div className="row mb-4">
            <Label htmlFor="quantity" className="col-sm-3 col-form-label">
              Quantity
            </Label>
            <Col sm={9}>
              <NumberFormat
                value={product.quantity}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                decimalScale={0}
                validationeventname="onValueChange"
                disabled={product.id != null}
                name="quantity"
                validations={["min|1", `max|${product.orderedProduct?.quantity}`]}
                onValueChange={({ value }) => {
                  handleSetValue("quantity", value);
                }}
              />
            </Col>
          </div>
          <div className="row mb-4">
            <Label htmlFor="notes" className="col-sm-3 col-form-label">
              Notes
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                value={product.notes}
                name="notes"
                placeholder="notes.."
                className="form-control"
                onChange={handleChange}
              />
            </Col>
          </div>
        </CardBody>
        <CardFooter>
          {product.orderedProduct?.id && (
            <>
              <Button color="primary" onClick={() => setOpenOrderedProductPopup(true)}>
                Change Product
              </Button>
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          <Button
            color="success"
            onClick={handleSubmit}
            disabled={saving || savingAll || product.orderedProduct == null}
            validate
          >
            {!product.id && "Add to delivery "}
            {product.id && "Update "}
            {saving && <Spinner size="sm" />}
          </Button>
          &nbsp;&nbsp;
          <Button color="success" outline disabled={saving || savingAll} onClick={handleAddAllProductsSubmit}>
            Add All Products
            {savingAll && <Spinner size="sm" />}
          </Button>
        </CardFooter>
      </Card>
      {openOrderedProductPopup && (
        <OrderedProductModal
          onClose={() => setOpenOrderedProductPopup(false)}
          onSelect={handleSelectOrderedProduct}
          service={() => getAvailableProducts(orderId)}
          showProductName
          showProductStatus
        />
      )}
    </ValidationForm>
  );
};

export default DeliveryOrderedProductForm;
