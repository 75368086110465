import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Attributes from "./Attributes";

const CreateVariantModal = ({ onClose, onCreate, product }) => {
  const [formValues, setFormValues] = useState({
    colors: [...product.colors],
    attributes: product.attributes
      .filter((a) => a.attribute.inputType == "SELECT")
      .map((a) => ({
        attribute: a.attribute,
        value: a.value,
        values: [...a.values],
        options: [...a.values],
      })),
  });

  const handleCreate = () => {
    if (product.colors.length > 0 && formValues.colors.length == 0) {
      toast.error("At least one color should be selected");
      return;
    }
    const invalidAttr = formValues.attributes.find(
      (a) =>
        (a.attribute.inputType == "SELECT" && (a.values == null || a.values.length == 0)) ||
        (a.attribute.inputType != "SELECT" && (a.value == null || a.value == ""))
    );
    if (invalidAttr != null) {
      toast.error("Every attribute should contain at least one value");
      return;
    }
    onCreate(formValues.colors, [], formValues.attributes);
  };

  const handleSetValue = useCallback((name, value, valueObject) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);

  const handleAttributeValueChange = useCallback((id, value) => {
    setFormValues((prevValues) => {
      const attr = prevValues.attributes.find((item) => item.attribute.id == id);
      attr.value = value;
      return {
        ...prevValues,
        attributes: [...prevValues.attributes],
      };
    });
  }, []);
  const handleAttributeValuesChange = useCallback((id, values, objValues) => {
    setFormValues((prevValues) => {
      const attr = prevValues.attributes.find((item) => item.attribute.id == id);
      attr.values = objValues;
      return {
        ...prevValues,
        attributes: [...prevValues.attributes],
      };
    });
  }, []);

  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Create Product Variant</ModalHeader>
      <ModalBody>
        <div className="p-2">
          {product.colors.length > 0 && (
            <div className="row mb-4">
              <Label className="col-sm-3 col-form-label" htmlFor="autoSizingSelect">
                Color
              </Label>
              <div className="col-9">
                <MultiselectWithService
                  service={() => Promise.resolve(formValues.colors)}
                  name="colors"
                  value={formValues.colors.map((item) => item.value)}
                  isMulti={true}
                  isClearable
                  setValue={handleSetValue}
                  labelField="nameFirstMaterial"
                />
              </div>
            </div>
          )}
          <Attributes
            items={formValues.attributes}
            setValue={handleAttributeValueChange}
            setValues={handleAttributeValuesChange}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="success" size="md" onClick={handleCreate}>
          Create Variant
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateVariantModal;
