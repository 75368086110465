import { useState } from "react";
import { ButtonDropdown, DropdownToggle } from "reactstrap";

const ActionMenu = ({ children, toggleClassName, horizontal = false }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <ButtonDropdown isOpen={open} toggle={toggle}>
      <DropdownToggle caret color="white" className={toggleClassName}>
        <i className={horizontal ? "fa fa-ellipsis-h" : "fa fa-ellipsis-v"}></i>
      </DropdownToggle>
      {children}
    </ButtonDropdown>
  );
};

export default ActionMenu;
