import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { Link, withRouter } from "react-router-dom";

import enums, { EnumValues, getEnumLabel } from "common/enums";
import DateTimeOutput from "components/Common/DateTimeOutput";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { showInfo } from "helpers/utils";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatePicker from "react-datepicker";
import { getCurrencies, getVehicleTypes, getVolumeTypes } from "store/definitions/services";
import {
  cancelShipment,
  completeShipment,
  createShipment,
  editShipment,
  getShipment,
  startShipment,
} from "store/shipment/services";
import { getStores } from "store/store/services";
import ShipmentDocuments from "./ShipmentDocuments";
import ShipmentNotes from "./ShipmentNotes";
import ShipmentProducts from "./ShipmentProducts";
import shipmentUtils from "./shipment_utils";

const initialLoadState = { loading: false, error: null, loaded: false };

const Shipment = ({ id, history }) => {
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [shipment, setShipment] = useState(shipmentUtils.initialValues());
  const [shipmentView, setShipmentView] = useState();

  const [loadingComplete, setLoadingComplete] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const [confirmState, setConfirmState] = useState({
    open: false,
    onConfirm: null,
    title: "",
  });

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setShipment((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setShipment((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setShipment((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);

  const loadShipment = async (shipmentId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getShipment(shipmentId);
      const prd = shipmentUtils.mapToFormDataModel(mdl);
      setShipment(prd);
      setShipmentView(mdl);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      console.error(error);
      setLoadState({ loading: false, error, loaded: false });
    }
  };

  //handle shipment
  const handleSaveShipment = async () => {
    setSaving(true);
    try {
      const saveModel = shipmentUtils.mapToSaveModel(shipment);
      const mdl = await createShipment(saveModel);
      showInfo("Shipment created successfully");
      history.push(`/shipment/${mdl.id}`);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateShipment = async () => {
    setSaving(true);
    try {
      const saveModel = shipmentUtils.mapToSaveModel(shipment);
      const mdl = await editShipment(id, saveModel);
      setShipment(shipmentUtils.mapToFormDataModel(mdl));
      setShipmentView(mdl);
      showInfo("Shipment updated successfully");
    } finally {
      setSaving(false);
    }
  };

  const handleCompleteShipment = async () => {
    async function confirmCompleteShipment() {
      setLoadingComplete(true);
      try {
        const mdl = await completeShipment(id);
        setShipment(shipmentUtils.mapToFormDataModel(mdl));
        setShipmentView(mdl);
        showInfo("Shipment completed successfully");
      } finally {
        setLoadingComplete(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to COMPLETE the shipment?",
      onConfirm: confirmCompleteShipment,
    });
  };
  const handleStartShipment = async () => {
    async function confirmStartShipment() {
      setLoadingPlan(true);
      try {
        const mdl = await startShipment(id);
        setShipment(shipmentUtils.mapToFormDataModel(mdl));
        setShipmentView(mdl);
        showInfo("Shipment planned successfully");
      } finally {
        setLoadingPlan(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to PLAN the shipment?",
      onConfirm: confirmStartShipment,
    });
  };
  const handleCancelShipment = async () => {
    async function confirmCancelShipment() {
      setLoadingCancel(true);
      try {
        const mdl = await cancelShipment(id);
        setShipment(shipmentUtils.mapToFormDataModel(mdl));
        setShipmentView(mdl);
        showInfo("Shipment cancelled successfully");
      } finally {
        setLoadingCancel(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to CANCEL the shipment?",
      onConfirm: confirmCancelShipment,
    });
  };

  useEffect(() => {
    if (id) {
      loadShipment(id);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="my-5 px-4 pt-sm-5">
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Shipment could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && (
          <h4 className="text-primary">
            <Breadcrumb listClassName="p-0">
              <BreadcrumbItem>
                <Link to="/shipments">Shipments</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {id == null && <b>New Shipment</b>}
                {id != null && <b>Edit Shipment</b>}
              </BreadcrumbItem>
            </Breadcrumb>
          </h4>
        )}
        {shipmentView && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Shipment Number</h6>
                <Col sm={4}>
                  <h5>{shipmentView.no}</h5>
                </Col>
                <h6 className="col-sm-2">Shipment Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.shipmentStatus, shipmentView.shipmentStatus)}</h5>
                </Col>
                <h6 className="col-sm-2">Created</h6>
                <Col sm={4}>
                  <b>by </b>
                  {shipmentView.createUser}
                  <b> at </b>
                  <DateTimeOutput date={shipmentView.createdDate} />
                </Col>
                <h6 className="col-sm-2">Last Modified At</h6>
                <Col sm={4}>{shipmentView.updateDate}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {loadState.loaded && (
          <>
            <Card className="shadow-lg">
              <CardBody>
                <div className="px-2 py-2">
                  <div className="row mb-4">
                    <Label htmlFor="exitDate" className="col-sm-3 col-form-label">
                      Exit Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        selected={shipment.exitDate}
                        placeholderText="Exit Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("exitDate", date);
                        }}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="arrivalDate" className="col-sm-3 col-form-label">
                      Arrival Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        selected={shipment.arrivalDate}
                        placeholderText="Arrival Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("arrivalDate", date);
                        }}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="shipmentBranch" className="col-sm-3 col-form-label">
                      Shipment Branch
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getStores}
                        name="shipmentBranch"
                        value={shipment.shipmentBranch?.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="vehicleType" className="col-sm-3 col-form-label">
                      Vehicle Type
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getVehicleTypes}
                        name="vehicleType"
                        value={shipment.vehicleType.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="containerNo" className="col-sm-3 col-form-label">
                      Container No
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        value={shipment.containerNo}
                        name="containerNo"
                        placeholder="Container No.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="plateNo" className="col-sm-3 col-form-label">
                      Plate No
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        value={shipment.plateNo}
                        name="plateNo"
                        placeholder="Plate No.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="totalVehicleCapacity" className="col-sm-3 col-form-label">
                      Total Vehicle Capacity
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="number"
                        value={shipment.totalVehicleCapacity}
                        name="totalVehicleCapacity"
                        placeholder="Total Vehicle Capacity.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm={3}>
                      <MultiselectWithService
                        service={getVolumeTypes}
                        name="totalVolumeType"
                        value={shipment.totalVolumeType.value}
                        isMulti={false}
                        placeholder="Volume Type.."
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  {/* <div className="row mb-4">
                    <Label htmlFor="totalVolumeType" className="col-sm-3 col-form-label">
                      Total Volume Type
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getVolumeTypes}
                        name="totalVolumeType"
                        value={shipment.totalVolumeType.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div> */}
                  <div className="row mb-4">
                    <Label htmlFor="productsVolume" className="col-sm-3 col-form-label">
                      Products Volume
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="number"
                        value={shipment.productsVolume}
                        name="productsVolume"
                        placeholder="Products Volume Capacity.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm={3}>
                      <MultiselectWithService
                        service={getVolumeTypes}
                        name="productsVolumeType"
                        value={shipment.productsVolumeType.value}
                        isMulti={false}
                        placeholder="Volume Type.."
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  {/* <div className="row mb-4">
                    <Label htmlFor="productsVolumeType" className="col-sm-3 col-form-label">
                      Product Volume Type
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getVolumeTypes}
                        name="productsVolumeType"
                        value={shipment.productsVolumeType.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div> */}
                  <div className="row mb-4">
                    <Label htmlFor="cost" className="col-sm-3 col-form-label">
                      Cost
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="number"
                        value={shipment.cost}
                        name="cost"
                        placeholder="Cost.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm={3}>
                      <MultiselectWithService
                        service={getCurrencies}
                        name="currency"
                        valueField="code"
                        labelField="code"
                        value={shipment.currency.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        placeholder="Currency.."
                      />
                    </Col>
                  </div>
                </div>
              </CardBody>
            </Card>

            {id && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <ShipmentProducts shipmentId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <ShipmentNotes shipmentId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <ShipmentDocuments shipmentId={id} />
                  </CardBody>
                </Card>
              </>
            )}
            <br />
            <br />
            {(!id ||
              shipmentView.shipmentStatus == EnumValues.SHIPMENT_STATUS.PLANNING ||
              shipmentView.shipmentStatus == EnumValues.SHIPMENT_STATUS.ON_THE_WAY) && (
              <Card className="shadow-lg nv-sticky-card mt-2">
                <CardBody className="">
                  <Row>
                    {id && (
                      <>
                        <Col>
                          {shipmentView.shipmentStatus == EnumValues.SHIPMENT_STATUS.PLANNING && (
                            <>
                              <Button color="success" size="md" onClick={handleUpdateShipment} disabled={saving}>
                                <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                              </Button>
                              &nbsp;&nbsp;
                              <Button color="success" size="md" onClick={handleStartShipment} disabled={loadingPlan}>
                                {loadingPlan ? <Spinner color="white" size="sm" /> : "Start Shipment"}
                              </Button>
                            </>
                          )}
                          {shipmentView.shipmentStatus == EnumValues.SHIPMENT_STATUS.ON_THE_WAY && (
                            <>
                              <Button
                                color="success"
                                size="md"
                                onClick={handleCompleteShipment}
                                disabled={loadingComplete}
                              >
                                {loadingComplete ? <Spinner color="white" size="sm" /> : "Complete Shipment"}
                              </Button>
                            </>
                          )}
                          {(shipmentView.shipmentStatus == EnumValues.SHIPMENT_STATUS.PLANNING ||
                            shipmentView.shipmentStatus == EnumValues.SHIPMENT_STATUS.ON_THE_WAY) && (
                            <>
                              &nbsp;&nbsp;
                              <Button color="danger" size="md" onClick={handleCancelShipment} disabled={loadingCancel}>
                                {loadingCancel ? <Spinner color="white" size="sm" /> : "Canceled"}
                              </Button>
                            </>
                          )}
                        </Col>
                      </>
                    )}
                    {!id && (
                      <Col>
                        <Button color="success" size="md" onClick={handleSaveShipment} disabled={saving}>
                          <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            )}
          </>
        )}
        {confirmState.open && (
          <SweetAlert
            title={confirmState.title}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setConfirmState({ open: false });
              confirmState.onConfirm();
            }}
            onCancel={() => {
              setConfirmState({ open: false });
            }}
          ></SweetAlert>
        )}
      </div>
    </React.Fragment>
  );
};

Shipment.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Shipment);
