class ProformaUtils {
  initialFilterValues = () => ({
    orderId: null,
    orderStatus: null,
    deliveryBranchId: null,
    salesPersonId: null,
    customerFullName: "",
    postCode: "",
    phone: "",
    email: "",
    proformaCancelStatus: null,
  });

  mapToProformaSearch = (
    filter = {
      orderId: null,
      orderStatus: null,
      deliveryBranchId: null,
      salesPersonId: null,
      customerFullName: "",
      postCode: "",
      phone: "",
      email: "",
      proformaCancelStatus: null,
    }
  ) => {
    return {
      proformaIds: filter.orderId != null ? [filter.orderId] : [],
      orderIds: filter.orderId != null ? [filter.orderId] : [],
      salesPersonIds: filter.salesPersonId != null ? [filter.salesPersonId] : [],
      statuses: filter.orderStatus != null ? [filter.orderStatus] : [],
      deliveryBranchIds: filter.deliveryBranchId != null ? [filter.deliveryBranchId] : [],
      customerFullName: filter.customerFullName,
      postCode: filter.postCode,
      phone: filter.phone,
      email: filter.email,
      proformaCancelStatuses: filter.proformaCancelStatus != null ? [filter.proformaCancelStatus] : [],
    };
  };
}
const proformaUtils = new ProformaUtils();
export default proformaUtils;
