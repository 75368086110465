import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

const CustomerFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col xl="12" xxl="6">
            <div className="row mb-4">
              <Label htmlFor="fullName" className="col-sm-3 col-form-label">
                Name
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.fullName || ""}
                  name="fullName"
                  placeholder="Name.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("fullName", e.target.value);
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="phone" className="col-sm-3 col-form-label">
                Phone
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.phone || ""}
                  name="phone"
                  placeholder="Phone.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("phone", e.target.value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col xl="12" xxl="6">
            <div className="row mb-4">
              <Label htmlFor="email" className="col-sm-3 col-form-label">
                Email
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.email || ""}
                  name="email"
                  placeholder="Email.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("email", e.target.value);
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="postCode" className="col-sm-3 col-form-label">
                Post Code
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.postCode || ""}
                  name="postCode"
                  placeholder="post code.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("postCode", e.target.value);
                  }}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp; &nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </form>
    </React.Fragment>
  );
};

export default CustomerFilterForm;
