import moment from "moment";

class TicketUtils {
  initialValues = ({ orderId = "", customerId = "" } = {}) => ({
    id: null,
    status: "y",
    order: {
      value: orderId,
    },
    ticketStatus: {},
    customerId: customerId,
    problemSubject: {},
    problemType: {},
    problemType2: {},
    problemType3: {},
    problemType4: {},
    problemType5: {},
    description: "",
    amount: "",
    orderedProducts: [],
    serviceDate: null,
    reminderDate: null,
  });
  mapToSaveModel = ({
    id,
    status = "y",
    order = {},
    customerId,
    problemSubject = {},
    problemType = {},
    problemType2 = {},
    problemType3 = {},
    problemType4 = {},
    problemType5 = {},
    amount,
    ticketStatus,
    orderedProducts = [],
    serviceDate = null,
    reminderDate = null,
  }) => ({
    id,
    status,
    orderId: order?.value,
    customerId,
    problemSubjectId: problemSubject?.value,
    problemTypeId: problemType?.value,
    problemTypeId2: problemType?.value && problemType2?.value,
    problemTypeId3: problemType?.value && problemType2?.value && problemType3?.value,
    problemTypeId4: problemType?.value && problemType2?.value && problemType3?.value && problemType4?.value,
    problemTypeId5:
      problemType?.value && problemType2?.value && problemType3?.value && problemType4?.value && problemType5?.value,
    amount,
    sshStatus: ticketStatus?.value,
    orderedProductIds: orderedProducts.map((p) => p.id),
    serviceDate: serviceDate && moment(serviceDate).utcOffset(0, true).format(),
    reminderDate: reminderDate && moment(reminderDate).utcOffset(0, true).format(),
  });

  mapToFormDataModel = ({
    id,
    status = "y",
    order = {},
    customer = {},
    problemSubject = {},
    problemType = {},
    problemType2 = {},
    problemType3 = {},
    problemType4 = {},
    problemType5 = {},
    amount,
    sshStatus,
    products = [],
    serviceDate,
    reminderDate,
  }) => ({
    id,
    status,
    customerId: customer?.id,
    customer,
    problemSubject: {
      label: problemSubject?.name,
      value: problemSubject?.id,
    },
    problemType: {
      label: problemType?.name,
      value: problemType?.id,
    },
    problemType2: {
      label: problemType2?.name,
      value: problemType2?.id,
    },
    problemType3: {
      label: problemType3?.name,
      value: problemType3?.id,
    },
    problemType4: {
      label: problemType4?.name,
      value: problemType4?.id,
    },
    problemType5: {
      label: problemType5?.name,
      value: problemType5?.id,
    },
    amount,
    ticketStatus: {
      value: sshStatus,
    },
    orderedProducts: products,
    order: {
      label: order?.orderId,
      value: order?.id,
    },
    serviceDate: serviceDate && moment(serviceDate, "YYYY-MM-DD HH:mm:ss").toDate(),
    reminderDate: reminderDate && moment(reminderDate, "YYYY-MM-DD HH:mm:ss").toDate(),
  });

  mapToNoteAddModel = ({ id, status = "y", notes }) => ({
    id,
    status,
    notes,
  });

  initialFilterValues = () => ({
    orderId: null,
    customerId: null,
    storeId: null,
    subjectId: null,
    problemId: null,
    sshStatus: null,
    serviceDateStart: null,
    serviceDateEnd: null,
    reminderDateStart: null,
    reminderDateEnd: null,
  });

  mapToTicketSearch = (
    filter = {
      orderId: null,
      customerId: null,
      storeId: null,
      subjectId: null,
      problemId: null,
      sshStatus: null,
      serviceDateStart: null,
      serviceDateEnd: null,
      reminderDateStart: null,
      reminderDateEnd: null,
    }
  ) => {
    return {
      orderId: filter.orderId,
      customerId: filter.customerId,
      storeId: filter.storeId,
      subjectId: filter.subjectId,
      problemId: filter.problemId,
      sshStatus: filter.sshStatus,
      serviceDateStart: filter.serviceDateStart == null ? null : moment(filter.serviceDateStart).format("YYYY-MM-DD"),
      serviceDateEnd: filter.serviceDateEnd == null ? null : moment(filter.serviceDateEnd).format("YYYY-MM-DD"),
      reminderDateStart:
        filter.reminderDateStart == null ? null : moment(filter.reminderDateStart).format("YYYY-MM-DD"),
      reminderDateEnd: filter.reminderDateEnd == null ? null : moment(filter.reminderDateEnd).format("YYYY-MM-DD"),
    };
  };
}
const ticketUtils = new TicketUtils();
export default ticketUtils;
