import CustomSpinner from "components/Common/CustomSpinner";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";

const { getCustomer } = require("store/customer/services");

const CustomerCard = ({ id }) => {
  const [customerState, setCustomerState] = useState({
    error: null,
    loading: false,
    customer: {},
  });
  const { customer } = customerState;
  const loadCustomer = async () => {
    setCustomerState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const customer = await getCustomer(id);
      setCustomerState((state) => ({
        ...state,
        customer,
        loading: false,
      }));
    } catch (error) {
      setCustomerState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadCustomer();
  }, [id]);

  return (
    <Row>
      <Col md={12}>
        <Card className="shadow-lg">
          <CardBody>
            {customerState.loading && <CustomSpinner />}
            {!customerState.loading && (
              <>
                <Row className="py-1">
                  {customer?.customerType == "COMPANY" && <h6 className="col-sm-2">Company Name</h6>}
                  {customer?.customerType != "COMPANY" && <h6 className="col-sm-2">Customer Name</h6>}
                  <Col sm={4}>
                    {customer?.customerType == "PERSON" && <>{customer?.title}</>} {customer.fullName}
                  </Col>
                  <h6 className="col-sm-2">Email</h6>
                  <Col sm={4}>{customer.email}</Col>
                </Row>
                <Row className="py-1">
                  <h6 className="col-sm-2">Mobile Phone</h6>
                  <Col sm={4}>{customer.mobilePhone}</Col>
                  <h6 className="col-sm-2">Alternative Phone</h6>
                  <Col sm={4}>{customer.phone}</Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CustomerCard;
