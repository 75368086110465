import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";
import React, { Fragment, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import { Button, Input, Label, Modal, Row, UncontrolledTooltip } from "reactstrap";
import {
  createProductAttrOption,
  createProductAttribute,
  deleteProductAttrOption,
  deleteProductAttribute,
  editProductAttribute,
  getEnumInputTypes,
  getProductAttribute,
  getProductTypes,
  updateProductAttrOption,
} from "store/definitions/services";
import * as Yup from "yup";

export const productAttributeFormValues = [
  { name: "name", label: "Name", size: 12, type: "text" },
  { name: "header", label: "Header", size: 12, type: "text" },
  {
    name: "inputType",
    label: "Input Type",
    size: 12,
    type: "select",
    getService: getEnumInputTypes,
  },

  { name: "orderNumber", label: "Order Number", size: 12, type: "text" },
  {
    name: "productTypeIds",
    label: "Product Type",
    size: 12,
    type: "select",
    isMulti: true,
    getService: getProductTypes,
    getValue: (value) => {
      return value.productTypes.map((item) => item.id);
    },
  },
  { name: "status", label: "status", size: 12, type: "checkbox" },
];

function popupFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <Button color="primary">
      <i className="fa fa-plus"></i>
    </Button>
  );
}

const ProductAttribute = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "header",
      text: "Header",
      sort: true,
    },
    {
      dataField: "orderNumber",
      text: "Order Number",
      sort: true,
    },
    {
      dataField: "inputType",
      text: "Input Type.",
      sort: true,
    },
    {
      dataField: "productTypes",
      text: "Types",
      sort: true,
      formatter: (cellContent, row, index) => {
        if (row.productTypes.length == 0) {
          return null;
        }
        if (row.productTypes.length == 1) {
          return row.productTypes[0].name;
        }
        const targetId = `targetProduct${index}`;
        return (
          <div>
            <span
              href="#"
              id={targetId}
              style={{
                color: "blue",
                textDecoration: "underline",
              }}
            >
              {row.productTypes[0].name} ...
            </span>
            <UncontrolledTooltip placement="top" target={targetId}>
              {row.productTypes.map((item) => item.name).join(", ")}
            </UncontrolledTooltip>
          </div>
        );
      },
    },
    {
      dataField: "options",
      text: "Options",
      sort: true,
      formatter: (cellContent, row) => <>{row.inputType == "SELECT" && <OptionsPopup row={row} />}</>,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product Attributes</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getProductAttribute,
            deleteapi: deleteProductAttribute,
            createapi: createProductAttribute,
            updateapi: editProductAttribute,
          }}
          tableColumns={columns}
          formValues={{
            values: productAttributeFormValues,
            validationSchema: {
              name: Yup.string().required(""),
              description: Yup.string(),
              header: Yup.string(),
              orderNumber: Yup.string(),
              productTypeIds: Yup.array(),
              inputType: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{
            definitionName: "Product Attribute",
            title: "Product Attribute List",
          }}
          privilege={{
            create: Privileges.PRODUCT_ATTRIBUTE.CREATE,
            update: Privileges.PRODUCT_ATTRIBUTE.UPDATE,
            delete: Privileges.PRODUCT_ATTRIBUTE.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

const OptionsPopup = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(row.options || []);
  const toggleModal = () => setIsOpen((prev) => !prev);
  const [editings, setEditings] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    status: "y",
    shortCode: "",
  });
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleAdd = () => {
    createProductAttrOption(row.id, formData).then((res) => {
      setOptions((prev) => [...prev, res]);
    });
  };
  const handleUpdate = async (opt) => {
    const saved = await updateProductAttrOption(opt.id, {
      ...opt,
      name: editings[opt.id],
    });
    const ix = options.findIndex((o) => o.id == opt.id);
    editings[opt.id] = null;
    setEditings({ ...editings });
    options[ix] = saved;
    setOptions([...options]);
  };

  const handleDelete = (id) => {
    deleteProductAttrOption(id).then((res) => {
      setOptions((prev) => [...prev.filter((i) => i.id !== id)]);
    });
  };
  return (
    <Fragment>
      <Button color="primary" onClick={toggleModal}>
        <i className="fa fa-plus"></i>
      </Button>
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Product Options
          </h5>
          <Button onClick={toggleModal} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="p-2">
            <div>
              <Label className="form-label">New Option</Label>
              <Row>
                <div className="col-7">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="name"
                    className="form-control"
                    placeholder={`Enter new option..`}
                    type="text"
                    onChange={handleChange}
                    value={formData.name}
                  />
                </div>
                <div className="col-3">
                  <Label className="form-label">Shortcode</Label>

                  <Input
                    name="shortCode"
                    className="form-control"
                    placeholder={`Enter short code.`}
                    type="text"
                    onChange={handleChange}
                    value={formData.shortCode}
                  />
                </div>
                <div className="col-2 d-flex">
                  <Button color="success" onClick={handleAdd} className="mt-auto">
                    Add
                  </Button>
                </div>
              </Row>
            </div>
            <div className="px-2 py-4">
              <h4>Options</h4>
              {options.map((i) => (
                <div className="row border py-2" key={i.id}>
                  <div className="col-5">
                    {editings[i.id] == null && (
                      <div className="d-flex align-items-center">
                        {i.name}
                        <i
                          className="fa fa-pen px-1"
                          title="Edit users"
                          role="button"
                          onClick={() =>
                            setEditings({
                              ...editings,
                              [i.id]: i.name,
                            })
                          }
                        ></i>
                      </div>
                    )}
                    {editings[i.id] != null && (
                      <>
                        <Input
                          type="text"
                          value={editings[i.id]}
                          onChange={(e) => {
                            editings[i.id] = e.target.value;
                            setEditings({ ...editings });
                          }}
                        />
                        <div className="pt-2 d-flex gap-2">
                          <Button onClick={() => handleUpdate(i)} disabled={!editings[i.id]}>
                            Save
                          </Button>
                          <Button
                            outline
                            onClick={() => {
                              editings[i.id] = null;
                              setEditings({ ...editings });
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-5 d-flex align-items-center">{i.shortCode}</div>
                  <div className="col-2">
                    <Button color="danger" className="ms-auto" onClick={() => handleDelete(i.id)}>
                      <i className="fa fa-trash"></i>
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default withRouter(ProductAttribute);
