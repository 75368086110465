import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { getCurrencyRate } from "store/definitions/services";
import ListDefinitionTable from "components/Tables/ListDefinitionTable";
import Privileges from "models/Privileges";
import DefinitionTable from "components/Tables/DefinitionTable";

export const currencyFormValues = [
  { name: "code", label: "Code", size: 12, type: "text" },
  { name: "recordOrder", label: "Record Order", size: 12, type: "text" },
  { name: "rate", label: "Rate", size: 12, type: "text" },
  { name: "date", label: "Date", size: 12, type: "datetime" },
];

const CurrencyRate = () => {
  const columns = [
    {
      dataField: "code",
      text: "Code",
      sort: true,
    },
    {
      dataField: "recordOrder",
      text: "Record Order",
      sort: true,
    },
    {
      dataField: "rate",
      text: "Rate",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
  ];
  // {
  //   "id": 0,
  //   "code": "string",
  //   "recordOrder": 0,
  //   "rate": 0,
  //   "date": "2022-05-14T12:01:09.011Z"
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Currency Rate</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getCurrencyRate,
          }}
          tableColumns={columns}
          formValues={{
            values: currencyFormValues,
            validationSchema: {
              code: Yup.string(),
              recordOrder: Yup.string(),
              rate: Yup.string(),
              date: Yup.string(),
            },
          }}
          pageDetails={{ definitionName: "CurrencyRate", title: "Currency Rate" }}
          privilege={{
            create: Privileges.CURRENCYRATE.CREATE,
            update: Privileges.CURRENCYRATE.UPDATE,
            delete: Privileges.CURRENCYRATE.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(CurrencyRate);
