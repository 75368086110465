import enums, { EnumValues, getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import ConfirmButton from "components/Common/ConfirmButton";
import CustomSpinner from "components/Common/CustomSpinner";
import DateOutput from "components/Common/DateOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import PaymentView from "pages/Payment/PaymentView";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col, DropdownItem, DropdownMenu, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { downloadService } from "services/DownloadService";
import { deletePayment, getInvoice } from "store/payment/services";
import { getOrderPayments } from "../../store/order/services";
import Payment from "../Payment/Payment";

const initialPaymentState = { open: false, paymentId: null };
const OrderPayments = ({ orderId, type, onChange }) => {
  const activeUser = useActiveUser();
  const [paymentState, setPaymentState] = useState(initialPaymentState);
  const [paymentViewState, setPaymentViewState] = useState(initialPaymentState);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const openPaymentAddPopup = () => {
    setPaymentState({ paymentId: null, open: true });
  };
  const openPaymentUpdatePopup = ({ id: paymentId }) => {
    setPaymentState({ paymentId, open: true });
  };
  const openPaymentViewPopup = (row) => {
    setPaymentViewState({ paymentId: row.id, open: true });
  };

  const handlePaymentSaved = ({ id: paymentId }) => {
    loadPayments(orderId);
    setPaymentState({ paymentId: null, open: false });
    onChange && onChange();
  };
  const handlePaymentUpdated = () => {
    setPaymentState({ paymentId: null, open: false });
    loadPayments(orderId);
    onChange && onChange();
  };
  const handleClosePayment = () => {
    setPaymentState({ paymentId: null, open: false });
  };
  const handleCloseViewPayment = () => {
    setPaymentViewState({ paymentId: null, open: false });
  };
  const loadPayments = async (orderId) => {
    setLoading(true);
    try {
      const data = await getOrderPayments(orderId);
      setPayments(data);
    } finally {
      setLoading(false);
    }
  };
  const handleDeletePayment = async ({ id }) => {
    await deletePayment(id);
    loadPayments(orderId);
    onChange && onChange();
  };
  const handleDownloadInvoice = async (paymentId) => {
    const data = await getInvoice(paymentId);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };
  useEffect(() => {
    loadPayments(orderId);
  }, [orderId]);

  const paymentColumns = useMemo(
    () => [
      {
        dataField: "actions",
        text: "Actions",
        align: "center",
        headerAlign: "center",
        hidden: !activeUser.hasPrivilege(Privileges.INVOICE.DOWNLOAD),
        formatter: (cell, row) => {
          if (row.paidType == EnumValues.PAID_TYPES.CASH) {
            return null;
          }
          return (
            <ActionMenu>
              <DropdownMenu>
                {activeUser.hasPrivilege(Privileges.INVOICE.DOWNLOAD) && (
                  <DropdownItem onClick={() => handleDownloadInvoice(row.id)}>Invoice PDF</DropdownItem>
                )}
              </DropdownMenu>
            </ActionMenu>
          );
        },
      },
      {
        text: "Date",
        dataField: "date",
        formatter: (cell) => <DateOutput date={cell} />,
      },
      {
        text: "Payment Number",
        dataField: "paymentNumber",
      },
      {
        text: "Paid With",
        dataField: "paidType",
        formatter: (cell) => getEnumLabel(enums.paidTypes, cell),
      },
      {
        text: "Payment Type",
        dataField: "paymentType",
        formatter: (cell) => getEnumLabel(enums.paymentTypes, cell),
      },
      {
        text: "Amount",
        dataField: "amount",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} currency="GBP" />,
      },
      {
        text: "Auth Code",
        dataField: "authCode",
      },
      {
        text: "Auth By",
        dataField: "authBy.fullName",
      },
      {
        text: "Created By",
        dataField: "createUser",
      },
      {
        dataField: "edit",
        text: "Edit",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => {
          return (
            <Button color="warning" size="sm" onClick={() => openPaymentUpdatePopup(row)}>
              <i className="fa fa-edit"></i>
            </Button>
          );
        },
      },
      {
        text: "Delete",
        dataField: "delete",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeletePayment(row)}
              title="Are you sure you want to delete the payment?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    []
  );
  const paymentViewColumns = useMemo(
    () => [
      {
        dataField: "actions",
        text: "Actions",
        align: "center",
        headerAlign: "center",
        hidden: !activeUser.hasPrivilege(Privileges.INVOICE.DOWNLOAD),
        formatter: (cell, row) => {
          return (
            <ActionMenu>
              <DropdownMenu>
                {activeUser.hasPrivilege(Privileges.INVOICE.DOWNLOAD) && (
                  <DropdownItem onClick={() => handleDownloadInvoice(row.id)}>Invoice PDF</DropdownItem>
                )}
              </DropdownMenu>
            </ActionMenu>
          );
        },
      },
      {
        text: "Date",
        dataField: "date",
        formatter: (cell) => <DateOutput date={cell} />,
      },
      {
        text: "Payment Number",
        dataField: "paymentNumber",
      },
      {
        text: "Paid With",
        dataField: "paidType",
        formatter: (cell) => getEnumLabel(enums.paidTypes, cell),
      },
      {
        text: "Payment Type",
        dataField: "paymentType",
        formatter: (cell) => getEnumLabel(enums.paymentTypes, cell),
      },
      {
        text: "Amount",
        dataField: "amount",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} currency="GBP" />,
      },
      {
        text: "Auth Code",
        dataField: "authCode",
      },
      {
        text: "Auth By",
        dataField: "authBy.fullName",
      },
      {
        dataField: "view",
        text: "View",
        formatter: (cell, row) => {
          return (
            <Button color="primary" size="sm" onClick={() => openPaymentViewPopup(row)}>
              <i className="fa fa-eye"></i>
            </Button>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Payments</b>
        {type != "view" && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" onClick={openPaymentAddPopup}>
              <i className="fa fa-plus"></i>Add Payment
            </Button>
          </span>
        )}
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading && payments.length > 0 && (
            <>
              {type == "view" ? (
                <BootstrapTable
                  keyField="id"
                  data={payments}
                  columns={paymentViewColumns}
                  wrapperClasses="table-responsive"
                  classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                  headerWrapperClasses={"thead-light"}
                />
              ) : (
                <BootstrapTable
                  keyField="id"
                  data={payments}
                  columns={paymentColumns}
                  wrapperClasses="table-responsive"
                  classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                  headerWrapperClasses={"thead-light"}
                />
              )}
            </>
          )}
        </Col>
      </Row>
      {paymentState.open && (
        <PaymentModal
          onClose={handleClosePayment}
          orderId={orderId}
          paymentId={paymentState.paymentId}
          onSave={handlePaymentSaved}
          onUpdate={handlePaymentUpdated}
        />
      )}
      {paymentViewState.open && (
        <ViewPaymentModal onClose={handleCloseViewPayment} orderId={orderId} paymentId={paymentViewState.paymentId} />
      )}
    </>
  );
};

const PaymentModal = ({ paymentId, onClose, onSave, onUpdate, orderId }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!paymentId ? "Add new payment" : "Edit payment"}</ModalHeader>
      <ModalBody>
        <Payment orderId={orderId} paymentId={paymentId} onSave={onSave} onUpdate={onUpdate} />
      </ModalBody>
    </Modal>
  );
};

const ViewPaymentModal = ({ paymentId, onClose, orderId }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Payment Details</ModalHeader>
      <ModalBody>
        <PaymentView orderId={orderId} paymentId={paymentId} />
      </ModalBody>
    </Modal>
  );
};

export default OrderPayments;
