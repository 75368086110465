import enums, { getEnumLabel } from "common/enums";
import DateOutput from "components/Common/DateOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import { getPayment } from "store/payment/services";
import PaymentNotes from "./PaymentNotes";

const initialLoadState = { loading: false, error: null, loaded: false };
const PaymentView = ({ paymentId = null, orderId }) => {
  const [loadState, setLoadState] = useState(initialLoadState);
  const [payment, setPayment] = useState({});

  const loadPayment = async (paymentId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getPayment(paymentId);
      setPayment(mdl);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      setLoadState({ loading: false, error, loaded: false });
    }
  };
  useEffect(() => {
    if (paymentId) {
      loadPayment(paymentId);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);
  return (
    <>
      <Row className="px-2 py-2">
        <Col sm={6}>
          <Row>
            <Col sm={4}>
              <Label>Date:</Label>
            </Col>
            <Col sm={8}>
              <DateOutput date={payment?.createdDate} />
            </Col>
            <Col sm={4}>
              <Label>Paid With:</Label>
            </Col>
            <Col sm={8}>{getEnumLabel(enums.paidTypes, payment?.paidType)}</Col>
            <Col sm={4}>
              <Label>Payment Type:</Label>
            </Col>
            <Col sm={8}>{getEnumLabel(enums.paymentTypes, payment?.paymentType)}</Col>
          </Row>
        </Col>
        <Col sm={6}>
          <Row>
            <Col sm={4}>
              <Label>Amount:</Label>
            </Col>
            <Col sm={8}>
              <MoneyOutput value={payment?.amount} />
            </Col>
            <Col sm={4}>
              <Label>Auth By:</Label>
            </Col>
            <Col sm={8}>{payment?.authBy?.fullName}</Col>
          </Row>
        </Col>
      </Row>
      <Row className="px-2 py-2">
        <Col sm={12}>
          <PaymentNotes paymentId={paymentId} type="view" />
        </Col>
      </Row>
    </>
  );
};

export default PaymentView;
