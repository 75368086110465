import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle, Col, Label, Row, Spinner } from "reactstrap";

import { Link, withRouter } from "react-router-dom";

import enums, { EnumValues, getEnumLabel } from "common/enums";
import CustomSpinner from "components/Common/CustomSpinner";
import DateTimeOutput from "components/Common/DateTimeOutput";
import CreatableSelectBoxWithAction from "components/Selectbox/CreatableSelectBoxWithAction";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { showInfo } from "helpers/utils";
import { manufacturerFormValues } from "pages/Definitions/Manufacturers.js/Manufacturers";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { getManufacturersAction } from "store/actions";
import { createManufacturers } from "store/definitions/services";
import {
  cancelProduction,
  completeProduction,
  createProduction,
  editProduction,
  getProduction,
  startProduction,
} from "store/production/services";
import { getBranches } from "store/store/services";
import ProductionDocuments from "./ProductionDocuments";
import ProductionNotes from "./ProductionNotes";
import ProductionProducts from "./ProductionProducts";
import productionUtils from "./production_utils";

const initialLoadState = { loading: false, error: null, loaded: false };

const Production = ({ id, history }) => {
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [production, setProduction] = useState(productionUtils.initialValues());
  const [productionView, setProductionView] = useState();
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [confirmState, setConfirmState] = useState({
    open: false,
    onConfirm: null,
    title: "",
  });
  const dispatch = useDispatch();

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setProduction((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setProduction((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setProduction((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);

  const loadProduction = async (productionId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getProduction(productionId);
      const prd = productionUtils.mapToFormDataModel(mdl);
      setProduction(prd);
      setProductionView(mdl);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      console.error(error);
      setLoadState({ loading: false, error, loaded: false });
    }
  };

  //handle production
  const handleSaveProductionOrder = async () => {
    setSaving(true);
    try {
      const saveModel = productionUtils.mapToSaveModel(production);
      const mdl = await createProduction(saveModel);
      showInfo("Production order created successfully");
      history.push(`/production-order/${mdl.id}`);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateProductionOrder = async () => {
    setSaving(true);
    try {
      const saveModel = productionUtils.mapToSaveModel(production);
      const mdl = await editProduction(id, saveModel);
      setProduction(productionUtils.mapToFormDataModel(mdl));
      setProductionView(mdl);
      showInfo("Production order updated successfully");
    } finally {
      setSaving(false);
    }
  };

  const handleCompleteProductionOrder = async () => {
    async function confirmCompleteProductionOrder() {
      setLoadingComplete(true);
      try {
        const mdl = await completeProduction(id);
        setProduction(productionUtils.mapToFormDataModel(mdl));
        setProductionView(mdl);
        showInfo("Production order completed successfully");
      } finally {
        setLoadingComplete(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to COMPLETE the production order?",
      onConfirm: confirmCompleteProductionOrder,
    });
  };
  const handleStartProductionOrder = async () => {
    async function confirmStartProductionOrder() {
      setLoadingPlan(true);
      try {
        const mdl = await startProduction(id);
        setProduction(productionUtils.mapToFormDataModel(mdl));
        setProductionView(mdl);
        showInfo("Production order planned successfully");
      } finally {
        setLoadingPlan(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to PLAN the production order?",
      onConfirm: confirmStartProductionOrder,
    });
  };
  const handleCancelProductionOrder = async () => {
    async function confirmCancelProductionOrder() {
      setLoadingCancel(true);
      try {
        const mdl = await cancelProduction(id);
        setProduction(productionUtils.mapToFormDataModel(mdl));
        setProductionView(mdl);
        showInfo("Production order cancelled successfully");
      } finally {
        setLoadingCancel(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to CANCEL the production order?",
      onConfirm: confirmCancelProductionOrder,
    });
  };

  useEffect(() => {
    if (id) {
      loadProduction(id);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="my-5 px-4 pt-sm-5">
        {loadState.loading && <CustomSpinner />}
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Production Order could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && (
          <h4 className="text-primary">
            <Breadcrumb listClassName="p-0">
              <BreadcrumbItem>
                <Link to="/production-orders">Productions</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {id == null && <b>New Production Order</b>}
                {id != null && <b>Edit Production Order</b>}
              </BreadcrumbItem>
            </Breadcrumb>
          </h4>
        )}
        {productionView && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Production Number</h6>
                <Col sm={4}>
                  <h5>{productionView.productionNo}</h5>
                </Col>
                <h6 className="col-sm-2">Production Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.productionOrderStatus, productionView.orderStatus)}</h5>
                </Col>
                <h6 className="col-sm-2">Created</h6>
                <Col sm={4}>
                  <b>by </b>
                  {productionView.createUser}
                  <b> at </b>
                  <DateTimeOutput date={productionView.createdDate} />
                </Col>
                <h6 className="col-sm-2">Last Modified</h6>
                <Col sm={4}>
                  <b>by </b>
                  {productionView.updateUser}
                  <b> at </b>
                  <DateTimeOutput date={productionView.updateDate} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {loadState.loaded && (
          <>
            <Card className="shadow-lg">
              <CardBody>
                <div className="px-2 py-2">
                  <div className="row mb-4">
                    <Label htmlFor="date" className="col-sm-3 col-form-label">
                      Production Start Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        selected={production.productionStartDate}
                        placeholderText="Production Start Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("productionStartDate", date);
                        }}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="date" className="col-sm-3 col-form-label">
                      Production End Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        selected={production.productionEndDate}
                        placeholderText="Production End Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("productionEndDate", date);
                        }}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="deliveryBranch" className="col-sm-3 col-form-label">
                      Delivery To
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getBranches}
                        name="deliveryBranch"
                        value={production.deliveryBranch.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" htmlFor="autoSizingSelect">
                      Manufacturer
                    </Label>
                    <div className="col-sm-9 col-form-label">
                      <CreatableSelectBoxWithAction
                        action={() => dispatch(getManufacturersAction({ page: 0 }))}
                        reduxStateName="manufacturers"
                        name="manufacturerId"
                        value={production.manufacturerId}
                        handleChange={handleChange}
                        newItemDetails={{
                          formValues: {
                            values: manufacturerFormValues,
                            itemName: "Manufacturer",
                          },
                          createapi: createManufacturers,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>

            {id && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    {!loadingComplete && !loadingPlan && <ProductionProducts productionId={id} />}
                    {(loadingComplete || loadingPlan) && <CustomSpinner />}
                  </CardBody>
                </Card>

                <Card className="shadow-lg">
                  <CardBody className="">
                    <ProductionNotes productionId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <ProductionDocuments productionId={id} />
                  </CardBody>
                </Card>
              </>
            )}
            <br />
            <br />
            {(!id ||
              productionView.orderStatus == EnumValues.PRODUCTION_ORDER_STATUS.PLANNING ||
              productionView.orderStatus == EnumValues.PRODUCTION_ORDER_STATUS.IN_PRODUCTION) && (
              <Card className="shadow-lg nv-sticky-card mt-2">
                <CardBody className="">
                  <Row>
                    {id && (
                      <>
                        <Col>
                          {productionView.orderStatus == EnumValues.PRODUCTION_ORDER_STATUS.PLANNING && (
                            <>
                              <Button color="success" size="md" onClick={handleUpdateProductionOrder} disabled={saving}>
                                <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                color="success"
                                size="md"
                                onClick={handleStartProductionOrder}
                                disabled={loadingPlan}
                              >
                                {loadingPlan ? <Spinner color="white" size="sm" /> : "Plan Production Order"}
                              </Button>
                            </>
                          )}
                          {productionView.orderStatus == EnumValues.PRODUCTION_ORDER_STATUS.IN_PRODUCTION && (
                            <>
                              <Button
                                color="success"
                                size="md"
                                onClick={handleCompleteProductionOrder}
                                disabled={loadingComplete}
                              >
                                {loadingComplete ? <Spinner color="white" size="sm" /> : "Complete Production Order"}
                              </Button>
                            </>
                          )}
                          {(productionView.orderStatus == EnumValues.PRODUCTION_ORDER_STATUS.PLANNING ||
                            productionView.orderStatus == EnumValues.PRODUCTION_ORDER_STATUS.IN_PRODUCTION) && (
                            <>
                              &nbsp;&nbsp;
                              <Button
                                color="danger"
                                size="md"
                                onClick={handleCancelProductionOrder}
                                disabled={loadingCancel}
                              >
                                {loadingCancel ? <Spinner color="white" size="sm" /> : "Cancel"}
                              </Button>
                            </>
                          )}
                        </Col>
                      </>
                    )}
                    {!id && (
                      <Col>
                        <Button color="success" size="md" onClick={handleSaveProductionOrder}>
                          <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            )}
          </>
        )}
        {confirmState.open && (
          <SweetAlert
            title={confirmState.title}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setConfirmState({ open: false });
              confirmState.onConfirm();
            }}
            onCancel={() => {
              setConfirmState({ open: false });
            }}
          ></SweetAlert>
        )}
      </div>
    </React.Fragment>
  );
};

Production.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Production);
