import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { createProductTypes, deleteProductTypes, editProductTypes, getProductTypes } from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

export const productTypeFormValues = [
  { name: "name", label: "Name", size: 12, type: "text" },
  { name: "description", label: "Description", size: 12, type: "textarea" },
  { name: "status", label: "status", size: 12, type: "checkbox" },
];
const ProductTypes = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product Types</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getProductTypes,
            deleteapi: deleteProductTypes,
            createapi: createProductTypes,
            updateapi: editProductTypes,
          }}
          tableColumns={columns}
          formValues={{
            values: productTypeFormValues,
            validationSchema: {
              name: Yup.string().required(""),
              description: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{
            definitionName: "Product Type",
            title: "Product Type List",
          }}
          privilege={{
            create: Privileges.PRODUCT_TYPE.CREATE,
            update: Privileges.PRODUCT_TYPE.UPDATE,
            delete: Privileges.PRODUCT_TYPE.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProductTypes);
