import enums, { getEnumLabel } from "common/enums";
import CustomSpinner from "components/Common/CustomSpinner";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getAddress } from "store/definitions/services";

const CustomerAddressCard = ({ id }) => {
  const [customerAddressState, setCustomerAddressState] = useState({
    error: null,
    loading: false,
    address: {},
  });
  const { address } = customerAddressState;
  const loadAddress = async () => {
    setCustomerAddressState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const address = await getAddress(id);
      setCustomerAddressState((state) => ({
        ...state,
        address,
        loading: false,
      }));
    } catch (error) {
      setCustomerAddressState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadAddress();
  }, [id]);

  return (
    <Row>
      <Col md={12}>
        <Card className="shadow-lg">
          <CardBody>
            {customerAddressState.loading && <CustomSpinner />}
            {!customerAddressState.loading && (
              <>
                <Row className="py-1">
                  <h6 className="col-sm-2">Address</h6>
                  <Col sm={10}>
                    {address.address1} {address.address2}
                  </Col>
                  {address.town && (
                    <>
                      <h6 className="col-sm-2">Town</h6>
                      <Col sm={4}>{address.town}</Col>
                    </>
                  )}
                  {address.city && (
                    <>
                      <h6 className="col-sm-2">City</h6>
                      <Col sm={4}>{address.city}</Col>
                    </>
                  )}
                  {address.postCode && (
                    <>
                      <h6 className="col-sm-2">Post Code</h6>
                      <Col sm={4}>{address.postCode}</Col>
                    </>
                  )}

                  {address.floor != null && (
                    <>
                      <h6 className="col-sm-2">Floor</h6>
                      <Col sm={4} className="highlight">
                        {address.floor > 3 && <strong className="text-danger">{address.floor}</strong>}
                        {address.floor <= 3 && address.floor}
                      </Col>
                    </>
                  )}
                  {address.houseType && (
                    <>
                      <h6 className="col-sm-2">House Type</h6>
                      <Col sm={4}>{getEnumLabel(enums.houseTypes, address.houseType)}</Col>
                    </>
                  )}

                  {address.parkingDistance && (
                    <>
                      <h6 className="col-sm-2">Parking distance</h6>
                      <Col sm={4}>
                        {address.parkingDistance}
                        {address.parkingDistance && " m"}
                      </Col>
                    </>
                  )}
                  <h6 className="col-sm-2">Elevator</h6>
                  <Col sm={4}>{address.elevator == true ? "Yes" : "No"}</Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CustomerAddressCard;
