import TicketDetailCard from "pages/Ass/TicketDetailCard";
import DeliveryDetailCard from "pages/Delivery/DeliveryDetailCard";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { MetaTags } from "react-meta-tags";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import DeliveriesCard from "./DeliveriesCard";
import TicketsCard from "./TicketsCard";

function OperationCenter() {
  const [selectionContentVisible, setSelectionContentVisible] = useState(true);
  const [date, setDate] = useState(new Date());
  const [selectionState, setSelectionState] = useState({
    type: null,
    data: null,
  });
  const handleDeliverySelect = (delivery) => {
    setSelectionState({
      type: "DELIVERY",
      data: delivery,
    });
  };
  const handleOrderSelect = (order) => {
    setSelectionState({
      type: "ORDER",
      data: order,
    });
  };
  const handleTicketSelect = (ticket) => {
    setSelectionState({
      type: "TICKET",
      data: ticket,
    });
  };
  const handleDeliveriesUpdated = (deliveryIds) => {
    if (selectionState.type == "DELIVERY" && deliveryIds.find((id) => id == selectionState.data.id) != null) {
      setSelectionContentVisible(false);
      setTimeout(() => {
        setSelectionContentVisible(true);
      }, 10);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Operation Center</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">
                        <div className="d-flex justify-content-between">
                          <h4>Select Date</h4>
                        </div>
                      </CardTitle>
                      <ReactDatePicker inline selected={date} onChange={(date) => setDate(date)} />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">
                        <div className="d-flex justify-content-between">
                          <h4>Orders of planned deliveries</h4>
                        </div>
                      </CardTitle>
                      <DeliveriesCard
                        plannedDate={date}
                        onSelect={handleDeliverySelect}
                        onUpdate={handleDeliveriesUpdated}
                        selected={selectionState.type == "DELIVERY"}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">
                        <div className="d-flex justify-content-between">
                          <h4>Tickets</h4>
                        </div>
                      </CardTitle>
                      <TicketsCard
                        serviceDate={date}
                        onSelect={handleTicketSelect}
                        selected={selectionState.type == "TICKET"}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={8}>
              {selectionContentVisible && (
                <>
                  {selectionState.type == "DELIVERY" && (
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          <div className="d-flex justify-content-between">
                            <h4>Delivery Details</h4>
                          </div>
                        </CardTitle>
                        <Row>
                          <Col sm="12">
                            <DeliveryDetailCard id={selectionState.data.id} />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                  {selectionState.type == "TICKET" && (
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          <div className="d-flex justify-content-between">
                            <h4>Ticket Details</h4>
                          </div>
                        </CardTitle>
                        <Row>
                          <Col sm="12">
                            <TicketDetailCard id={selectionState.data.id} />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(OperationCenter);
