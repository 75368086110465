// import NumberFormat from 'react-number-format';

import NumberFormat from "react-number-format";

function NumberOutput({ value = 0 }) {
  const numVal = Number(value);
  let decimalScale = 2;
  if (!Number.isNaN(numVal)) {
    const s = `${numVal}`.split(".");
    if (s.length > 1) {
      decimalScale = s[1].length;
    }
  }
  if (decimalScale < 2) {
    decimalScale = 2;
  }

  return (
    <NumberFormat
      value={value || ""}
      displayType="text"
      thousandSeparator={true}
      fixedDecimalScale
      decimalScale={decimalScale}
    />
  );
}

export default NumberOutput;
