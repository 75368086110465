import CustomSpinner from "components/Common/CustomSpinner";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, ListGroup, Row } from "reactstrap";
import { getPreviousNext } from "store/order/services";
import OrderDetailCard from "./OrderDetailCard";

const OrderDetailPage = () => {
  const { id } = useParams();
  const [prevNext, setPrevNext] = useState({
    previous: null,
    next: null,
    proforma: false,
  });
  const [loading, setLoading] = useState(false);
  const { proforma } = prevNext;

  const loadPrevNext = async (id) => {
    setLoading(true);
    try {
      const data = await getPreviousNext(id);
      setPrevNext(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadPrevNext(id);
    }
  }, [id]);

  return (
    <>
      <MetaTags>
        <title>Novia{!loading && <>{proforma ? " - Proforma" : " - Order"} Detail</>}</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        {loading && <CustomSpinner />}
        {!loading && (
          <>
            <Row>
              <Col sm={6}>
                <h4 className="text-primary">
                  <Breadcrumb listClassName="p-0">
                    <BreadcrumbItem>
                      {!proforma && <Link to="/orders">Orders</Link>}
                      {proforma && <Link to="/proformas">Proformas</Link>}
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      {!proforma && <b>Order Detail</b>}
                      {proforma && <b>Proforma Detail</b>}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </h4>
              </Col>
              <Col sm={6} className="d-flex justify-content-end align-items-end pb-2">
                {(prevNext.previous || prevNext.next) && (
                  <ListGroup horizontal="sm" className="gap-2">
                    {prevNext.previous && (
                      <Link className="p-2x" to={`/order/${prevNext.previous}/detail`}>
                        Previous {proforma ? "Proforma" : "Order"}
                      </Link>
                    )}
                    {prevNext.previous && prevNext.next && "|"}
                    {prevNext.next && (
                      <Link className="p-2x" to={`/order/${prevNext.next}/detail`}>
                        Next {proforma ? "Proforma" : "Order"}
                      </Link>
                    )}
                  </ListGroup>
                )}
              </Col>
            </Row>
            <OrderDetailCard id={id} />
          </>
        )}
      </div>
    </>
  );
};

OrderDetailPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(OrderDetailPage);
