import moment from "moment";

class ProductionUtils {
  initialValues = () => ({
    id: null,
    status: "y",
    deliveryBranch: {},
    deliveryNotes: "",
    orderStatus: {},
    productionStartDate: new Date(),
    productionEndDate: new Date(),
    manufacturerId: "",
    deliveryAddressId: "",
  });
  mapToSaveModel = ({
    id,
    status = "y",
    deliveryBranch = {},
    deliveryNotes = "",
    orderStatus = {},
    productionStartDate,
    productionEndDate,
    manufacturerId,
  }) => ({
    id,
    status,
    deliveryBranchId: deliveryBranch?.value,
    deliveryNotes,
    orderStatus: orderStatus?.value,
    productionStartDate: productionStartDate && moment(productionStartDate).utcOffset(0, true).format(),
    productionEndDate: productionEndDate && moment(productionEndDate).utcOffset(0, true).format(),
    manufacturerId,
  });

  mapToFormDataModel = ({
    id,
    status,
    deliveryBranch,
    deliveryNotes,
    orderStatus,
    productionStartDate,
    productionEndDate,
    manufacturer,
  }) => ({
    id,
    status,
    deliveryBranch: {
      label: deliveryBranch?.name,
      value: deliveryBranch?.id,
    },
    deliveryNotes,
    orderStatus: {
      label: orderStatus,
      value: orderStatus,
    },
    productionStartDate: productionStartDate && moment(productionStartDate, "YYYY-MM-DD HH:mm:ss").toDate(),
    productionEndDate: productionEndDate && moment(productionEndDate, "YYYY-MM-DD HH:mm:ss").toDate(),
    manufacturerId: manufacturer?.id,
  });

  mapToProductAddModel = ({ id, status = "y", variant = {}, quantity, notes }) => ({
    id,
    status,
    variantId: variant?.id,
    quantity,
    notes,
  });

  mapToOrderedProductAddModel = ({ orderedProduct, notes }) => ({
    id: orderedProduct?.id,
    notes,
  });

  mapToNoteAddModel = ({ id, status = "y", notes }) => ({
    id,
    status,
    notes,
  });

  initialFilterValues = () => ({
    orderId: null,
    productionNo: null,
    productionStatus: null,
    branchId: null,
    manufacturerId: null,
    orderDateStart: null,
    orderDateEnd: null,
    deliveryDateStart: null,
    deliveryDateEnd: null,
  });

  mapToProductionSearch = (
    filter = {
      orderId: null,
      productionNo: null,
      productionStatus: null,
      branchId: null,
      manufacturerId: null,
      orderDateStart: null,
      orderDateEnd: null,
      deliveryDateStart: null,
      deliveryDateEnd: null,
    }
  ) => {
    return {
      productionNo: filter.productionNo,
      orderIds: filter.orderId ? [filter.orderId] : [],
      branchIds: filter.branchId ? [filter.branchId] : [],
      manufacturerIds: filter.manufacturerId ? [filter.manufacturerId] : [],
      statuses: filter.productionStatus ? [filter.productionStatus] : [],
      orderDateStart: filter.orderDateStart == null ? null : moment(filter.orderDateStart).format("YYYY-MM-DD"),
      orderDateEnd: filter.orderDateEnd == null ? null : moment(filter.orderDateEnd).format("YYYY-MM-DD"),
      estimateDeliveryDateStart:
        filter.deliveryDateStart == null ? null : moment(filter.deliveryDateStart).format("YYYY-MM-DD"),
      estimateDeliveryDateEnd:
        filter.deliveryDateEnd == null ? null : moment(filter.deliveryDateEnd).format("YYYY-MM-DD"),
    };
  };
}
const productionUtils = new ProductionUtils();
export default productionUtils;
