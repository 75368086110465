import { atom } from "recoil";
import orderUtils from "./order_utils";

export const ordersAppliedFilterState = atom({
  key: "Orders.appliedFilter",
  default: orderUtils.initialFilterValues(),
});
export const ordersAppliedSortState = atom({
  key: "Orders.appliedSort",
  default: { sortField: "orderId", sortOrder: "desc" },
});
