import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import {
  createProductTypes,
  createWebCategories,
  deleteProductTypes,
  deleteWebCategories,
  editProductTypes,
  editWebCategories,
  getProductTypes,
  getWebCategories,
} from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

const ProductTypes = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Web Categories</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getWebCategories,
            deleteapi: deleteWebCategories,
            createapi: createWebCategories,
            updateapi: editWebCategories,
          }}
          tableColumns={columns}
          formValues={{
            values: [
              { name: "name", label: "Web Category Name", size: 12, type: "text" },
              { name: "description", label: "Description", size: 12, type: "textarea" },
              { name: "status", label: "status", size: 12, type: "checkbox" },
            ],
            validationSchema: {
              name: Yup.string().required(""),
              description: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{ definitionName: "Web Category", title: "Web Categories List" }}
          privilege={{
            create: Privileges.WEB_CATEGORY.CREATE,
            update: Privileges.WEB_CATEGORY.UPDATE,
            delete: Privileges.WEB_CATEGORY.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProductTypes);
