import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { createColors, deleteColors, editColors, getColors, getMaterials } from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

export const colorFormValues = [
  { name: "materialId", label: "Material", size: 12, type: "select", getService: getMaterials },
  { name: "name", label: "Color Name", size: 12, type: "text" },
  { name: "description", label: "Description", size: 12, type: "textarea" },
  { name: "shortCode", label: "Short Code", size: 12, type: "text" },
  { name: "status", label: "status", size: 12, type: "checkbox" },
];

const Colors = () => {
  const columns = [
    {
      dataField: "materialId",
      text: "Material",
      sort: true,
      formatter: (cell, row) => row?.material?.name,
    },
    {
      dataField: "name",
      text: "Color Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "shortCode",
      text: "Short Code",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Colors</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: (...args) =>
              getColors(...args).then((data) => data.map((item) => ({ ...item, materialId: item.material?.id }))),
            deleteapi: deleteColors,
            createapi: createColors,
            updateapi: editColors,
          }}
          tableColumns={columns}
          hasStatus={false}
          formValues={{
            values: colorFormValues,
            validationSchema: {
              name: Yup.string().required(""),
              description: Yup.string(),
              shortCode: Yup.string().required(""),
            },
          }}
          pageDetails={{ definitionName: "Color", title: "Color List" }}
          privilege={{
            create: Privileges.COLOR.CREATE,
            update: Privileges.COLOR.UPDATE,
            delete: Privileges.COLOR.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Colors);
