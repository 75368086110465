import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo/logo.png";
import { setPassword } from "store/auth/login/services";

//Import config

const SetNewPassword = (props) => {
  const dispatch = useDispatch();
  const { credentials } = useSelector((state) => ({
    credentials: state.Login.credentials,
  }));
  const [apiDetails, setApiDetails] = useState({
    loading: false,
    success: null,
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("can't be empty"),
      confirmPassword: Yup.string()
        .required("can't be empty")
        .oneOf([Yup.ref("password"), null], "Passwords don't match!"),
    }),
    onSubmit: (values) => {
      setApiDetails({ loading: true, success: null });
      setPassword({
        code: credentials.code,
        oldPassword: credentials.old_pass,
        password: values.password,
      })
        .then((res) => {
          // console.log(res, "successfully set new password");
          setApiDetails({ loading: false, success: true });
          setTimeout(() => {
            props.history.push("/login");
          }, 3000);
        })
        .catch((err) => {
          setApiDetails({ loading: false, success: false });
        });
    },
  });

  useEffect(() => {
    if (credentials == null) {
      props.history.push("/login");
    }
  }, []);

  const error = "";

  return (
    <React.Fragment>
      <MetaTags>
        <title>Set New Password | Novia Furniture CRM</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Novia Furniture CRM</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-2">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded" height="18" />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <p className="text-primary">
                      <i className="fa fa-info fa-fw"></i>
                      For security purposes, please set your own password.
                    </p>
                  </div>
                  {apiDetails.success ? (
                    <div className="text-center">
                      <i className="fa fa-check fa-2x text-success"></i> <br />
                      You have successfully set your new password. You will be redirected to login page in 3 seconds..
                      Please login with your new password.
                    </div>
                  ) : (
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <Label className="form-label">New Password</Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={validation.touched.password && validation.errors.password ? true : false}
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Confirm New Password</Label>
                          <Input
                            name="confirmPassword"
                            value={validation.values.confirmPassword || ""}
                            type="password"
                            placeholder="Enter confirmPassword"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                            }
                          />
                          {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3 d-grid">
                          <button className="btn btn-primary btn-block" type="submit">
                            Set New Password
                          </button>
                        </div>
                      </Form>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SetNewPassword);

SetNewPassword.propTypes = {
  history: PropTypes.object,
};
