import enums, { getEnumLabel } from "common/enums";
import MoneyOutput from "components/Common/MoneyOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

const OrderHeaderCardView = ({ order }) => {
  const activeUser = useActiveUser();

  return (
    <Card className="shadow-lg">
      <CardBody>
        <Row className="py-1">
          <h6 className="col-sm-2">Order Number</h6>
          <Col sm={4}>
            <h5>
              {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (
                <Link to={`/order/${order.id}/detail`} title="View order detail">
                  {order.orderId}
                </Link>
              )}
              {!activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && order.orderId}
              {activeUser.hasPrivilege(Privileges.ORDER.UPDATE) && (
                <Link to={`/order/${order.id}`} className="p-2" title="Edit order">
                  <i className="fa fa-edit"></i>
                </Link>
              )}
            </h5>
          </Col>
          <h6 className="col-sm-2">Order Status</h6>
          <Col sm={4}>
            <h5>{getEnumLabel(enums.orderStatus, order.orderStatus)}</h5>
          </Col>
        </Row>
        <Row className="py-1">
          <h6 className="col-sm-2">Created By</h6>
          <Col sm={4}>{order.createUser}</Col>
          <h6 className="col-sm-2">Created At</h6>
          <Col sm={4}>{order.createdDate}</Col>
        </Row>
        <Row className="py-1">
          <h6 className="col-sm-2">Branch</h6>
          <Col sm={4}>{order.deliveryBranch?.name}</Col>
          <h6 className="col-sm-2">Sales Person</h6>
          <Col sm={4}>{order.salesPerson?.fullName}</Col>
        </Row>
        <Row className="py-1">
          {activeUser.hasPrivilege(Privileges.CUSTOMER.BALANCE_VIEW) && (
            <>
              <h6 className="col-sm-2">Balance</h6>
              <Col sm={4}>
                <h5>
                  <MoneyOutput value={order.balance} currency="GBP" />
                </h5>
              </Col>
            </>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default OrderHeaderCardView;
