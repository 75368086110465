import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { addDocumentToCustomer, deleteCustomerDocument, getCustomerDocuments } from "../../store/customer/services";
import ConfirmButton from "components/Common/ConfirmButton";
import UploadDocumentModal from "components/Common/UploadDocumentModal";
import enums, { getEnumLabel } from "common/enums";

const initialDocumentState = { open: false, error: null, loading: false, document: {} };
const CustomerDocuments = ({ customerId }) => {
  const [documentState, setDocumentState] = useState(initialDocumentState);
  const [documents, setDocuments] = useState({});
  const openDocumentAddPopup = () => {
    setDocumentState((prev) => ({ ...prev, document: {}, open: true }));
  };
  const handleUploadDocument = async ({ file, type }) => {
    const fd = new FormData();
    fd.append("file", file, file.path);
    return addDocumentToCustomer(customerId, fd, type);
  };
  const handleUploadSuccess = () => {
    loadDocuments(customerId);
  };
  const handleCloseDocument = () => {
    setDocumentState((prev) => ({ ...prev, open: false }));
  };
  const loadDocuments = async (customerId) => {
    const docs = await getCustomerDocuments(customerId);
    const docsMap = {};
    docs.forEach((doc) => {
      let curr = docsMap[doc.mediaType];
      if (curr == null) {
        curr = [];
        docsMap[doc.mediaType] = curr;
      }
      curr.push(doc);
    });
    setDocuments(docsMap);
  };
  const handleDeleteDocument = async ({ id }) => {
    await deleteCustomerDocument(customerId, id);
    loadDocuments(customerId);
  };
  useEffect(() => {
    loadDocuments(customerId);
  }, [customerId]);
  return (
    <>
      <h4 className="text-secondary">
        <b>Documents</b>
        <span style={{ float: "right" }}>
          <Button color="primary" size="sm" onClick={openDocumentAddPopup}>
            <i className="fa fa-plus"></i>Add Document
          </Button>
        </span>
      </h4>
      <br />
      {Object.keys(documents).map((mediaType) => (
        <li key={mediaType}>
          {getEnumLabel(enums.mediaTypes, mediaType)}
          {documents[mediaType].map((doc) => (
            <ul key={doc.id}>
              <span>
                <a href={doc.awsUrl} target="_blank">
                  {doc.fileName}
                </a>
                , <strong>Created At</strong> {doc.createdDate}
                &nbsp;&nbsp;&nbsp;
                <ConfirmButton
                  size="sm"
                  color="danger"
                  onConfirm={() => handleDeleteDocument(doc)}
                  title="Are you sure you want to delete the document?"
                >
                  <i className="fa fa-trash"></i>
                </ConfirmButton>
              </span>
            </ul>
          ))}
        </li>
      ))}
      {documentState.open && (
        <UploadDocumentModal
          onClose={handleCloseDocument}
          onUpload={handleUploadDocument}
          onUploadSuccess={handleUploadSuccess}
          showSelectMedia={false}
        />
      )}
    </>
  );
};

export default CustomerDocuments;
