import enums, { EnumValues, getEnumLabel } from "common/enums";
import CustomPaginate from "components/Common/CustomPaginate";
import CustomSpinner from "components/Common/CustomSpinner";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { showInfo } from "helpers/utils";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import Privileges from "models/Privileges";
import { useEffect, useState } from "react";
import { Button, Label, ListGroup, ListGroupItem, ListGroupItemText } from "reactstrap";
import { getUserListContent } from "store/definitions/services";
import { getDeliveries, updateUsers } from "store/delivery/services";
import operationCenterUtils from "./operationCenter_utils";

function DeliveriesCard({ plannedDate, onSelect, onUpdate, selected }) {
  const activeUser = useActiveUser();
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deliveries, setDeliveries] = useState([]);
  const [appliedSort, setAppliedSort] = useState({ sortField: "order.orderId", sortOrder: "desc" });
  const [appliedFilter, setAppliedFilter] = useState(operationCenterUtils.initialDeliveryFilterValues(plannedDate));
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging(null, 10);
  const [activeDelivery, setActiveDelivery] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handlePageChange = (type, details) => {
    const { page, sizePerPage } = details;
    loadDeliveries(setPageNumber(page, sizePerPage), appliedFilter, appliedSort);
  };

  const handleSelect = (delivery) => {
    setActiveDelivery(delivery);
    onSelect(delivery);
  };
  const loadDeliveries = async (page, filter, sort) => {
    setLoading(true);
    try {
      const {
        content,
        page: { totalElements },
      } = await getDeliveries(page, operationCenterUtils.mapToDeliverySearch(filter), sort);
      setTotalSize(totalElements);
      setDeliveries(content);
      setSelectedDeliveries([]);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDeliveryUsers = async () => {
    await updateUsers({
      deliveryIds: selectedDeliveries,
      userIds: selectedUsers,
    });
    showInfo("Selected users assigned to the selected deliveries succesfully");
    onUpdate && onUpdate(selectedDeliveries);
  };

  useEffect(() => {
    const filter = { plannedDate };
    setAppliedFilter(filter);
    loadDeliveries(pageOptions, filter, appliedSort);
  }, [plannedDate]);
  return (
    <>
      {loading && <CustomSpinner />}
      {!loading && deliveries.length == 0 && <div>No planned delivery found</div>}
      {!loading && deliveries.length > 0 && (
        <>
          <ListGroup flush>
            {deliveries.map((item) => {
              const active = activeDelivery?.id == item.id && selected;
              const ListGroupItemWrapper = active ? ListGroupItemText : ListGroupItemText;
              return (
                <div className="row p-1" key={item.id}>
                  <div className="col-12 col-md-11">
                    <ListGroupItem
                      action
                      tag="button"
                      style={{ backgroundColor: active ? "var(--bs-gray-400)" : "" }}
                      onClick={() => handleSelect(item)}
                    >
                      <ListGroupItemWrapper>
                        The order <b>{item.order?.orderId}</b> belongs to <b> {item.order?.customer.fullName}</b>, is in{" "}
                        <b>{getEnumLabel(enums.deliveryStatus, item.deliveryStatus)}</b> status with delivery number{" "}
                        <b>{item.no}</b>.
                      </ListGroupItemWrapper>
                    </ListGroupItem>
                  </div>
                  <div className="col-12 col-md-1 d-flex justify-content-center justify-content-md-start">
                    {activeUser.hasPrivilege(Privileges.DELIVERY.UPDATE) &&
                      (item.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNING ||
                        item.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNED) && (
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            if (!e.target.checked) {
                              setSelectedDeliveries((prev) => [...prev, item.id]);
                            } else {
                              setSelectedDeliveries((prev) => {
                                prev.splice(
                                  prev.findIndex((i) => i.id == item.id),
                                  1
                                );
                                return [...prev];
                              });
                            }
                          }}
                          checked={selectedDeliveries.includes(item.id)}
                          onChange={() => {}}
                        />
                      )}
                  </div>
                </div>
              );
            })}
          </ListGroup>
          {selectedDeliveries.length > 0 && (
            <>
              <div className="row p-2">
                <Label className="col-sm-3 col-form-label" htmlFor="autoSizingSelect">
                  Users
                </Label>
                <div className="col-9">
                  <MultiselectWithService
                    service={getUserListContent}
                    name="Users"
                    isMulti={true}
                    setValue={(name, value) => {
                      setSelectedUsers(value);
                    }}
                    labelField="fullName"
                  />
                </div>
              </div>
              <Button disabled={selectedUsers.length == 0} onClick={handleUpdateDeliveryUsers}>
                Assign User
              </Button>
            </>
          )}

          <CustomPaginate
            containerClassName="pt-3"
            sizePerPage={pageOptions.sizePerPage}
            totalSize={pageOptions.totalSize}
            onPageChange={handlePageChange}
            page={pageOptions.page}
          />
        </>
      )}
    </>
  );
}

export default DeliveriesCard;
