import enums, { EnumValues, getEnumLabel } from "common/enums";
import { Badge } from "reactstrap";

const ProformaCancelStatusLabel = ({ status }) => {
  if (
    status == EnumValues.PROFORMA_CANCEL_STATUS.SOLD ||
    status == EnumValues.PROFORMA_CANCEL_STATUS.SOLD_FROM_OTHER_BRANCH
  ) {
    return (
      <Badge color="success" style={{ padding: "7px" }} pill>
        {getEnumLabel(enums.proformaCancelStatus, status)}
      </Badge>
    );
  }
  if (
    status == EnumValues.PROFORMA_CANCEL_STATUS.NOT_INTERESTED ||
    status == EnumValues.PROFORMA_CANCEL_STATUS.NO_REPLY ||
    status == EnumValues.PROFORMA_CANCEL_STATUS.ANOTHER_COMPANY
  ) {
    return (
      <Badge color="danger" style={{ padding: "7px" }} pill>
        {getEnumLabel(enums.proformaCancelStatus, status)}
      </Badge>
    );
  }
  return (
    <Badge style={{ padding: "7px" }} pill>
      {getEnumLabel(enums.proformaCancelStatus, status)}
    </Badge>
  );
};

export default ProformaCancelStatusLabel;
