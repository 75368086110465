import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Alert, CardBody, Button, Label, Input, FormFeedback, Form } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/user.png";
// actions
import { useActiveUser } from "hooks";
import { changePassword } from "store/auth/services";
import { toast } from "react-toastify";

const UserProfile = (props) => {
  const activeUser = useActiveUser();
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  // const validation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     username: activeUser.name || "",
  //   },
  //   validationSchema: Yup.object({
  //     username: Yup.string().required("Please Enter Your UserName"),
  //   }),
  //   onSubmit: (values) => {
  //     dispatch(editProfile(values));
  //   },
  // });
  const passwordValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      password: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Please Enter Your Current Passord"),
      password: Yup.string().required("Please Enter Your New Passord").min(6, "Password must be at least 6 character"),
    }),
    onSubmit: async (values) => {
      console.log("passwordValidation", values);
      await changePassword(values);
      passwordValidation.resetForm();
      toast.success("Your password changed successfully");
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Novia Furniture CRM</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Novia" breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{activeUser.name}</h5>
                        <p className="mb-1">{activeUser.email}</p>
                        <p className="mb-0">Id no: #{activeUser.id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <h4 className="card-title mb-4">Change User Name</h4>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={validation.touched.username && validation.errors.username ? true : false}
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                  ) : null}
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}
          <h4 className="card-title mb-4">Change Password</h4>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  passwordValidation.handleSubmit();
                  return false;
                }}
              >
                <div>
                  <div className="row mb-2">
                    <Label className="col-12">Current Password</Label>
                    <Col sm={12}>
                      <Input
                        name="oldPassword"
                        // value={name}
                        className="form-control"
                        placeholder="Enter Current Password"
                        type="password"
                        onChange={passwordValidation.handleChange}
                        onBlur={passwordValidation.handleBlur}
                        value={passwordValidation.values.oldPassword || ""}
                        invalid={
                          passwordValidation.touched.oldPassword && passwordValidation.errors.oldPassword ? true : false
                        }
                      />
                      {passwordValidation.touched.oldPassword && passwordValidation.errors.oldPassword ? (
                        <FormFeedback type="invalid">{passwordValidation.errors.oldPassword}</FormFeedback>
                      ) : null}
                    </Col>
                  </div>
                  <div className="row">
                    <Label className="col-12">New Password</Label>
                    <Col sm={12}>
                      <Input
                        name="password"
                        // value={name}
                        className="form-control"
                        placeholder="Enter New Password"
                        type="password"
                        onChange={passwordValidation.handleChange}
                        onBlur={passwordValidation.handleBlur}
                        value={passwordValidation.values.password || ""}
                        invalid={
                          passwordValidation.touched.password && passwordValidation.errors.password ? true : false
                        }
                      />
                      {passwordValidation.touched.password && passwordValidation.errors.password ? (
                        <FormFeedback type="invalid">{passwordValidation.errors.password}</FormFeedback>
                      ) : null}
                    </Col>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Change Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
