import React, { useEffect, useState } from "react";
import "./ScrollToTop.css";

const scrollToTopFnc = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
function ScrollToTop() {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      setScrolled(window.scrollY > 50);
    });
  }, []);
  return (
    <button
      className={`btn btn-warning btn-block btn-scroll-to-top ${scrolled ? "" : "hidden"}`}
      onClick={scrollToTopFnc}
    >
      <i className="bx bx-chevron-up icon-scroll-to-top"></i>
    </button>
  );
}

export default ScrollToTop;
