import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Button, Col, Form, Label, Row } from "reactstrap";
import { getUserListContent } from "store/definitions/services";
import { getStores } from "store/store/services";

const DashboardFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="branchId" className="col-sm-3 col-form-label">
                Branch
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getStores}
                  name="branchId"
                  value={filter.branchId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="salesPersonId" className="col-sm-3 col-form-label">
                Sales Person
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getUserListContent}
                  name="salesPersonId"
                  value={filter.salesPersonId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                  labelField="fullName"
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="startDate" className="col-sm-3 col-form-label">
                Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.startDate}
                      placeholderText="Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("startDate", date);
                      }}
                    />
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.endDate}
                      placeholderText="End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("endDate", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default DashboardFilterForm;
