import moment from "moment";

class ProductReportUtils {
  initialFilterValues = () => ({
    orderId: null,
    productionOrderId: null,
    productionNumber: null,
    customerId: null,
    orderStatus: null,
    createDateStart: null,
    createDateEnd: null,
    warehouseId: null,
    storeId: null,
    orderType: null,
    productionStatus: null,
    shipmentStatus: null,
    orderedProductStatus: null,
    orderedProductStatus: null,
    manufacturerId: null,
    transferId: null,
    deliveryId: null,
    quantity: null,
    sku: null,
  });

  mapToOrderSearch = (
    filter = {
      orderId: null,
      productionOrderId: null,
      productionNumber: null,
      customerId: null,
      orderStatus: null,
      createDateStart: null,
      createDateEnd: null,
      warehouseId: null,
      storeId: null,
      orderType: null,
      productionStatus: null,
      shipmentStatus: null,
      orderedProductStatus: null,
      manufacturerId: null,
      transferId: null,
      deliveryId: null,
      quantity: null,
      sku: null,
    }
  ) => {
    const branchIds = [];
    if (filter.warehouseId) {
      branchIds.push(filter.warehouseId);
    }
    if (filter.storeId) {
      branchIds.push(filter.storeId);
    }
    return {
      manufacturerId: filter.manufacturerId,
      transferId: filter.transferId,
      deliveryId: filter.deliveryId,
      quantity: filter.quantity,
      sku: filter.sku,
      productionNumbers: filter.productionNumber != null ? [filter.productionNumber] : [],
      productionOrderIds: filter.productionOrderId != null ? [filter.productionOrderId] : [],
      orderedProductStatuses: filter.orderedProductStatus != null ? [filter.orderedProductStatus] : [],
      shipmentStatuses: filter.shipmentStatus != null ? [filter.shipmentStatus] : [],
      productionStatuses: filter.productionStatus != null ? [filter.productionStatus] : [],
      orderTypes: filter.orderType != null ? [filter.orderType] : [],
      orderIds: filter.orderId != null ? [filter.orderId] : [],
      customerIds: filter.customerId != null ? [filter.customerId] : [],
      salesPersonIds: filter.salesPersonId != null ? [filter.salesPersonId] : [],
      statuses: filter.orderStatus != null ? [filter.orderStatus] : [],
      branchIds: branchIds,
      addStartDate: filter.createDateStart != null ? moment(filter.createDateStart).format("YYYY-MM-DD") : "",
      addEndDate: filter.createDateEnd != null ? moment(filter.createDateEnd).format("YYYY-MM-DD") : "",
    };
  };
}
const productReportUtils = new ProductReportUtils();
export default productReportUtils;
