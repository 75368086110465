import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./UploadDocumentModal.scss";
import { Col, Modal, ModalBody, Row, Button, ModalHeader, ModalFooter, Spinner, List } from "reactstrap";
import { showInfo } from "helpers/utils";
import { getMediaTypes } from "store/definitions/services";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";

const anonymousFunc = () => Promise.resolve();

const UploadDocumentModal = ({
  onClose = anonymousFunc,
  onUpload = anonymousFunc,
  onUploadSuccess = anonymousFunc,
  showSelectMedia,
  title = "Upload Document",
}) => {
  const [files, setFiles] = useState([]);
  const [mediaTypes, setMediaTypes] = useState([]);

  const handCancelUpload = async () => {
    const notUploadeds = files.filter(({ uploaded, uploading }) => !uploaded && !uploading);
    if (notUploadeds.length > 0) {
      notUploadeds.forEach((item) => (item.cancelled = true));
      setFiles((prev) => [...prev]);
    }
  };

  const handleSetMediaType = (file, mediaType) => {
    file.mediaType = mediaType;
    setFiles((prevValues) => [...prevValues]);
  };

  const handleUploadFiles = async () => {
    let successCount = 0;
    for (let index = 0; index < files.length; index++) {
      const item = files[index];
      if (item.uploaded || item.cancelled) {
        continue;
      }
      item.error = null;
      if (showSelectMedia && !item.mediaType) {
        item.error = new Error("Please select media type");
        setFiles((prev) => [...prev]);
        continue;
      }
      try {
        item.uploading = true;
        setFiles((prev) => [...prev]);
        await onUpload({ file: item.file, type: item.mediaType });
        item.uploaded = true;
        item.uploading = false;
        setFiles((prev) => [...prev]);
        successCount += 1;
      } catch (error) {
        item.uploading = false;
        item.error = error;
        setFiles((prev) => [...prev]);
        console.error(error);
      }
    }
    if (successCount > 0) {
      showInfo(`${successCount} document${successCount > 1 ? "s" : ""} uploaded successfully`);
      onUploadSuccess();
      if (files.filter((f) => f.error).length == 0) {
        onClose();
      }
    }
  };

  useEffect(async () => {
    const mt = await getMediaTypes();
    setMediaTypes(mt);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  useEffect(() => {
    setFiles(
      acceptedFiles.map((file) => {
        const data = {
          file,
          uploading: false,
          cancelled: false,
          uploaded: false,
          error: null,
        };
        return data;
      })
    );
  }, [acceptedFiles]);

  const formUploading = files.find((item) => item.uploading) != null;
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody className="py-3 px-5">
        <Row className="mt-3">
          <Col>
            <div {...getRootProps({ className: "documentDropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            {files?.length > 0 && (
              <Row className="mt-3">
                <Col md={12}>
                  <br />
                  <List>
                    {files.map((item) => {
                      const { file, uploading, uploaded, cancelled, error, mediaType } = item;
                      return (
                        <li key={file.path} style={{ textDecoration: cancelled ? "line-through" : "" }}>
                          {file.path} &nbsp;
                          {uploaded && <i className="fas fa-check-circle text-success"></i>}
                          {uploading && <Spinner size="sm" />}
                          {error && <i className="fas fa-exclamation-triangle text-danger"> {error?.message}</i>}
                          {showSelectMedia && (
                            <div className="pt-2">
                              <MultiselectWithService
                                service={() => Promise.resolve(mediaTypes)}
                                name="mediaType"
                                value={mediaType}
                                isMulti={false}
                                placeholder="Select Media Type.."
                                setValue={(name, value) => handleSetMediaType(item, value)}
                                isDisabled={uploading}
                              />
                              <hr />
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </List>
                  <Button color="success" onClick={handleUploadFiles} disabled={formUploading}>
                    {!formUploading && "Upload"}
                    {formUploading && (
                      <>
                        Uploading.. <Spinner size="sm" />
                      </>
                    )}
                  </Button>
                  &nbsp;
                  <Button color="danger" onClick={handCancelUpload}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};
export default UploadDocumentModal;
